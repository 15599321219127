import { observer } from "mobx-react-lite";
import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { Button, Checkbox, Container, Box, Stack, Typography, FormControlLabel } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import Card from '@mui/material/Card';
import { Link } from 'react-router-dom';
import ArrowCircleLeftRoundedIcon from '@mui/icons-material/ArrowCircleLeftRounded';
import LoadingButton from '@mui/lab/LoadingButton';
import FormGroup from '@mui/material/FormGroup';
import { useStore } from '../../stores/store';
import { Select } from '@mui/material';
import ShiftSetupModel from "../../models/ShiftSetup";
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from "dayjs";

export default observer(function AddNewShift() {
    const { setupsStore, userStore } = useStore();
    const { loading, getCompanyRoles, loadCompanyRoles } = userStore;
    const cono = window.localStorage.getItem('cono');
    let startdefault = new Date();
    let enddefault = new Date();
    useEffect(() => {
        startdefault.setHours(6,0,0,0);
        enddefault.setHours(14,0,0,0);
        loadCompanyRoles();
    }, []);

    const formik = useFormik({
        initialValues: new ShiftSetupModel(),
        // validateOnChange: false,
        // validateOnBlur: true,
        onSubmit: values => {
            formik.setFieldValue('cono', cono);
            setupsStore.createNewCompanyShift(values, cono || '').then(() => {
                handleReset();
            })
        }
    });

    const handleReset = () => {
        console.log('try to reset');
        formik.resetForm({
            values: {
                cono: cono || '', role: '', roleid: -1, shiftid: -1, shiftstarttime: startdefault, shiftendtime: enddefault, shiftendsnextday: false,
                descr: '', row: 0, shiftstarttimestring: '', shiftendtimestring: ''
            },
        });
    };

    return (
        <Container component="main" maxWidth="sm" sx={{ paddingBottom: 10 }}>
            <Card sx={{ margin: 2, paddingLeft: 1, paddingRight: 1 }} raised>
                <Box
                    sx={{
                        marginTop: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Stack direction="row" justifyContent="left" alignItems="center">
                        <Button component={Link} to="/shiftsetup"><ArrowCircleLeftRoundedIcon fontSize="large" /></Button>
                        <Typography component="h1" variant="h5">
                            <span>Create New Shift</span>
                        </Typography>
                    </Stack>
                    <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                        <InputLabel id="demo-simple-select-autowidth-label" sx={{ marginTop: 2, textAlign: 'left' }}>Role</InputLabel>
                        <Select
                            name="roleid"
                            value={formik.values.roleid}
                            onChange={formik.handleChange}
                            displayEmpty
                            style={{ display: "block" }}
                        >
                            {getCompanyRoles.map((role) => (
                                <MenuItem key={role.roleid} value={role.roleid}>{role.role}</MenuItem>
                            ))}
                        </Select>
                        <InputLabel id="demo-simple-select-autowidth-label" sx={{ marginTop: 2, textAlign: 'left' }}></InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                                minutesStep={15}
                                label="Shift Start Time"
                                value={dayjs(formik.values.shiftstarttime)}
                                onChange={(newValue) => {
                                    formik.setFieldValue("shiftstarttime", dayjs(newValue));
                                }}
                            />
                        </LocalizationProvider>
                        <InputLabel id="demo-simple-select-autowidth-label" sx={{ marginTop: 2, textAlign: 'left' }}></InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                                minutesStep={15}
                                label="Shift End Time"
                                value={dayjs(formik.values.shiftendtime)}
                                onChange={(newValue) => {
                                    formik.setFieldValue("shiftendtime", dayjs(newValue));
                                }}
                            />
                        </LocalizationProvider>
                        <FormGroup sx={{ marginTop: 2, textAlign: 'left' }}>
                            <FormControlLabel control={<Checkbox name="shiftendsnextday" checked={formik.values.shiftendsnextday} onChange={formik.handleChange} inputProps={{ 'aria-label': 'controlled' }} />} label="Shift Ends Next Day" />
                        </FormGroup>
                        <FormGroup>
                            <InputLabel id="demo-simple-select-autowidth-label" sx={{ marginTop: 2, textAlign: 'left' }}>Description</InputLabel>
                            <TextField
                                name="descr"
                                value={formik.values.descr}
                                onChange={formik.handleChange} />
                        </FormGroup>
                        <LoadingButton
                            sx={{ marginTop: 2, marginBottom: 2,borderRadius: 5 }}
                            variant="contained"
                            color="primary"
                            onClick={formik.submitForm}
                            loading={false}>
                            Create Shift
                        </LoadingButton>
                    </form>
                </Box>
            </Card>
        </Container>
    )
})