import React from 'react';
import Container from '@mui/material/Container';
import { Box } from '@mui/system';
import InActiveUsersDataGrid from './InActiveUsersDataGrid';

export default function InactiveUsersMain() {
    return (
        <Container sx={{paddingBottom: 40}}>
            <Box sx={{paddingBottom: 40, marginBottom:20}}>
                <InActiveUsersDataGrid />
            </Box>
        </Container>
    );
}