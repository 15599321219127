import React, { useEffect } from 'react';
import { observer } from "mobx-react-lite";
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControl, Grid, IconButton, InputLabel, LinearProgress, List, ListItem, ListItemText, MenuItem, Select, SelectChangeEvent, Stack, Typography } from '@mui/material';
import { useStore } from '../../stores/store';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import { format } from 'date-fns';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { NewShiftInRotation } from '../../models/Schedule/NewShiftInRotation';
import { LoadingButton } from '@mui/lab';
import DeleteIcon from '@mui/icons-material/Delete';
import * as Yup from "yup";

interface SelectRoleDialogProps {
    open: boolean;
    name: string;
    username: string;
    shiftdate: Date;
    scheduleid: number;
    roleid: number;
    role: string;
    onClose: (value: boolean) => void;
    isEditing: boolean;
    unitobject: string;
    shiftobject: string;
    shiftstarttime: Date;
    shiftendtime: Date;
}
interface Rotation {
    dow: string;
    shiftid: number;
    starttime: Date;
    endTime: Date;
    shiftendsnextday: boolean;
    partialshift: boolean;
    descr: string;
}

interface FormValues {
    shiftDate: string;
    selectedrole: string;
    selectedshift: string;
    startTime: Date;
    endTime: Date;
}
const ValidSchema = Yup.object().shape({
    selectedshift: Yup.string().required("Shift Is Required"),
});

export default observer(function AddNewShiftToScheduleDialog(props: SelectRoleDialogProps) {
    const { shiftStore, scheduleStore } = useStore();
    const { loadShiftSetupsForRole, getShiftSetupsForRole } = shiftStore;
    const { loadUsersForRoleAndDate, putNewShiftInRotation, loadingDetails,
        loadUnitSetups, loading, getUnitSetups, removeShiftFromSchedule,putEditUserShiftInSchedule } = scheduleStore;
    const { onClose, open } = props;
    const [selectedroleobject, setSelectedRoleObject] = React.useState('');
    const [selectedshiftobject, setSelectedShiftObject] = React.useState('');
    const [selectedunitobject, setSelectedUnitObject] = React.useState('');

    const [originalStartTime, setOriginalStartTime] = React.useState(new Date()); //used to see if we are doing a partial shift
    const [originalEndTime, setOriginalEndTime] = React.useState(new Date());
    const [error, setError] = React.useState('');
    const [loadingEdit, setLoadingEdit] = React.useState(true);
    const [areYouSureDeleteShiftOpen, setAreYouSureDeleteShiftOpen] = React.useState(false);
    const [shiftSelectedError, setShiftSelectedError] = React.useState(false);

    useEffect(() => {
        if (props.open) {
            if (props.isEditing) { //if we are editing, then we want to set the form to show the loading until all the data is loaded and filled in
                setLoadingEdit(true);
            }
            loadShiftSetupsForRole(props.roleid).then(() => {
                console.log('is editing' + props.isEditing + ' and ' + props.shiftobject);

                if (props.shiftobject != '') {
                    setSelectedShiftObject(props.shiftobject);
                    formik.values.startTime = new Date(props.shiftstarttime);
                    formik.values.endTime = new Date(props.shiftendtime);
                }
                setLoadingEdit(false);
            });
            loadUnitSetups().then(() => {
                if (props.unitobject != '') {
                    setSelectedUnitObject(props.unitobject);
                }
                setLoadingEdit(false);
            });
            setShiftSelectedError(false);
        }

    }, [props.username, props.shiftobject, props.unitobject, props.shiftdate]);

    const handleClickDeleteShiftNo = () => {
        setAreYouSureDeleteShiftOpen(false);
    }

    const handleClickDeleteShiftYes = () => {
        let cono = window.localStorage.getItem('cono');
        if (!cono) {
            return;
        }
        let shift = {
            cono: cono, username: props.username, scheduleid: props.scheduleid
        };

        if (shift.scheduleid > -1) {
            removeShiftFromSchedule(shift).then(() => {
                setAreYouSureDeleteShiftOpen(false);
                setSelectedShiftObject('');
                setSelectedUnitObject('');
                formik.resetForm();
                onClose(true);
            })
        }
    }

    const handleClickRemoveShift = () => {
        setAreYouSureDeleteShiftOpen(true);
    }

    const handleUnitChange = (event: SelectChangeEvent<string>) => {
        setSelectedUnitObject(event.target.value.toString());
        let unit = JSON.parse(event.target.value.toString());

        if (unit != null && unit.unitid > -1) {
            formik.handleChange(event.target.value.toString());
        }
    }

    const handleShiftChange = (event: SelectChangeEvent<string>) => {
        console.log(event.target.value.toString());
        setSelectedShiftObject(event.target.value.toString());
        console.log(selectedshiftobject);
        let shift = JSON.parse(event.target.value.toString());
        if (shift != null && shift.shiftid > -1) {
            //loadShiftSetupsForRole(selectedRole);

            getShiftSetupsForRole.forEach((s) => {
                if (s.shiftid == shift.shiftid) {
                    formik.values.startTime = new Date(s.shiftstarttime);
                    formik.values.endTime = new Date(s.shiftendtime);
                    setOriginalStartTime(s.shiftstarttime);
                    setOriginalEndTime(s.shiftendtime);
                }
            })
            formik.handleChange(event.target.value.toString());

            setShiftSelectedError(false);
        }
    }

    const handleClose = () => {
        setSelectedShiftObject('');
        setSelectedUnitObject('');
        formik.resetForm();
        onClose(false);
    };

    function areDatesEqual(date1: Date, date2: Date): boolean {
        // Convert both dates to the same timezone (e.g., UTC)
        const utcDate1 = Date.UTC(
            date1.getFullYear(),
            date1.getMonth(),
            date1.getDate(),
            date1.getHours(),
            date1.getMinutes(),
            date1.getSeconds()
        );
        const utcDate2 = Date.UTC(
            date2.getFullYear(),
            date2.getMonth(),
            date2.getDate(),
            date2.getHours(),
            date2.getMinutes(),
            date2.getSeconds()
        );

        // Compare the UTC timestamps
        return utcDate1 === utcDate2;
    }

    function doesShiftEndNextDay(date1: Date, date2: Date): boolean {
        // Convert both dates to the same timezone (e.g., UTC)
        if (date2.getHours() < date1.getHours()) {
            return true;
        }
        else {
            return false;
        }
    }

    const handleSaveShiftClick = () => {
        let cono1 = window.localStorage.getItem('cono');
        if (!cono1) {
            return;
        }
        let shift = JSON.parse(selectedshiftobject);
        let unit = { unitid: -1, unitname: '' };
        if (selectedunitobject != '') {
            unit = JSON.parse(selectedunitobject);
        }
        let rot = {
            cono: cono1, roleid: props.roleid, username: props.username,
            shiftid: shift.shiftid, shiftstarttime: formik.values.startTime, shiftendtime: formik.values.endTime, shiftendsnextday: false, partialshift: false, descr: '',
            shiftstarttimestring: '', shiftendtimestring: '', unitid: unit.unitid
        };
        rot.shiftstarttimestring = new Date(formik.values.startTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        rot.shiftendtimestring = new Date(formik.values.endTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        if (areDatesEqual(new Date(originalStartTime), new Date(formik.values.startTime)) && areDatesEqual(new Date(originalEndTime), new Date(formik.values.endTime))) {
            rot.partialshift = false;
        }
        else {
            rot.partialshift = true;
        }
        rot.shiftendsnextday = doesShiftEndNextDay(new Date(formik.values.startTime), new Date(formik.values.endTime));

        console.log(rot);
        // putNewShiftInRotation(rot).then(() => {
        //     setSelectedShiftObject('');
        //     formik.resetForm();
        //     onClose(true);
        // })
    }

    const formik = useFormik({
        initialValues: {
            shiftDate: new Date().toISOString().split('T')[0],
            selectedroleobject: '',
            selectedshiftobject: '',
            selectedunitobject: '',
            startTime: new Date(),
            endTime: new Date(),
        },
        // validationSchema: ValidSchema,
        // validateOnBlur: true,
        onSubmit: (values) => {
            if (!props.isEditing) {
                setError('');
                let co = window.localStorage.getItem('cono');
                if (!co) {
                    return;
                }
                if(selectedshiftobject.trim() === ""){
                    setShiftSelectedError(true);
                    return;
                }
                let shift = JSON.parse(selectedshiftobject);
                let unit = JSON.parse(selectedunitobject);
                let usernames = [];
                usernames.push(props.username);
                const shiftdatewithouttime = new Date(props.shiftdate.getFullYear(), props.shiftdate.getMonth(), props.shiftdate.getDate());
                const endshiftdate = new Date(props.shiftdate.getFullYear(), props.shiftdate.getMonth(), props.shiftdate.getDate());
                if(shift.shiftendsnextday){
                    endshiftdate.setDate(endshiftdate.getDate() + 1);
                }
                let user = {
                    cono: co, roleid: props.roleid, shiftdate: shiftdatewithouttime,
                    shiftstarttime: new Date(new Date(shiftdatewithouttime).setHours(new Date(values.startTime).getHours(), new Date(values.startTime).getMinutes(), 0, 0)).toLocaleString(),
                    shiftendtime: new Date(new Date(endshiftdate).setHours(new Date(values.endTime).getHours(), new Date(values.endTime).getMinutes(), 0, 0)).toLocaleString(),
                    shiftid: shift.shiftid, shiftendsnextday: shift.shiftendsnextday, partialshift: false,
                    usernames: usernames, unitid: unit.unitid
                }
                scheduleStore.putAddUserShiftsToSchedule(user).then(() => {
                    formik.resetForm();
                    onClose(true);
                })
            }
            else { //we are editing. 
                if (props.scheduleid == -1) { //we are editting a FOS shift
                    console.log('save for editing a fos shift');

                }
                else { //we are editting a normal scheduled shift in the "Schedule" table
                    console.group('save for edit a schduled shift');
                    let co = window.localStorage.getItem('cono');
                if (!co) {
                    return;
                }
                let shift = JSON.parse(selectedshiftobject);
                console.log('shift' + shift);
                let unit = JSON.parse(selectedunitobject);
                console.log('unit ' + unit);
                let usernames = [];
                usernames.push(props.username);
                const shiftdatewithouttime = new Date(props.shiftdate.getFullYear(), props.shiftdate.getMonth(), props.shiftdate.getDate());
                let user = {
                    cono: co, roleid: props.roleid, shiftdate: shiftdatewithouttime,
                    shiftstarttime: new Date(new Date(values.shiftDate).setHours(new Date(values.startTime).getHours(), new Date(values.startTime).getMinutes(), 0, 0)).toLocaleString(),
                    shiftendtime: new Date(new Date(values.shiftDate).setHours(new Date(values.endTime).getHours(), new Date(values.endTime).getMinutes(), 0, 0)).toLocaleString(),
                    shiftid: shift.shiftid, shiftendsnextday: shift.shiftendsnextday, partialshift: false,
                    usernames: usernames, unitid: unit.unitid,username:props.username,scheduleid:props.scheduleid
                }
                console.log(user);
                scheduleStore.putEditUserShiftInSchedule(user).then(() => {
                    formik.resetForm();
                    onClose(true);
                })
                }
            }
        }
    });

    return (
        <>
            <Dialog open={open} onClose={handleClose} maxWidth='md'>
                {props.isEditing ?
                    <DialogTitle>Edit Shift In Schedule</DialogTitle>
                    :
                    <DialogTitle>Add Shift To Schedule</DialogTitle>
                }
                {loadingEdit ?
                    <>
                        <DialogContent dividers={true}>
                            <LinearProgress />
                        </DialogContent>
                    </>
                    :
                    <DialogContent dividers={true}>
                        <form onSubmit={formik.handleSubmit} >
                            <Stack alignContent={'center'} alignItems={'center'}>
                                <Typography sx={{ margin: 1 }}>User: {props.name}({props.username})</Typography>
                                <Typography sx={{ margin: 1 }}>Date: {new Date(props.shiftdate).toDateString()}</Typography>
                                <Typography sx={{ margin: 1 }}>Role: {props.role}</Typography>
                                <FormControl sx={{ marginTop: 1, marginRight: 1 }}>
                                    <InputLabel id="Unit-labelid">Unit</InputLabel>
                                    <Select
                                        labelId="Unit-labelid"
                                        label="Unit"
                                        sx={{ width: 260, fontSize: 13, height: 50, margin: 1 }}
                                        value={selectedunitobject}
                                        onChange={handleUnitChange}>
                                        {getUnitSetups.map((unit) => (
                                            <MenuItem key={unit.unitid} value={JSON.stringify({ unitid: unit.unitid, unitname: unit.unitname, unitshortname: unit.unitshortname })}>
                                                {unit.unitname}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl sx={{ marginTop: 1, marginRight: 1 }}>
                                    <InputLabel id="shift-labelid">Shift</InputLabel>
                                    <Select
                                        labelId="shift-labelid"
                                        label="Shift"
                                        sx={{ width: 260, fontSize: 13, height: 50, margin: 1 }}
                                        value={selectedshiftobject}
                                        error={shiftSelectedError}
                                        onChange={handleShiftChange}>
                                        {getShiftSetupsForRole.map((shift) => (
                                            <MenuItem key={shift.shiftid} value={JSON.stringify({ shiftid: shift.shiftid, shiftendsnextday: shift.shiftendsnextday })}>
                                                {shift.descr}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                {formik.errors.selectedshiftobject &&
                                    <Typography style={{ marginBottom: 10 }} color='red'>Shift Is Required</Typography>
                                }
                                {selectedshiftobject != '' ?
                                    <>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <TimePicker
                                                name="startTime"
                                                label="Start Time"
                                                minutesStep={15}
                                                value={dayjs(formik.values.startTime)}
                                                onChange={(newValue) => {
                                                    formik.setFieldValue("startTime", dayjs(newValue));
                                                }}
                                                formatDensity='dense'
                                                slotProps={{ textField: { size: 'small' } }}
                                                sx={{ marginTop: 1, width: 135, height: 50 }}
                                            />
                                        </LocalizationProvider>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <TimePicker
                                                name="endTime"
                                                label="End Time"
                                                minutesStep={15}
                                                value={dayjs(formik.values.endTime)}
                                                onChange={(newValue) => {
                                                    formik.setFieldValue("endTime", dayjs(newValue));
                                                }}
                                                formatDensity='dense'
                                                slotProps={{ textField: { size: 'small' } }}
                                                sx={{ margin: 1, width: 135, height: 50 }}
                                            />
                                        </LocalizationProvider>
                                    </>
                                    : <></>}
                            </Stack>
                        </form>
                    </DialogContent>
                }
                <DialogActions>
                    <Box display="flex" justifyContent="end" width="100%">
                        {props.isEditing ?
                            <LoadingButton sx={{ width: 35, height: 35, marginRight:1 }} loading={loadingDetails} variant='contained' color='error' onClick={handleClickRemoveShift}>
                                <DeleteIcon sx={{ fontSize: 16 }} />
                            </LoadingButton>
                            : <></>}
                        <Box display="flex" justifyContent="end" sx={{ alignContent: 'end', alignItems: 'end', alignSelf: 'end' }}>
                            <Button variant='outlined' onClick={handleClose}>Cancel</Button>
                            <LoadingButton sx={{ marginLeft: 1 }} loading={loadingDetails} variant='contained' onClick={formik.submitForm}>Save Shift</LoadingButton>
                        </Box>
                    </Box>
                </DialogActions>
            </Dialog>
            <Dialog
                open={areYouSureDeleteShiftOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Are You Sure?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to remove this shift?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' onClick={handleClickDeleteShiftNo}>No</Button>
                    <LoadingButton loading={loading} variant='contained' onClick={handleClickDeleteShiftYes} autoFocus>
                        Yes
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
})