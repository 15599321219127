import React from 'react';
import { useFormik } from 'formik';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import ListItemText from '@mui/material/ListItemText';
import { Button, Checkbox, Container, Box, Stack, Typography } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import Card from '@mui/material/Card';
import { useStore } from '../../stores/store';
import { Link, useParams } from 'react-router-dom';
import ArrowCircleLeftRoundedIcon from '@mui/icons-material/ArrowCircleLeftRounded';
import NewUserModel, { NewUserModelSchema } from '../../models/NewUser';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import ChangePasswordModel, { ChangePasswordSchema } from '../../models/ChangePasswordModel';
import LoadingButton from '@mui/lab/LoadingButton';
import { history } from '../../..';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function CreatePassword() {
    const { userStore } = useStore();
    const { changeUserPassword, loading } = userStore;
    const { username } = useParams<{ username: string }>();

    const formik = useFormik({
        initialValues: new ChangePasswordModel(),
        onSubmit: values => {
            if (!username) {
                return;
            }
            let u: ChangePasswordModel = { email: username,phone:'', password: formik.values.password, confirmpassword: formik.values.password, resetkey: formik.values.resetkey }
            // alert(JSON.stringify(u, null, 2));
            changeUserPassword(u).then(() => {
                handleGoToUserSetup();
            })
        },
        validationSchema: ChangePasswordSchema,
        validateOnBlur: true,

    });

    const handleGoToUserSetup = () => {
        history.push("/users");
        history.go(0);
    }

    const handleChange = () => {

    };

    const handleChange2 = (event: { target: { value: any; }; }) => {
        const {
            target: { value },
        } = event;
    };

    const handleReset = () => {
        console.log('try to reset');
        formik.resetForm({
            values: { email: '', password: '', confirmpassword: '', resetkey: '',phone:'' },
        });
    };
    // console.log('Form Values', formik.values);

    return (
        <Container component="main" maxWidth="sm">
            <Card sx={{ margin: 2, paddingLeft: 1, paddingRight: 1 }} raised>
                <Box
                    sx={{
                        marginTop: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Stack direction="column" justifyContent="left" alignItems="center">
                        <Stack direction="row" justifyContent="left" alignItems="center">
                            <Button component={Link} to="/users"><ArrowCircleLeftRoundedIcon fontSize="large" /></Button>
                            <Typography component="h1" variant="h5">
                                Create or Change a Password
                            </Typography>
                        </Stack>
                        <Typography marginTop={1} textAlign={'center'}>
                            Only users who will be creating and sending out shift alerts need a password.
                        </Typography>
                    </Stack>
                    <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                        <InputLabel id="demo-simple-select-autowidth-label" sx={{ marginTop: 2, textAlign: 'left' }}>Username</InputLabel>
                        <TextField
                            name="email"
                            value={username}
                            disabled
                        />

                        <InputLabel id="demo-simple-select-autowidth-label" sx={{ marginTop: 2, textAlign: 'left' }}>Password</InputLabel>
                        <TextField
                            error={!!formik.errors.password}
                            name="password"
                            type="password"
                            value={formik.values.password}
                            onChange={formik.handleChange} />
                        <InputLabel id="demo-simple-select-autowidth-label" sx={{ marginTop: 2, textAlign: 'left' }}>Confim Password</InputLabel>
                        <TextField
                            error={!!formik.errors.confirmpassword}
                            name="confirmpassword"
                            type="password"
                            value={formik.values.confirmpassword}
                            onChange={formik.handleChange} />
                        <Typography style={{ marginBottom: 10 }} color='red' fontSize={11.5}>{formik.errors.confirmpassword}</Typography>
                        <InputLabel id="demo-simple-select-autowidth-label" sx={{ marginTop: 2, textAlign: 'left' }}></InputLabel>
                        <LoadingButton
                            sx={{ marginTop: 2, marginBottom: 2 }}
                            variant="contained"
                            color="primary"
                            onClick={formik.submitForm}
                            loading={loading}>
                            Save Password
                        </LoadingButton>
                    </form>

                    {/* <div>
                        <h3>Validation Model</h3>
                        <pre>{JSON.stringify(formik.errors, null, 2)}</pre>
                    </div>
                    <div>
                        <h3>Form Model</h3>
                        <pre>{JSON.stringify(formik.values, null, 2)}</pre>
                    </div> */}
                </Box >
            </Card>
        </Container>
    );
}

export default CreatePassword;