import { observer } from "mobx-react-lite";
import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { Button, Checkbox, Container, Box, Stack, Typography } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import Card from '@mui/material/Card';
import { Link } from 'react-router-dom';
import ArrowCircleLeftRoundedIcon from '@mui/icons-material/ArrowCircleLeftRounded';
import LoadingButton from '@mui/lab/LoadingButton';
import FormGroup from '@mui/material/FormGroup';
import { useStore } from '../../stores/store';
import { DragHandleRounded } from "@mui/icons-material";
import CompanyRoleModel from "../../models/CompanyRole";

export default observer(function AddNewRole() {
    const { setupsStore } = useStore();
    const cono = window.localStorage.getItem('cono');

    const formik = useFormik({
        initialValues: new CompanyRoleModel(),
        onSubmit: values => {
            formik.setFieldValue('cono', cono);

            //alert(JSON.stringify(values, null, 2));
            //console.log(cono);
            setupsStore.createNewCompanyRole(values, cono || '').then(() =>{
                handleReset();
            })

        }
    });

    const handleReset = () => {
        console.log('try to reset');
        formik.resetForm({
            values: { cono: cono || '', role: '', roleid: -1,ppdbudget:0,ppdbudgetmin:0,ppdbudgetmax:0, isSelected: false, isadministrationrole: false },
        });
    };

    return (
        <Container component="main" maxWidth="sm" sx={{paddingBottom: 10}}>
            <Card sx={{ margin: 2, paddingLeft: 1, paddingRight: 1 }} raised>
                <Box
                    sx={{
                        marginTop: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Stack direction="row" justifyContent="left" alignItems="center">
                        <Button component={Link} to="/rolesetup"><ArrowCircleLeftRoundedIcon fontSize="large" /></Button>
                        <Typography component="h1" variant="h5">
                            <span>Create New Role</span>
                        </Typography>
                    </Stack>
                    <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                        <FormGroup>
                            <InputLabel id="demo-simple-select-autowidth-label" sx={{ marginTop: 2, textAlign: 'left' }}>Role Name</InputLabel>
                            <TextField
                                name="role"
                                value={formik.values.role}
                                onChange={formik.handleChange} />
                        </FormGroup>
                        <LoadingButton
                            sx={{ marginTop: 2, marginBottom: 2,borderRadius: 5 }}
                            variant="contained"
                            color="primary"
                            onClick={formik.submitForm}
                            loading={false}>
                            Create Role
                        </LoadingButton>
                    </form>
                </Box>
            </Card>
        </Container>
    )
})