import React, { useEffect } from 'react';
import { useStore } from "../../stores/store";
import { observer } from "mobx-react-lite";
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import { Button, Grid, IconButton, Link, Tooltip } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LinearProgress from '@mui/material/LinearProgress';
import { Stack, Box } from '@mui/system';
import { blue } from '@mui/material/colors';
import { history } from '../../..';
import { DataGrid, GridRowsProp, GridColDef, GridRowParams, GridRenderCellParams } from '@mui/x-data-grid';
import ArrowCircleLeftRoundedIcon from '@mui/icons-material/ArrowCircleLeftRounded';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        fontSize: 16,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableCell2 = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.info.light,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 12,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


export default observer(function InActiveUsersDataGrid() {
    const { userStore } = useStore();
    const { loadInactiveUsers, getInactiveUsers, loading } = userStore;
    const [open, setOpen] = React.useState(false);
    const [selectedUsername, setSelectedUsername] = React.useState('');
    const [refreshKey, setRefreshKey] = React.useState(0);
    const cono = window.localStorage.getItem('cono');
    const [pageSize, setPageSize] = React.useState<number>(20);

    const handleEditUserClick = (row: GridRenderCellParams<any, any, any>) => {
        history.push("/edituser/" + cono + '/' + row.row.id + '/');
        history.go(0);
    }

    const handleClickGoToActiveUsers = () => {
        history.push("/users");
        history.go(0);
    }

    const columns: GridColDef[] = [
        { field: 'email', headerName: 'Username', flex: 1, headerClassName: 'super-app-theme--header' },
        { field: 'firstname', headerName: 'First Name', flex: .8, headerClassName: 'super-app-theme--header' },
        { field: 'lastname', headerName: 'Last Name', flex: 1, headerClassName: 'super-app-theme--header' },
        { field: 'credentials', headerName: 'Credentials', flex: .5, headerClassName: 'super-app-theme--header', hide:true },
        { field: 'role', headerName: 'Role(s)', flex: .6, headerClassName: 'super-app-theme--header' },
        { field: 'phone', headerName: 'Phone', flex: .9, headerClassName: 'super-app-theme--header' },
        { field: 'optinemail', headerName: 'Opt In Email', flex: .6, headerClassName: 'super-app-theme--header' },
        { field: 'optintext', headerName: 'Opt In Text', flex: .6, headerClassName: 'super-app-theme--header' },
        {
            field: "activate",
            headerName: "",
            sortable: false,
            minWidth: 105,
            flex: .6,
            headerClassName: 'super-app-theme--header',
            renderCell: (row) =>
                // <Button onClick={() => handleClickActivateUser(row)} color='success' variant='contained' sx={{ paddingLeft: 5, paddingRight: 5 }} className="btn btn-sm btn-danger btn-delete-user">
                //     Activate
                // </Button>,
                <Tooltip title="Activate User">
                <IconButton aria-label="edit" onClick={() => handleClickActivateUser(row)}>
                    <PersonAddIcon  color='success' />
                </IconButton>
            </Tooltip>,
        },
    ];

    const handleClickActivateUser = (row: GridRenderCellParams<any, any, any>) => {
        userStore.activateUser(row.row.id).then(() => {
            setSelectedUsername('');
            setRefreshKey(refreshKey + 1);
        });
    }


    const myrows = getInactiveUsers.map((user) => {
        return {
            id: user.username,
            email: user.email,
            firstname: user.firstname,
            lastname: user.lastname,
            credentials: user.credentials,
            role: user.Roles,
            phone: user.phone,
            optinemail: user.optinemail,
            optintext: user.optintext,
            accountverifiedbycompany: user.accountverifiedbycompany,
            onhold: user.onhold,
        }
    })

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        document.title = 'Inactive Users';
        async function fetchData() {
            loadInactiveUsers();
        }

        fetchData();
    }, [refreshKey]);

    const deleteUser = () => {
        console.log('ref ' + refreshKey);
        userStore.inactivateUser(selectedUsername).then(() => {
            setSelectedUsername('');
            setRefreshKey(refreshKey + 1);
            setOpen(false);
        });
        console.log('refy ' + refreshKey);
    }


    if (loading) return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Stack style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <h1 style={{
                    textAlign: 'center', fontSize: '18',
                    fontWeight: 700,
                    color: 'inherit',
                }}>INACTIVE USERS</h1>
                <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                </Box>
            </Stack>
        </div>
    )

    return (
        <div>
            <Grid container sx={{ width: '100%' }}>
                <Grid item xs={9}>
                    <h1 style={{
                        textAlign: 'center', fontSize: '18',
                        fontWeight: 700,
                        color: 'inherit'
                    }}>INACTIVE USERS</h1>
                </Grid>
                <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    <Button
                        sx={{ margin: 1, borderRadius:5  }}
                        variant="contained"
                        color="warning" onClick={handleClickGoToActiveUsers}>
                        Show Active Users
                    </Button>
                </Grid>
            </Grid>
            <Box
                sx={{
                    height: 300,
                    width: '100%',
                    '& .super-app-theme--header': {
                        backgroundColor: blue[700],
                        fontWeight: 'bold',
                        color: 'white',
                    },
                    marginBottom: 15,
                }}
            >
                <DataGrid sx={{
                    '.MuiDataGrid-columnSeparator': {
                        display: 'none',
                    },
                    '&.MuiDataGrid-root': {
                        border: 'none',
                    },
                }} autoHeight={true} columns={columns} rows={myrows} pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)} 
                    rowsPerPageOptions={[10, 20, 50, 100]} disableSelectionOnClick={true}></DataGrid>
                {/* <Button sx={{ margin: 1 }}
                    variant="contained"
                    color="primary"
                    component={Link} to="/createuser" onClick={() => setEditUser(null)}>
                    Create New User
                </Button> */}
            </Box>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Are You Sure?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want inactivate this user?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>No</Button>
                    <Button onClick={deleteUser} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
})