export interface SendShiftAlertForRole{
    cono: string;
    roleid: number;
    username: string;
}

export class SendShiftAlertForRoleModel{
    public cono: string = "";
    public roleid: number = -1;
    public username: string = "";
}