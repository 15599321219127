import React, { useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { Stack } from '@mui/system';
import { useStore } from "../../../app/stores/store";
import { observer } from "mobx-react-lite";
import { format } from 'date-fns'
import { Button } from "@mui/material";
import { Link } from 'react-router-dom';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 13,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default observer(function ShiftSetupList() {
    const { shiftStore, userStore } = useStore();
    const [openSuccessSnack, setOpenSuccessSnack] = React.useState(false);
    const { loading, loadShiftSetups, getShiftSetups } = shiftStore;

    useEffect(() => {
        loadShiftSetups();
    }, []);

    if(loading) return(
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Stack style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <h1 style={{
                textAlign: 'center', fontSize: '18',
                fontWeight: 700,
                color: 'inherit',
            }}>SHIFT SETUP</h1>
            <Box sx={{ width: '100%' }}>
                <LinearProgress color='secondary' />
            </Box>
        </Stack>
    </div>
    )

    return (
        <>
            <Stack marginLeft={2} marginRight={2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: 55 }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Stack style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <h1 style={{
                            textAlign: 'center', fontSize: '18',
                            fontWeight: 700,
                            color: 'inherit',
                        }}>SHIFT SETUP</h1>
                        {/* <Box sx={{ width: '100%' }}>
                        <LinearProgress />
                    </Box> */}
                    </Stack>
                </div>
                <TableContainer component={Paper} sx={{ margin: 2, }}>
                    <Table sx={{ minWidth: 300 }} size='small' aria-label="simple table">
                        <TableHead>
                            <StyledTableRow key="100">
                                <StyledTableCell align="center">Shift Start</StyledTableCell>
                                <StyledTableCell align="center">Shift End</StyledTableCell>
                                <StyledTableCell align="center">Overnight</StyledTableCell>
                                <StyledTableCell align="center">Description</StyledTableCell>
                                <StyledTableCell align="center">Role</StyledTableCell>
                                <StyledTableCell width={200}></StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {getShiftSetups && getShiftSetups.map((shift) =>
                                <StyledTableRow key={shift.row}>
                                    <StyledTableCell align="center">{format(new Date(shift.shiftstarttime), 'hh:mm a')}</StyledTableCell>
                                    <StyledTableCell align="center">{format(new Date(shift.shiftendtime), 'hh:mm a')}</StyledTableCell>
                                    <StyledTableCell align="center">{String(shift.shiftendsnextday)}</StyledTableCell>
                                    <StyledTableCell align="center">{shift.descr}</StyledTableCell>
                                    <StyledTableCell align="center">{shift.role}</StyledTableCell>
                                    <StyledTableCell width={200}>
                                        <Button
                                            sx={{ margin: .3, borderRadius:5  }}
                                            variant="contained"
                                            color="secondary"
                                            component={Link} to={'/editshift/' + shift.roleid + '/' + shift.shiftid}>
                                            Edit Shift
                                        </Button>
                                    </StyledTableCell>
                                </StyledTableRow >
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Button
                    sx={{ margin: 1, borderRadius:5 }}
                    variant="contained"
                    color="secondary"
                    component={Link} to="/addnewshift"
                >
                    Create New Shift
                </Button>
            </Stack>
        </>
    );
})