export class OpenShiftResponse{
    private cono: string;
    private shiftalertid: number;
    private username: string;
    private shiftaccepted: boolean;

    constructor(cono: string, shiftalert: number, username: string, shiftaccepted: boolean){
        this.cono = cono;
        this.shiftalertid = shiftalert;
        this.username = username;
        this.shiftaccepted = shiftaccepted;
    }
}