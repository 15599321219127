import { observer } from "mobx-react-lite";
import React, { useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { Stack } from '@mui/system';
import { useStore } from "../../../../app/stores/store";
import { format } from 'date-fns'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, TextField } from "@mui/material";
import { Link } from 'react-router-dom';
import { UnitSetupDTO } from "../../../models/Setups/UnitSetupDTO";
import { LoadingButton } from "@mui/lab";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default observer(function UnitSetupMain() {
    const { scheduleStore } = useStore();
    const { loadingDetails, loadUnitSetups, getUnitSetups, putNewUnit, putEditUnit } = scheduleStore;

    const [selectedUnit, setSelectedUnit ] = React.useState(-1);
    const [unitName, setUnitName] = React.useState('');
    const [unitShortName, setUnitShortName] = React.useState('');
    const [isEditingUnit, setIsEditingUnit ] = React.useState(false);
    const [openCreateOrEditUnit, setOpenCreateOrEditUnit ] = React.useState(false);
    const [unitNameError, setUnitNameError] = React.useState(false);
    const [unitShortNameError, setUnitShortNameError] = React.useState(false);
    const [refreshKey, setRefreshKey] = React.useState(0);

    useEffect(() => {
        loadUnitSetups();
    }, [refreshKey]);

    const onCloseCreatOrEditUnit = () =>{
        setUnitNameError(false);
        setUnitShortNameError(false);
        setOpenCreateOrEditUnit(false);
    }

    const handleSaveCreateOrEditUnit = () =>{
        if(unitName.trim() === ''){
            setUnitNameError(true);
            return;
        }
        if(unitShortName.trim() === ''){
            setUnitShortNameError(true);
            return;
        }

        let cono = window.localStorage.getItem('cono');
        if (!cono) {
            return;
        }
        let u: UnitSetupDTO = {cono: cono, unitid: selectedUnit, unitname: unitName, unitshortname: unitShortName}
        if(isEditingUnit){
            putEditUnit(u).then(() =>{
                setRefreshKey(refreshKey + 1);
                setOpenCreateOrEditUnit(false);
            });
        }
        else{
            putNewUnit(u).then(() =>{
                setRefreshKey(refreshKey + 1);
                setOpenCreateOrEditUnit(false);
            });;
        }
    }

    const handleClickEditUnit = (unitid: number,name:string,shortname:string) =>{
        setUnitName(name);
        setUnitShortName(shortname);
        setSelectedUnit(unitid);
        setIsEditingUnit(true);
        setOpenCreateOrEditUnit(true);
    }
    const handleClickCreateNewUnit = () =>{
        setIsEditingUnit(false);
        setUnitName('');
        setUnitShortName('');
        setOpenCreateOrEditUnit(true);
    }

    if (loadingDetails) return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Stack style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <h1 style={{
                    textAlign: 'center', fontSize: '18',
                    fontWeight: 700,
                    color: 'inherit',
                }}>UNIT SETUP</h1>
                <Box sx={{ width: '100%' }}>
                    <LinearProgress color="secondary" />
                </Box>
            </Stack>
        </div>
    )

    return (
        <>
            <Stack marginLeft={2} marginRight={2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: 55 }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Stack style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <h1 style={{
                            textAlign: 'center', fontSize: '18',
                            fontWeight: 700,
                            color: 'inherit',
                        }}>UNIT SETUP</h1>
                        {/* <Box sx={{ width: '100%' }}>
                        <LinearProgress />
                    </Box> */}
                    </Stack>
                </div>
                <TableContainer component={Paper} sx={{ margin: 2 }}>
                    <Table sx={{ minWidth: 300 }} size='small' aria-label="simple table">
                        <TableHead>
                            <StyledTableRow key="100">
                                <StyledTableCell align="center">Unit</StyledTableCell>
                                <StyledTableCell align="center">Unit Short Name</StyledTableCell>
                                <StyledTableCell width={200}></StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {getUnitSetups && getUnitSetups.map((unit) =>
                                <StyledTableRow key={unit.unitid}>
                                    <StyledTableCell align="center">{unit.unitname}</StyledTableCell>
                                    <StyledTableCell align="center">{unit.unitshortname}</StyledTableCell>
                                    <StyledTableCell width={200}>
                                        <Button
                                            sx={{ margin: 1, borderRadius: 5 }}
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => handleClickEditUnit(unit.unitid,unit.unitname,unit.unitshortname)}
                                            >
                                            Edit Unit
                                        </Button>
                                    </StyledTableCell>
                                </StyledTableRow >
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Button
                    sx={{ margin: 1, borderRadius: 5 }}
                    variant="contained"
                    color="secondary"
                    onClick={() => handleClickCreateNewUnit()}
                >
                    Create New Unit
                </Button>
            </Stack>
            <Dialog open={openCreateOrEditUnit} onClose={onCloseCreatOrEditUnit}>
                {isEditingUnit ?
                <DialogTitle>Edit Unit</DialogTitle>
                :
                <DialogTitle>Create New Unit</DialogTitle>
}
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="unitName"
                        label="Unit Name"
                        type="text"
                        fullWidth
                        value={unitName}
                        required
                        
                        onChange={(e) => setUnitName(e.target.value)}
                        error={unitNameError}
                        helperText={unitNameError ? "Unit Name is required" : ""}
                        inputProps={{ maxLength: 100 }}
                    />
                    <TextField
                        margin="dense"
                        id="unitShortName"
                        label="Unit Short Name"
                        type="text"
                        fullWidth
                        value={unitShortName}
                        required
                        onChange={(e) => setUnitShortName(e.target.value)}
                        error={unitShortNameError}
                        helperText={unitShortNameError ? "Unit Short Name is required" : ""}
                        inputProps={{ maxLength: 3 }}
                    />
                </DialogContent>
                <DialogActions>
                    <LoadingButton loading={loadingDetails} onClick={onCloseCreatOrEditUnit}>Cancel</LoadingButton>
                    <LoadingButton loading={loadingDetails} variant="contained" onClick={handleSaveCreateOrEditUnit}>Save</LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
})