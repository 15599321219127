import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { observer } from "mobx-react-lite";
import CreateShiftAlertNewest from "./CreateShiftAlertNewest";
import React from "react";
interface CreateNewShiftAlertDialog {
    open: boolean;
    roleid:number;
    selecteddate:Date;
    onClose: (didsave: boolean) => void;  
}

export default observer(function ConfirmShiftsDialog(props: CreateNewShiftAlertDialog) {
    const { onClose, open } = props;

    const handleClose = () => {
        onClose(false);
    };

    return (
        <>
        <Dialog onClose={handleClose} open={open}>
        <DialogTitle>Create Shift Alert</DialogTitle>
        <DialogContent dividers={true}>
            <CreateShiftAlertNewest
                roleid={props.roleid}
                selecteddate={props.selecteddate}/>
        </DialogContent>
        </Dialog>
        </>
    )
})