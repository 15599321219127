export interface ClaimedAlertEmailSetup{
    cono: string;
    email: string;
    roleid: number;
    role: string;
    rownum: number;
}

class ClaimedAlertEmailSetupModel implements ClaimedAlertEmailSetup{
    cono: string = '';
    email: string = '';
    roleid: number = -1;
    role: string = '';
    rownum: number = 0;
}

export default ClaimedAlertEmailSetupModel;