import { makeAutoObservable, runInAction } from 'mobx';
import agent from '../api/agent';
import CompanyRoleModel from '../models/CompanyRole';
import NewUserModel from '../models/NewUser';
import ShiftSetupModel from '../models/ShiftSetup';
import { ClaimedAlertEmailSetup } from '../models/Setups/ClaimedAlertEmailSetup';
import { ClaimedAlertTextSetup } from '../models/Setups/ClaimedAlertTextSetup';
import ShiftTemplateSetupModel, { ShiftTemplateSetup } from '../models/Setups/ShiftTemplateSetup';
import { ShiftTemplate } from '../models/Setups/ShiftTemplate';
import ShiftInTemplate from '../models/Setups/ShiftInTemplate';
import RemoveShiftFromTemplateModel from '../models/Setups/RemoveShiftTemplateTemplateModel';
import UserRolesModel, { UserRoles } from '../models/UserRoles';
import AutoAssignUsersForTemplateShiftDTOModel from '../models/Setups/AutoAssignUsersForTemplateShiftDTO';
import AutoAssignedUserForTemplateModel from '../models/Setups/AutoAssignedUserForTemplate';
import { User } from '../models/User';
import { CompanyRolePPD } from '../models/Setups/CompanyRolePPD';

export default class SetupsStore {
    editRole: CompanyRoleModel | undefined = undefined;
    editShift: ShiftSetupModel | undefined = undefined;
    editUser: NewUserModel | undefined = undefined;
    editUserRoles = new Map<number, UserRoles>();
    claimedAlertEmails = new Map<number, ClaimedAlertEmailSetup>();
    claimedAlertTexts = new Map<number, ClaimedAlertTextSetup>();
    templateSetups = new Map<number, ShiftTemplateSetup>();
    templateDetails = new Map<number, ShiftTemplate>();    
    companyRolePPDs = new Map<number, CompanyRolePPD>();   
    autoAssignedUsersForTemplate = new Map<string, AutoAssignedUserForTemplateModel>();//string is a concatenated string of dow,shift,username
    userListForApplyingTemplate = new Map<string, User>();    
    loading = false;
    loadingEmails = false;
    loadingTexts = false;
    savingData = false;
    constructor() {
        makeAutoObservable(this);
    }

    createNewCompanyRole = async (role: CompanyRoleModel, cono: string) => {
        this.loading = true;
        role.cono = cono;
        console.log('h ' + role.cono);
        try {
            await agent.Setups.createcompanyrole(role);
            this.loading = false;
        }
        catch (error) {
            console.log('error creating role' + error);
            this.loading = false;
        }
    }

    editCompanyRole = async (role: CompanyRoleModel) => {
        try {
            await agent.Setups.editcompanyrole(role);
        } catch (error) {
            console.log('error editing role' + error);
        }
    }

    createNewCompanyShift = async (shift: ShiftSetupModel, cono: string) => {
        console.log('s ' + shift.shiftstarttime);
        this.loading = true;
        shift.cono = cono;
        shift.shiftstarttimestring = new Date(shift.shiftstarttime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        shift.shiftendtimestring = new Date(shift.shiftendtime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        try {
            await agent.Setups.createcompanyshift(shift);
            //alert(JSON.stringify(shift.shiftstarttimestring, null, 2));
            this.loading = false;
        }
        catch (error) {
            console.log('error creating role' + error);
            this.loading = false;
        }
        this.loading = false;
    }

    editCompanyShift = async (shift: ShiftSetupModel) => {
        this.loading = true;
        const cono = window.localStorage.getItem('cono');
        if (!cono) {
            return
        }

        shift.cono = cono;
        shift.shiftstarttimestring = new Date(shift.shiftstarttime).toLocaleTimeString();
        shift.shiftendtimestring = new Date(shift.shiftendtime).toLocaleTimeString();
        try {
            await agent.Setups.editcompanyshift(shift);
        } catch (error) {
            console.log('error editing role' + error);
        }
    }

    loadCompanyRolePPDs = async () => {
        const cono = window.localStorage.getItem('cono');
        if (!cono) {
            return
        }

        this.loading = true;
        try {
            const ppds = await agent.Setups.getcompanyroleppds(cono);
            ppds.forEach(r => {
                this.setCompanyRolePPD(r);
            })
            this.loading = false;
        } catch (error) {
            console.log('error getting role' + error);
            this.loading = false;
        }
    }
    private setCompanyRolePPD = (role: CompanyRolePPD) => {
        this.companyRolePPDs.set(role.roleid, role);
    }
    get getCompanyRolePPDs() {
        return Array.from(this.companyRolePPDs.values());
    }

    loadCompanyRole = async (roleid: number) => {
        const cono = window.localStorage.getItem('cono');
        if (!cono) {
            return
        }

        this.loading = true;
        try {
            const role = await agent.Setups.companyrole(cono, roleid);
            runInAction(() => {
                this.editRole = role;
                console.log(this.editRole.role);
            })
            this.loading = false;
        } catch (error) {
            console.log('error getting role' + error);
            this.loading = false;
        }
    }

    loadShift = async (roleid: string, shiftid: string) => {
        const cono = window.localStorage.getItem('cono');
        if (!cono) {
            return
        }

        this.loading = true;
        try {
            const shift = await agent.Setups.companyshiftsetup(cono, Number.parseInt(roleid), Number.parseInt(shiftid));
            runInAction(() => {
                this.editShift = shift;
            })
            this.loading = false;
        } catch (error) {
            console.log('error getting shift' + error);
            this.loading = false;
        }
    }

    putEditUser = async (user: NewUserModel) => {
        console.log('try to create new user');
        this.savingData = true;
        try {
            await agent.Setups.edituser(user);            
        }
        catch (error) {
            console.log('Error editing user ' + error);
            this.savingData = false;
        }

        this.savingData = false;
    }

    loadEditUser = async (username: string) => {
        const cono = window.localStorage.getItem('cono');
        if (!cono) {
            return
        }

        this.loading = true;
        try {
            const roles = await agent.Setups.getusersetuproles(cono, username);
            roles.forEach(r => {
                this.setUserEditRole(r);
            })
            console.log(roles.length);
            const user = await agent.Setups.getusersetup(cono, username);            
            runInAction(() => {
                user.username = username;
                this.editUser = user;                
            })
            this.loading = false;
        } catch (error) {
            console.log('error getting user' + error);
            this.loading = false;
        }
    }
    private setUserEditRole = (role: UserRolesModel) => {
        this.editUserRoles.set(role.roleid, role);
    }
    get getUserEditRoles() {
        return Array.from(this.editUserRoles.values());
    }
    removeUserEditRole = async (role: UserRoles) => {
        this.editUserRoles.delete(role.roleid);
    }
    addUserEditRole = async (role: UserRoles) => {
        this.editUserRoles.set(role.roleid,role);
    }    

    loadClaimAlertEmails = async () => {
        const cono = window.localStorage.getItem('cono');
        if (!cono) {
            return
        }
        this.loadingEmails = true;
        this.claimedAlertEmails.clear();
        try {
            const emails = await agent.Setups.getclaimedalertemails(cono);
            emails.forEach(r => {
                this.setEmail(r);
            })
            this.loadingEmails = false;
        } catch (error) {
            console.log('error getting roles' + error);
            this.loadingEmails = false;
        }
        this.loadingEmails = false;
    }
    private setEmail = (email: ClaimedAlertEmailSetup) => {
        this.claimedAlertEmails.set(email.rownum, email);
    }
    get getClaimedAlertEmails() {
        return Array.from(this.claimedAlertEmails.values());
    }
    loadClaimAlertTexts = async () => {
        const cono = window.localStorage.getItem('cono');
        if (!cono) {
            return
        }
        this.loadingTexts = true;
        this.claimedAlertTexts.clear();
        try {
            const texts = await agent.Setups.getclaimedalerttexts(cono);
            texts.forEach(r => {
                this.setTexts(r);
            })
            this.loadingTexts = false;
        } catch (error) {
            console.log('error getting roles' + error);
            this.loadingTexts = false;
        }
        this.loadingTexts = false;
    }
    private setTexts = (text: ClaimedAlertTextSetup) => {
        this.claimedAlertTexts.set(text.rownum, text);
    }
    get getClaimedAlertTexts() {
        return Array.from(this.claimedAlertTexts.values());
    }

    putClaimedAlertEmail = async (email: ClaimedAlertEmailSetup, cono: string) => {
        this.loading = true;
        email.cono = cono;
        try {
            await agent.Setups.putclaimedalertemail(email);
            this.loading = false;
        }
        catch (error) {
            this.loading = false;
        }
    }
    removeClaimedAlertEmail = async (email: ClaimedAlertEmailSetup) => {
        this.loading = true;
        try {
            await agent.Setups.removeclaimedalertemail(email);
            this.loading = false;
        }
        catch (error) {
            this.loading = false;
        }
    }
    putClaimedAlertText = async (text: ClaimedAlertTextSetup, cono: string) => {
        this.loading = true;
        text.cono = cono;
        try {
            await agent.Setups.putclaimedalerttext(text);
            this.loading = false;
        }
        catch (error) {
            this.loading = false;
        }
    }
    removeClaimedAlertText = async (text: ClaimedAlertTextSetup) => {
        this.loading = true;
        try {
            await agent.Setups.removeclaimedalerttext(text);
            this.loading = false;
        }
        catch (error) {
            this.loading = false;
        }
    }

    loadTemplateSetups = async () => {
        const cono = window.localStorage.getItem('cono');
        if (!cono) {
            return
        }
        this.templateSetups.clear();
        try {
            const templates = await agent.Setups.getshifttemplatesetups(cono);

            await templates.forEach(r => {
                this.setTemplateSetups(r);
            })

            // for(const temp of templates){
            //     await this.setTemplateSetups(temp);
            // }
        } catch (error) {
            console.log('error getting roles' + error);
        }

    }
    private setTemplateSetups = (template: ShiftTemplateSetup) => {
        template.rownum = this.templateSetups.size;
        this.templateSetups.set(template.rownum, template);
    }
    get getTemplateSetups() {
        return Array.from(this.templateSetups.values());
    }

    clearTemplateDetails = async () => {
        this.templateDetails.clear();
    }

    loadTemplateDetails = async (roleid: number, templateid: number) => {
        this.loading = true;
        const cono = window.localStorage.getItem('cono');
        if (!cono) {
            return
        }
        let createdbyusername = window.localStorage.getItem('user');
        if (!createdbyusername) {
            createdbyusername = '';
        }
        this.templateDetails.clear();
        try {

            const texts = await agent.Setups.getshifttemplate(cono, roleid, templateid);
            texts.forEach(r => {
                r.createdbyusername = createdbyusername!;
                this.setTemplateDetail(r);
            })
        } catch (error) {
            console.log('error getting templates' + error);
            this.loading = false;
        }

        this.loading = false;
    }

    loadAutoAssignUsersForTemplate = async (roleid: number, templateid: number) => {
        this.loading = true;
        const cono = window.localStorage.getItem('cono');
        if (!cono) {
            return
        }
        console.log('get auto assigned users for template ' + templateid + ' and role ' + roleid);
        this.autoAssignedUsersForTemplate.clear();
        try {

            const users = await agent.Setups.getautoassignusersfortemplate(cono, roleid, templateid);            
            users.forEach(r => {
                this.setAutoAssignedUsersForTemplate(r);
            })
        } catch (error) {
            console.log('error getting templates' + error);
            this.loading = false;
        }

        this.loading = false;
    }
    private setAutoAssignedUsersForTemplate = (user: AutoAssignedUserForTemplateModel) => {
        console.log('ok great ' + user.rownum);
        user.rownum = this.autoAssignedUsersForTemplate.size;
        this.autoAssignedUsersForTemplate.set(this.getUniqueKeyForAutoAssignedUser(user.dow,user.shiftid,user.username), user);
    }

    private getUniqueKeyForAutoAssignedUser(dow: string,shiftid:number,username:string){
        return `${dow}${shiftid}${username}`;
    }

    get getAutoAssignedUsersForTemplate() {
        return Array.from(this.autoAssignedUsersForTemplate.values());
    }
    removeAutoAssignedUserForTemplate = async(dow: string, shiftid: number, username: string) =>{
        console.log('try to remove ' + username + ' for shift ' + shiftid + ' on ' + dow);
        for(const each of this.getAutoAssignedUsersForTemplate){
            if(each.dow == dow && each.shiftid == shiftid && each.username == username){
                this.autoAssignedUsersForTemplate.delete(this.getUniqueKeyForAutoAssignedUser(each.dow,each.shiftid,each.username));
            }
        }
    }
    addAutoAssignUserForTemplate = async(templateid: number,roleid: number, dow: string, shiftid: number, username: string) =>{
        console.log('try to add ' + username + ' for shift ' + shiftid + ' on ' + dow + ' q ' + templateid + ' w ' + roleid);
        const cono = window.localStorage.getItem('cono');
        if (!cono) {
            return
        }
        let user: AutoAssignedUserForTemplateModel = {
            cono: cono,
            roleid:roleid,
            templateid:templateid,
            dow:dow,
            shiftid:shiftid,
            id:-1,
            username:username,
            rownum:this.autoAssignedUsersForTemplate.size
        };
        this.autoAssignedUsersForTemplate.set(this.getUniqueKeyForAutoAssignedUser(user.dow,user.shiftid,user.username), user);
    }

    loadUserListForApplyingTemplates = async (roleid: number, templateid: number) => {
        this.loading = true;
        const cono = window.localStorage.getItem('cono');
        if (!cono) {
            return
        }
        this.userListForApplyingTemplate.clear();
        try {

            const users = await agent.Setups.getuserlistforapplyingtemplate(cono, roleid);            
            users.forEach(r => {
                this.setUserForApplyingTemplate(r);
            })
        } catch (error) {
            console.log('error getting templates' + error);
            this.loading = false;
        }

        this.loading = false;
    }
    private setUserForApplyingTemplate = (user: User) => {
        this.userListForApplyingTemplate.set(user.username, user);
    }
    get getUserListForApplyingTemplate() {
        return Array.from(this.userListForApplyingTemplate.values());
    }

    putShiftTemplateSetupNew = async (template: ShiftTemplateSetupModel) => {
        this.loading = true;
        try {
            await agent.Setups.putshifttemplatesetupnew(template);
            this.loading = false;
        }
        catch (error) {
            this.loading = false;
        }
    }

    putNewShiftInTemplate = async (template: ShiftInTemplate) => {
        this.loading = true;
        try {
            await agent.Setups.putnewshiftintemplate(template);
            this.loading = false;
        }
        catch (error) {
            this.loading = false;
        }
    }
    removeShiftFromTemplate = async (template: RemoveShiftFromTemplateModel) => {
        this.loading = true;
        try {
            await agent.Setups.removeshiftfromtemplate(template);
            this.loading = false;
        }
        catch (error) {
            this.loading = false;
        }
    }
    private setTemplateDetail = (shift: ShiftTemplate) => {
        shift.rownum = this.templateDetails.size;
        switch (shift.dow) {
            case 'SUNDAY': shift.orderno = 0;
            case 'MONDAY': shift.orderno = 1;
            case 'TUESDAY': shift.orderno = 2;
            case 'WEDNESDAY': shift.orderno = 3;
            case 'THURSDAY': shift.orderno = 4;
            case 'FRIDAY': shift.orderno = 5;
            case 'SATURDAY': shift.orderno = 6;
            default: shift.orderno = 0;
        }
        this.templateDetails.set(this.templateDetails.size, shift);
    }
    get getTemplateDetails() {
        return Array.from(this.templateDetails.values());
    }

    get getTemplateDetailsForCalendarView() {
        const newArray = Array.from(this.templateDetails.values()).map(item => ({
            event_id: item.rownum, title: item.shiftdescr + ' - ' + item.templateshiftdescr,
            start: new Date(new Date(item.shiftdate).setHours(0,0,0,0)),
            end: new Date(new Date(item.shiftdate).setHours(0,0,0,0)),
            disabled: false, admin: 1, color: '#2196F3',
            starttime: new Date(item.shiftstarttime),
            endtime: new Date(item.shiftendtime),    
        }))
        newArray.forEach(d => {
            d.start = new Date(new Date(d.start).setHours(d.starttime.getHours(),d.starttime.getMinutes(),0,0));
            d.end = new Date(new Date(d.end).setHours(d.endtime.getHours(),d.endtime.getMinutes(),0,0));
        })     
        return newArray;
    }

    editTemplateDetail = async (shift: ShiftTemplate) => {
        this.templateDetails.set(shift.rownum, shift);
    }   

    setTemplateDetailsStartDate = async (startdate: Date) => {
        this.templateDetails.forEach(shift => {
            switch (shift.dow) {
                case 'SUNDAY':
                    shift.shiftdate = startdate;
                    break;
                case "MONDAY":
                    shift.shiftdate = new Date(startdate.getTime() + 1 * 86400000)
                    break;
                case "TUESDAY":
                    shift.shiftdate = new Date(startdate.getTime() + 2 * 86400000)
                    break;
                case 'WEDNESDAY':
                    shift.shiftdate = new Date(startdate.getTime() + 3 * 86400000)
                    break;
                case 'THURSDAY':
                    shift.shiftdate = new Date(startdate.getTime() + 4 * 86400000)
                    break;
                case 'FRIDAY':
                    shift.shiftdate = new Date(startdate.getTime() + 5 * 86400000)
                    break;
                case 'SATURDAY':
                    shift.shiftdate = new Date(startdate.getTime() + 6 * 86400000)
                    break;
                default:
                    break;
            }
        })
    }

    putShiftTemplateAutoAssignedUsers = async (users: AutoAssignUsersForTemplateShiftDTOModel) => {
        console.log('try to save auto assigned users. count ' + users.usernames.length);
        this.savingData = true;
        try {
            await agent.Setups.putshifttemplateautoassignedusers(users);            
        }
        catch (error) {
            console.log('Error editing user ' + error);
            this.savingData = false;
        }

        this.savingData = false;
    }
}