import React from 'react';
import Container from '@mui/material/Container';
import ResponsesList from './ResponsesList';
import { Box } from '@mui/system';

export default function ResponsesMain() {
    return (
        <Container maxWidth="false" sx={{ paddingBottom: 40 }}>
            <Box sx={{ paddingBottom: 80 }}>
                <ResponsesList />
            </Box>
        </Container>
    );
}