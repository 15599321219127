import { observer } from "mobx-react-lite";
import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { Button, Checkbox, Container, Box, Stack, Typography, Select, SelectChangeEvent } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import Card from '@mui/material/Card';
import { Link } from 'react-router-dom';
import ArrowCircleLeftRoundedIcon from '@mui/icons-material/ArrowCircleLeftRounded';
import LoadingButton from '@mui/lab/LoadingButton';
import FormGroup from '@mui/material/FormGroup';
import { useStore } from '../../stores/store';
import { DragHandleRounded } from "@mui/icons-material";
import CompanyRoleModel from "../../models/CompanyRole";
import ShiftTemplateSetupModel from "../../models/Setups/ShiftTemplateSetup";
import * as Yup from "yup";

export interface SimpleProps {
    handleClose: () => void;
}

const ValidationSchema = Yup.object().shape({
    templatename: Yup.string().required("Template Name Is Required"),
    roleid: Yup.number().required("Role is Required").positive(),
});

export default observer(function CreateNewTemplate(props: SimpleProps) {
    const { setupsStore, userStore } = useStore();
    const { getCompanyRoles, loadCompanyRoles } = userStore;
    const { loading } = setupsStore;
    const cono = window.localStorage.getItem('cono');
    const [selectedRole, setSelectedRole] = React.useState('');


    useEffect(() => {
        loadCompanyRoles();
    }, []);
    const formik = useFormik({
        initialValues: { cono: '', templatename: '', roleid: -1 },
        validationSchema: ValidationSchema,
        validateOnBlur: true,
        onSubmit: values => {
            if (cono == null) {
                return;
            }
            let template: ShiftTemplateSetupModel = { cono: cono, roleid: values.roleid, templatedescr: values.templatename, templateid: -1, role: '', rownum: -1 };
            //alert(JSON.stringify(template, null, 2));
            setupsStore.putShiftTemplateSetupNew(template).then(() => {
                props.handleClose();
            });
            // console.log(cono);
            // setupsStore.createNewCompanyRole(values, cono || '').then(() => {
            //     handleReset();
            // })

        }
    });

    const handleReset = () => {
        console.log('try to reset');
        formik.resetForm({
            values: { cono: '', roleid: -1, templatename: '' },
        });
    };

    const handleChange = () => {

    };
    const handleSelectRole = (e: SelectChangeEvent<number>) => {
        console.log('change role' + e.target.value.toString());
        if (+e.target.value > -1) {
            setSelectedRole(e.target.value.toString());
            formik.handleChange(e);
        }
    }

    return (
        <Box
            sx={{
                margin: 5,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Stack direction="row" justifyContent="left" alignItems="center">
                <Typography component="h1" variant="h5">
                    <span>Create New Template</span>
                </Typography>
            </Stack>
            <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                <FormGroup>
                    <InputLabel id="demo-simple-select-autowidth-label" sx={{ marginTop: 2, textAlign: 'left' }}>Template Name</InputLabel>
                    <TextField
                        name="templatename"
                        value={formik.values.templatename}
                        onChange={formik.handleChange} />
                    {formik.errors.templatename &&
                        <Typography style={{ marginBottom: 10 }} color='red'>Template Name Is Required</Typography>
                    }
                    <InputLabel id="demo-simple-select-autowidth-label" sx={{ marginTop: 2, textAlign: 'left' }}>Role</InputLabel>
                    <Select
                        name="roleid"
                        value={formik.values.roleid}
                        onChange={handleSelectRole}
                        style={{ display: "block" }}>
                        {getCompanyRoles.map((role) => (
                            <MenuItem key={role.roleid} value={role.roleid}>{role.role}</MenuItem>
                        ))}
                    </Select>
                    {formik.errors.roleid &&
                        <Typography style={{ marginBottom: 10 }} color='red'>Role Is Required</Typography>
                    }
                </FormGroup>
                <LoadingButton
                    sx={{ marginTop: 2, borderRadius:5  }}
                    variant="contained"
                    color="primary"
                    onClick={formik.submitForm}
                    loading={loading}>
                    Create Template
                </LoadingButton>
            </form>
        </Box>
    )
})