import React, { useEffect } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { useStore } from '../stores/store';
import { observer } from "mobx-react-lite";
import { Divider } from '@mui/material';
import CompanyRoleWithLastSent from '../models/CompanyRoleWithLastSent';
import { format } from 'date-fns'

interface SelectRoleDialogProps {
  open: boolean;
  selectedValue: number;
  onClose: (value: number) => void;
}

export default observer(function SelectRoleDialog(props: SelectRoleDialogProps) {
  const { userStore } = useStore();
  const { onClose, selectedValue, open } = props;
  const { loadCompanyRolesWithLastSent, getCompanyRolesWithLastSent } = userStore;
  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value: CompanyRoleWithLastSent) => {
    onClose(value.roleid);
  };

  useEffect(() => {
    loadCompanyRolesWithLastSent();
}, []);
function excludeAdministrationRole(role: CompanyRoleWithLastSent) {
  return !role.isadministrationrole;
}
  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Send Open Shift Alerts To:</DialogTitle>
      <List sx={{ pt: 0 }}>
        {getCompanyRolesWithLastSent.filter(excludeAdministrationRole).map((role) => (
          <>
          <ListItem key={role.roleid}>
            <ListItemButton onClick={() => handleListItemClick(role)} key={role.roleid}>
              {/* <ListItemAvatar>
      <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
        <PersonIcon />
      </Avatar>
    </ListItemAvatar> */}
              <ListItemText key={role.roleid} primary={role.role} secondary={new Date(role.lastsentdate).getFullYear() > 1901 ? "Last Sent: " + format(new Date(role.lastsentdate), 'MM/dd/yyyy hh:mm a') : 'Last Sent: Never'} />
            </ListItemButton>
          </ListItem>
          <Divider/>
          </>
        ))}       
      </List>
    </Dialog>
  );
})