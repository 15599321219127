import React from 'react';
import ShiftList from './ShiftList';
import Container from '@mui/material/Container';

export default function ShiftsMain(){
    return(
        <Container maxWidth="false" sx={{paddingBottom: 10}}>
            <ShiftList />
        </Container>
    );
}