import { Scheduler } from "@aldabil/react-scheduler";
import React, { useEffect } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from "../../stores/store";

interface DefaultDateProps {
    defaultdate: Date;
  }

export default observer(function ApplyTemplateCalendarView(props: DefaultDateProps) {
    const { userStore, setupsStore } = useStore();
    const { getUsersShiftsByKeyForSchedulerView } = userStore;
    const {getTemplateDetails, getTemplateDetailsForCalendarView} = setupsStore;
    return (
        <div style={{ width: '100%', margin:5 }}>
            {props.defaultdate.toDateString()}
            <Scheduler
                    editable={false}
                    deletable={false}
                    selectedDate={new Date(props.defaultdate)}
                    view="month"
                    agenda={false}
                    week={{
                        weekDays: [0, 1, 2, 3, 4, 5, 6],
                        weekStartOn: 0,
                        startHour: 0,
                        endHour: 24,
                        step: 180
                    }}
                    day={null}
                    month={{
                        weekDays: [0, 1, 2, 3, 4, 5, 6],
                        weekStartOn: 0,
                        startHour: 0,
                        endHour: 24,
                    }}
                    events={getTemplateDetailsForCalendarView}/>
        </div>
    )
})