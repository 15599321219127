import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { pdfjs, Document, Page } from "react-pdf";
import { Box, Button, IconButton, Link, Stack, Tooltip, Typography } from "@mui/material";
import "react-pdf/dist/esm/Page/TextLayer.css";
import DownloadIcon from '@mui/icons-material/Download';
//import pdfjsWorker from "pdfjs-dist/build/pdf.worker.min.mjs";


const pdfUrl = require('../../../../src/FOS User Manual v3.pdf');

export default observer(function ViewUserManual() {
    pdfjs.GlobalWorkerOptions.workerSrc = new URL(
        'pdfjs-dist/build/pdf.worker.min.mjs',
        import.meta.url,
    ).toString();
    const [numOfPages, setNumOfPages] = useState<number>(0);
    const [currPage, setCurrentPage] = useState<number>(1);

    const onLoadSuccess = ({ numPages }: { numPages: number }): void => setNumOfPages(numPages);

    const clickNext = () => {
        let i = currPage + 1;
        if (currPage >= numOfPages) {
            i = numOfPages;
        }
        setCurrentPage(i);
    }
    const clickPrevious = () => {
        let i = currPage - 1;
        if (currPage <= 1) {
            i = 1;
        }
        setCurrentPage(i);
    }
    const getPageString = () => {
        return 'Page ' + currPage.toString() + '/' + numOfPages.toString();
    }

    return (
        <>
            {/* <iframe src="../../../usermanual.pdf"  width="100%" height="100%">
    </iframe> */}
            <Stack>
                <Stack direction='row' sx={{ margin: 1, alignSelf: 'center', justifyContent: 'center', alignItems: 'center' }}>
                    {/* <Button onClick={clickPrevious} sx={{ width: 160,marginLeft:3 }} variant="outlined">Previous Page</Button>
                    <Typography sx={{ marginLeft: 2, marginRight: 2, justifyContent:'center', alignItems:'center' }}>{getPageString()}</Typography>
                    <Button onClick={clickNext} sx={{ width: 160,marginRight:2 }} variant="outlined">Next Page</Button> */}
                    <Tooltip title="Download PDF">
                        <a href={pdfUrl} target="_blank" download>
                            <IconButton>
                                <DownloadIcon color='primary' />
                                Download PDF
                            </IconButton>
                        </a>
                    </Tooltip>
                </Stack>
                {/* <Box border={1} borderColor={'#cdcdcd'} sx={{ alignSelf: 'center',marginBottom:4 }}>
                    <Document file={pdfUrl} onLoadSuccess={onLoadSuccess}>
                        <Page pageNumber={currPage} renderAnnotationLayer={false}
                            renderTextLayer={false} />
                    </Document>
                </Box> */}
            </Stack>
        </>
    );
})