import React from 'react';
import { Typography, TypographyProps } from '@mui/material';

interface ColoredTypographyProps extends TypographyProps {
  value: number;
}

const ColoredTypography: React.FC<ColoredTypographyProps> = ({ value,fontSize, ...props }) => {
  return (
    <Typography
      variant="body1"
      sx={{
        color: value > 40 ? 'red' : 'inherit',
        fontSize: fontSize !== undefined ? fontSize: 'inherit',
      }}
      {...props}
    >
      {value}
    </Typography>
  );
};

export default ColoredTypography;