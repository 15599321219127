import { ErrorMessage, Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { Box, Button, Stack } from '@mui/material';
import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import { UserFormValuesModel } from '../../models/User';
import { useStore } from '../../stores/store';
import { useNavigate } from "react-router-dom";
import { Link as Link1 } from "react-router-dom";
import LoadingButton from '@mui/lab/LoadingButton';
import Link from '@mui/material/Link';
import logo from '../../components/colorlogo.png';

export default observer(function LoginForm() {
    const { userStore } = useStore();
    const { loading } = userStore;
    const [location, setLocation ] = React.useState('');

    const [error, setError] = React.useState('');

    useEffect(() => {
        document.title = 'Log In';
        fetch('https://api.ipregistry.co/?key=hf0tqm8wuu9klnfx')
            .then(function (response) {
                return response.json();
            })
            .then(function (payload) {
                setLocation(payload.location.city + ', ' + payload.location.region.name + ', ' + payload.location.country.name + ', ' + payload.ip);
            }).catch( error => {                
                setLocation('');
            });
    }, []);

    const formik = useFormik({
        initialValues: new UserFormValuesModel('', ''),
        onSubmit: (values, { setErrors }) => {
            setError('');
            userStore.login(values,location).catch((error) => setError('Invalid Email or Password')).finally(() => setError('Invalid Email or Password'));
            //routeChange();
        }
    })

    let navigate = useNavigate();
    const routeChange = () => {
        let path = `/home`;
        navigate(path);
    }

    // const handleChange = (event: { target: { value: any; }; }) => {
    //     const {
    //         target: { value },
    //     } = event;
    // };

    const handleChange = () => {

    };

    return (
        <>
            {userStore.isLoggedIn ? (
                <Stack direction="column" justifyContent="center" alignItems="center">
                    <h1 style={{
                        textAlign: 'center', fontSize: '18',
                        fontWeight: 700,
                        color: 'inherit',
                    }}>Welcome to Fill Open Shifts</h1>
                    <Button sx={{ margin: 1, borderRadius: 5 }} variant='contained' size='large' component={Link1} to="/shifts">GO TO SHIFTS</Button>
                </Stack>
            ) : (
                <form onSubmit={formik.handleSubmit}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            formik.handleSubmit();
                        }
                    }}>
                    <Stack direction="column" justifyContent="left" alignItems="center">
                        <Box textAlign={'center'} mb={1}>
                            <img style={{ maxWidth: '100%' }} src={logo} height={115} />
                        </Box>
                        <TextField sx={{ margin: 1 }} name='email' placeholder='Username' value={formik.values.email} onChange={formik.handleChange}></TextField>
                        <TextField sx={{ margin: 1 }} name='password' placeholder='Password' type='password' onChange={formik.handleChange}></TextField>
                        {/* <ErrorMessage
                            name='error' render={() => <Typography style={{marginBottom: 10}} color='red'>{formik.initialValues.error} </Typography>}
                            /> */}
                        <Typography style={{ marginBottom: 10 }} color='red'>{error}</Typography>
                        <Link href="/forgotpassword">Forgot Password?</Link>
                        <LoadingButton sx={{ margin: 1, width: 150, borderRadius: 10, fontSize: 18 }} loading={loading} variant='contained' size='large' onClick={formik.submitForm}>LOG IN</LoadingButton>
                    </Stack>
                </form>
            )}
        </>
    )
})