import { object, string, number, boolean } from "yup";

export const ContactFormModelSchema = object({
    firstname: string(),
    lastname: string(),
    email: string().required(),
    companyname:string(),
    phone:string(),
    jobtitle:string(),
})

export interface IContactForm{
    firstname:string;
    lastname:string;
    email:string;
    companyname:string;
    phone:string;
    jobtitle:string;
}

class ContactFormModel implements IContactForm{
    firstname:string = "";
    lastname: string = "";
    email: string = "";
    companyname: string = "";
    phone: string = "";
    jobtitle: string = "";
    secret: string = "gaioubh31sd";
}

export default ContactFormModel;