import React from 'react';
import Container from '@mui/material/Container';
import UserListDataGrid from './UserListDataGrid';
import { Box } from '@mui/system';

export default function UsersMain() {
    return (
        <Container maxWidth="false" sx={{paddingBottom: 10}}>
            <Box sx={{paddingBottom: 5, marginBottom:5}}>
                <UserListDataGrid />
            </Box>
        </Container>
    );
}