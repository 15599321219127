import { observer } from "mobx-react-lite";
import React, { useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { Stack } from '@mui/system';
import { useStore } from "../../../app/stores/store";
import { format } from 'date-fns'
import { Button, Dialog, InputLabel, MenuItem, Select, SelectChangeEvent, Box, LinearProgress, List, Typography, ListItemButton, ListItemIcon, Checkbox, ListItemText, DialogActions, CircularProgress, Snackbar, Alert, IconButton, Tooltip } from "@mui/material";
import { Link, useNavigate } from 'react-router-dom';
import { history } from '../../..';
import CreateNewTemplate from "./CreateNewTemplate";
import AddShiftToTemplate from "./AddShiftToTemplate";
import ShiftTemplate from "../../models/Setups/ShiftTemplate";
import RemoveShiftFromTemplateModel from "../../models/Setups/RemoveShiftTemplateTemplateModel";
import { LoadingButton } from "@mui/lab";
import ListItem from '@mui/material/ListItem';
import { User } from "../../models/User";
import AutoAssignUsersForTemplateShiftDTOModel from "../../models/Setups/AutoAssignUsersForTemplateShiftDTO";
import PeopleIcon from '@mui/icons-material/People';
import DeleteIcon from '@mui/icons-material/Delete';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default observer(function TemplateSetupMain() {
    const { setupsStore, userStore } = useStore();
    const { getUsersForTemplateShift, loadUsersForTemplateShift, loadingLookup } = userStore;
    const { getTemplateSetups, loadTemplateSetups, getTemplateDetails, loadTemplateDetails, removeShiftFromTemplate, loading, putShiftTemplateAutoAssignedUsers } = setupsStore;
    const [selectedTemplate, setSelectedTemplate] = React.useState('');
    const [createnewtemplateopen, setCreateNewTemplateOpen] = React.useState(false);
    const [addnewshifteopen, setAddNewShiftOpen] = React.useState(false);
    const [selectedTemplateName, setSelectedTemplateName] = React.useState('');
    const [selectedRoleid, setSelectedRoleid] = React.useState(-1);
    const [selectedTemplateid, setSelectedTemplateid] = React.useState(-1);
    const [selectedDOW, setSelectedDOW] = React.useState('');
    const [selectedShiftid, setSelectedShiftid] = React.useState(-1);
    const [showAssignedUsers, setShowAssignedUsers] = React.useState(false);
    const [openSuccessSavedAutoAssignedUsersSnack, setOpenSuccessAutoAssignedUsersSnack] = React.useState(false);
    //const [checked, setChecked] = React.useState([]);
    const handleCloseSnack = () => {
        setOpenSuccessAutoAssignedUsersSnack(false);
    }
    useEffect(() => {
        loadTemplateSetups();
        //loadUsers();
    }, []);

    const handleToggle = (u: User) => () => {
        console.log("value:" + u.username + " checked: " + u.AutoAssigned);
        for (const each of getUsersForTemplateShift) {
            if (each.username == u.username) {
                each.AutoAssigned = !u.AutoAssigned;
            }
        }
    };

    const handleCloseAssignUsersDialogSave = () => {
        //alert(JSON.stringify(getUsersForTemplateShift[1], null, 2));     
        let temp = JSON.parse(selectedTemplate);
        let conotemp = window.localStorage.getItem('cono');
        if (!conotemp) {
            return;
        }
        let usernamestemp = Array.from(getUsersForTemplateShift.values()).filter(o => o.AutoAssigned == true && o.username != "").map(a => a.username);
        let users: AutoAssignUsersForTemplateShiftDTOModel = {
            cono: conotemp, roleid: selectedRoleid,
            templateid: selectedTemplateid, dow: selectedDOW,
            shiftid: selectedShiftid, id: temp.id, usernames: usernamestemp
        };
        //alert(JSON.stringify(temp, null, 2));                                                              
        putShiftTemplateAutoAssignedUsers(users).then(() =>{
            loadTemplateDetails(selectedRoleid,selectedTemplateid);
        });
        setShowAssignedUsers(false);
        setOpenSuccessAutoAssignedUsersSnack(true);
    }

    const handleCloseAssignUsersDialogCancel = () => {
        setShowAssignedUsers(false);
    }

    const handleAddNewClick = () => {
        // let temp = JSON.parse(selectedTemplate);
        // history.push("/addshifttotemplate/" + temp.templateid + '/' + temp.roleid);
        // history.go(0);
        setCreateNewTemplateOpen(true);
    }
    const handleAddNewShiftToTemplateClick = () => {
        let temp = JSON.parse(selectedTemplate);
        setSelectedTemplateName(temp.templatedescr);
        setSelectedRoleid(temp.roleid);
        setSelectedTemplateid(temp.templateid);
        setSelectedDOW(temp.dow);
        setSelectedShiftid(temp.shiftid);
        console.log('send ' + selectedTemplate);
        setAddNewShiftOpen(true);
    }

    const handleCloseCreateNewTemplate = () => {
        setCreateNewTemplateOpen(false);
    }

    const handleCloseAddNewShift = () => {
        setAddNewShiftOpen(false);
        loadTemplateDetails(selectedRoleid, selectedTemplateid);
    }

    const refreshTemplateListOnSaveNew = () => {
        loadTemplateDetails(selectedRoleid, selectedTemplateid);
        setAddNewShiftOpen(false);
    }

    const refreshTemplatesOnNewTemplate = () => {
        loadTemplateSetups();
        setCreateNewTemplateOpen(false);
    }

    const handleOpenSelectAssignedUsers = (shift: ShiftTemplate) => {
        setSelectedTemplateid(shift.templateid);
        setSelectedRoleid(shift.roleid);
        setSelectedDOW(shift.dow);
        setSelectedShiftid(shift.shiftid);
        loadUsersForTemplateShift(shift.roleid, shift.templateid, shift.dow, shift.shiftid);        
        setShowAssignedUsers(true);
    }

    const handleSelectAssignedUsersClosed = () => {

    }

    const handleSelectTemplate = (e: SelectChangeEvent<string>) => {
        let temp = JSON.parse(e.target.value);
        console.log('getting shifts for template' + temp.roleid + ' - ' + temp.templateid);
        if (temp.roleid > -1 && temp.templateid > -1) {
            setSelectedTemplate(e.target.value.toString());
            loadTemplateDetails(temp.roleid, temp.templateid);
            //formik.handleChange(e);
            //loadShiftSetupsForRole(+e.target.value);
        } else {
            //clearShiftSetupsForRole();
        }
    }

    const handleRemoveShiftFromTemplate = (shift: ShiftTemplate) => {
        let temp: RemoveShiftFromTemplateModel = { cono: shift.cono, roleid: shift.roleid, templateid: shift.templateid, dow: shift.dow, shiftid: shift.shiftid, id: shift.id }
        removeShiftFromTemplate(temp).then(() => {
            loadTemplateDetails(temp.roleid, temp.templateid);
        });
    }

    return (
        <>
            <Stack style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: 55 }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Stack style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <h1 style={{
                            textAlign: 'center', fontSize: '18',
                            fontWeight: 700,
                            color: 'inherit',
                        }}>Weekly Template Setup</h1>
                        {/* <Box sx={{ width: '100%' }}>
                        <LinearProgress />
                    </Box> */}
                        <Stack direction="row" style={{ display: 'flex', alignItems: 'end', justifyContent: 'center', marginBottom: 2 }}>
                            <Stack style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <InputLabel id="demo-simple-select-label">Select a Template</InputLabel>
                                <Select
                                    name="templates"
                                    value={selectedTemplate}
                                    onChange={handleSelectTemplate}
                                    style={{ display: "block", height: '50', minWidth: 200 }}>
                                    {getTemplateSetups.map(template =>
                                        <MenuItem key={template.rownum} value={JSON.stringify({ roleid: template.roleid, role: template.role, templateid: template.templateid, rownum: template.rownum, templatedescr: template.templatedescr })}>{template.role} - {template.templatedescr}</MenuItem>
                                    )}
                                </Select>
                            </Stack>
                            <Button
                                sx={{ marginLeft: 1, height: 55, borderRadius:10  }}
                                variant="contained"
                                color="secondary"
                                onClick={handleAddNewClick} >
                                NEW
                            </Button>
                        </Stack>
                    </Stack>
                </div>
                {loading ?
                    <Box sx={{ width: '250px', marginTop: 2 }}>
                        <LinearProgress />
                    </Box>
                    :
                    <>{selectedTemplate != '' && <>
                        <TableContainer component={Paper} sx={{ margin: 2 }}>
                            <Table sx={{ minWidth: 300 }} size='small' aria-label="simple table">
                                <TableHead>
                                    <StyledTableRow key="100">
                                        <StyledTableCell align="center">DOW</StyledTableCell>
                                        <StyledTableCell align="center">Shift Start Time</StyledTableCell>
                                        <StyledTableCell align="center">Shift End Time</StyledTableCell>
                                        <StyledTableCell align="center">Shift Descr</StyledTableCell>
                                        <StyledTableCell align="center">Description/Details</StyledTableCell>
                                        <StyledTableCell align="center">Openings</StyledTableCell>
                                        <StyledTableCell align="center">Auto Confirm</StyledTableCell>
                                        <StyledTableCell width={100}>Assigned Users</StyledTableCell>
                                        <StyledTableCell width={100}></StyledTableCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {getTemplateDetails && getTemplateDetails.map((role) =>
                                        <StyledTableRow key={role.rownum}>
                                            <StyledTableCell align="center">{role.dow}</StyledTableCell>
                                            <StyledTableCell align="center">{format(new Date(role.shiftstarttime), "hh:mm a")}</StyledTableCell>
                                            <StyledTableCell align="center">{format(new Date(role.shiftendtime), "hh:mm a")}</StyledTableCell>
                                            <StyledTableCell align="center">{role.shiftdescr}</StyledTableCell>
                                            <StyledTableCell align="center">{role.templateshiftdescr}</StyledTableCell>
                                            <StyledTableCell align="center">{role.openings}</StyledTableCell>
                                            {role.autoconfirm === true &&
                                                <StyledTableCell align="center">True</StyledTableCell>
                                            }
                                            {role.autoconfirm === false &&
                                                <StyledTableCell align="center">False</StyledTableCell>
                                            }
                                            <StyledTableCell width={100}>
                                                {/* <LoadingButton
                                                    sx={{ margin: 1 }}
                                                    variant="contained"
                                                    color="warning"
                                                    loading={loading}
                                                    onClick={() => handleOpenSelectAssignedUsers(role)}>
                                                    Assigned Users
                                                </LoadingButton> */}
                                                <Tooltip title="Auto Assigned Users For This Shift">
                                                    <IconButton aria-label="delete" onClick={() => handleOpenSelectAssignedUsers(role)}>
                                                        <PeopleIcon color='warning' />
                                                    </IconButton>
                                                </Tooltip>
                                                {role.autoassignusercount > 0 ?
                                                role.autoassignusercount : <></>}
                                            </StyledTableCell>
                                            <StyledTableCell width={100}>
                                                {/* <LoadingButton
                                                    sx={{ margin: 1 }}
                                                    variant="contained"
                                                    color="error"
                                                    loading={loading}
                                                    onClick={() => handleRemoveShiftFromTemplate(role)}>
                                                    Remove Shift
                                                </LoadingButton> */}
                                                <Tooltip title="Remove Shift">
                                                    <IconButton aria-label="delete" onClick={() => handleRemoveShiftFromTemplate(role)}>
                                                        <DeleteIcon color='error' />
                                                    </IconButton>
                                                </Tooltip>
                                            </StyledTableCell>
                                        </StyledTableRow >
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Button
                            sx={{ marginTop: 1, marginBottom: 5, borderRadius:5  }}
                            variant="contained"
                            color="primary"
                            onClick={handleAddNewShiftToTemplateClick}
                        //component={Link} to={"/addshifttotemplate/" + selectedTemplate.templateid}
                        >
                            Add Shift To Template
                        </Button>
                    </>}</>}
            </Stack>

            <Dialog
                open={showAssignedUsers}
                onClose={handleSelectAssignedUsersClosed}
                fullWidth={true}
                maxWidth={'sm'}>
                <Stack margin={4}>
                    <h1 style={{
                        textAlign: 'center', fontSize: '16',
                        fontWeight: 700,
                        color: 'inherit', marginTop: 2, marginBottom: 0,
                    }}>Auto Assign Shift To User(s)</h1>
                    <Typography variant="subtitle1" textAlign={'center'}>When this template is applied, these users will automatically be assigned this shift</Typography>
                    <Typography variant="subtitle2" textAlign={'center'} color='GrayText'>(These users will not count for your Number of Openings for a shift)</Typography>
                    {loadingLookup ?
                        <Stack margin={2}>
                            <LinearProgress />
                        </Stack>
                        :
                        <>
                            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>


                                {getUsersForTemplateShift.map((user, index) => (
                                    <ListItem
                                        key={user.username}
                                        disablePadding>
                                        <ListItemButton key={user.username} onClick={handleToggle(user)} dense>
                                            <ListItemIcon>
                                                <Checkbox
                                                    edge="start"
                                                    checked={user.AutoAssigned}
                                                    tabIndex={-1}
                                                    disableRipple
                                                    inputProps={{ 'aria-labelledby': user.username }}
                                                />
                                            </ListItemIcon>
                                            <ListItemText id={user.username} primary={`${user.lastname}, ${user.firstname} - ${user.username} (${user.Roles}) `} />
                                        </ListItemButton>
                                    </ListItem>
                                ))
                                }
                            </List></>
                    }
                </Stack>
                <DialogActions>
                    <Button onClick={handleCloseAssignUsersDialogCancel}>Cancel</Button>
                    <Button onClick={handleCloseAssignUsersDialogSave}>Save</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={createnewtemplateopen}
                onClose={handleCloseCreateNewTemplate}
                fullWidth={true}
                maxWidth={'sm'}>
                <CreateNewTemplate handleClose={refreshTemplatesOnNewTemplate}></CreateNewTemplate>
            </Dialog>
            <Dialog
                open={addnewshifteopen}
                onClose={handleCloseAddNewShift}
                fullWidth={true}
                maxWidth={'sm'}>
                <AddShiftToTemplate templatename={selectedTemplateName} templateid={selectedTemplateid} roleid={selectedRoleid} handleClose={refreshTemplateListOnSaveNew}></AddShiftToTemplate>
            </Dialog>
            <Snackbar open={openSuccessSavedAutoAssignedUsersSnack} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} sx={{ width: "100%" }} autoHideDuration={3000} onClose={handleCloseSnack}>
                <Alert onClose={handleCloseSnack} severity="success" variant="filled" sx={{ fontSize: 24 }}>
                    Successfully Saved Auto Assigned Users
                </Alert>
            </Snackbar>
        </>
    );

})