import React, { useEffect } from 'react';
import { useStore } from '../stores/store';
import { observer } from "mobx-react-lite";
import { Button, Checkbox, DialogActions, DialogContent, Drawer, FormControlLabel, FormGroup, Grid, LinearProgress, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { CheckBox } from '@mui/icons-material';
import CompanyRole from '../models/CompanyRole';

interface SelectRoleDialogProps {
  open: boolean;
  hideStoppedShifts: boolean;
  onClose: (value: boolean, from: Date, thru: Date) => void;
}

export default observer(function ShiftListFilterDialog(props: SelectRoleDialogProps) {
  const [fromDate, setFromDate] = React.useState(new Date());
  const today = new Date();
  const futuredate = today.setDate(today.getDate() + 30);
  const [thruDate, setThruDate] = React.useState(new Date(futuredate));
  const { onClose, open, hideStoppedShifts } = props;
  const [hideStopped, setHideStopped] = React.useState(hideStoppedShifts);
  const { userStore } = useStore();
  const { getCompanyRoles, loadCompanyRoles, loading, setCompanyRoleSelected, setCompanyRoleUnSelected, setCompanyRoleToDefault } = userStore;

  useEffect(() => {
    loadCompanyRoles();
    if(hideStoppedShifts === true){
      setHideStopped(hideStoppedShifts);
    }
    else{
      setHideStopped(false);
    }    
  }, []);

  const handleClose = () => {
    onClose(hideStopped, fromDate, thruDate);
  };

  const clickHideStopped = () =>{
    if(hideStopped){
      setHideStopped(false);
    }
    else{
      setHideStopped(true);
    }
  }
  const handleResetToDefault = () => {
    setHideStopped(false);
    setFromDate(new Date());
    const today = new Date();
    const futuredate = today.setDate(today.getDate() + 30);
    setThruDate(new Date(futuredate));
    setCompanyRoleToDefault();
    //onClose(hideStopped,fromDate,thruDate);
  }

  const handleToggleRoleSelect = (r: CompanyRole) => () => {
    if (r.isSelected) {
      setCompanyRoleUnSelected(r);
    }
    else {
      setCompanyRoleSelected(r);
    }
  }
  function excludeAdministrationRole(role: CompanyRole) {
    return !role.isadministrationrole;
  }
  return (
    <Drawer
      anchor={'right'}
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>Filter Results</DialogTitle>
      <DialogContent>
        <Stack margin={2}>
          {/* <Typography component="h2" variant="h5">
        <span>Filter Results</span>
      </Typography> */}
          {/* <Grid>
          <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center', marginBottom: 1 }}> */}
          <Stack direction={'row'}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                sx={{ margin: 1, width: 180 }}
                label="From"
                value={dayjs(fromDate)}
                onChange={(newValue) => {
                  if (newValue == null) {
                    return;
                  }
                  setFromDate(newValue.toDate())
                }}
              />
            </LocalizationProvider>
            {/* </Grid>
          <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center', marginBottom: 1 }}> */}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                sx={{ margin: 1, width: 180 }}
                label="Thru"
                value={dayjs(thruDate)}
                onChange={(newValue) => {
                  if (newValue == null) {
                    return;
                  }
                  setThruDate(newValue.toDate())
                }}
              />
            </LocalizationProvider>
          </Stack>
          {/* </Grid>
          <Grid item xs={12}></Grid>
        </Grid> */}
          <FormGroup>
            <FormControlLabel control={<Checkbox sx={{ margin: 1 }} onClick={clickHideStopped} checked={hideStopped} />} label="Hide Stopped Shifts" />
          </FormGroup>
          <Typography component="h6" variant="h6">
            <span>Roles</span>
          </Typography>
          {loading ?
            <Stack margin={2}>
              <LinearProgress />
            </Stack>
            :
            <>
              <List sx={{ width: '100%', bgcolor: 'background.paper' }}>


                {getCompanyRoles.filter(excludeAdministrationRole).map((role, index) => (
                  <ListItem
                    key={role.roleid}
                    disablePadding>
                    <ListItemButton key={role.roleid} onClick={handleToggleRoleSelect(role)} dense>
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={role.isSelected}
                          tabIndex={-1}
                          disableRipple
                          defaultValue={'true'}
                          inputProps={{ 'aria-labelledby': role.role }}
                        />
                      </ListItemIcon>
                      <ListItemText id={role.roleid.toString()} primary={role.role} />
                    </ListItemButton>
                  </ListItem>
                ))
                }
              </List></>
          }
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="primary"
          autoFocus onClick={handleResetToDefault}
          sx={{ margin: 1, borderRadius: 5 }}>
          Reset To Default
        </Button>
        <Button
          variant="contained"
          color="primary"
          autoFocus onClick={handleClose}
          sx={{ margin: 1, borderRadius: 5 }}>
          Apply
        </Button>
      </DialogActions>
    </Drawer>
  );
})