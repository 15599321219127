import React, { useEffect } from 'react';
import Container from '@mui/material/Container';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { useStore } from '../../stores/store';
import { Box, LinearProgress, Select, SelectChangeEvent, Stack } from '@mui/material';
import { observer } from "mobx-react-lite";
import { UpdateUserDefaultCono } from '../../models/UpdateUserDefaultCono';

export default observer(function Preferences() {
    const { userStore } = useStore();
    const { loadCompanies, GetCompanies, companies, putupdateuserdefaultcono, loading } = userStore;
    const [selectedcompany, setSelectedCompany] = React.useState('');

    const handleChange = (e: SelectChangeEvent) => {
        const value = e.target.value;
        setSelectedCompany(value);
        let company = JSON.parse(value);
        console.log('fac changed to ' + company.cono);
        window.localStorage.setItem('cono', company.cono);
        window.localStorage.setItem('enablescheduling', company.enablescheduling.toString());

        const user = window.localStorage.getItem('user');
        if (user != null) {
            let u: UpdateUserDefaultCono = { username: user, cono: company.cono }
            putupdateuserdefaultcono(u);
        }
    }

    useEffect(() => {
        if (companies.size < 1) loadCompanies();
    }, [companies.size, loadCompanies, GetCompanies]);

    if (loading) return (
        <Container sx={{ margin: '10' }}>
            <InputLabel id="demo-simple-select-label">Selected Company</InputLabel>
            <Box sx={{ width: '100%',marginTop:2 }}>
                <LinearProgress />
            </Box>
        </Container>
    )

    return (
        <Container sx={{ margin: '10' }}>
            <InputLabel id="demo-simple-select-label">Selected Company</InputLabel>
            {GetCompanies && GetCompanies.length > 0
                ? <Select
                    name="companies"
                    value={selectedcompany}
                    style={{ display: "block", height: '50' }}
                    onChange={handleChange}>
                    {GetCompanies.map(comp =>
                        <MenuItem key={comp.cono} value={JSON.stringify({ cono: comp.cono, companyname: comp.companyname, enablescheduling:comp.enablescheduling })}>{comp.companyname}</MenuItem>
                    )}
                </Select>
                : <span>Loading Facilities</span>
            }
        </Container>
    );
})