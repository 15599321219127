import React, { useEffect } from 'react';
import { useStore } from "../../stores/store";
import { observer } from "mobx-react-lite";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { IconButton } from "@mui/material";
import Box from '@mui/material/Box';
import { format } from 'date-fns'
import LinearProgress from '@mui/material/LinearProgress';
import { Stack } from '@mui/system';
import { useParams } from 'react-router-dom'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ViewListIcon from '@mui/icons-material/ViewList';
import MainCalendar from '../calendar/MainCalendar';
import { Scheduler } from '@aldabil/react-scheduler';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 13,
    },
}));

const StyledTableInnerRow = styled(TableRow)(({ theme }) => ({
    root: {
        border: 1
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    root: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
}));

export default observer(function ViewUserShifts() {
    const { userStore } = useStore();
    const { loadUserShiftsByKey, getUsersShiftsByKey, loading, getUsersUniqueFacilities, getUsersShiftsByKeyForSchedulerView } = userStore;
    const { key } = useParams<{ key: string }>();
    const [username] = React.useState('');
    const [shiftWasClaimed, setShiftWasClaimed] = React.useState(false);
    const [shiftWasDenied, setShiftWasDenied] = React.useState(false);
    const [shiftIsUnavailable, setShiftIsUnavailable] = React.useState(false);
    const [showListView, setShowListView] = React.useState(false);

    useEffect(() => {
        document.title = 'Your Shifts';
        if (key) {
            loadUserShiftsByKey(key);
        }
    }, []);

    const clickShowListView = () => {
        setShowListView(true);
    }

    const clickShowCalendarView = () => {
        setShowListView(false);
    }


    if (loading) return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Stack style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <h1 style={{
                    textAlign: 'center', fontSize: '14',
                    fontWeight: 700,
                    color: 'inherit', marginTop: 2, marginBottom: 0,
                }}>Loading Shifts...</h1>
                <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                </Box>
            </Stack>
        </div>
    )

    return (
        <div style={{ justifyContent: 'center', width: '100%' }}>
            <Stack style={{ marginBottom: 25, alignItems: 'center', justifyContent: 'center' }} >
                <Stack direction='row' marginLeft={11}>
                    {/* <h1 style={{
                        textAlign: 'center', fontSize: '16',
                        fontWeight: 700,
                        color: 'inherit', marginTop: 2, marginBottom: 0,
                    }}>YOUR SHIFTS</h1> */}
                    <Stack alignItems={'center'} textAlign={'center'} margin={2}>
                                    <b>User Shifts:</b>
                                    {getUsersShiftsByKey[0] &&
                                    <Stack direction='row' alignItems={'center'}>
                                    {getUsersShiftsByKey[0].name}
                                    </Stack>
}
                                </Stack>
                    <IconButton
                        sx={{ marginLeft: 3 }}
                        aria-label="expand row"
                        size="small"
                        onClick={clickShowCalendarView}>
                        {showListView ? <CalendarTodayIcon /> : <CalendarTodayIcon color='primary' />}
                    </IconButton>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={clickShowListView}>
                        {showListView ? <ViewListIcon color='primary' /> : <ViewListIcon />}
                    </IconButton>
                </Stack>
                {showListView ?
                    <>
                        {getUsersUniqueFacilities.map(fac =>
                            <><b>{fac}</b>
                                <TableContainer component={Paper} sx={{ width: "max-content", alignItems: 'center', marginBottom: 4, marginTop: 1 }}>
                                    <Table size='small' aria-label="simple table">
                                        <TableBody>
                                            {getUsersShiftsByKey.filter(item => item.companyname === fac && item.shiftid > -1).map(shift =>
                                                <React.Fragment>
                                                    <TableRow sx={{ backgroundColor: '#1976d2' }} key={shift.shiftdate.toString() + shift.shiftstarttime.toString() + shift.shiftalertid.toString()}>
                                                        <TableCell sx={{ fontSize: 16, color: 'white' }} align="center">Shift</TableCell>
                                                        <TableCell sx={{ fontSize: 16, color: 'white' }} align="center">Role</TableCell>
                                                    </TableRow>
                                                    <TableRow key={shift.shiftalertid.toString()} sx={{ "& td": { border: 0 } }}>
                                                        <StyledTableCell align="center">{format(new Date(shift.shiftdate), 'MM/dd/yyyy')}<br />{format(new Date(shift.shiftstarttime), "hh:mm a")} - {format(new Date(shift.shiftendtime), "hh:mm a")}</StyledTableCell>
                                                        <StyledTableCell align="center">{shift.role}</StyledTableCell>
                                                        {/* <StyledTableCell align="center">}</StyledTableCell> */}
                                                        {/* <StyledTableCell align="center">{shift.shiftdescr}</StyledTableCell> */}
                                                    </TableRow>
                                                    <StyledTableInnerRow sx={{ "& td": { border: 0 } }}>
                                                        <StyledTableCell colSpan={2} align="center">{shift.descr}</StyledTableCell>
                                                        {/* <StyledTableCell></StyledTableCell> */}
                                                        {/* <StyledTableCell></StyledTableCell> */}
                                                    </StyledTableInnerRow>
                                                    {shift.status === 1 &&
                                                        <><TableRow>
                                                            <TableCell sx={{ fontSize: 13, backgroundColor: '#ffb74d', border: 0 }} align="center" colSpan={2}>
                                                                Claimed On: {format(new Date(shift.responsedate), 'MM/dd/yyyy hh:mm a')}
                                                            </TableCell>
                                                        </TableRow><TableRow>
                                                                <TableCell sx={{ fontSize: 16, backgroundColor: '#ffb74d', border: 0 }} align="center" colSpan={2}>
                                                                    <b>Not Confirmed</b>
                                                                </TableCell>
                                                            </TableRow></>
                                                    }
                                                    {shift.status === 2 && //2 means denied
                                                        <TableRow>
                                                            <TableCell sx={{ fontSize: 16, backgroundColor: '#e6e6e6' }} align="center" colSpan={2}>
                                                                <b>Denied This Shift</b>
                                                            </TableCell>
                                                        </TableRow>
                                                    }
                                                    {shift.status === 4 &&
                                                        <><TableRow>
                                                            <TableCell sx={{ fontSize: 13, backgroundColor: '#aed581', border: 0 }} align="center" colSpan={2}>
                                                                Claimed On: {format(new Date(shift.responsedate), 'MM/dd/yyyy hh:mm a')}
                                                            </TableCell>
                                                        </TableRow>
                                                            <TableRow>
                                                                <TableCell sx={{ fontSize: 16, backgroundColor: '#aed581', border: 0 }} align="center" colSpan={2}>
                                                                    <b>Confirmed</b>
                                                                </TableCell>
                                                            </TableRow></>
                                                    }
                                                    {shift.status === -1 && //-1 means from the schedule
                                                        <TableRow>
                                                            <TableCell sx={{ fontSize: 16, backgroundColor: 'primary' }} align="center" colSpan={2}>
                                                                <b>Scheduled</b>
                                                            </TableCell>
                                                        </TableRow>
                                                    }
                                                </React.Fragment>
                                            )}
                                            {!getUsersShiftsByKey &&
                                                <StyledTableRow key={-2}>
                                                    <StyledTableCell className="text-center">
                                                        <div className="p-2">No Shifts Or Invalid Key</div>
                                                    </StyledTableCell>
                                                </StyledTableRow >
                                            }
                                            {getUsersShiftsByKey && !getUsersShiftsByKey.length &&
                                                <StyledTableRow key={-3}>
                                                    <StyledTableCell className="text-center">
                                                        <div className="p-2">No Shifts Or Invalid Key</div>
                                                    </StyledTableCell>
                                                </StyledTableRow >
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </>)}</>
                    :
                    <>
                        {
                            getUsersShiftsByKey[0] &&
                            // <Grid container>
                            <Stack direction='row' alignItems={'center'}>
                                {/* <Stack alignItems={'center'} textAlign={'center'} marginLeft={2} marginRight={2} marginBottom={2}>
                                    <b>User:</b>
                                    {getUsersShiftsByKey[0].name}
                                </Stack> */}
                                {/* <Stack alignItems={'center'} textAlign={'center'} marginLeft={2} marginRight={2}>
                        <b>Role:</b>
                        {getUsersShiftsByKey[0].role}
                    </Stack> */}
                            </Stack>
                            // </Grid>
                        }
                        {getUsersShiftsByKeyForSchedulerView && getUsersShiftsByKeyForSchedulerView.length > 0 ?
                            <div style={{ width: '100%' }}>
                                <Scheduler
                                    editable={false}
                                    deletable={false}
                                    view="month"
                                    week={{
                                        weekDays: [0, 1, 2, 3, 4, 5, 6],
                                        weekStartOn: 0,
                                        startHour: 0,
                                        endHour: 24,
                                        step: 180
                                    }}
                                    agenda={false}
                                    day={null}
                                    events={getUsersShiftsByKeyForSchedulerView.filter(item => item.status == 1 || item.status == 2 || item.status == 4 || item.status == -1 && item.shiftid > -1)} />
                            </div>
                            :
                            <div className="p-2">No Shifts Or Invalid Key</div>
                        }
                    </>
                }
            </Stack>
        </div >
    );
})