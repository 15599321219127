import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useFormik } from 'formik';
import {
    TextField,
    Button,
    Container,
    Typography,
    Box,
    Snackbar,
    Alert,
} from '@mui/material';
import logo from '../../components/colorlogo.png';
import ContactFormModel, { ContactFormModelSchema } from '../../models/ContactForm';
import * as Yup from "yup";
import { LoadingButton } from '@mui/lab';
import { useStore } from '../../stores/store';

export default observer(function ContactUsForm() {
    const { userStore } = useStore();
    const { loading } = userStore;
    const [openSuccessSnack, setOpenSuccessSnack] = React.useState(false);

    useEffect(() => {
        document.title = 'Contact Us'; //contact us is for our home page link
      }, []);

    // const ContactSchema = Yup.object().shape({
    //     email: Yup.string()
    //         .email("Invalid email address format"),
    //     phone: Yup.string()
    //         .min(10).max(12),
    //     firstname: Yup.string(),
    //     lastname: Yup.string(),
    //     companyname: Yup.string(),
    //     jobtitle: Yup.string(),
    // });

    const handleCloseSnack = () => {
        setOpenSuccessSnack(false);
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: new ContactFormModel(),
        validationSchema: ContactFormModelSchema,
        validateOnBlur: true,
        onSubmit: values => {
            // Handle form submission logic here
            //console.log(values);
            userStore.putContactFormSubmission(values).then(() => {
                setOpenSuccessSnack(true);
                formik.resetForm();                
            });
        },
    });

    return (
        <>
            <Container maxWidth="sm">
                <Box textAlign={'center'} mb={1}>
                    <a href="/">
                        <img style={{ maxWidth: '100%' }} src={logo} height={95} />
                    </a>
                </Box>
                <Typography textAlign={'center'} fontFamily={'Segoe UI'} fontSize={20} fontWeight={'bold'} style={{color:'#464646'}}>
                    Complete the form to get more information!
                </Typography>
                <form onSubmit={formik.handleSubmit}>
                    <Box display="flex" justifyContent="space-between">
                        <TextField
                            fullWidth
                            label="First Name"
                            id="firstname"
                            name="firstname"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.firstname}
                            margin="normal"
                            sx={{ marginRight: 2 }}
                        />
                        <TextField
                            fullWidth
                            label="Last Name"
                            id="lastname"
                            name="lastname"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.lastname}
                            margin="normal"
                        />
                    </Box>
                    <TextField
                        fullWidth
                        label="Email"
                        id="email"
                        name="email"
                        type="email"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                        margin="normal"
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                        required
                    />
                    <TextField
                        fullWidth
                        label="Company Name"
                        id="companyname"
                        name="companyname"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.companyname}
                        margin="normal"
                    />
                    <TextField
                        fullWidth
                        label="Phone Number"
                        id="phone"
                        name="phone"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.phone}
                        margin="normal"
                    />
                    <TextField
                        fullWidth
                        label="Job Title"
                        id="jobtitle"
                        name="jobtitle"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.jobtitle}
                        margin="normal"
                    />
                    <Box mt={2}>
                        <LoadingButton
                            style={{ height: 55, width: 200, borderRadius: 30, fontSize: 18 }}
                            type="submit"
                            variant="contained"
                            color="primary"
                            onClick={formik.submitForm}
                            loading={loading}>
                            Submit
                        </LoadingButton>
                    </Box>
                </form>
                <Snackbar open={openSuccessSnack} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} sx={{ width: "100%" }} autoHideDuration={6000} onClose={handleCloseSnack}>
                <Alert onClose={handleCloseSnack} severity="success" variant="filled" sx={{ fontSize: 24 }}>
                    Thank you for your submission, we will reach out to you soon!
                </Alert>
            </Snackbar>
            </Container >
        </>
    );
})