export interface ShiftAlertForSingleUser{
    cono: string;
    shiftalertid: number;
    sentbyusername: string;
    sendtousername: string;
}

export class ShiftAlertForSingleUser{
    public cono: string = "";
    public shiftalertid: number = -1;
    public sentbyusername: string = "";
    public sendtousername: string = "";
}