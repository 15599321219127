import React, { useEffect } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { useStore } from '../stores/store';
import { observer } from "mobx-react-lite";
import { DialogContent, Divider } from '@mui/material';
import CompanyRoleWithLastSent from '../models/CompanyRoleWithLastSent';
import { format } from 'date-fns'

interface SelectRoleDialogProps {
    open: boolean;
    selectedValue: number;
    onClose: (value: number) => void;
}

export default observer(function ConfirmShiftsDialog(props: SelectRoleDialogProps) {
    const { onClose, selectedValue, open } = props;

    const handleClose = () => {
        onClose(selectedValue);
    };

    const handleListItemClick = (value: number) => {
        onClose(value);
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>Confirm Selected Shifts:</DialogTitle>
            <DialogContent dividers={true}>
            <List sx={{ pt: 0 }}>
                {/* <ListItem key={0}>
                    <ListItemButton onClick={() => handleListItemClick(0)} key={0}>
                        <ListItemText primary={'Send Individual Confirmations'} secondary={'Sends a confirmation message for each shift for each user'} />
                    </ListItemButton>
                </ListItem> */}
                <ListItem key={1}>
                    <ListItemButton onClick={() => handleListItemClick(1)} key={1}>
                        <ListItemText primary={'Send Confirmations'} secondary={'Sends confirmation message to each user'} />
                    </ListItemButton>
                </ListItem>
                <ListItem key={2}>
                    <ListItemButton onClick={() => handleListItemClick(2)} key={2}>
                        <ListItemText primary={'Send Nothing'} secondary={'Send no messages, just confirm shifts'} />
                    </ListItemButton>
                </ListItem>
                {/* <Divider /> */}
            </List>
            </DialogContent>
        </Dialog>
    );
})