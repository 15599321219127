import { createContext, useContext } from 'react';
import SetupsStore from './setupsStore';
import ShiftStore from './shiftStore';
import UserStore from './userStore';
import ScheduleStore from './scheduleStore';

interface Store{
    shiftStore: ShiftStore;
    userStore: UserStore;
    setupsStore: SetupsStore;
    scheduleStore: ScheduleStore;
}

export const store : Store = {
    shiftStore: new ShiftStore(),
    userStore: new UserStore(),
    setupsStore: new SetupsStore(),
    scheduleStore: new ScheduleStore(),
}

export const StoreContext = createContext(store);

export function useStore(){
    return useContext(StoreContext);
}