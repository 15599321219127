export interface ClaimedAlertTextSetup{
    cono: string;
    phone: string;
    roleid: number;
    role: string;
    rownum: number;
}
class ClaimedAlertTextSetupModel implements ClaimedAlertTextSetup{
    cono: string = '';
    phone: string = '';
    roleid: number = -1;
    role: string = '';
    rownum: number = 0;
}

export default ClaimedAlertTextSetupModel;