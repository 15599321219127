import { Button } from "@mui/material";
import { Stack } from "@mui/system";
import { Link } from "react-router-dom";
import React from "react";
import { useStore } from '../../stores/store';
import Typography from '@mui/material/Typography';

export default function HomePage() {
  const { userStore } = useStore();
  return (
    <>
      <Stack margin={5} direction="column" justifyContent="center" alignItems="center">
        <Typography style={{ marginBottom: 4 }}>Hello {userStore.getFirstName}</Typography>
        <Typography style={{ marginBottom: 8 }}>Welcome to Fill Open Shifts</Typography>
        <Button sx={{ margin: 1, borderRadius:5 }} component={Link} to="/users" variant="contained" size="large" style={{ marginTop: 10 }}>Users</Button>
        <Button sx={{ margin: 1, borderRadius:5 }} component={Link} to="/responses" variant="contained" size="large" style={{ marginTop: 10 }}>Responses</Button>
        <Button sx={{ margin: 1, borderRadius:5 }} component={Link} to="/shifts" variant="contained" size="large" style={{ marginTop: 10 }}>Shifts</Button>
      </Stack>
    </>
  );
}