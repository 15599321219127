import React, { useEffect } from "react";
import { observer } from 'mobx-react-lite';
import { Container, Stack } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import { Scheduler } from "@aldabil/react-scheduler";
import { EVENTS } from '../calendar/EventsTest'
import { useStore } from "../../stores/store";


export default observer(function MainCalendar() {
    const { userStore } = useStore();
    const { getUsersShiftsByKeyForSchedulerView } = userStore;

    useEffect(() => {
        //loadUserShiftsByKey('');
    }, []);

    return (
        <div style={{ width: '100%' }}>
            {getUsersShiftsByKeyForSchedulerView && getUsersShiftsByKeyForSchedulerView.length > 0 ?
                <Scheduler
                    editable={false}
                    deletable={false}
                    view="month"
                    agenda={false}
                    week={{
                        weekDays: [0, 1, 2, 3, 4, 5, 6],
                        weekStartOn: 0,
                        startHour: 0,
                        endHour: 24,
                        step: 180
                    }}
                    day={null}
                    events={getUsersShiftsByKeyForSchedulerView}/>
                :
                <div className="p-2">No Shifts Or Invalid Key</div>
            }
        </div>
    )
})