import { object, string, number, boolean } from "yup";

export const RegisterNewUserModelSchema = object({
    firstname: string().required(),
    lastname: string().required(),
    email: string().required(),
    optinemail: boolean(),
    phone: string(),
    optintext: boolean(),
    role: number().required().moreThan(0),
    employeeid: string(),
});

export interface IRegisterNewUser{
    key: string;
    firstname: string;
    lastname: string;
    email: string;
    optinemail: boolean;
    phone: string;
    optintext: boolean;
    roleid: number;
    employeeid: string;
}

class RegisterNewUserModel implements IRegisterNewUser{
    key: string = "";
    firstname: string = "";
    lastname: string = "";
    email: string = "";
    optinemail: boolean = false;
    phone: string = "";
    optintext: boolean = false;
    roleid: number = 0;
    employeeid: string = "";
}

export default RegisterNewUserModel;