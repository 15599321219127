import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { ErrorMessage, Formik, FormikConsumer, FormikHelpers, FormikValues, useFormik, useFormikContext } from 'formik';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import NewShiftAlertModel, { NewShiftAlertModelSchema } from '../../models/NewShiftAlert';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import ListItemText from '@mui/material/ListItemText';
import { Button, Checkbox, Container, Box, Stack, Typography, Alert, Snackbar, Tooltip, LinearProgress } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import Card from '@mui/material/Card';
import { useStore } from '../../stores/store';
import { Link } from 'react-router-dom';
import ArrowCircleLeftRoundedIcon from '@mui/icons-material/ArrowCircleLeftRounded';
import LoadingButton from '@mui/lab/LoadingButton';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { format } from 'date-fns'
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import dayjs, { Dayjs } from 'dayjs';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

interface CreateNewShiftAlert {
    roleid: number;
    selecteddate: Date;
}

export default observer(function CreateShiftAlertNewest(props: CreateNewShiftAlert) {
    const { shiftStore, userStore } = useStore();
    const [openSuccessSnack, setOpenSuccessSnack] = React.useState(false);
    const { loading, loadShiftSetupsForRole, getShiftSetupsForRole, clearShiftSetupsForRole, savingShiftAlert } = shiftStore;
    const { getCompanyRoles, loadCompanyRoles } = userStore;
    const [selectedRole, setSelectedRole] = React.useState('');
    const [formErrors, setFormErrors] = React.useState(false);
    const [loadingDialog, setLoadingDialog] = React.useState(true);
    const [ selectedshiftdate, setSelectedShiftDate ] = React.useState(new Date());
    useEffect(() => {
        document.title = 'Create New Shift';
        loadCompanyRoles().then(() => {
            console.log('ok role ' + props.roleid);
            if (props.roleid > -1) {
                setSelectedRole(props.roleid.toString());
                setSelectedShiftDate(props.selecteddate);
                loadShiftSetupsForRole(props.roleid).then(() =>{
                    setLoadingDialog(false);
                });
            }
            else{
                setLoadingDialog(false);
            }
            // if (props.selecteddate >= new Date()) {
            //     formik.values.shiftdate = new Date(props.selecteddate).toString();
            // }
        });
        // loadShiftSetups();
    }, []);

    useEffect(() => {
        if (props.roleid > -1) {
            console.log('other useeffect set ');
            formik.setFieldValue("roleid", props.roleid);
            formik.setFieldValue("shiftdate", new Date(props.selecteddate));
            setSelectedShiftDate(props.selecteddate);
        }
        // console.log('shift date ' + new Date(formik.values.shiftdate));
    }, [selectedRole]);

    const formik = useFormik({
        // enableReinitialize: true,
        initialValues: new NewShiftAlertModel(),
        validationSchema: NewShiftAlertModelSchema,
        validateOnBlur: true,
        onSubmit: (values) => {
            formik.setFieldValue("shiftdate", dayjs(selectedshiftdate));
            shiftStore.createShiftAlert(values).then(() => {
                setOpenSuccessSnack(true);
                formik.setFieldValue("shiftid", -1);
                formik.setFieldValue("descr", '');
            })
            //formik.resetForm();
        }
    });

    const handleSelectRole = (e: SelectChangeEvent<number>) => {
        console.log('getting shifts for role' + e.target.value.toString());
        if (+e.target.value > -1) {
            setSelectedRole(e.target.value.toString());
            formik.handleChange(e);
            loadShiftSetupsForRole(+e.target.value);
        } else {
            clearShiftSetupsForRole();
        }
    }

    

    const handleChange = () => {

    };
    const handleChange2 = (event: { target: { value: any; }; }) => {
        const {
            target: { value },
        } = event;
    };
    // console.log('Form Values', formik.values);

    const handleCloseSnack = () => {
        setOpenSuccessSnack(false);
    }

    if(loadingDialog){
        return(
            <Box sx={{ width: '100%',marginTop:2 }}>
                <LinearProgress />
            </Box>
        )
    }
else{
    return (
        <Container component="main" sx={{ paddingBottom: 1 }}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                {/* <Stack direction="row" justifyContent="left" alignItems="center">
                    <Button component={Link} size='small' to="/shifts"><ArrowCircleLeftRoundedIcon fontSize="large" /></Button>
                    <Typography>
                        Create New Shift Alert
                    </Typography>
                </Stack> */}
                <Formik initialValues={formik.initialValues} onSubmit={function (values: FormikValues, formikHelpers: FormikHelpers<FormikValues>): void | Promise<any> {
                    throw new Error('Function not implemented.');
                } }>
                    <form onSubmit={formik.handleSubmit} onChange={handleChange}>
                        <InputLabel id="demo-simple-select-autowidth-label" sx={{ textAlign: 'left' }}>Role</InputLabel>
                        <Select
                            name="roleid"
                            size='small'
                            value={formik.values.roleid}
                            onChange={handleSelectRole}
                            style={{ display: "block" }}>
                            {getCompanyRoles.map((role) => (
                                <MenuItem key={role.roleid} value={role.roleid}>{role.role}</MenuItem>
                            ))}
                        </Select>
                        {formik.errors.roleid &&
                            <Typography style={{ marginBottom: 10 }} color='red' fontSize={11.5}>Role Is Required</Typography>
                        }
                        <InputLabel id="demo-simple-select-autowidth-label" sx={{ marginTop: 1, textAlign: 'left' }}>Shift Date</InputLabel>
                        {/* <input
                        className={
                            !!formik.errors.shiftdate ? "control-error" : ""
                        }
                        type="date"
                        name="shiftdate"
                        value={formik.values.shiftdate}
                        //onChange={formik.handleChange}
                    /> */}
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                value={dayjs(formik.values.shiftdate)}
                                slotProps={{ textField: { size: 'small' } }}
                                onChange={(val) => 
                                {
                                    formik.setFieldValue("shiftdate", dayjs(val)); 
                                 }} />
                        </LocalizationProvider>
                        {formik.errors.shiftdate &&
                            <Typography style={{ marginBottom: 5 }} color='red' fontSize={11.5}>Shift Date Is Required</Typography>
                        }
                        {/* <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                                label=""
                                value={formik.values.shiftdate}
                                onChange={formik.handleChange}
                                renderInput={(params) => <TextField {...params} />}
                            />
                            </LocalizationProvider> */}
                        <InputLabel id="demo-simple-select-autowidth-label" sx={{ marginTop: 1, textAlign: 'left' }}>Shift</InputLabel>
                        <Select
                            name="shiftid"
                            size='small'
                            value={formik.values.shiftid}
                            onChange={formik.handleChange}
                            style={{ display: "block", height: '50' }}
                        >
                            {/* <MenuItem value="0" key="0">12AM - 8AM</MenuItem>
                            <MenuItem value="1" key="1">8AM - 4PM</MenuItem>
                            <MenuItem value="2" key="2">10AM - 6PM</MenuItem>
                            <MenuItem value="3" key="3">12PM - 8PM</MenuItem>
                            <MenuItem value="4" key="4">8PM - 4AM(Next Day)</MenuItem> */}
                            {getShiftSetupsForRole.map((shift) => (
                                <MenuItem key={shift.shiftid} value={shift.shiftid}>{format(new Date(shift.shiftstarttime), "hh:mm a")} - {format(new Date(shift.shiftendtime), "hh:mm a")} - {shift.descr}</MenuItem>
                            ))}
                        </Select>
                        {formik.errors.shiftid &&
                            <Typography style={{ marginBottom: 10 }} color='red' fontSize={11.5}>Shift Is Required</Typography>
                        }
                        <InputLabel id="demo-simple-select-autowidth-label" sx={{ marginTop: 1, textAlign: 'left' }}>Shift Description and Details</InputLabel>
                        <TextField
                            size='small'
                            error={!!formik.errors.descr}
                            autoComplete='off'
                            name="descr"
                            value={formik.values.descr} fullWidth
                            onChange={formik.handleChange} />
                        {formik.errors.descr &&
                            <Typography style={{ marginBottom: 10 }} color='red' fontSize={11.5}>Shift Description Is Required</Typography>
                        }
                        <InputLabel id="demo-simple-select-autowidth-label" sx={{ marginTop: 1, textAlign: 'left' }}>Number of Shift Openings</InputLabel>
                        <Select
                            name="numofopenings"
                            size='small'
                            value={formik.values.numofopenings}
                            onChange={formik.handleChange}
                            style={{ display: "block" }}>
                            <MenuItem key="1" value="1">1</MenuItem>
                            <MenuItem key="2" value="2">2</MenuItem>
                            <MenuItem key="3" value="3">3</MenuItem>
                            <MenuItem key="4" value="4">4</MenuItem>
                            <MenuItem key="5" value="5">5</MenuItem>
                            <MenuItem key="6" value="6">6</MenuItem>
                            <MenuItem key="7" value="7">7</MenuItem>
                            <MenuItem key="8" value="8">8</MenuItem>
                            <MenuItem key="8" value="9">9</MenuItem>
                            <MenuItem key="8" value="10">10</MenuItem>
                            <MenuItem key="8" value="11">11</MenuItem>
                            <MenuItem key="8" value="12">12</MenuItem>
                            <MenuItem key="8" value="13">13</MenuItem>
                            <MenuItem key="8" value="14">14</MenuItem>
                            <MenuItem key="8" value="15">15</MenuItem>
                        </Select>

                        <Stack direction="row" alignItems="center" sx={{ marginTop: 1 }}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox name="sendimmediatealert" checked={formik.values.sendimmediatealert} onChange={formik.handleChange} />} label="Send Individual Alerts" />
                            </FormGroup>
                            <Tooltip title="Send out messages immediately to users in the selected role">
                                <HelpOutlineIcon color='warning' />
                            </Tooltip>
                        </Stack>
                        <Stack direction="row" alignItems="center" sx={{ marginTop: 1 }}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox name="autoconfirm" checked={formik.values.autoconfirm} onChange={formik.handleChange} />} label="Auto Confirm" />
                            </FormGroup>
                            <Tooltip title="Auto confirm shifts immediately after the shift is claimed by a user">
                                <HelpOutlineIcon color='warning' />
                            </Tooltip>
                        </Stack>

                        <LoadingButton
                            sx={{ marginTop: 1, marginBottom: 1, borderRadius: 5 }}
                            variant="contained"
                            color="primary"
                            size='medium'
                            fullWidth
                            onClick={formik.submitForm}
                            loading={savingShiftAlert}>
                            Create Shift Alert
                        </LoadingButton>
                    </form>
                </Formik>
            </Box>
            <Snackbar open={openSuccessSnack} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} sx={{ width: "100%" }} autoHideDuration={4000} onClose={handleCloseSnack}>
                <Alert onClose={handleCloseSnack} severity="success" variant="filled" sx={{ fontSize: 24 }}>
                    Successfully Created Shift
                </Alert>
            </Snackbar>
        </Container >
    );
}})
