import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { Button, Checkbox, Container, Box, Stack, Typography, SelectChangeEvent, OutlinedInput, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, IconButton, Dialog, DialogActions, DialogContent, DialogTitle, FormControl } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import Card from '@mui/material/Card';
import { useStore } from '../../stores/store';
import { Link, useParams } from 'react-router-dom';
import ArrowCircleLeftRoundedIcon from '@mui/icons-material/ArrowCircleLeftRounded';
import NewUserModel, { NewUserModelSchema } from '../../models/NewUser';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import LoadingButton from '@mui/lab/LoadingButton';
import { Select } from '@mui/material';
import { observer } from 'mobx-react-lite';
import CircularProgress from '@mui/material/CircularProgress';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import { history } from '../../..';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CompanyRole from '../../models/CompanyRole';
import UserRoles from '../../models/UserRoles';

// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//     PaperProps: {
//         style: {
//             maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//             width: 250,
//         },
//     },
// };

export default observer(function EditUser() {
    const { userStore, setupsStore } = useStore();
    const { loading, getCompanyRoles, loadCompanyRoles, checkUniqueUsername, IsUsernameAvailable } = userStore;
    const { loadEditUser, editUser, getUserEditRoles, removeUserEditRole, addUserEditRole, savingData } = setupsStore;
    let [checkUserStatus, setCheckUserStatus] = React.useState(0); //0 mean never checked, 1 means check and not unique, 2 means checks and we are unique
    const { username } = useParams<{ username: string }>();
    const [selectedRoles, setSelectedRoles] = React.useState<string[]>([]);
    const [ openSelectRole, setOpenSelectRole ] = React.useState(false);
    const [ selectDialogRole, setSelectDialogRole ] = React.useState(''); 
    //  useEffect(() => {
    //     if(editUser){
    //         formik.values.email = editUser!.email;
    //         console.log('check e ' + formik.values.email);
    //         console.log('check data ' + editUser!.email);
    //     }
    //  }, [])
    useEffect(() => {
        loadCompanyRoles();

        if (!username) {
            return;
        }
        formik.setFieldValue("username", username);
        loadEditUser(username);
    }, [loadEditUser]);

    const formik = useFormik({
        initialValues: editUser || new NewUserModel(),
        enableReinitialize: true,
        onSubmit: values => {
            values.roles = Array.from(getUserEditRoles.values())            
            //alert(JSON.stringify(values, null, 2));
            formik.setFieldValue("username", username);
            setupsStore.putEditUser(values).then(() => {
                // console.log('done trying to edit user');
                handleGoToUserSetup();
            })
        },
        validationSchema: NewUserModelSchema,
        validateOnBlur: true,

    });

    const handleGoToUserSetup = () => {
        history.push("/users");
        history.go(0);
    }

    function IsUsernameUnique() {
        if (checkUserStatus === 0) {
            return (
                <></>
            )
        }
        if (checkUserStatus === 1) {
            return (
                <ClearRoundedIcon color="error" />
            )
        }
        if (checkUserStatus === 2) {
            return (
                <DoneRoundedIcon color="success" />
            )
        }
        return (<></>);

    };

    // const handleChangehandleChangeRole = (event: SelectChangeEvent<typeof formik.values.roleid>) => {
    //     const {
    //       target: { value },
    //     } = event;
    //     setRoles(
    //       // On autofill we get a stringified value.
    //       typeof value === 'string' ? value.split(',') : value,
    //     );
    //   };
    const handleInput = async () => {
        if (formik.values.email) {
            await IsUsernameAvailable(formik.values.email).then((response) => {
                console.log('ok ' + response);
                if (response === true) {
                    setCheckUserStatus(2);
                } else {
                    console.log('set 1');
                    setCheckUserStatus(1);
                }
            });
        }
    };
    const handleChange = () => {
        console.log('change');
    };
    const handleChange2 = (event: { target: { value: any; }; }) => {
        const {
            target: { value },
        } = event;
    };

    const handleChangeRoleDialog = (e: SelectChangeEvent<string>) => {        
         setSelectDialogRole(e.target.value.toString());
      };
    // const handleChangeRoles = (event: SelectChangeEvent<typeof String[]>) => {
    //     const {
    //         target: { value },
    //     } = event;
    //     // setSelectedRoles(      
    //     //     // On autofill we get a stringified value.
    //     // typeof value === 'string' ? value.split(',') : value,
    //     // )
    //     var temp = typeof value === 'string' ? value.split(',') : value;
    //     console.log('changed to ' + temp);
    // };
    const handleAddNewRoleClick = () => {
        setOpenSelectRole(true);
    }
    const handleCloseRoleDialogYes = () =>{
        if(selectDialogRole === undefined || getCompanyRoles === undefined){
            return;
        }
        let temp = JSON.parse(selectDialogRole);
        let cono = window.localStorage.getItem('cono');
        if(!cono){
            return;
        }
        if(!username){
            return;
        }
         let role: UserRoles = { cono: cono, roleid: temp.roleid, role: temp.role, username: username, isSelected: false, ppdbudget:0,ppdbudgetmin:0,ppdbudgetmax:0,isadministrationrole:false }
        addUserEditRole(role);
        setOpenSelectRole(false);
        console.log('add role ' + selectDialogRole);

        //addUserEditRole(getCompanyRoles.find(selectDialogRole));
    }
    const handleCloseRoleDialogNo = () =>{
        setOpenSelectRole(false);
    }
    const handleClickRemoveRole = (row: UserRoles) => {
        console.log(row.role);
        removeUserEditRole(row);
    }
    function excludeAlreadyAddedRoles(value: CompanyRole){
        return !getUserEditRoles.some((role: UserRoles) => role.roleid === value.roleid);
    }
    const handleChangeRoles = (event: SelectChangeEvent<typeof selectedRoles>) => {
        const {
            target: { value },
        } = event;
        setSelectedRoles(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleGiveUserPasswordClick = () => {
        history.push("/createnewpassword/" + username + '/');
        history.go(0);
    }

    const handleReset = () => {
        console.log('try to reset');
        formik.resetForm({
            values: { cono: window.localStorage.getItem('cono') || '1', firstname: '', lastname: '', credentials: '', email: '', optinemail: false, optintext: false, phone: '', roleid: 0, hiredate: '', roles: [], employeeid:'', username:'' },
        });
    };

    if (loading || !editUser) return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Stack style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <h1 style={{
                    textAlign: 'center', fontSize: '18',
                    fontWeight: 700,
                    letterSpacing: '.2rem',
                    color: 'inherit'
                }}>Loading User</h1>
                <CircularProgress />
            </Stack>
        </div>
    )


    return (
        <>
        <Container component="main" maxWidth="sm" sx={{ paddingBottom: 10 }}>
            <Card sx={{ margin: 2, paddingLeft: 1, paddingRight: 1 }} raised>
                <Box
                    sx={{
                        marginTop: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Stack direction="row" justifyContent="left" alignItems="center">
                        <Button component={Link} to="/users"><ArrowCircleLeftRoundedIcon fontSize="large" /></Button>
                        <Typography component="h1" variant="h5">
                            <span>Edit user</span>
                        </Typography>
                    </Stack>
                    <LoadingButton
                        sx={{ marginTop: 2, marginBottom: 2, borderRadius:5 }}
                        variant="contained"
                        color="warning"                        
                        onClick={handleGiveUserPasswordClick}
                        loading={loading}>
                        Create or Change Password
                    </LoadingButton>
                    <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                    <InputLabel id="demo-simple-select-autowidth-label" sx={{ marginTop: 2, textAlign: 'left' }}>Username</InputLabel>                        
                        <TextField
                            disabled
                            error={!!formik.errors.username}
                            name="username"
                            value={username}
                            onChange={formik.handleChange}
                            onBlur={handleInput} />
                        <InputLabel id="demo-simple-select-autowidth-label" sx={{ marginTop: 2, textAlign: 'left' }}>First Name</InputLabel>
                        <TextField
                            error={!!formik.errors.firstname}
                            name="firstname"
                            value={formik.values.firstname}
                            onChange={formik.handleChange} />

                        <InputLabel id="demo-simple-select-autowidth-label" sx={{ marginTop: 2, textAlign: 'left' }}>Last Name</InputLabel>
                        <TextField
                            error={!!formik.errors.lastname}
                            name="lastname"
                            value={formik.values.lastname}
                            onChange={formik.handleChange} />
                        <InputLabel id="demo-simple-select-autowidth-label" sx={{ marginTop: 2, textAlign: 'left' }}>Employee ID</InputLabel>
                        <TextField
                            error={!!formik.errors.employeeid}
                            name="employeeid"
                            value={formik.values.employeeid}
                            onChange={formik.handleChange} />
                        <InputLabel id="demo-simple-select-autowidth-label" sx={{ marginTop: 2, textAlign: 'left' }}>Credentials</InputLabel>
                        <TextField
                            error={!!formik.errors.credentials}
                            name="credentials"
                            value={formik.values.credentials}
                            onChange={formik.handleChange} />
                        {/* <InputLabel id="demo-simple-select-autowidth-label" sx={{ marginTop: 2, textAlign: 'left' }}>Hire Date</InputLabel>
                        <input
                            type="date"
                            name="hiredate"
                            value={formik.values.hiredate}
                            onChange={formik.handleChange}
                        /> */}                        
                            <InputLabel id="demo-simple-select-autowidth-label" sx={{ marginTop: 2, textAlign: 'left' }}>Email Address</InputLabel>
                        <TextField
                            error={!!formik.errors.email}
                            name="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={handleInput} /> {checkUniqueUsername ? <><CircularProgress /></> : <><IsUsernameUnique /></>}
                        <FormGroup>
                            <FormControlLabel onChange={formik.handleChange} name="optinemail" control={<Checkbox checked={formik.values.optinemail} />} label="Allow Email Alerts" />
                        </FormGroup>
                        <InputLabel id="demo-simple-select-autowidth-label" sx={{ marginTop: 2, textAlign: 'left' }}>Phone Number</InputLabel>
                        <TextField
                            error={!!formik.errors.phone}
                            name="phone"
                            value={formik.values.phone}
                            onChange={formik.handleChange} />
                        <FormGroup>
                            <FormControlLabel onChange={formik.handleChange} name="optintext" control={<Checkbox checked={formik.values.optintext} />} label="Allow Phone Alerts" />
                        </FormGroup>
                        <Stack direction='row'>
                            <InputLabel id="demo-simple-select-autowidth-label" sx={{ marginTop: 2,marginBottom:2, textAlign: 'left' }}>Role(s)</InputLabel>
                            <IconButton aria-label="delete" onClick={() => handleAddNewRoleClick()}>
                                <AddCircleIcon color='warning' />
                            </IconButton>
                        </Stack>
                        <div>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 200 }} size='small' aria-label="simple table">
                                    <TableHead>
                                        {/* <TableRow>
                                            <TableCell>Role</TableCell>
                                            <TableCell></TableCell>
                                        </TableRow> */}
                                    </TableHead>
                                    <TableBody>
                                        {getUserEditRoles && getUserEditRoles.map((role) =>
                                            <TableRow key={role.roleid}>
                                                <TableCell>{role.role}</TableCell>
                                                <TableCell align='right'>
                                                    <IconButton aria-label="delete" onClick={() => handleClickRemoveRole(role)}>
                                                        <DeleteIcon color='error' />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                        <LoadingButton
                            sx={{ marginTop: 2, marginBottom: 2, borderRadius: 5 }}
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={formik.submitForm}
                            loading={savingData}>
                            Save Changes
                        </LoadingButton>
                    </form>

                    {/* <div>
                        <h3>Validation Model</h3>
                        <pre>{JSON.stringify(formik.errors, null, 2)}</pre>
                    </div>
                    <div>
                        <h3>Form Model</h3>
                        <pre>{JSON.stringify(formik.values, null, 2)}</pre>
                    </div> */}
                </Box >
            </Card>
        </Container>
        <Dialog disableEscapeKeyDown open={openSelectRole}>
        <DialogTitle>Add a Role</DialogTitle>
        <DialogContent>
          <Box component="form" sx={{ display: 'flex', flexWrap: 'wrap', margin: 6 }}>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              {/* <InputLabel htmlFor="demo-dialog-native">Age</InputLabel> */}
              <Select
                name="roledialog"                
                value={selectDialogRole}
                onChange={handleChangeRoleDialog}
                input={<OutlinedInput label="" id="demo-dialog-native" />}
              >
                {getCompanyRoles.filter(excludeAlreadyAddedRoles).map((role) => (
                                <MenuItem key={role.roleid} value={JSON.stringify({roleid: role.roleid, role: role.role })}>
                                    {role.role}
                                </MenuItem>
                            ))}
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseRoleDialogNo}>Cancel</Button>
          <Button onClick={handleCloseRoleDialogYes}>Ok</Button>
        </DialogActions>
      </Dialog>
      </>
    );
})