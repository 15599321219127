export interface CompanyRole{
    cono: string;
    roleid: number;
    role: string;
    ppdbudget: number;
    ppdbudgetmin: number;
    ppdbudgetmax: number;
    isSelected: boolean; //used for filtering shifts in shifts view
    isadministrationrole:boolean
}


class CompanyRoleModel implements CompanyRole{
    cono: string = '';
    roleid: number = -1;
    role: string = '';
    ppdbudget: number = 0;
    ppdbudgetmin: number = 0;
    ppdbudgetmax: number = 0;
    isSelected: boolean = false;
    isadministrationrole: boolean = false;
}

export default CompanyRoleModel;