import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent,
    Stack,
    TextField,
} from '@mui/material';
import { observer } from "mobx-react-lite";
import { useStore } from "../../stores/store";
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from "dayjs";
import { LoadingButton } from '@mui/lab';
import { User } from '../../models/User';
import { format } from 'date-fns';

interface DialogProps {
    open: boolean;
    dorefresh: boolean;
    onClose: (value: boolean) => void;
}

interface FormValues {
    shiftDate: string;
    selectedrole: string;
    selectedshift: string;
    startTime: Date;
    endTime: Date;
    assignedUsers: string[];
}

export default observer(function AddNewShiftDialog(props: DialogProps) {
    const { userStore, shiftStore, scheduleStore } = useStore();
    const { getCompanyRoles, } = userStore;
    const { putAddUserShiftsToSchedule, loadingDialog, loadUsersForRoleAndDate, getUsersByRoleAndDate } = scheduleStore;
    const { loadShiftSetupsForRole, getShiftSetupsForRole } = shiftStore;
    const { onClose, dorefresh, open } = props;
    const [selectedshiftobject, setSelectedShiftObject] = React.useState('');
    const [selectedroleobject, setSelectedRoleObject] = React.useState('');

    useEffect(() => {
        
    }, []);
    
    const formik = useFormik<FormValues>({
        initialValues: {
            shiftDate: new Date().toISOString().split('T')[0],
            selectedrole: '',
            selectedshift: '',
            startTime: new Date(),
            endTime: new Date(),
            assignedUsers: [],
        },
        onSubmit: (values) => {
            console.log('ok eyeah' + selectedshiftobject);
            let co = window.localStorage.getItem('cono');
            if (!co) {
                return;
            }
            let shift = JSON.parse(selectedshiftobject);
            let role = JSON.parse(selectedroleobject);            
            // Handle form submission
            let user = {
                cono: co, roleid: role.roleid, shiftdate: new Date(values.shiftDate),
                shiftstarttime: new Date(new Date(values.shiftDate).setHours(values.startTime.getHours(), values.startTime.getMinutes(), 0, 0)).toLocaleString(),
                shiftendtime: new Date(new Date(values.shiftDate).setHours(values.endTime.getHours(), values.endTime.getMinutes(), 0, 0)).toLocaleString(),
                shiftid: shift.shiftid, shiftendsnextday: shift.shiftendsnextday, partialshift: false,
                usernames: values.assignedUsers,unitid:-1
            }
            scheduleStore.putAddUserShiftsToSchedule(user).then(() => {
                formik.resetForm();
                onClose(true);
            })
        },
    });

    const handleClose = () => {
        formik.resetForm();
        setSelectedShiftObject('');
        onClose(false);
    };
    const handleRoleChange = (event: SelectChangeEvent<string>) => {
        console.log(event.target.value.toString());
        setSelectedRoleObject(event.target.value.toString());
        let role = JSON.parse(event.target.value.toString());
        if (role != null && role.roleid > -1) {
            let datestring = format(new Date(formik.values.shiftDate), 'MMddyyyy');
            loadShiftSetupsForRole(role.roleid);
            loadUsersForRoleAndDate(role.roleid,datestring);
            formik.handleChange(event.target.value.toString());
        }
    };

    const handleShiftChange = (event: SelectChangeEvent<string>) => {
        console.log(event.target.value.toString());
        setSelectedShiftObject(event.target.value.toString());
        console.log(selectedshiftobject);
        let shift = JSON.parse(event.target.value.toString());
        if (shift != null && shift.shiftid > -1) {
            //loadShiftSetupsForRole(selectedRole);

            getShiftSetupsForRole.forEach((s) => {
                if (s.shiftid == shift.shiftid) {
                    formik.values.startTime = new Date(s.shiftstarttime);
                    formik.values.endTime = new Date(s.shiftendtime);
                }
            })
            formik.handleChange(event.target.value.toString());
        }
    }
    return (
        <Dialog open={open} onClose={handleClose} maxWidth='md'>
            <DialogTitle>Add Shift To Schedule</DialogTitle>
            <form onSubmit={formik.handleSubmit}>
                <DialogContent>
                    <Stack sx={{ width: 350 }}>
                        <TextField
                            autoFocus
                            id="shiftDate"
                            name="shiftDate"
                            label="Shift Date"
                            type="date"
                            value={formik.values.shiftDate}
                            onChange={formik.handleChange}
                            sx={{ margin: 1 }}
                        />
                        <FormControl sx={{ margin: 1 }}>
                            <InputLabel id="role-label">Role</InputLabel>
                            <Select
                                labelId="role-label"
                                name="roleid"
                                value={selectedroleobject}
                                onChange={handleRoleChange}>
                                {getCompanyRoles.map((role) => (
                                    <MenuItem key={role.roleid} value={JSON.stringify({ roleid: role.roleid, role: role.role })}>
                                        {role.role}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {selectedroleobject != '' ?
                            <>
                                <FormControl sx={{ margin: 1 }}>
                                    <InputLabel id="shift-label">Shift</InputLabel>
                                    <Select
                                        name="shiftid"
                                        value={selectedshiftobject}
                                        onChange={handleShiftChange}                                        
                                    >
                                        {getShiftSetupsForRole.map((shift) => (
                                            <MenuItem key={shift.shiftid} value={JSON.stringify({ shiftid: shift.shiftid, shiftendsnextday: shift.shiftendsnextday })}>
                                                {shift.descr}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </>
                            : <></>}
                        {selectedshiftobject != '' && selectedshiftobject.length > 4 ?
                            <>
                                <Stack direction={'row'}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <TimePicker
                                            readOnly={true}
                                            name="startTime"
                                            label="Start Time"
                                            minutesStep={15}
                                            value={dayjs(formik.values.startTime)}
                                            onChange={(newValue) => {
                                                formik.setFieldValue("startTime", dayjs(newValue));
                                            }}
                                            sx={{ margin: 1 }}
                                        />
                                    </LocalizationProvider>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <TimePicker
                                            readOnly={true}
                                            name="endTime"
                                            label="End Time"
                                            minutesStep={15}
                                            value={dayjs(formik.values.endTime)}
                                            onChange={(newValue) => {
                                                formik.setFieldValue("endTime", dayjs(newValue));
                                            }}
                                            sx={{ margin: 1 }}
                                        />
                                    </LocalizationProvider>
                                </Stack>
                                <FormControl sx={{ margin: 1 }}>
                                    <InputLabel id="assigned-users-label">Assigned Users</InputLabel>
                                    <Select
                                        labelId="assigned-users-label"
                                        id="assignedUsers"
                                        name="assignedUsers"
                                        multiple
                                        value={formik.values.assignedUsers}
                                        onChange={formik.handleChange}
                                        renderValue={(selected) => selected.join(', ')}
                                    >
                                        {getUsersByRoleAndDate.map((user) => (
                                            <MenuItem key={user.username} value={user.username}>
                                                <Checkbox checked={formik.values.assignedUsers.indexOf(user.username) > -1} />
                                                <ListItemText>
                                                    {user.lastname}, {user.firstname} - {user.username}
                                                </ListItemText>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </> : <></>}
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' onClick={handleClose}>CANCEL</Button>
                    <LoadingButton variant='contained' loading={loadingDialog} type="submit">ADD TO SCHEDULE</LoadingButton>
                </DialogActions>
            </form>
        </Dialog>
    );
})