import React, { useEffect } from 'react';
import { observer } from "mobx-react-lite";
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControl, Grid, IconButton, InputLabel, LinearProgress, List, ListItem, ListItemText, MenuItem, Select, SelectChangeEvent, Stack, Typography } from '@mui/material';
import { useStore } from '../../stores/store';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import { format } from 'date-fns';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { NewShiftInRotation } from '../../models/Schedule/NewShiftInRotation';
import { LoadingButton } from '@mui/lab';
import DeleteIcon from '@mui/icons-material/Delete';

interface SelectRoleDialogProps {
    open: boolean;
    name: string;
    username: string;
    dow: string;
    roleid: number;
    role: string;
    rotationid: number;
    unitid: string;
    shiftid: string;
    shiftstarttime: Date;
    shiftendtime: Date;
    onClose: (value: boolean) => void;
    isEditing: boolean;
}
interface Rotation {
    dow: string;
    shiftid: number;
    starttime: Date;
    endTime: Date;
    shiftendsnextday: boolean;
    partialshift: boolean;
    descr: string;
}

interface FormValues {
    shiftDate: string;
    selectedrole: string;
    selectedshift: string;
    startTime: Date;
    endTime: Date;
}

export default observer(function AddNewRotationShift(props: SelectRoleDialogProps) {
    const { shiftStore, scheduleStore } = useStore();
    const { loadShiftSetupsForRole, getShiftSetupsForRole } = shiftStore;
    const { loadUsersForRoleAndDate, putNewShiftInRotation, loading, getUnitSetups, removeShiftFromRotation } = scheduleStore;
    const { onClose, open } = props;
    const [selectedroleobject, setSelectedRoleObject] = React.useState('');
    const [selectedshiftobject, setSelectedShiftObject] = React.useState('');
    const [selectedunitobject, setSelectedUnitObject] = React.useState('');

    const [originalStartTime, setOriginalStartTime] = React.useState(new Date()); //used to see if we are doing a partial shift
    const [originalEndTime, setOriginalEndTime] = React.useState(new Date());

    const [loadingEdit, setLoadingEdit] = React.useState(true);
    const [areYouSureDeleteShiftOpen, setAreYouSureDeleteShiftOpen] = React.useState(false);

    useEffect(() => {
        if (props.open) {
            console.log('is editing' + props.isEditing);
            if (props.isEditing) { //if we are editing, then we want to set the form to show the loading until all the data is loaded and filled in
                setLoadingEdit(true);
            }
            loadShiftSetupsForRole(props.roleid).then(() => {
                console.log('is editing' + props.isEditing + ' and ' + props.unitid);
                if (props.unitid != '') {
                    setSelectedUnitObject(props.unitid);
                }
                if (props.shiftid != '') {
                    setSelectedShiftObject(props.shiftid);
                    formik.values.startTime = new Date(props.shiftstarttime);
                    formik.values.endTime = new Date(props.shiftendtime);
                }
                setLoadingEdit(false);
            });
            // loadUnitSetups().then(() => {
            //     console.log('try to set unit ' + props.unitid);
            //     if (props.unitid != '') {
            //         setSelectedUnitObject(props.unitid);
            //     }
            //     if (props.shiftid != '') {
            //         setSelectedShiftObject(props.shiftid);
            //         formik.values.startTime = new Date(props.shiftstarttime);
            //         formik.values.endTime = new Date(props.shiftendtime);
            //     }
            //     setLoadingEdit(false);
            // });

        }

    }, [props.username, props.dow, props.shiftid]);

    const handleClickDeleteShiftNo = () =>{
        setAreYouSureDeleteShiftOpen(false);
    }

    const handleClickDeleteShiftYes = () =>{
        let cono = window.localStorage.getItem('cono');
        if (!cono) {
            return;
        }
        let shift = JSON.parse(selectedshiftobject);
        let rot = {
            cono: cono, roleid: props.roleid, username: props.username,
            rotationid: props.rotationid, shiftid: shift.shiftid, dow: props.dow.toUpperCase()
        };

        removeShiftFromRotation(rot).then(() => {
            setAreYouSureDeleteShiftOpen(false);
            setSelectedShiftObject('');
            setSelectedUnitObject('');
            formik.resetForm();
            onClose(true);
        })  
    }

    const handleClickRemoveShift = () =>{
        setAreYouSureDeleteShiftOpen(true);
    }

    const handleUnitChange = (event: SelectChangeEvent<string>) => {
        setSelectedUnitObject(event.target.value.toString());
        let unit = JSON.parse(event.target.value.toString());

        if (unit != null && unit.unitid > -1) {
            formik.handleChange(event.target.value.toString());
        }
    }

    const handleShiftChange = (event: SelectChangeEvent<string>) => {
        console.log(event.target.value.toString());
        setSelectedShiftObject(event.target.value.toString());
        console.log(selectedshiftobject);
        let shift = JSON.parse(event.target.value.toString());
        if (shift != null && shift.shiftid > -1) {
            //loadShiftSetupsForRole(selectedRole);

            getShiftSetupsForRole.forEach((s) => {
                if (s.shiftid == shift.shiftid) {
                    formik.values.startTime = new Date(s.shiftstarttime);
                    formik.values.endTime = new Date(s.shiftendtime);
                    setOriginalStartTime(s.shiftstarttime);
                    setOriginalEndTime(s.shiftendtime);
                }
            })
            formik.handleChange(event.target.value.toString());
        }
    }

    const handleClose = () => {
        setSelectedShiftObject('');
        setSelectedUnitObject('');
        formik.resetForm();
        onClose(false);
    };

    function areDatesEqual(date1: Date, date2: Date): boolean {
        // Convert both dates to the same timezone (e.g., UTC)
        const utcDate1 = Date.UTC(
            date1.getFullYear(),
            date1.getMonth(),
            date1.getDate(),
            date1.getHours(),
            date1.getMinutes(),
            date1.getSeconds()
        );
        const utcDate2 = Date.UTC(
            date2.getFullYear(),
            date2.getMonth(),
            date2.getDate(),
            date2.getHours(),
            date2.getMinutes(),
            date2.getSeconds()
        );

        // Compare the UTC timestamps
        return utcDate1 === utcDate2;
    }

    function doesShiftEndNextDay(date1: Date, date2: Date): boolean {
        // Convert both dates to the same timezone (e.g., UTC)
        if (date2.getHours() < date1.getHours()) {
            return true;
        }
        else {
            return false;
        }
    }

    const handleSaveShiftClick = () => {
        let cono1 = window.localStorage.getItem('cono');
        if (!cono1) {
            return;
        }
        let shift = JSON.parse(selectedshiftobject);
        let unit = { unitid: -1, unitname: '' };
        if (selectedunitobject != '') {
            unit = JSON.parse(selectedunitobject);
        }
        let rot = {
            cono: cono1, roleid: props.roleid, username: props.username, rotationid: props.rotationid, dow: props.dow,
            shiftid: shift.shiftid, shiftstarttime: formik.values.startTime, shiftendtime: formik.values.endTime, shiftendsnextday: false, partialshift: false, descr: '',
            shiftstarttimestring: '', shiftendtimestring: '', unitid: unit.unitid
        };
        rot.shiftstarttimestring = new Date(formik.values.startTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        rot.shiftendtimestring = new Date(formik.values.endTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        if (areDatesEqual(new Date(originalStartTime), new Date(formik.values.startTime)) && areDatesEqual(new Date(originalEndTime), new Date(formik.values.endTime))) {
            rot.partialshift = false;
        }
        else {
            rot.partialshift = true;
        }
        rot.shiftendsnextday = doesShiftEndNextDay(new Date(formik.values.startTime), new Date(formik.values.endTime));

        console.log(rot);
        putNewShiftInRotation(rot).then(() => {
            setSelectedShiftObject('');
            setSelectedUnitObject('');
            formik.resetForm();
            onClose(true);
        })
    }

    const formik = useFormik<FormValues>({
        initialValues: {
            shiftDate: new Date().toISOString().split('T')[0],
            selectedrole: '',
            selectedshift: '',
            startTime: new Date(),
            endTime: new Date(),
        },
        onSubmit: (values) => {
            console.log(values);
        }
    });

    return (
        <>
            <Dialog open={open} onClose={handleClose} maxWidth='md'>
                {props.isEditing ?
                    <DialogTitle>Edit Shift In Rotation</DialogTitle>
                    :
                    <DialogTitle>Add Shift To Rotation</DialogTitle>
                }
                {loadingEdit ?
                    <>
                        <DialogContent dividers={true}>
                            <LinearProgress />
                        </DialogContent>
                    </>
                    :
                    <DialogContent dividers={true}>

                        <Stack alignContent={'center'} alignItems={'center'}>
                            <Typography sx={{ margin: 1 }}>User: {props.name}</Typography>
                            <Typography sx={{ margin: 1 }}>Day Of Week: {props.dow}</Typography>
                            <Typography sx={{ margin: 1 }}>Role: {props.role}</Typography>
                            <FormControl sx={{ marginTop: 1, marginRight: 1 }}>
                                <InputLabel id="Unit-labelid">Unit</InputLabel>
                                <Select
                                    labelId="Unit-labelid"
                                    label="Unit"
                                    sx={{ width: 260, fontSize: 13, height: 50, margin: 1 }}
                                    value={selectedunitobject}
                                    onChange={handleUnitChange}>
                                    {getUnitSetups.map((unit) => (
                                        <MenuItem key={unit.unitid} value={JSON.stringify({ unitid: unit.unitid, unitname: unit.unitname })}>
                                            {unit.unitname}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl sx={{ marginTop: 1, marginRight: 1 }}>
                                <InputLabel id="shift-labelid">Shift</InputLabel>
                                <Select
                                    labelId="shift-labelid"
                                    label="Shift"
                                    sx={{ width: 260, fontSize: 13, height: 50, margin: 1 }}
                                    value={selectedshiftobject}
                                    onChange={handleShiftChange}>
                                    {getShiftSetupsForRole.map((shift) => (
                                        <MenuItem key={shift.shiftid} value={JSON.stringify({ shiftid: shift.shiftid, shiftendsnextday: shift.shiftendsnextday })}>
                                            {shift.descr}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            {selectedshiftobject != '' ?
                                <>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <TimePicker
                                            name="startTime"
                                            label="Start Time"
                                            minutesStep={15}
                                            value={dayjs(formik.values.startTime)}
                                            onChange={(newValue) => {
                                                formik.setFieldValue("startTime", dayjs(newValue));
                                            }}
                                            formatDensity='dense'
                                            slotProps={{ textField: { size: 'small' } }}
                                            sx={{ marginTop: 1, width: 135, height: 50 }}
                                        />
                                    </LocalizationProvider>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <TimePicker
                                            name="endTime"
                                            label="End Time"
                                            minutesStep={15}
                                            value={dayjs(formik.values.endTime)}
                                            onChange={(newValue) => {
                                                formik.setFieldValue("endTime", dayjs(newValue));
                                            }}
                                            formatDensity='dense'
                                            slotProps={{ textField: { size: 'small' } }}
                                            sx={{ margin: 1, width: 135, height: 50 }}
                                        />
                                    </LocalizationProvider>
                                </>
                                : <></>}
                        </Stack>
                    </DialogContent>
                }
                <DialogActions>
                    <Box display="flex" justifyContent="space-between" width="100%">
                        {props.isEditing ?
                            <LoadingButton sx={{ width:35,height:35}} loading={loading} variant='contained' color='error' onClick={handleClickRemoveShift}>
                                <DeleteIcon sx={{ fontSize: 16 }} />
                                </LoadingButton>
                            : <></>}
                        <Box display="flex" justifyContent="end" sx={{ alignContent: 'end', alignItems: 'end', alignSelf: 'end' }}>
                            <Button variant='outlined' onClick={handleClose}>Cancel</Button>
                            <LoadingButton sx={{ marginLeft: 1 }} loading={loading} variant='contained' onClick={handleSaveShiftClick}>Save Shift</LoadingButton>
                        </Box>
                    </Box>
                </DialogActions>
            </Dialog>
            <Dialog
                open={areYouSureDeleteShiftOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Are You Sure?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to remove this shift?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' onClick={handleClickDeleteShiftNo}>No</Button>
                    <LoadingButton loading={loading} variant='contained' onClick={handleClickDeleteShiftYes} autoFocus>
                        Yes
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
})