import { observer } from "mobx-react-lite";
import React, { useEffect } from 'react';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import { Stack, Box } from '@mui/system';
import { useStore } from "../../../app/stores/store";
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent, Snackbar, TextFieldProps, Tooltip, Typography, List, ListItemButton, ListItemIcon, ListItemText, ListItem, LinearProgress, Checkbox } from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { DataGrid, GridCellModes, GridCellModesModel, GridCellParams, GridColDef, GridRenderCellParams, GridRowModel } from "@mui/x-data-grid";
import { blue } from '@mui/material/colors';
import ShiftTemplate from "../../models/Setups/ShiftTemplate";
import DeleteIcon from '@mui/icons-material/Delete';
import ViewListIcon from '@mui/icons-material/ViewList';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ApplyTemplateCalendarView from "./ApplyTemplateCalendarView";
import PeopleIcon from '@mui/icons-material/People';
import { User } from "../../models/User";
import AutoAssignedUserForTemplateModel from "../../models/Setups/AutoAssignedUserForTemplate";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        fontSize: 16,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableCell2 = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.info.light,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 12,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

function getNextSunday() {
    let dt = new Date();
    switch (dt.getDay()) {
        case 0:
            break;
        case 1:
            dt.setDate(dt.getDate() + 6);
            break;
        case 2:
            dt.setDate(dt.getDate() + 5);
            break;
        case 3:
            dt.setDate(dt.getDate() + 4);
            break;
        case 4:
            dt.setDate(dt.getDate() + 3);
            break;
        case 5:
            dt.setDate(dt.getDate() + 2);
            break;
        case 6:
            dt.setDate(dt.getDate() + 1);
            break;
    }
    return dt;
}
function getPreviousSunday() {
    let dt = new Date();
    switch (dt.getDay()) {
        case 0:
            dt.setDate(dt.getDate() - 1);
            break;
        case 1:
            dt.setDate(dt.getDate() - 7);
            break;
        case 2:
            dt.setDate(dt.getDate() - 6);
            break;
        case 3:
            dt.setDate(dt.getDate() - 5);
            break;
        case 4:
            dt.setDate(dt.getDate() - 4);
            break;
        case 5:
            dt.setDate(dt.getDate() - 3);
            break;
        case 6:
            dt.setDate(dt.getDate() - 2);
            break;
    }
    return dt;
}
export default observer(function CreateShiftsWithTemplate() {
    const nextSunday = dayjs().endOf('week').startOf('day').add(1, 'day');
    const { setupsStore, shiftStore, userStore } = useStore();
    const { putShiftsByTemplate } = shiftStore;
    const { getTemplateSetups, loadTemplateSetups, getTemplateDetails, templateDetails,
        loadTemplateDetails, setTemplateDetailsStartDate, editTemplateDetail,
        loadAutoAssignUsersForTemplate, getAutoAssignedUsersForTemplate, getUserListForApplyingTemplate,
        loadUserListForApplyingTemplates, removeAutoAssignedUserForTemplate, addAutoAssignUserForTemplate } = setupsStore;
    const [selectedTemplate, setSelectedTemplate] = React.useState('');
    const [selectedTemplateid, setSelectedTemplateid] = React.useState(-1);
    const [selectedRoleid, setSelectedRoleid] = React.useState(-1);
    const [selectedShiftid, setSelectedShiftid] = React.useState(-1);
    const [selectedDow, setSelectedDow] = React.useState('');
    const [startDate, setStartDate] = React.useState(getNextSunday());
    const [pageSize, setPageSize] = React.useState<number>(20);
    const [cellModesModel, setCellModesModel] = React.useState<GridCellModesModel>({});
    const [removedRows, setRemovedRow] = React.useState<number[]>([]);
    const [openareyousure, setOpenAreyouSure] = React.useState(false);
    const [isediting, setIsEditing] = React.useState(false);
    const [openSuccessSnack, setOpenSuccessSnack] = React.useState(false);
    const [showListView, setShowListView] = React.useState(true);
    const [showAssignedUsers, setShowAssignedUsers] = React.useState(false);

    const handleToggle = (u: User) => () => {
        // for (const each of getUsersForTemplateShift) {
        //     if (each.username == u.username) {
        //         each.AutoAssigned = !u.AutoAssigned;
        //     }
        // }
        if (getIsUserAutoAssigned(u.username)) {
            removeAutoAssignedUserForTemplate(selectedDow, selectedShiftid, u.username);
        }
        else {
            addAutoAssignUserForTemplate(selectedTemplateid, selectedRoleid, selectedDow, selectedShiftid, u.username);
        }
    };

    function getNumberOfAssignedUsers(template: ShiftTemplate) {
        let count: number = 0;
        for (const each of getAutoAssignedUsersForTemplate) {
            if (each.templateid == template.templateid && each.roleid == template.roleid && each.dow == template.dow) {
                count += 1;
            }
        }
        return count;
    }

    function getIsUserAutoAssigned(username: string) {
        for (const each of getAutoAssignedUsersForTemplate) {
            if (each.username == username && each.dow == selectedDow && each.shiftid == selectedShiftid) {
                return true;
            }
        }
        return false;
    }

    const handleSelectAssignedUsersClosed = () => {
        setShowAssignedUsers(false);
    }

    const handleCloseAssignUsersDialogSave = () => {
        setShowAssignedUsers(false);
    }

    const handleCellClick = React.useCallback(
        (params: GridCellParams, event: React.MouseEvent) => {
            if (!params.isEditable) {
                return;
            }

            // Ignore portal
            if (!event.currentTarget.contains(event.target as Element)) {
                return;
            }

            setCellModesModel((prevModel) => {
                return {
                    // Revert the mode of the other cells from other rows
                    ...Object.keys(prevModel).reduce(
                        (acc, id) => ({
                            ...acc,
                            [id]: Object.keys(prevModel[id]).reduce(
                                (acc2, field) => ({
                                    ...acc2,
                                    [field]: { mode: GridCellModes.View },
                                }),
                                {},
                            ),
                        }),
                        {},
                    ),
                    [params.id]: {
                        // Revert the mode of other cells in the same row
                        ...Object.keys(prevModel[params.id] || {}).reduce(
                            (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
                            {},
                        ),
                        [params.field]: { mode: GridCellModes.Edit },
                    },
                };
            });
        },
        [],
    );

    const handleCloseSnack = () => {
        setOpenSuccessSnack(false);
    }

    const handleCellModesModelChange = React.useCallback(
        (newModel: GridCellModesModel) => {
            setCellModesModel(newModel);
        },
        [],
    );

    const handleClose = () => {
        setOpenAreyouSure(false);
    }
    const handleCloseYes = () => {
        //actually save the shifts
        setIsEditing(true);
        setOpenAreyouSure(false);
        console.log('ok ' + JSON.stringify(myrows));
        putShiftsByTemplate(JSON.stringify(myrows)).then(() => {
            setSelectedTemplate('');
            setIsEditing(false);
            setupsStore.clearTemplateDetails();
            setOpenSuccessSnack(true);
        });
    }
    const clickShowListView = () => {
        setShowListView(true);
    }

    const clickShowCalendarView = () => {
        console.log('temalte ' + selectedTemplateid);
        setShowListView(false);
    }

    useEffect(() => {
        loadTemplateSetups();
    }, []);

    const lastMonday = dayjs().startOf('week');

    const isWeekend = (date: dayjs.Dayjs) => {
        const day = date.day();

        return day !== 0;
    };

    function showDatagrid() {
        let result = true;
        if (selectedTemplate == '') { result = false; }
        return result;
    }

    function excludeRemovedShifts() {

    }
    const onCellEditStart = () => {
        setIsEditing(true);
    }
    const onCellEditStop = () => {
        setIsEditing(false);
    }

    const applytemplateclick = () => {
        //alert(JSON.stringify(myrows, null, 2));
        setOpenAreyouSure(true);
        //putShiftsByTemplate(JSON.stringify(myrows));
    }

    const handleSelectTemplate = (e: SelectChangeEvent<string>) => {
        let temp = JSON.parse(e.target.value);
        console.log('getting shifts for template' + temp.roleid + ' - ' + temp.templateid);
        if (temp.roleid > -1 && temp.templateid > -1) {
            setSelectedTemplate(e.target.value.toString());
            setSelectedTemplateid(temp.templateid);
            setSelectedRoleid(temp.roleid);
            setRemovedRow([]);
            loadUserListForApplyingTemplates(temp.roleid, temp.templateid).then(() => {
                console.log('auto assigned user count ' + getUserListForApplyingTemplate.length)
            })
            loadAutoAssignUsersForTemplate(temp.roleid, temp.templateid);
            loadTemplateDetails(temp.roleid, temp.templateid).then(() => {
                console.log('try set ' + startDate);
                setTemplateDetailsStartDate(startDate);
            })

        } else {
            //clearShiftSetupsForRole();
            setSelectedTemplate('');
            setSelectedTemplateid(-1);
            setSelectedRoleid(-1);
            setupsStore.clearTemplateDetails();
        }
    }

    const handleClickRemoveRow = (row: GridRenderCellParams<any, any, any>) => {
        templateDetails.delete(row.row.id);
    }

    const handleClickAssignUsers = (row: GridRenderCellParams<any, any, any>) => {
        //templateDetails.delete(row.row.id);
        let shift: ShiftTemplate = row.row;
        //loadUsersForTemplateShift(shift.roleid, shift.templateid, shift.dow, shift.shiftid);
        setSelectedDow(shift.dow);
        setSelectedShiftid(shift.shiftid);
        setShowAssignedUsers(true);
    }

    const columns: GridColDef[] = [
        { field: 'dow', headerName: 'DOW', flex: .9, headerClassName: 'super-app-theme--header' },
        {
            field: 'shiftdate', headerName: 'Shift Date', flex: .9, headerClassName: 'super-app-theme--header',
            valueFormatter: (params) => dayjs(params.value).format('MM/DD/YYYY')
        },
        {
            field: 'shiftstarttime', headerName: 'Start Time', flex: .9, headerClassName: 'super-app-theme--header',
            valueFormatter: (params) => dayjs(params.value).format('hh:mm A'),
        },
        {
            field: 'shiftendtime', headerName: 'End Time', flex: .9, headerClassName: 'super-app-theme--header',
            valueFormatter: (params) => dayjs(params.value).format('hh:mm A'),
        },
        { field: 'shiftdescr', headerName: 'Shift Description', flex: 1, headerClassName: 'super-app-theme--header' },
        { field: 'templateshiftdescr', headerName: 'Description/Details', flex: 1, headerClassName: 'super-app-theme--header', editable: true, type: 'string' },
        { field: 'openings', headerName: 'Openings', flex: .6, headerClassName: 'super-app-theme--header', editable: true, type: 'singleSelect', valueOptions: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15'] },
        { field: 'autoconfirm', headerName: 'Auto Confirm', flex: .6, headerClassName: 'super-app-theme--header', editable: true, type: 'singleSelect', valueOptions: ['true', 'false'] },
        {
            field: "autoassign",
            headerName: "Assigned Users",
            sortable: false,
            minWidth: 80,
            flex: .5,
            align: 'center',
            headerClassName: 'super-app-theme--header',
            renderCell: (row) => {
                if (getNumberOfAssignedUsers(row.row) > 0) {
                    return (
                        <>
                            <Tooltip title="Auto Assigned Users For This Shift">
                                <IconButton aria-label="delete" onClick={() => handleClickAssignUsers(row)}>
                                    <PeopleIcon color='warning' />
                                </IconButton>
                            </Tooltip>{getNumberOfAssignedUsers(row.row)}</>);
                }
                return (
                    <Tooltip title="Auto Assigned Users For This Shift">
                        <IconButton aria-label="delete" onClick={() => handleClickAssignUsers(row)}>
                            <PeopleIcon color='warning' />
                        </IconButton>
                    </Tooltip>);
            }
        },
        // { field: 'autoassignusercount', headerName: '', flex: .1, headerClassName: 'super-app-theme--header', editable: false },
        {
            field: "remove",
            headerName: "",
            sortable: false,
            minWidth: 50,
            flex: .2,
            align: 'center',
            headerClassName: 'super-app-theme--header',
            renderCell: (row) => {
                return (
                    <Tooltip title="Remove Shift">
                        <IconButton aria-label="delete" onClick={() => handleClickRemoveRow(row)}>
                            <DeleteIcon color='error' />
                        </IconButton>
                    </Tooltip >);
            }
        },
    ];

    function includeOnlyForThisShiftAndDOW(value: AutoAssignedUserForTemplateModel,) {
        return value.shiftid == selectedShiftid && value.dow == selectedDow;
    }

    let myrows = getTemplateDetails.map((shift) => {
        return {
            cono: shift.cono,
            id: shift.rownum,
            roleid: shift.roleid,
            templateid: shift.templateid,
            role: shift.role,
            dow: shift.dow,
            shiftdate: shift.shiftdate,
            shiftstarttime: shift.shiftstarttime,
            shiftendtime: shift.shiftendtime,
            shiftdescr: shift.shiftdescr,
            openings: shift.openings,
            autoconfirm: shift.autoconfirm,
            shiftid: shift.shiftid,
            templateshiftdescr: shift.templateshiftdescr,
            createdbyusername: shift.createdbyusername,
            rownum: shift.rownum,
            autoassignusercount: shift.autoassignusercount,
            autoassignedusers: JSON.stringify(getAutoAssignedUsersForTemplate.filter((value) => { if (value.shiftid == shift.shiftid && value.dow == shift.dow) return true }).map((shift) => shift.username)),
        }
    })

    const updateRowData = (newRow: GridRowModel) => {
        const updatedRow = { ...newRow, isNew: false };
        let shift: ShiftTemplate = {
            cono: newRow.cono, id: newRow.rownum, roleid: newRow.roleid, templateid: newRow.templateid,
            role: newRow.role, dow: newRow.dow, shiftdate: newRow.shiftdate, shiftstarttime: newRow.shiftstarttime,
            shiftendtime: newRow.shiftendtime, shiftdescr: newRow.shiftdescr, openings: newRow.openings,
            shiftid: newRow.shiftid, templateshiftdescr: newRow.templateshiftdescr, createdbyusername: newRow.createdbyusername,
            autoconfirm: newRow.autoconfirm, shiftendsnextday: newRow.shiftendsnextday, rownum: newRow.rownum,
            orderno: newRow.orderno, autoassignusercount: newRow.autoassignusercount, autoassignedusers: ''
        }
        editTemplateDetail(shift);
        console.log(newRow.rownum);
        return updatedRow;
    }
    // const deleteRow = (newRow: GridRowModel) => {
    //     const updatedRow = { ...newRow, isNew: false };

    //     return updatedRow;
    // }

    return (
        <>
            <Stack style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: 55 }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Stack style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <h1 style={{
                            textAlign: 'center', fontSize: '18',
                            fontWeight: 700,
                            color: 'inherit',
                        }}>Apply a Template</h1>
                        {/* <Box sx={{ width: '100%' }}>
                        <LinearProgress />
                    </Box> */}
                        <InputLabel id="demo-simple-select-label">Select a Template</InputLabel>
                        <Select
                            name="templates"
                            value={selectedTemplate}
                            onChange={handleSelectTemplate}
                            style={{ display: "block", height: '50', minWidth: 200, marginBottom: 10 }}>
                            {getTemplateSetups.map(template =>
                                <MenuItem key={template.rownum} value={JSON.stringify({ roleid: template.roleid, templateid: template.templateid })}>{template.role} - {template.templatedescr}</MenuItem>
                            )}
                        </Select>
                        <Stack direction='row' marginLeft={11}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker sx={{ marginBottom: 2 }} value={dayjs(startDate)} label="Start Date (Sunday)" minDate={dayjs(getPreviousSunday())}
                                    shouldDisableDate={isWeekend}
                                    onChange={(newValue) => {
                                        if (newValue === null) {
                                            return;
                                        }
                                        setStartDate(newValue.toDate());
                                        setTemplateDetailsStartDate(newValue.toDate() || getNextSunday());
                                        clickShowListView();
                                    }} />
                            </LocalizationProvider>
                            <Stack direction='row'>
                                <Tooltip title="List View">
                                    <IconButton
                                        sx={{ marginLeft: 3 }}
                                        aria-label="expand row"
                                        size="small"
                                        onClick={clickShowListView}>
                                        {showListView ? <ViewListIcon color='primary' /> : <ViewListIcon />}
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Calendar View">
                                    <IconButton
                                        aria-label="expand row"
                                        size="small"
                                        onClick={clickShowCalendarView}>
                                        {showListView ? <CalendarTodayIcon /> : <CalendarTodayIcon color='primary' />}
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                        </Stack>
                    </Stack>
                </div>

                {/* {selectedTemplate != '' && <>
                    <TableContainer component={Paper} sx={{ marginLeft: 4, marginRight: 4 }}>
                        <Table sx={{ minWidth: 300 }} size='small' aria-label="simple table">
                            <TableHead>
                                <StyledTableRow key="100">
                                    <StyledTableCell align="center">DOW</StyledTableCell>
                                    <StyledTableCell align="center">Date</StyledTableCell>
                                    <StyledTableCell align="center">Shift Start Time</StyledTableCell>
                                    <StyledTableCell align="center">Shift End Time</StyledTableCell>
                                    <StyledTableCell align="center">Shift Descr</StyledTableCell>
                                    <StyledTableCell align="center">Openings</StyledTableCell>
                                    <StyledTableCell align="center">Auto Confirm</StyledTableCell>
                                    <StyledTableCell width={200}></StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {getTemplateDetails && getTemplateDetails.map((role) =>
                                    <StyledTableRow key={role.roleid}>
                                        <StyledTableCell align="center">{role.dow}</StyledTableCell>
                                        <StyledTableCell align="center">{role.shiftdate && format(new Date(role.shiftdate), "MM/dd/yyyy")}</StyledTableCell>
                                        <StyledTableCell align="center">{format(new Date(role.shiftstarttime), "hh:mm a")}</StyledTableCell>
                                        <StyledTableCell align="center">{format(new Date(role.shiftendtime), "hh:mm a")}</StyledTableCell>
                                        <StyledTableCell align="center">{role.shiftdescr}</StyledTableCell>
                                        <StyledTableCell align="center">{role.openings}</StyledTableCell>
                                        <StyledTableCell align="center">{role.autoconfirm}</StyledTableCell>
                                        <StyledTableCell width={200}>
                                            <Button
                                                sx={{ margin: 1 }}
                                                variant="contained"
                                                color="error"
                                                component={Link} to={'/editrole/' + role.roleid}>
                                                Remove Shift
                                            </Button>
                                        </StyledTableCell>
                                    </StyledTableRow >
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Button
                        sx={{ margin: 1 }}
                        variant="contained"
                        color="primary"
                        component={Link} to="/addnewrole"
                    >
                        Apply Template and Create Shifts
                    </Button>
                </>} */}
                {/* { showDatagrid() && */}
                {showListView ?
                    <>
                        {myrows.length > 0 && selectedTemplateid >= 0 ?
                            <Box
                                sx={{
                                    height: 300,
                                    width: '100%',
                                    '& .super-app-theme--header': {
                                        backgroundColor: blue[700],
                                        fontWeight: 'bold',
                                        color: 'white',
                                    },
                                    marginBottom: 5,
                                    marginLeft: 1,
                                    marginRight: 1
                                }}
                            >
                                <DataGrid
                                    processRowUpdate={(updatedRow, originalRow) =>
                                        updateRowData(updatedRow)}
                                    sx={{
                                        '.MuiDataGrid-columnSeparator': {
                                            display: 'none',
                                        },
                                        '&.MuiDataGrid-root': {
                                            border: 'none',
                                        },
                                        marginBottom: 1,
                                        marginLeft: 1,
                                        marginRight: 1
                                    }}
                                    experimentalFeatures={{ newEditingApi: true }}
                                    autoHeight={true} columns={columns} rows={myrows} pageSize={20}
                                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                    rowsPerPageOptions={[20, 50, 100]} disableSelectionOnClick={true}
                                    onRowEditStart={onCellEditStart}
                                    onRowEditStop={onCellEditStop}
                                    onRowEditCommit={onCellEditStop}
                                    initialState={{
                                        sorting: {
                                            sortModel: [{ field: 'shiftdate', sort: 'asc' }],
                                        },
                                    }}
                                    editMode="row"
                                ></DataGrid>
                                <Button
                                    sx={{ marginBottom: 10, marginLeft: 1, borderRadius:5  }}
                                    variant="contained"
                                    color="primary"
                                    onClick={applytemplateclick}
                                    disabled={isediting}
                                >
                                    Apply Template and Create Shifts
                                </Button>
                            </Box> : <></>}
                    </> :
                    <>{myrows.length > 0 && selectedTemplateid >= 0 ? <ApplyTemplateCalendarView defaultdate={startDate} /> : <></>}
                    </>}
            </Stack>
            <Dialog
                open={openareyousure}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Are you sure?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Typography paragraph>
                            Are you sure you want to create these shifts?
                        </Typography>
                        <Typography paragraph>
                            This will create {getTemplateDetails.length} shift(s)
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>No</Button>
                    <Button onClick={handleCloseYes} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={showAssignedUsers}
                onClose={handleSelectAssignedUsersClosed}
                fullWidth={true}
                maxWidth={'sm'}>
                <Stack margin={4}>
                    <h1 style={{
                        textAlign: 'center', fontSize: '16',
                        fontWeight: 700,
                        color: 'inherit', marginTop: 2, marginBottom: 0,
                    }}>Auto Assign Shift To User(s)</h1>
                    <Typography variant="subtitle1" textAlign={'center'}>When this template is applied, these users will automatically be assigned this shift</Typography>
                    <Typography variant="subtitle2" textAlign={'center'} color='GrayText'>(These users will not count for your Number of Openings for a shift)</Typography>
                    <>
                        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                            {getUserListForApplyingTemplate.map((user) => (
                                <ListItem
                                    key={user.username}
                                    disablePadding>
                                    <ListItemButton key={user.username} onClick={handleToggle(user)} dense>
                                        <ListItemIcon>
                                            <Checkbox
                                                edge="start"
                                                checked={getIsUserAutoAssigned(user.username)}
                                                tabIndex={-1}
                                                disableRipple
                                                inputProps={{ 'aria-labelledby': user.username }}
                                            />
                                        </ListItemIcon>
                                        <ListItemText id={user.username} primary={`${user.lastname}, ${user.firstname} - ${user.username} (${user.Roles}) `} />
                                    </ListItemButton>
                                </ListItem>
                            ))
                            }
                        </List>
                    </>
                </Stack>
                <DialogActions>
                    <Button onClick={handleCloseAssignUsersDialogSave}>Close</Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={openSuccessSnack} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} sx={{ width: "100%" }} autoHideDuration={5000} onClose={handleCloseSnack}>
                <Alert onClose={handleCloseSnack} severity="success" variant="filled" sx={{ fontSize: 24 }}>
                    Successfully Created Shifts
                </Alert>
            </Snackbar>
        </>
    );
})