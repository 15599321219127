import { ErrorMessage, Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { Button, Stack } from '@mui/material';
import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import { UserFormValuesModel } from '../../models/User';
import { useStore } from '../../stores/store';
import { useNavigate } from "react-router-dom";
import { Link as Link1 } from "react-router-dom";
import LoadingButton from '@mui/lab/LoadingButton';
import Link from '@mui/material/Link';
import * as Yup from "yup";

export default observer(function EnterCompanyCode() {
    const { userStore } = useStore();
    const { loading } = userStore;

    const [error, setError] = React.useState('');

    const ValidSchema = Yup.object().shape({
        companycode: Yup.string().trim()
            .required("Company Code Is Required"),
    });

    useEffect(() => {
        document.title = 'Enter Company Code';
    }, []);

    const formik = useFormik({
        initialValues: {companycode: ''},
        onSubmit: (values, { setErrors }) => {
            setError('');
            //userStore.login(values).catch(error => setError('Invalid Email or Password'));
            //routeChange();
            let path = `/register/` + values.companycode.trim();
            navigate(path);
        },
        validationSchema: ValidSchema,
        validateOnBlur: true,
    });

    let navigate = useNavigate();
    const routeChange = () => {
        let path = `/home`;
        navigate(path);
    }

    // const handleChange = (event: { target: { value: any; }; }) => {
    //     const {
    //         target: { value },
    //     } = event;
    // };

    const handleChange = () => {

    };

    return (
        <>
            {userStore.isLoggedIn ? (
                <Button sx={{ margin: 1, borderRadius:5 }} variant='contained' size='large' component={Link1} to="/home">HOME PAGE</Button>
            ) : (
                <form onSubmit={formik.handleSubmit}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            formik.handleSubmit();
                        }
                    }}>
                    <Stack direction="column" justifyContent="left" alignItems="center">
                        <Typography sx={{ margin: 1 }} component="h1" variant="h5">Enter Your Company Code</Typography>
                        <TextField sx={{ margin: 1 }} name='companycode' placeholder='Company Code' autoComplete='off' value={formik.values.companycode} onChange={formik.handleChange}></TextField>
                        {/* <ErrorMessage
                            name='error' render={() => <Typography style={{marginBottom: 10}} color='red'>{formik.initialValues.error} </Typography>}
                            /> */}                            
                        <Typography style={{ marginBottom: 10 }} color='red'>{formik.errors.companycode}</Typography>
                        {/* <Link href="/forgotpassword">Forgot Password?</Link> */}
                        <LoadingButton sx={{ margin: 1, borderRadius:5 }} loading={loading} variant='contained' size='large' onClick={formik.submitForm}>CONTINUE</LoadingButton>
                    </Stack>
                </form>                
            )}
        </>
    )
})