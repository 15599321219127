import React, { useRef, useEffect } from "react";
import { observer } from 'mobx-react-lite';
import { Box, Button, Checkbox, CssBaseline, Divider, Drawer, FormControlLabel, FormGroup,  List, ListItem, ListItemButton, ListItemText, Paper, Stack, Tab, Table, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField } from "@mui/material";
import { Scheduler } from "@aldabil/react-scheduler";
import { SchedulerRef, RemoteQuery, SchedulerHelpers } from "@aldabil/react-scheduler/types";
import { useStore } from "../../stores/store";
import CompanyRole from "../../models/CompanyRole";
import Typography from '@mui/material/Typography';
import { User } from "../../models/User";
import { View } from "@aldabil/react-scheduler/components/nav/Navigation";
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import AddNewShiftDialog from "./AddNewShiftDialog";

const style = (theme: any) => ({
    margin: 0,
    top: 'auto',
    left: 'auto',
    bottom: 20,
    right: 20,
    position: 'fixed',

});

function compareDatesIgnoringTime(date1: Date, date2: Date): boolean {
    const date1WithoutTime = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate());
    const date2WithoutTime = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate());

    return date1WithoutTime.getTime() === date2WithoutTime.getTime();
}
function countHoursBetweenDates(startDate: Date, endDate: Date): number {
    const timeDiff = endDate.getTime() - startDate.getTime();
    const hoursDiff = timeDiff / (1000 * 60 * 60);
    return Math.abs(Math.round(hoursDiff));
}

export default observer(function ScheduleMain() {
    const { userStore } = useStore();
    const { loadResponsesGrouped, loadCompanyRoles, getCompanyRoles,
        setCompanyRoleUnSelected, setCompanyRoleSelected, getUserResponsesForCalendarViewGrouped, getUserResponsesGrouped,
        getAllUsers, loadUsers, setUserSelected, setUserUnSelected, loadingCalendar, loading, loadingUsers,
        getUserResponsesForCalendarView, loadResponses } = userStore;
    const [sideMenu, setSideMenu] = React.useState(false);
    const [currentCensus, setCurrentCensus] = React.useState([110, 110, 110, 110, 110, 110, 110]);
    const calendarRef = useRef<SchedulerRef>(null);
    const [currentView, setCurrentView] = React.useState('week');
    const [startDateView, setStartDateView] = React.useState(new Date()); //start date in the current view of the scheduler component. used for totals calc
    const [endDateView, setEndDateView] = React.useState(new Date());//end date in the current view of the scheduler component. used for totals calc
    const [selectedRoleIDTab, setSelectRoleTab] = React.useState(1);
    const [compactMode, setCompactMode] = React.useState(true);
    const [openAddNewShift, setOpenAddNewShift] = React.useState(false);
    const [refreshKey, setRefreshKey] = React.useState(0);
    const [dorefresh, setDoRefresh ] = React.useState(false);
    //const [ load, setLoad ] = React.useState(true);
    const handleUpdateCensus = (index: number, newValue: number) => {
        setCurrentCensus((prev) => {
            const updated = [...prev];
            updated[index] = newValue;
            return updated;
        });
    }

    const getBudgetPPD = () => {
        if (getCompanyRoles == null || getCompanyRoles.length == 0) {
            return 0;
        }
        let result = 0;
        getCompanyRoles.forEach(r => {
            if (r.roleid === selectedRoleIDTab) {
                result = r.ppdbudget;
            }
        })

        return result;
    }

    const getCalcPPD = (index: number) => {
        return getScheduledHoursForDay(index) / currentCensus[index];
    }

    const getVariance = (index: number) => {
        return Math.round(getBudgetPPD() - getCalcPPD(index));
    }
    const getScheduledHoursForDay = (i: number) => {
        let columnDate = new Date(startDateView);
        columnDate.setDate(columnDate.getDate() + i);
        let count: number = 0;
        getUserResponsesForCalendarViewGrouped.filter(excludeRoles).forEach(d => {
            let multiply = countValues(d.usernames); //how many users are in this shift/shift time, we gotta add the hours together
            for (var i = 0; i < multiply; i++) {
                if (compareDatesIgnoringTime(d.start, columnDate)) {
                    count += countHoursBetweenDates(d.start, d.end);
                }
            }
        })
        return count;
    }

    useEffect(() => {
        document.title = 'Schedule';
        async function fetchData() {
            loadCompanyRoles();
            loadResponsesGrouped(new Date());
            // loadResponses(new Date())
            loadUsers();
            //setLoad(false);
        }

        fetchData();
    }, [refreshKey]);

    const getDayDateFromView = (i: number) => {
        let result = new Date(startDateView);
        result.setDate(result.getDate() + i);
        return result;
    }

    const onSelectedDateChanged = (date: Date) => {
        console.log('selected date change event ' + date.toDateString());
    }
    const onNavigation = async ({ start, end }: RemoteQuery) => {
        setStartDateView(new Date(start));
        setEndDateView(new Date(end));
    };

    const getViewStartDate = () => {
        if (calendarRef.current === null || calendarRef.current === null ||
            calendarRef.current.scheduler.selectedDate === undefined) {
            return "ok"
        }
        else {
            //return calendarRef.current.scheduler.selectedRange.toString();
            return calendarRef.current.scheduler.selectedDate.toString();
        }
    }
    const countValues = (s: string) => {
        const values = s.split(',');
        const count = values.filter(value => value.trim() !== '').length;
        return count;
    };

    const handleToggleRoleSelect = (r: CompanyRole) => () => {
        if (r.isSelected) {
            setCompanyRoleUnSelected(r);
        }
        else {
            setCompanyRoleSelected(r);
        }
    }

    const handleToggleUserSelect = (u: User) => () => {
        if (u.isSelected) {
            setUserUnSelected(u)
        }
        else {
            setUserSelected(u);
        }
    }
    function excludeRoles(shift: any) {
        if (shift.roleid == selectedRoleIDTab) {
            return true;
        }
        else {
            return false;
        }
    }
    const handleSelectAllUsers = () => {
        for (const each of getAllUsers) {
            each.isSelected = true;
        }
    }
    const handleSelectNoneUsers = () => {
        for (const each of getAllUsers) {
            each.isSelected = false;
        }
    }
    // const handleSelectAllRoles = () => {
    //     for (const each of getCompanyRoles) {
    //         each.isSelected = true;
    //     }
    // }
    // const handleSelectNoneRoles = () => {
    //     for (const each of getCompanyRoles) {
    //         each.isSelected = false;
    //     }
    // }
    function excludeUsers(shift: any) {
        if (getAllUsers == null) {
            return true;
        }
        else {
            for (const each of getAllUsers) {
                if (shift.username == each.username) {
                    return each.isSelected;
                }
            }
        }
    }

    function excludeAdministrationRole(role: CompanyRole) {
            return !role.isadministrationrole;
    }
    const closeDrawer = () => {
        setSideMenu(false);
    }
    const openDrawer = () => {
        setSideMenu(true);
    }
    const onCalendarViewChanged = (view: View, agenda?: boolean) => {
        console.log('view changed yeah');
        setCurrentView(view);
    }

    const isAnyLoading = () => {
        if (loading || loadingCalendar) {
            return true;
        }
        else {
            return false;
        }
    }

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectRoleTab(newValue);
        console.log('tab ' + newValue);
    };
    const tabColorByRole = (r: number) => {
        if (r === 0) {
            return '#D18C19';
        }
        if (r === 1) {
            return '#1976D2';
        }
        else if (r === 2) {
            return '#3DD119';
        }
        else if (r === 3) {
            return '#D61AB0';
        }
        else if (r === 4) {
            return '#4E7EAD';
        }
        else {
            return '#1976d2';
        }
    }
    const getSchedulerStep = () => {
        if (compactMode) {
            return 240;
        }
        else {
            return 60;
        }
    }
    const getSchedulerHeight = () => {
        if (compactMode) {
            return 400;
        }
        else {
            return 410;
        }
    }
    const getFontSize = () => {
        if (compactMode) {
            return 12;
        }
        else {
            return 14;
        }
    }
    const getPPDVarianceColor = (variance: number) => {
        if (variance < 0) {
            return 'orange';
        }
        else if (variance > 10) {
            return 'red';
        }
        else {
            return 'black';
        }
    }

    const handleOnCloseNewShiftDialog = (value:boolean) => {
        setOpenAddNewShift(false);
        if(value){
            setRefreshKey(refreshKey + 1);
        }
    }
    const onClickScheduler = () => {

    }

    return (
        <Stack sx={{ display: 'flex' }} divider={<Divider orientation="horizontal" flexItem />}>
            <CssBaseline />
            <Tabs
                value={selectedRoleIDTab}
                onChange={handleChange}
                TabIndicatorProps={{ style: { backgroundColor: tabColorByRole(selectedRoleIDTab) } }}
                centered
                scrollButtons="auto"
                aria-label="scrollable auto tabs example" >
                {getCompanyRoles.filter(excludeAdministrationRole).map((role) => //////{getCompanyRoles.filter(t => t.roleid > 0).map((role) =>
                    <Tab label={role.role} value={role.roleid} style={{ color: tabColorByRole(role.roleid) }}></Tab>
                )}
            </Tabs>
            {sideMenu ?
                <Box>
                    <Drawer
                        sx={{
                            width: 240,
                            flexShrink: 0,
                            '& .MuiDrawer-paper': {
                                width: 240,
                                boxSizing: 'border-box',
                            }
                        }}
                        anchor={'left'}
                        variant="permanent"
                        open={sideMenu}
                        onClose={closeDrawer}
                    >
                        <Button sx={{ margin: 2 }} onClick={closeDrawer} variant="outlined">Close</Button>
                        {/* <Stack>
                            <Stack margin={1} alignItems={'center'} alignContent={'center'}>
                                <Typography component="div" textAlign="center" sx={{ fontWeight: 'bold', fontSize: 14 }}>
                                    Roles
                                </Typography>
                                <Stack direction="row" margin={2}>
                                    <Button onClick={handleSelectAllRoles} sx={{ fontSize: 10 }}>Select All</Button>
                                    <Button onClick={handleSelectNoneRoles} sx={{ fontSize: 10 }}>Select None</Button>
                                </Stack>
                            </Stack>
                            <Paper>
                                <List sx={{ bgcolor: 'background.paper', alignItems: 'center', justifyContent: 'center', alignContent: 'center' }}>
                                    {getCompanyRoles.map((role, index) => (
                                        <ListItem
                                            key={role.roleid}
                                            disablePadding>
                                            <ListItemButton key={role.roleid} onClick={handleToggleRoleSelect(role)} dense>
                                                <Checkbox
                                                    sx={{ '& .MuiSvgIcon-root': { fontSize: 16 } }}
                                                    edge="start"
                                                    checked={role.isSelected}
                                                    tabIndex={-1}
                                                    disableRipple
                                                    defaultValue={'true'}
                                                    inputProps={{ 'aria-labelledby': role.role }}
                                                />
                                                <ListItemText id={role.roleid.toString()}>
                                                    <Typography sx={{ fontSize: 11 }}>{role.role}</Typography>
                                                </ListItemText>
                                            </ListItemButton>
                                        </ListItem>
                                    ))
                                    }
                                </List>
                            </Paper>
                        </Stack> */}
                        <Stack>
                            <Stack margin={1} alignItems={'center'} alignContent={'center'}>
                                <Typography component="div" textAlign="center" sx={{ fontWeight: 'bold', fontSize: 14 }}>
                                    Users
                                </Typography>
                                <Stack direction="row">
                                    <Button onClick={handleSelectAllUsers} sx={{ fontSize: 10 }}>Select All</Button>
                                    <Button onClick={handleSelectNoneUsers} sx={{ fontSize: 10 }}>Select None</Button>
                                </Stack>
                            </Stack>
                            <Paper>
                                <List sx={{ bgcolor: 'background.paper', alignItems: 'center', justifyContent: 'center', alignContent: 'center' }}>
                                    {getAllUsers.map((user) => (
                                        <ListItem
                                            key={user.username}
                                            disablePadding>
                                            <ListItemButton key={user.username} onClick={handleToggleUserSelect(user)} dense>
                                                <Checkbox
                                                    sx={{ '& .MuiSvgIcon-root': { fontSize: 16 } }}
                                                    edge='start'
                                                    checked={user.isSelected}
                                                    tabIndex={-1}
                                                    disableRipple
                                                    defaultValue={'true'}
                                                    inputProps={{ 'aria-labelledby': user.username }}
                                                />
                                                <ListItemText id={user.username.toString()}>
                                                    <Typography sx={{ fontSize: 11 }}>{user.lastname + ', ' + user.firstname + ' - ' + user.username}</Typography>
                                                </ListItemText>
                                            </ListItemButton>
                                        </ListItem>
                                    ))
                                    }
                                </List>
                            </Paper>
                        </Stack>
                    </Drawer>
                </Box>
                :
                <>
                    {/* <Button
                        size="large"
                        variant="outlined"
                        disableRipple
                        onClick={openDrawer}
                        sx={{ height: 0, marginLeft: 1, marginTop: 1, width: 40 }}
                    >
                        <ChevronRightIcon />
                    </Button> */}
                </>}
            <Box
                component="main"
                sx={{ m: 1, verticalAlign: 'top' }}
            >
                {/* <Toolbar /> */}
                <Scheduler
                    loading={isAnyLoading()}
                    getRemoteEvents={onNavigation}
                    onSelectedDateChange={onSelectedDateChanged}
                    height={getSchedulerHeight()}
                    ref={calendarRef}
                    deletable={false}
                    editable={false}
                    onCellClick={onClickScheduler}
                    onEventClick={onClickScheduler}
                    onEventEdit={onClickScheduler}
                    onClickMore={onClickScheduler}
                    onViewChange={onCalendarViewChanged}
                    draggable={false}
                    view='week'
                    agenda={false}
                    month={null}
                    week={{
                        weekDays: [0, 1, 2, 3, 4, 5, 6],
                        weekStartOn: 0,
                        startHour: 0,
                        endHour: 24,
                        step: getSchedulerStep()
                    }}
                    customEditor={(scheduler) => <div />}
                    viewerExtraComponent={(fields, event) => {
                        return (
                            <div>
                                <p>{event.usernames}</p>
                            </div>
                        )
                    }}
                    // resources={RESOURCES}
                    // resourceFields={{
                    //     idField: "roleid",
                    //     textField: "title",
                    //     subTextField: "mobile",
                    //     avatarField: "title",
                    //     colorField: "color"
                    // }}
                    // fields={[
                    //     {
                    //         name: "roleid",
                    //         type: "select",
                    //         default: RESOURCES[0].roleid,
                    //         options: getCompanyRoles.map((res) => {
                    //             return {
                    //                 id: res.roleid,
                    //                 text: `${res.role} (${res.role})`,
                    //                 value: res.roleid //Should match "name" property
                    //             };
                    //         }),
                    //         config: { label: "Role", required: true }
                    //     }
                    // ]}
                    eventRenderer={({ event, ...props }) => {
                        if (!event.allDay) {
                            return (
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "space-between",
                                        height: "100%",
                                        background: event.color,
                                    }}
                                    {...props}>
                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                        <Typography variant="body1" marginLeft={1} color={'white'} fontSize={10}>{event.start.toLocaleTimeString("en-US", { timeStyle: "short" })} </Typography>
                                        <Typography variant="body1" marginRight={1} align="right" color={'white'} fontSize={10}>{event.title}</Typography>
                                    </Box>
                                    <div style={{ color: "white", fontSize: 11 }}>{event.usernames}</div>
                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                        <Typography variant="body1" marginLeft={1} color={'white'} fontSize={10}>{event.end.toLocaleTimeString("en-US", { timeStyle: "short" })} </Typography>
                                        <Typography variant="body1" marginRight={1} align="right" color={'white'} fontSize={10}>Count: {countValues(event.usernames)}</Typography>
                                    </Box>
                                </div>
                            );
                        }
                        return null;
                    }}
                    events={getUserResponsesForCalendarViewGrouped.filter(excludeRoles)}></Scheduler>
                <Fab color="primary" aria-label="add" sx={style} onClick={() => { setOpenAddNewShift(true) }}>
                    <AddIcon />
                </Fab>
            </Box>
            {/* {currentView == 'week' ?
                <FormGroup sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <FormControlLabel sx={{ alignContent: 'end' }} control={<Checkbox sx={{ alignContent: 'end' }} checked={compactMode} onChange={e => { setCompactMode(e.target.checked) }} />} label="Compact" />
                </FormGroup>
                : <></>} */}
            {currentView == 'week' ?
                <TableContainer component={Paper} sx={{ marginLeft: 1, marginRight: 1, marginBottom: 1 }}>
                    <Table aria-label="spanning table">
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={6}></TableCell>
                                <TableCell>
                                    <TextField value={currentCensus[0]} size='small'
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            handleUpdateCensus(0, Number(event.target.value));
                                        }}
                                        sx={{ alignSelf: 'center', width: 140 }} id="outlined-basic" label="Census" variant="outlined">

                                    </TextField>
                                </TableCell>
                                <TableCell>
                                    <TextField value={currentCensus[1]} size='small'
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            handleUpdateCensus(1, Number(event.target.value));
                                        }}
                                        sx={{ alignSelf: 'center', width: 140 }} id="outlined-basic" label="Census" variant="outlined">

                                    </TextField>
                                </TableCell>
                                <TableCell>
                                    <TextField value={currentCensus[2]} size='small'
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            handleUpdateCensus(2, Number(event.target.value));
                                        }}
                                        sx={{ alignSelf: 'center', width: 140 }} id="outlined-basic" label="Census" variant="outlined">

                                    </TextField>
                                </TableCell>
                                <TableCell>
                                    <TextField value={currentCensus[3]} size='small'
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            handleUpdateCensus(3, Number(event.target.value));
                                        }}
                                        sx={{ alignSelf: 'center', width: 140 }} id="outlined-basic" label="Census" variant="outlined">

                                    </TextField>
                                </TableCell>
                                <TableCell>
                                    <TextField value={currentCensus[4]} size='small'
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            handleUpdateCensus(4, Number(event.target.value));
                                        }}
                                        sx={{ alignSelf: 'center', width: 140 }} id="outlined-basic" label="Census" variant="outlined">

                                    </TextField>
                                </TableCell>
                                <TableCell>
                                    <TextField value={currentCensus[5]} size='small'
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            handleUpdateCensus(5, Number(event.target.value));
                                        }}
                                        sx={{ alignSelf: 'center', width: 140 }} id="outlined-basic" label="Census" variant="outlined">

                                    </TextField>
                                </TableCell>
                                <TableCell>
                                    <TextField value={currentCensus[6]} size='small'
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            handleUpdateCensus(6, Number(event.target.value));
                                        }}
                                        sx={{ alignSelf: 'center', width: 140 }} id="outlined-basic" label="Census" variant="outlined">

                                    </TextField>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ fontSize: getFontSize() }} colSpan={6}>Date</TableCell>
                                <TableCell sx={{ fontSize: getFontSize() }}>{startDateView.toDateString()}</TableCell>
                                <TableCell sx={{ fontSize: getFontSize() }}>{getDayDateFromView(1).toDateString()}</TableCell>
                                <TableCell sx={{ fontSize: getFontSize() }}>{getDayDateFromView(2).toDateString()}</TableCell>
                                <TableCell sx={{ fontSize: getFontSize() }}>{getDayDateFromView(3).toDateString()}</TableCell>
                                <TableCell sx={{ fontSize: getFontSize() }}>{getDayDateFromView(4).toDateString()}</TableCell>
                                <TableCell sx={{ fontSize: getFontSize() }}>{getDayDateFromView(5).toDateString()}</TableCell>
                                <TableCell sx={{ fontSize: getFontSize() }}>{getDayDateFromView(6).toDateString()}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ fontSize: getFontSize() }} colSpan={6}>Scheduled Hours</TableCell>
                                <TableCell sx={{ fontSize: getFontSize() }}>{getScheduledHoursForDay(0)}</TableCell>
                                <TableCell sx={{ fontSize: getFontSize() }}>{getScheduledHoursForDay(1)}</TableCell>
                                <TableCell sx={{ fontSize: getFontSize() }}>{getScheduledHoursForDay(2)}</TableCell>
                                <TableCell sx={{ fontSize: getFontSize() }}>{getScheduledHoursForDay(3)}</TableCell>
                                <TableCell sx={{ fontSize: getFontSize() }}>{getScheduledHoursForDay(4)}</TableCell>
                                <TableCell sx={{ fontSize: getFontSize() }}>{getScheduledHoursForDay(5)}</TableCell>
                                <TableCell sx={{ fontSize: getFontSize() }}>{getScheduledHoursForDay(6)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ fontSize: getFontSize() }} colSpan={6}>Budget PPD</TableCell>
                                <TableCell sx={{ fontSize: getFontSize() }}>{getBudgetPPD().toString()}</TableCell>
                                <TableCell sx={{ fontSize: getFontSize() }}>{getBudgetPPD()}</TableCell>
                                <TableCell sx={{ fontSize: getFontSize() }}>{getBudgetPPD()}</TableCell>
                                <TableCell sx={{ fontSize: getFontSize() }}>{getBudgetPPD()}</TableCell>
                                <TableCell sx={{ fontSize: getFontSize() }}>{getBudgetPPD()}</TableCell>
                                <TableCell sx={{ fontSize: getFontSize() }}>{getBudgetPPD()}</TableCell>
                                <TableCell sx={{ fontSize: getFontSize() }}>{getBudgetPPD()}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ fontSize: getFontSize() }} colSpan={6}>Calc PPD</TableCell>
                                <TableCell sx={{ fontSize: getFontSize() }}>{getCalcPPD(0)}</TableCell>
                                <TableCell sx={{ fontSize: getFontSize() }}>{getCalcPPD(1)}</TableCell>
                                <TableCell sx={{ fontSize: getFontSize() }}>{getCalcPPD(2)}</TableCell>
                                <TableCell sx={{ fontSize: getFontSize() }}>{getCalcPPD(3)}</TableCell>
                                <TableCell sx={{ fontSize: getFontSize() }}>{getCalcPPD(4)}</TableCell>
                                <TableCell sx={{ fontSize: getFontSize() }}>{getCalcPPD(5)}</TableCell>
                                <TableCell sx={{ fontSize: getFontSize() }}>{getCalcPPD(6)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ fontSize: getFontSize() }} colSpan={6}>PPD Variance</TableCell>
                                <TableCell sx={{ fontSize: getFontSize(), color: getPPDVarianceColor(getVariance(0)) }}>{getVariance(0)}</TableCell>
                                <TableCell sx={{ fontSize: getFontSize(), color: getPPDVarianceColor(getVariance(1)) }}>{getVariance(1)}</TableCell>
                                <TableCell sx={{ fontSize: getFontSize(), color: getPPDVarianceColor(getVariance(2)) }}>{getVariance(2)}</TableCell>
                                <TableCell sx={{ fontSize: getFontSize(), color: getPPDVarianceColor(getVariance(3)) }}>{getVariance(3)}</TableCell>
                                <TableCell sx={{ fontSize: getFontSize(), color: getPPDVarianceColor(getVariance(4)) }}>{getVariance(4)}</TableCell>
                                <TableCell sx={{ fontSize: getFontSize(), color: getPPDVarianceColor(getVariance(5)) }}>{getVariance(5)}</TableCell>
                                <TableCell sx={{ fontSize: getFontSize(), color: getPPDVarianceColor(getVariance(6)) }}>{getVariance(6)}</TableCell>
                            </TableRow>
                        </TableHead>
                    </Table>
                </TableContainer>
                :
                <></>
            }
            <AddNewShiftDialog            
                open={openAddNewShift}
                dorefresh={dorefresh}
                onClose={handleOnCloseNewShiftDialog}></AddNewShiftDialog>
        </Stack>
    )
})