import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom'
import { Button, Container, CircularProgress } from '@mui/material';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import { useStore } from '../../stores/store';
import { observer } from 'mobx-react-lite';
import { OpenShiftResponse } from '../../models/OpenShiftReponse';
import { format } from 'date-fns'
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import ReactAddToCalendar from 'react-add-to-calendar';
import 'react-add-to-calendar/dist/react-add-to-calendar.css'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default observer(function ClaimShiftForm() {
    const { shiftStore } = useStore();
    const { getShiftInfoByKey, loading, shiftClaimOrDeny } = shiftStore;
    const { id } = useParams<{ id: string }>();
    const { fac } = useParams<{ fac: string }>();
    const [shiftWasClaimed, setShiftWasClaimed] = React.useState(false);
    const [shiftWasDenied, setShiftWasDenied] = React.useState(false);
    const [shiftIsUnavailable, setShiftIsUnavailable] = React.useState(false);
    let icon = { 'calendar-plus-o': 'left' };
    let event = {
        title: 'Sample Event',
        description: 'This is the sample event provided as an example only',
        location: 'Portland, OR',
        startTime: '2016-09-16T20:15:00-04:00',
        endTime: '2016-09-16T21:45:00-04:00'
    }

    useEffect(() => {
        document.title = 'Claim Shift';
        if (fac && id) {
            getShiftInfoByKey(fac, id.toString());
        }
    }, [fac, id, getShiftInfoByKey]);

    const handleDenyShiftClick = () => {
        console.log('try to deny shift ' + id);
        if (!shiftClaimOrDeny) {
            return;
        }

        // alert(JSON.stringify(new OpenShiftResponse(shiftClaimOrDeny.cono, shiftClaimOrDeny.shiftalertid, shiftClaimOrDeny.username, false), null, 2));
        shiftStore.putOpenShiftResponse(new OpenShiftResponse(shiftClaimOrDeny.cono, shiftClaimOrDeny.shiftalertid, shiftClaimOrDeny.username, false)).then((result) => {
            console.log('our result is ' + result);
            if (result === 'Shift Unavailable') {
                setShiftIsUnavailable(true);
            }
            else {
                setShiftWasDenied(true);
            }
        });

    }

    const handleClaimShiftClick = () => {
        console.log('try to claim shift ' + id);
        if (!shiftClaimOrDeny) {
            return;
        }
        // alert(JSON.stringify(new OpenShiftResponse(shiftClaimOrDeny.cono, shiftClaimOrDeny.shiftalertid, shiftClaimOrDeny.username, true), null, 2));
        shiftStore.putOpenShiftResponse(new OpenShiftResponse(shiftClaimOrDeny.cono, shiftClaimOrDeny.shiftalertid, shiftClaimOrDeny.username, true)).then((result) => {
            console.log('our result is ' + result);
            if (result === 'Shift Unavailable') {
                setShiftIsUnavailable(true);
            }
            else {
                setShiftWasClaimed(true);
            }
        });
    }


    // console.log('Form Values', formik.values);

    //if (!fac || !id) return 'Shift does not exist or has expired';
    if (loading || !shiftClaimOrDeny) return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Stack style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <h1 style={{
                    textAlign: 'center', fontSize: '18',
                    fontWeight: 700,
                    letterSpacing: '.2rem',
                    color: 'inherit'
                }}>Loading Shift Data</h1>
                <CircularProgress />
            </Stack>
        </div>
    )

    if (shiftClaimOrDeny.shiftStatus === 6 || shiftIsUnavailable) {
        return (
            <Container component="main" maxWidth='sm'>
                <Card sx={{ margin: 2, paddingLeft: 1, paddingRight: 1 }} raised>
                    <h1 style={{
                        textAlign: 'center', fontSize: '18',
                        fontWeight: 700,
                        letterSpacing: '.2rem',
                        color: 'inherit'
                    }}>Shift can no longer be claimed</h1>
                </Card>
            </Container>
        )
    }

    if (shiftClaimOrDeny.shiftStatus === 5) {
        return (
            <Container component="main" maxWidth='sm'>
                <Card sx={{ margin: 2, paddingLeft: 1, paddingRight: 1 }} raised>
                    <h1 style={{
                        textAlign: 'center', fontSize: '18',
                        fontWeight: 700,
                        letterSpacing: '.2rem',
                        color: 'inherit'
                    }}>User Already Declined This Shift</h1>
                </Card>
            </Container>
        )
    }

    if (shiftClaimOrDeny.shiftStatus === 4) {
        return (
            <Container component="main" maxWidth='sm'>
                <Card sx={{ margin: 2, paddingLeft: 1, paddingRight: 1 }} raised>
                    <h1 style={{
                        textAlign: 'center', fontSize: '18',
                        fontWeight: 700,
                        letterSpacing: '.2rem',
                        color: 'inherit'
                    }}>User Already Accepted This Shift</h1>
                </Card>
            </Container>
        )
    }

    if (shiftClaimOrDeny.shiftStatus === 3) {
        return (
            <Container component="main" maxWidth='sm'>
                <Card sx={{ margin: 2, paddingLeft: 1, paddingRight: 1 }} raised>
                    <h1 style={{
                        textAlign: 'center', fontSize: '18',
                        fontWeight: 700,
                        letterSpacing: '.2rem',
                        color: 'inherit'
                    }}>Invalid Key</h1>
                </Card>
            </Container>
        )
    }

    if (shiftClaimOrDeny.shiftStatus === 2) {
        return (
            <Container component="main" maxWidth='sm'>
                <Card sx={{ margin: 2, paddingLeft: 1, paddingRight: 1 }} raised>
                    <h1 style={{
                        textAlign: 'center', fontSize: '18',
                        fontWeight: 700,
                        letterSpacing: '.2rem',
                        color: 'inherit'
                    }}>Key Expired</h1>
                </Card>
            </Container>
        )
    }

    if (shiftClaimOrDeny.shiftStatus === 1) {
        return (
            <Container component="main" maxWidth='sm'>
                <Card sx={{ margin: 2, paddingLeft: 1, paddingRight: 1 }} raised>
                    <h1 style={{
                        textAlign: 'center', fontSize: '18',
                        fontWeight: 700,
                        letterSpacing: '.2rem',
                        color: 'inherit'
                    }}>All Shift Openings Were Claimed</h1>
                </Card>
            </Container>
        )
    }

    if (shiftWasDenied) {
        return (
            <Container component="main" maxWidth='sm'>
                <Card sx={{ margin: 2, paddingLeft: 1, paddingRight: 1 }} raised>
                    <h1 style={{
                        textAlign: 'center', fontSize: '18',
                        fontWeight: 700,
                        letterSpacing: '.2rem',
                        color: 'inherit'
                    }}>Shift was denied successfully</h1>
                </Card>
            </Container>
        )
    }

    if (shiftWasClaimed) {
        return (
            <Container component="main" maxWidth='sm'>
                <Card sx={{ margin: 2, paddingLeft: 1, paddingRight: 1 }} raised>
                    <h1 style={{
                        textAlign: 'center', fontSize: '18',
                        fontWeight: 700,
                        letterSpacing: '.2rem',
                        color: 'inherit'
                    }}>Shift was claimed successfully</h1>
                </Card>
                {/* <Stack direction='column' alignItems={'center'} margin={5}>
                    <ReactAddToCalendar event={event} buttonLabel="Add To Calendar" buttonTemplate={icon} />
                </Stack> */}
            </Container>
        )
    }

    return (
        <Container component="main" maxWidth='sm' sx={{ paddingBottom: 10 }}>
            <Card sx={{ margin: 1, paddingLeft: 1, paddingRight: 1 }} raised>
                <Grid container>
                    <Grid item xs={12}>
                        <h1 style={{
                            textAlign: 'center', fontSize: '18',marginBottom:'0',
                            fontWeight: 700,
                            letterSpacing: '.2rem',
                            color: 'inherit'
                        }}>Claim Shift</h1>
                    </Grid>
                    <Grid item xs={12}>
                        <Stack direction='column' alignItems={'center'}>
                        <Stack alignItems={'center'} sx={{ margin: 1 }}>
                                <b>Company:</b>
                                {shiftClaimOrDeny.companyname}
                            </Stack>
                            <Stack alignItems={'center'} sx={{ margin: 1 }}>
                                <b>Shift Date:</b>
                                {format(new Date(shiftClaimOrDeny.shiftdate), 'MM/dd/yyyy')} {format(new Date(shiftClaimOrDeny.shiftstarttime), "hh:mm a")} - {format(new Date(shiftClaimOrDeny.shiftendtime), "hh:mm a")}
                            </Stack>
                            {/* <Stack alignItems={'center'} sx={{ margin: 1 }}>
                                <b>Shift:</b>
                                {format(new Date(shiftClaimOrDeny.shiftstarttime), "hh:mm a")} - {format(new Date(shiftClaimOrDeny.shiftendtime), "hh:mm a")}
                            </Stack> */}
                            {/* <Stack alignItems={'center'} sx={{ margin: 1 }}>
                                <b>Openings:</b>
                                {shiftClaimOrDeny.numofopenings}
                            </Stack> */}
                            <Stack alignItems={'center'} sx={{ margin: 1 }}>
                                <b>Role:</b>
                                {shiftClaimOrDeny.Roles}
                            </Stack>
                            <Stack alignItems={'center'} sx={{ margin: 1 }}>
                                <b>Description:</b>
                                {shiftClaimOrDeny.descr}
                            </Stack>
                            <Stack alignItems={'center'} sx={{ margin: 1 }}>
                                <b>User:</b>
                                {shiftClaimOrDeny.username}
                            </Stack>
                        </Stack>
                    </Grid>
                    <Grid item xs={6} display="flex" justifyContent="center">
                        <Button
                            sx={{ margin: 1, height: 50 }}
                            variant="contained"
                            color="warning"
                            onClick={handleClaimShiftClick}>
                            Claim Shift
                        </Button>
                    </Grid>
                    <Grid item xs={6}  alignItems='center' display="flex" justifyContent="center">
                        <Button                        
                            sx={{ m: 1, height: 50 }}
                            variant="contained"
                            color="error"
                            onClick={handleDenyShiftClick}>
                            Deny Shift
                        </Button>
                    </Grid>                    
                </Grid>
            </Card>
        </Container >
    );
})