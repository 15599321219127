import { observer } from "mobx-react-lite";
import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import TextField from '@mui/material/TextField';
import ArrowCircleLeftRoundedIcon from '@mui/icons-material/ArrowCircleLeftRounded';
import Card from '@mui/material/Card';
import { Button, Checkbox, Container, Box, Stack, Typography } from '@mui/material';
import ClaimedAlertEmailSetupModel from "../../models/Setups/ClaimedAlertEmailSetup";
import { Link } from 'react-router-dom';
import InputLabel from '@mui/material/InputLabel';
import FormGroup from '@mui/material/FormGroup';
import { useStore } from '../../stores/store';
import LoadingButton from '@mui/lab/LoadingButton';

export default observer(function AddNewClaimAlertEmail() {
    const cono = window.localStorage.getItem('cono');
    const { setupsStore } = useStore();

    const formik = useFormik({
        initialValues: new ClaimedAlertEmailSetupModel(),
        onSubmit: values => {
            formik.setFieldValue('cono', cono);
            //alert(JSON.stringify(values, null, 2));
            console.log(cono);
            setupsStore.putClaimedAlertEmail(values, cono || '').then(() =>{
                handleReset();
        })
    }
    });

    const handleReset = () => {
        console.log('try to reset');
        formik.resetForm({
            values: { email: '', role: '', roleid: -1, cono: '', rownum: 0 },
        });
    };

return (
    <Container component="main" maxWidth="sm" sx={{ paddingBottom: 10 }}>
        <Card sx={{ margin: 2, paddingLeft: 1, paddingRight: 1 }} raised>
            <Box
                sx={{
                    marginTop: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Stack direction="row" justifyContent="left" alignItems="center">
                    <Button component={Link} to="/claimalerts"><ArrowCircleLeftRoundedIcon fontSize="large" /></Button>
                    <Typography component="h1" variant="h5">
                        <span>Add New Email</span>
                    </Typography>
                </Stack>
                <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                    <FormGroup>
                        <InputLabel id="demo-simple-select-autowidth-label" sx={{ marginTop: 2, textAlign: 'left' }}>Email</InputLabel>
                        <TextField
                            name="email"
                            value={formik.values.email}
                            onChange={formik.handleChange} />
                    </FormGroup>
                    <LoadingButton
                        sx={{ marginTop: 2, marginBottom: 2,borderRadius: 5, width:100 }}
                        variant="contained"
                        color="primary"
                        onClick={formik.submitForm}
                        loading={false}>
                        Save
                    </LoadingButton>
                </form>
            </Box>
        </Card>
    </Container>
)
})