import React, { useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { Stack } from '@mui/system';
import { useStore } from "../../../app/stores/store";
import { observer } from "mobx-react-lite";
import { format } from 'date-fns'
import { Button, Grid } from "@mui/material";
import { Link } from 'react-router-dom';
import ClaimedAlertTextSetup from '../../models/Setups/ClaimedAlertTextSetup';
import ClaimedAlertEmailSetup from '../../models/Setups/ClaimedAlertEmailSetup';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 13,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


export default observer(function ClaimAlertsList() {
    const { setupsStore } = useStore();
    const [openSuccessSnack, setOpenSuccessSnack] = React.useState(false);
    const { loadingEmails, loadingTexts, loadClaimAlertEmails, getClaimedAlertEmails, getClaimedAlertTexts, loadClaimAlertTexts } = setupsStore;
    const [refreshKey, setRefreshKey] = React.useState(0);
    useEffect(() => {
        loadClaimAlertEmails();
        loadClaimAlertTexts();
    }, [refreshKey]);

    const deletePhone = (selectedphone: string, selectroleid: number) => {
        const cono = window.localStorage.getItem('cono');
        if (cono === null) {
            return;
        }
        let phone: ClaimedAlertTextSetup = { cono: cono, phone: selectedphone, roleid: selectroleid, role: '', rownum: 0 };
        setupsStore.removeClaimedAlertText(phone).then(() => {
            //setSelectedUsername('');
            setRefreshKey(refreshKey + 1);
            // setOpen(false);
        })
    }

    const deleteEmail = (selectedemail: string, selectedroleid: number) => {
        const cono = window.localStorage.getItem('cono');
        if (cono === null) {
            return;
        }
        let email: ClaimedAlertEmailSetup = { cono: cono, email: selectedemail, roleid: selectedroleid, role: '', rownum: 0 };
        setupsStore.removeClaimedAlertEmail(email).then(() => {
            //setSelectedUsername('');
            setRefreshKey(refreshKey + 1);
            // setOpen(false);
        })
    }

    if (loadingEmails || loadingTexts) return (
        <Stack style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: 55 }} marginRight={4}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Stack style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <h1 style={{
                        textAlign: 'center', fontSize: '18',
                        fontWeight: 700,
                        color: 'inherit',
                    }}>Claimed Alerts</h1>
                    Get an email or text message when a shift is claimed
                    <Box sx={{ width: '100%',marginTop:2 }}>
                        <LinearProgress />
                    </Box>
                </Stack>
            </div>
        </Stack>
    )


    return (
        <>
            <Stack style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: 55 }} marginRight={4}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Stack style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <h1 style={{
                            textAlign: 'center', fontSize: '18',
                            fontWeight: 700,
                            color: 'inherit',
                        }}>Claimed Alerts</h1>
                        Get an email or text message when a shift is claimed
                        {/* <Box sx={{ width: '100%' }}>
                        <LinearProgress />
                    </Box> */}
                    </Stack>
                </div>
                <Grid container spacing={2}>
                    <Grid item sm={6}>
                        <div>
                            <TableContainer component={Paper} sx={{ margin: 2 }}>
                                <Table sx={{ minWidth: 200 }} size='small' aria-label="simple table">
                                    <TableHead>
                                        <StyledTableRow>
                                            <StyledTableCell align="center">Email</StyledTableCell>
                                            <StyledTableCell align="center">Role</StyledTableCell>
                                            <StyledTableCell width={80}></StyledTableCell>
                                        </StyledTableRow>
                                    </TableHead>
                                    <TableBody>
                                        {getClaimedAlertEmails && getClaimedAlertEmails.map((email) =>
                                            <StyledTableRow key={email.rownum}>
                                                <StyledTableCell align="center">{email.email}</StyledTableCell>
                                                <StyledTableCell align="center">{email.role}</StyledTableCell>
                                                <StyledTableCell width={80}>
                                                    <Button
                                                        sx={{ margin: .3, borderRadius:5  }}
                                                        variant="contained"
                                                        color="error"
                                                        onClick={() => { deleteEmail(email.email, email.roleid) }}>
                                                        Remove
                                                    </Button>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </Grid>
                    <Grid item sm={6}>
                        <TableContainer component={Paper} sx={{ margin: 2 }}>
                            <Table sx={{ minWidth: 200 }} size='small' aria-label="simple table">
                                <TableHead>
                                    <StyledTableRow key="200">
                                        <StyledTableCell align="center">Phone</StyledTableCell>
                                        <StyledTableCell align="center">Role</StyledTableCell>
                                        <StyledTableCell width={80}></StyledTableCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {getClaimedAlertTexts && getClaimedAlertTexts.map((text) =>
                                        <StyledTableRow key={text.rownum}>
                                            <StyledTableCell align="center">{text.phone}</StyledTableCell>
                                            <StyledTableCell align="center">{text.role}</StyledTableCell>
                                            <StyledTableCell width={80}>
                                                <Button
                                                    sx={{ margin: .3, borderRadius:5  }}
                                                    variant="contained"
                                                    color="error"
                                                    onClick={() => { deletePhone(text.phone, text.roleid) }}>
                                                    Remove
                                                </Button>
                                            </StyledTableCell>
                                        </StyledTableRow >
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item sm={6}>
                        <Button
                            sx={{ marginLeft: 2, borderRadius:5  }}
                            variant="contained"
                            color="primary"
                            component={Link} to="/addclaimemail"
                        >
                            Add Email
                        </Button>
                    </Grid>
                    <Grid item sm={6}>
                        <Button
                            sx={{ marginLeft: 2, borderRadius:5  }}
                            variant="contained"
                            color="primary"
                            component={Link} to="/addclaimtext"
                        >
                            Add Phone
                        </Button>
                    </Grid>
                </Grid>
            </Stack>
        </>
    );
})