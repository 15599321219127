import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom'
import { useStore } from "../../../app/stores/store";
import { observer } from "mobx-react-lite";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Stack, Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import { ShiftAlertResponse } from '../../models/ShiftAlertResponse';
import { ShiftAlertInfoByID } from '../../models/ShiftAlertInfoByID';
import LinearProgress from '@mui/material/LinearProgress';
import { format } from 'date-fns'
import Grid from '@mui/material/Grid';
import { Container } from '@mui/material';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        fontSize: 16,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default observer(function ViewSentHistory() {
    const { fac } = useParams<{ fac: string }>();
    const { shiftalertid } = useParams<{ shiftalertid: string }>();

    const { shiftStore } = useStore();
    const { loadShiftSentHistory, loading, shiftInfo, getAllShiftHistory } = shiftStore;
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (fac && shiftalertid) {
            loadShiftSentHistory(shiftalertid);
        }
    }, [fac, shiftalertid, loadShiftSentHistory]);

    function deleteUser() {
        // setUsers(users.map(x => {
        //     if (x.id === id) { x.isDeleting = true; }
        //     return x;
        // }));
        // userService.delete(id).then(() => {
        //     setUsers(users => users.filter(x => x.id !== id));
        // });
        setOpen(false);
    }

    if (loading) return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Stack style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <h1 style={{
                    textAlign: 'center', fontSize: '18',
                    fontWeight: 700,
                    letterSpacing: '.2rem',
                    color: 'inherit'
                }}>Sent History</h1>
                <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                </Box>
            </Stack>
        </div>
    )

    return (
        <div>
            <Container component="main">
                <Grid container style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Grid item xs={12} alignItems={'center'} alignContent={'center'}>
                        <h1 style={{
                            textAlign: 'center', fontSize: '18',
                            fontWeight: 700,
                            letterSpacing: '.2rem',
                            color: 'inherit'
                        }}>Sent History For Shift:</h1>
                    </Grid>
                    <Grid item xs={6}>
                        <Stack direction='column' alignItems={'center'}>
                            <Stack alignItems={'center'} sx={{ margin: 1 }}>
                                <b>Date:</b>
                                {shiftInfo && shiftInfo[0].descr ? format(new Date(shiftInfo[0].shiftdate), 'MM/dd/yyyy') : ''}
                            </Stack>
                            <Stack alignItems={'center'} sx={{ margin: 1 }}>
                                <b>Shift:</b>
                                {shiftInfo && shiftInfo[0].descr ? format(new Date(shiftInfo[0].shiftstarttime), "hh:mm a") : ''}  - {shiftInfo && shiftInfo[0].descr ? format(new Date(shiftInfo[0].shiftendtime), "hh:mm a") : ''}
                            </Stack>
                        </Stack>
                    </Grid>
                    <Grid item xs={6}>
                        <Stack direction='column' alignItems={'center'}>
                            <Stack alignItems={'center'} sx={{ margin: 1 }}>
                                <b>Description:</b>
                                {shiftInfo && shiftInfo[0].descr ? shiftInfo[0].descr : ' Error Loading Shift Info'}
                            </Stack>
                            <Stack alignItems={'center'} sx={{ margin: 1 }}>
                                <b>Overnight:</b>
                                {shiftInfo && shiftInfo[0].descr ? (shiftInfo[0] as ShiftAlertInfoByID).shiftendsnextday.toString() : ''}
                            </Stack>
                        </Stack>
                    </Grid>
                </Grid>

                {/* <Link to={`${path}/add`} className="btn btn-sm btn-success mb-2">Add User</Link> */}
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 300 }} aria-label="simple table">
                        <TableHead color='primary'>
                            <StyledTableRow >
                                <StyledTableCell align="center">Date Sent</StyledTableCell>
                                <StyledTableCell align="center">Sent By</StyledTableCell>
                                <StyledTableCell align="center">Emails Sent</StyledTableCell>
                                <StyledTableCell align="center">Text Messages Sent</StyledTableCell>
                            </StyledTableRow >
                        </TableHead>
                        <TableBody>
                            {getAllShiftHistory && getAllShiftHistory.map(user =>
                                <StyledTableRow key={user.datesent.toString()}>
                                    <StyledTableCell align="center">{format(new Date(user.datesent), 'MM/dd/yyyy hh:mm a')}</StyledTableCell>
                                    <StyledTableCell align="center">{user.sentbyusername}</StyledTableCell>
                                    <StyledTableCell align="center">{user.EmailsSent}</StyledTableCell>
                                    <StyledTableCell align="center">{user.TextsSent}</StyledTableCell>
                                </StyledTableRow >
                            )}
                            {!getAllShiftHistory || getAllShiftHistory.length === 0 &&
                                <StyledTableRow>
                                    <StyledTableCell align="center">
                                        No Sent History
                                    </StyledTableCell>
                                </StyledTableRow >
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Container>
        </div>
    );
})