import React, { useEffect } from 'react';
import { Stack, Box } from '@mui/system';
import { observer } from "mobx-react-lite";
import Grid from '@mui/material/Grid';
import { Container } from '@mui/material';
import { useParams } from 'react-router-dom'
import LinearProgress from '@mui/material/LinearProgress';
import { useStore } from "../../../app/stores/store";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { format } from 'date-fns'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        fontSize: 16,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default observer(function ViewUserSentHistory() {
    const { username } = useParams<{ username: string }>();
    const { userStore } = useStore();
    const { loadUserSentHistory, loading, getUserSentHistory } = userStore;

    useEffect(() => {
        if(username){
            loadUserSentHistory(username);
        }
    },[]);

    if(loading) return (
        <div>
            <Container component="main">
                <Stack style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Grid item xs={12} alignItems={'center'} alignContent={'center'}>
                        <h1 style={{
                            textAlign: 'center', fontSize: '18',
                            fontWeight: 700,
                            letterSpacing: '.2rem',
                            color: 'inherit'
                        }}>User Messages Sent History </h1>
                    </Grid>
                    <Box sx={{ width: '100%' }}>
                        <LinearProgress />
                    </Box>
                </Stack>
            </Container>
        </div>
    )

    return(
        <div>
        <Container component="main">
            <Stack style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Grid item xs={12} alignItems={'center'} alignContent={'center'}>
                    <h1 style={{
                        textAlign: 'center', fontSize: '18',
                        fontWeight: 700,
                        letterSpacing: '.2rem',
                        color: 'inherit'
                    }}>User Messages Sent History</h1>
                </Grid>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 300 }} aria-label="simple table">
                        <TableHead color='primary'>
                            <StyledTableRow >
                                <StyledTableCell align="center">Date Sent</StyledTableCell>
                                <StyledTableCell align="center">Phone</StyledTableCell>
                                <StyledTableCell align="center">Status</StyledTableCell>
                            </StyledTableRow >
                        </TableHead>
                        <TableBody>
                            {getUserSentHistory && getUserSentHistory.map(user =>
                                <StyledTableRow key={user.datesent.toString()}>
                                    <StyledTableCell align="center">{format(new Date(user.datesent), 'MM/dd/yyyy hh:mm a')}</StyledTableCell>
                                    <StyledTableCell align="center">{user.phone}</StyledTableCell>
                                    <StyledTableCell align="center">{user.status}</StyledTableCell>
                                </StyledTableRow >
                            )}
                            {!getUserSentHistory || getUserSentHistory.length === 0 &&
                                <StyledTableRow>
                                    <StyledTableCell align="center">
                                        No Sent History
                                    </StyledTableCell>
                                </StyledTableRow >
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Stack>
        </Container>
    </div>
    );
})