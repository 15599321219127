import { observer } from "mobx-react-lite";
import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { Button, Checkbox, Container, Box, Stack, Typography, Select, SelectChangeEvent, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import Card from '@mui/material/Card';
import { Link } from 'react-router-dom';
import ArrowCircleLeftRoundedIcon from '@mui/icons-material/ArrowCircleLeftRounded';
import LoadingButton from '@mui/lab/LoadingButton';
import FormGroup from '@mui/material/FormGroup';
import { useStore } from '../../stores/store';
import { DragHandleRounded } from "@mui/icons-material";
import CompanyRoleModel from "../../models/CompanyRole";
import ShiftTemplateSetupModel from "../../models/Setups/ShiftTemplateSetup";
import * as Yup from "yup";
import { RotationSetup } from "../../models/Schedule/RotationSetup";

export interface SimpleProps {
    open: boolean;
    onClose: (value: boolean) => void;
}

const ValidationSchema = Yup.object().shape({
    rotationdescr: Yup.string().required("Rotation Description Is Required"),
});

export default observer(function CreateNewRotation(props: SimpleProps) {
    const { scheduleStore, userStore } = useStore();
    const { loading, putNewRotationSetup } = scheduleStore;
    const cono = window.localStorage.getItem('cono');
    const [selectedRole, setSelectedRole] = React.useState('');
    const { onClose, open } = props;

    const formik = useFormik({
        initialValues: { cono: '', rotationdescr: '', rotationid: -1 },
        validationSchema: ValidationSchema,
        validateOnBlur: true,
        onSubmit: values => {
            if (cono == null) {
                return;
            }
            let rotation: RotationSetup = { cono: cono, rotationid: values.rotationid, rotationdescr: values.rotationdescr };
            //alert(JSON.stringify(template, null, 2));
            putNewRotationSetup(rotation).then(() => {
                onClose(true);
            });
            // console.log(cono);
            // setupsStore.createNewCompanyRole(values, cono || '').then(() => {
            //     handleReset();
            // })

        }
    });

    const handleReset = () => {
        console.log('try to reset');
        formik.resetForm({
            values: { cono: '', rotationid: -1, rotationdescr: '' },
        });
    };

    const handleChange = () => {

    };
    const handleSelectRole = (e: SelectChangeEvent<number>) => {
        console.log('change role' + e.target.value.toString());
        if (+e.target.value > -1) {
            setSelectedRole(e.target.value.toString());
            formik.handleChange(e);
        }
    }

    const handleClose = () =>{
        setSelectedRole('');
        formik.resetForm();
        onClose(true);
    }

    return (
        <Dialog open={open} onClose={handleClose} maxWidth='md'>
            <DialogTitle>Create New Rotation</DialogTitle>
            <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
            <DialogContent dividers={true}>            
                <FormGroup>
                    <InputLabel id="demo-simple-select-autowidth-label" sx={{ margin: 1, textAlign: 'left' }}>Rotation Name</InputLabel>
                    <TextField
                        name="rotationdescr"
                        value={formik.values.rotationdescr}
                        onChange={formik.handleChange}
                        sx={{width:260, margin:1}} />
                    {formik.errors.rotationdescr &&
                        <Typography style={{ marginBottom: 10 }} color='red'>Rotation Name Is Required</Typography>
                    }
                    {/* <InputLabel id="demo-simple-select-autowidth-label" sx={{ marginTop: 2, textAlign: 'left' }}>Role</InputLabel>
                    <Select
                        name="roleid"
                        value={formik.values.roleid}
                        onChange={handleSelectRole}
                        style={{ display: "block" }}>
                        {getCompanyRoles.map((role) => (
                            <MenuItem key={role.roleid} value={role.roleid}>{role.role}</MenuItem>
                        ))}
                    </Select>
                    {formik.errors.roleid &&
                        <Typography style={{ marginBottom: 10 }} color='red'>Role Is Required</Typography>
                    } */}
                </FormGroup>
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' onClick={handleClose}>Cancel</Button>
                    <LoadingButton variant='contained' loading={loading} type="submit">Save</LoadingButton>
                </DialogActions>
                </form>
        </Dialog>
    )
})