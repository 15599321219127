import { observer } from "mobx-react-lite";
import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { Button, Checkbox, Container, Box, Stack, Typography, FormControlLabel, CircularProgress } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import Card from '@mui/material/Card';
import { Link, useParams } from 'react-router-dom';
import ArrowCircleLeftRoundedIcon from '@mui/icons-material/ArrowCircleLeftRounded';
import LoadingButton from '@mui/lab/LoadingButton';
import FormGroup from '@mui/material/FormGroup';
import { useStore } from '../../stores/store';
import { Select } from '@mui/material';
import ShiftSetupModel from "../../models/ShiftSetup";
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { history } from '../../..';
import dayjs from "dayjs";

export default observer(function EditShift() {
    const { setupsStore, userStore } = useStore();
    const { loading, getCompanyRoles, loadCompanyRoles } = userStore;
    const { loadShift, editShift } = setupsStore;
    const cono = window.localStorage.getItem('cono');
    const { roleid } = useParams<{ roleid: string }>();
    const { shiftid } = useParams<{ shiftid: string }>();

    useEffect(() => {
        loadCompanyRoles();
        if (!roleid || !shiftid) {
            return;
        }
        loadShift(roleid, shiftid);
    }, []);

    const formik = useFormik({
        initialValues: editShift || new ShiftSetupModel(),
        enableReinitialize: true,
        onSubmit: values => {
            formik.setFieldValue('cono', cono);
            // console.log('time is ' + values.shiftstarttime + " or " + values.shiftstarttime)
            // alert(JSON.stringify(values, null, 2));
            setupsStore.editCompanyShift(values).then(() => handleGoToShiftSetup());
        }
    });

    const handleChange = () => {

    };

    const handleGoToShiftSetup = () => {
        history.push("/shiftsetup");
        history.go(0);
      }

    const handleReset = () => {
        console.log('try to reset');
        formik.resetForm({
            values: {
                cono: cono || '', role: '', roleid: -1, shiftid: -1, shiftstarttime: new Date(), shiftendtime: new Date(), shiftendsnextday: false,
                descr: '', row: 0, shiftstarttimestring: '', shiftendtimestring: ''
            },
        });
    };

    if (loading || !editShift) return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Stack style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <h1 style={{
                    textAlign: 'center', fontSize: '18',
                    fontWeight: 700,
                    letterSpacing: '.2rem',
                    color: 'inherit'
                }}>Loading Shift</h1>
                <CircularProgress />
            </Stack>
        </div>
    )

    return (
        <Container component="main" maxWidth="sm" sx={{paddingBottom: 10}}>
            <Card sx={{ margin: 2, paddingLeft: 1, paddingRight: 1 }} raised>
                <Box
                    sx={{
                        marginTop: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Stack direction="row" justifyContent="left" alignItems="center">
                        <Button component={Link} to="/shiftsetup"><ArrowCircleLeftRoundedIcon fontSize="large" /></Button>
                        <Typography component="h1" variant="h5">
                            <span>Edit Shift</span>
                        </Typography>
                    </Stack>
                    <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                        <InputLabel id="demo-simple-select-autowidth-label" sx={{ marginTop: 2, textAlign: 'left' }}>Role</InputLabel>
                        <Select
                            disabled
                            name="roleid"
                            value={formik.values.roleid}
                            onChange={formik.handleChange}
                            displayEmpty
                            style={{ display: "block" }}
                        >
                            {getCompanyRoles.map((role) => (
                                <MenuItem key={role.roleid} value={role.roleid}>{role.role}</MenuItem>
                            ))}
                        </Select>
                        <InputLabel id="demo-simple-select-autowidth-label" sx={{ marginTop: 2, textAlign: 'left' }}></InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                                minutesStep={15}
                                label="Shift Start Time"
                                value={dayjs(formik.values.shiftstarttime)}
                                onChange={(newValue) => {
                                    console.log(newValue);
                                    formik.setFieldValue("shiftstarttime", dayjs(newValue));
                                }}                                
                            />
                        </LocalizationProvider>
                        <InputLabel id="demo-simple-select-autowidth-label" sx={{ marginTop: 2, textAlign: 'left' }}></InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                            minutesStep={15}
                                label="Shift End Time"
                                className="shiftendtime"
                                value={dayjs(formik.values.shiftendtime)}
                                onChange={(newValue) => {
                                    formik.setFieldValue("shiftendtime", dayjs(newValue));
                                }}
                            />
                        </LocalizationProvider>
                        <FormGroup sx={{ marginTop: 2, textAlign: 'left' }}>
                            <FormControlLabel control={<Checkbox name="shiftendsnextday" checked={formik.values.shiftendsnextday} onChange={formik.handleChange} inputProps={{ 'aria-label': 'controlled' }} />} label="Shift Ends Next Day" />
                        </FormGroup>
                        <FormGroup>
                            <InputLabel id="demo-simple-select-autowidth-label" sx={{ marginTop: 2, textAlign: 'left' }}>Description</InputLabel>
                            <TextField
                                name="descr"
                                value={formik.values.descr}
                                onChange={formik.handleChange} />
                        </FormGroup>
                        <LoadingButton
                            sx={{ marginTop: 2, marginBottom: 2,borderRadius: 5 }}
                            variant="contained"
                            color="primary"
                            onClick={formik.submitForm}
                            loading={false}>
                            Save Changes
                        </LoadingButton>
                    </form>
                </Box>
            </Card>
        </Container>
    )
})