export interface UserConoAndPhone{
    phone: string;
    secret: string;
}

class UserConoAndPhoneModel implements UserConoAndPhone{
    phone: string ="";
    secret: string = "";
}

export default UserConoAndPhoneModel;