import { observer } from "mobx-react-lite";
import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { Button, Checkbox, Container, Box, Stack, Typography, Select, SelectChangeEvent, FormControlLabel, Tooltip, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, OutlinedInput } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import Card from '@mui/material/Card';
import { Link, useLocation, useParams } from 'react-router-dom';
import ArrowCircleLeftRoundedIcon from '@mui/icons-material/ArrowCircleLeftRounded';
import LoadingButton from '@mui/lab/LoadingButton';
import FormGroup from '@mui/material/FormGroup';
import { useStore } from '../../stores/store';
import { DragHandleRounded } from "@mui/icons-material";
import CompanyRoleModel from "../../models/CompanyRole";
import ShiftTemplateSetupModel from "../../models/Setups/ShiftTemplateSetup";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { format } from 'date-fns'
import { waitFor } from "@testing-library/react";
import ShiftInTemplate from "../../models/Setups/ShiftInTemplate";
import * as Yup from "yup";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { User } from "../../models/User";
import DeleteIcon from '@mui/icons-material/Delete';
import UserList from "../users/UserList";

export interface SimpleProps {
    templatename: string;
    templateid: number;
    roleid: number;
    handleClose: () => void;
}

const ValidationSchema = Yup.object().shape({
    dow: Yup.string().required("Day Of Week Is Required"),
    shiftid: Yup.number().required("Shift Is Required").moreThan(-1),
    descr: Yup.string().required("Description Is Required"),
    numofopenings: Yup.number().required("Numer of Openings is Required"),
});
export default observer(function AddShiftToTemplate(props: SimpleProps) {
    const { setupsStore, userStore, shiftStore } = useStore();
    const { getShiftSetupsForRole, loadShiftSetupsForRole, loading } = shiftStore;
    const { loadUsers, getAllUsers } = userStore;
    const cono = window.localStorage.getItem('cono');
    const [selectAssignedUser, setSelectedAssignedUser] = React.useState('');
    //let userList = new Map<number, string>();
    const [userList, setUsers] = React.useState([{ username: '' }]);

    useEffect(() => {
        if (props.roleid != null) {
            loadShiftSetupsForRole(props.roleid);
            loadUsers();
        }
    }, []);
    const formik = useFormik({
        initialValues: { cono: '', roleid: -1, numofopenings: '', dow: '', autoconfirm: false, shiftid: -1, descr: '', rownum: -1 },
        validationSchema: ValidationSchema,
        validateOnBlur: true,
        onSubmit: values => {
            if (cono == null) {
                return;
            }
            let template: ShiftInTemplate = { cono: cono, roleid: props.roleid, templateid: props.templateid, dow: values.dow, shiftid: values.shiftid, openings: parseInt(values.numofopenings), descr: values.descr, autoconfirm: values.autoconfirm };
            //alert(JSON.stringify(template, null, 2));         
            setupsStore.putNewShiftInTemplate(template).then(() => {
                props.handleClose();
            });
            // console.log(cono);
            // setupsStore.createNewCompanyRole(values, cono || '').then(() => {
            //     handleReset();
            // })

        }
    });

    const handleRemoveUsername = (row: string) => {
        console.log(row);
        //removeUserEditRole(row);
    }

    const handleAddNewUsername = () => {
        if (selectAssignedUser === null) {
            return;
        }

        const newItem = { username: selectAssignedUser };
        if (newItem.username != '') {
            const newItems = [...userList, newItem];
            setUsers(newItems);
            console.log('adding ' + selectAssignedUser + ' ' + userList.length);
        }
    }

    const handleChangeUserDialog = (e: SelectChangeEvent<string>) => {
        if (e.target.value === null) {
            return;
        }
        setSelectedAssignedUser(e.target.value.toString());

    };

    const handleReset = () => {
        console.log('try to reset');
        formik.resetForm({
            values: { cono: '', roleid: -1, numofopenings: '', dow: '', autoconfirm: false, shiftid: -1, descr: '', rownum: -1 },
        });
    };

    const handleChange = () => {

    };

    return (
        <Box
            sx={{
                marginTop: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}>
            <h3 style={{
                textAlign: 'center', fontSize: '14',
                fontWeight: 700,
                color: 'inherit',
            }}>{props.templatename}</h3>
            <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                <FormGroup>
                    <InputLabel id="demo-simple-select-autowidth-label" sx={{ marginTop: 2, textAlign: 'left' }}>Day Of Week</InputLabel>
                    <Select
                        name="dow"
                        value={formik.values.dow}
                        onChange={formik.handleChange}
                        style={{ display: "block" }}>
                        <MenuItem key="SUNDAY" value="SUNDAY">Sunday</MenuItem>
                        <MenuItem key="MONDAY" value="MONDAY">Monday</MenuItem>
                        <MenuItem key="TUESDAY" value="TUESDAY">Tuesday</MenuItem>
                        <MenuItem key="WEDNESDAY" value="WEDNESDAY">Wednesday</MenuItem>
                        <MenuItem key="THURSDAY" value="THURSDAY">Thursday</MenuItem>
                        <MenuItem key="FRIDAY" value="FRIDAY">Friday</MenuItem>
                        <MenuItem key="SATURDAY" value="SATURDAY">Saturday</MenuItem>
                    </Select>
                    {formik.errors.dow &&
                        <Typography style={{ marginBottom: 10 }} color='red'>Day Of Week Is Required</Typography>
                    }
                    <InputLabel id="demo-simple-select-autowidth-label" sx={{ marginTop: 2, textAlign: 'left' }}>Shift</InputLabel>
                    <Select
                        name="shiftid"
                        value={formik.values.shiftid}
                        onChange={formik.handleChange}
                        style={{ display: "block", height: '50' }}
                    >
                        {getShiftSetupsForRole.map((shift) => (
                            <MenuItem key={shift.shiftid} value={shift.shiftid}>{format(new Date(shift.shiftstarttime), "hh:mm a")} - {format(new Date(shift.shiftendtime), "hh:mm a")} - {shift.descr}</MenuItem>
                        ))}
                    </Select>
                    {formik.errors.shiftid &&
                        <Typography style={{ marginBottom: 10 }} color='red'>Shift Is Required</Typography>
                    }
                    <InputLabel id="demo-simple-select-autowidth-label" sx={{ marginTop: 2, textAlign: 'left' }}>Shift Description and Details</InputLabel>
                    <TextField
                        name="descr"
                        value={formik.values.descr}
                        onChange={formik.handleChange}
                    />
                    {formik.errors.descr &&
                        <Typography style={{ marginBottom: 10 }} color='red'>Description Is Required</Typography>
                    }
                    <InputLabel id="demo-simple-select-autowidth-label" sx={{ marginTop: 2, textAlign: 'left' }}>Number of Shift Openings</InputLabel>
                    <Select
                        name="numofopenings"
                        value={formik.values.numofopenings}
                        onChange={formik.handleChange}
                        style={{ display: "block" }}>
                        <MenuItem key="1" value="1">1</MenuItem>
                        <MenuItem key="2" value="2">2</MenuItem>
                        <MenuItem key="3" value="3">3</MenuItem>
                        <MenuItem key="4" value="4">4</MenuItem>
                        <MenuItem key="5" value="5">5</MenuItem>
                        <MenuItem key="6" value="6">6</MenuItem>
                        <MenuItem key="7" value="7">7</MenuItem>
                        <MenuItem key="8" value="8">8</MenuItem>
                        <MenuItem key="9" value="9">9</MenuItem>
                        <MenuItem key="10" value="10">10</MenuItem>
                        <MenuItem key="11" value="11">11</MenuItem>
                        <MenuItem key="12" value="12">12</MenuItem>
                        <MenuItem key="13" value="13">13</MenuItem>
                        <MenuItem key="14" value="14">14</MenuItem>
                        <MenuItem key="15" value="15">15</MenuItem>
                    </Select>
                    {formik.errors.numofopenings &&
                        <Typography style={{ marginBottom: 10 }} color='red'>Number of Openings Is Required</Typography>
                    }
                    <Stack direction="row" alignItems="center" sx={{ marginTop: 1 }}>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox name="autoconfirm" checked={formik.values.autoconfirm} onChange={formik.handleChange} />} label="Auto Confirm" />
                        </FormGroup>
                        <Tooltip title="Auto confirm shifts immediately after the shift is claimed by a user">
                            <HelpOutlineIcon color='warning' />
                        </Tooltip>
                    </Stack>
                    {/* <Stack direction="row" alignItems="center" sx={{ marginTop: 2 }}>
                        <InputLabel id="demo-simple-select-autowidth-label" sx={{ textAlign: 'left' }}>Auto Assign To Users(optional)</InputLabel>
                        <Tooltip title="Automatically Assign Shift To A Specific User When Template Is Applied">
                            <HelpOutlineIcon color='warning' />
                        </Tooltip>
                    </Stack>
                    <Stack direction="row" alignItems="center" sx={{ marginTop: 2 }}>
                        <Select
                            name="userselect"
                            value={selectAssignedUser}
                            onChange={handleChangeUserDialog}
                            input={<OutlinedInput label="" id="demo-dialog-native" />}>
                            {getAllUsers.map((user) => (
                                <MenuItem key={user.username} value={user.username}>{user.lastname}, {user.firstname} - {user.username}
                                </MenuItem>
                            ))}
                        </Select>
                        <IconButton aria-label="add" onClick={handleAddNewUsername}>
                            <AddCircleIcon color='warning' />
                        </IconButton>
                    </Stack>
                    {userList && userList.length > 0 &&
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 200 }} size='small' aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>User</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {userList && userList.map((user) =>

                                        <TableRow key={user.username}>
                                            <TableCell>{user.username}</TableCell>
                                            <TableCell align='right'>
                                                <IconButton aria-label="delete" onClick={() => handleRemoveUsername(user.username)}>
                                                    <DeleteIcon color='error' />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>

                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    } */}
                </FormGroup>
                <LoadingButton
                    sx={{ marginTop: 2, marginBottom: 2, borderRadius:5  }}
                    variant="contained"
                    color="primary"
                    onClick={formik.submitForm}
                    loading={loading}>
                    Save Shift
                </LoadingButton>
            </form>
        </Box>
    )
})