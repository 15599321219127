export interface ShiftSetup{
    cono: string;
    roleid: number;
    shiftid: number;
    shiftstarttime: Date;
    shiftendtime: Date;
    shiftendsnextday: boolean;
    descr: string;
    row: number;
    role: string;
}

class ShiftSetupModel implements ShiftSetup{
    cono: string = '';
    roleid: number = -1;
    shiftid: number = -1;
    shiftstarttime: Date = new Date();
    shiftendtime: Date = new Date();
    shiftstarttimestring: string = "";
    shiftendtimestring: string = "";
    shiftendsnextday: boolean = false;
    descr: string = '';
    row: number = 0;
    role: string = '';
}

export default ShiftSetupModel;