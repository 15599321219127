import { Box, Button, TextField } from "@mui/material";
import { Stack } from "@mui/system";
import { Link } from "react-router-dom";
import React, { useEffect } from "react";
import { useStore } from '../../stores/store';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import LoadingButton from '@mui/lab/LoadingButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { observer } from 'mobx-react-lite';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { format } from 'date-fns'
import dayjs from 'dayjs';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default observer(function CompanyUsage() {
    const { shiftStore } = useStore();
    const { loadTextEmailUsage, getTextAndEmailUsage } = shiftStore;
    const today = new Date();
    const daysago = today.setDate(today.getDate() - 7);
    const [fromDate, setFromDate] = React.useState(dayjs(new Date(daysago)));
    const [throughDate, setThroughDate] = React.useState(dayjs(new Date()));

    useEffect(() => {
        //loadTextEmailUsage();
    }, []);

    const refresh_click = () => {
        const cono = window.localStorage.getItem('cono');
        if (cono === null) {
            return;
        }
        loadTextEmailUsage(cono, format(new Date(fromDate.toDate()), 'MMddyyyy'), format(new Date(throughDate.toDate()), 'MMddyyyy'));
    }

    return (
        <>
            <Stack style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: 55 }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Stack style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <h1 style={{
                            textAlign: 'center', fontSize: '18',
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.2rem',
                            color: 'inherit',
                        }}>Email and Text Message Usage</h1>
                        {/* <Box sx={{ width: '100%' }}>
                        <LinearProgress />
                    </Box> */}
                    </Stack>
                </div>
                <Stack direction={'row'} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Box sx={{ margin: 1 }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="From Date:"
                                value={dayjs(fromDate)}
                                onChange={(newValue) => {
                                    setFromDate(newValue!);
                                }}
                            />
                        </LocalizationProvider>
                    </Box>
                    <Box sx={{ margin: 1 }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="Through Date"
                                value={dayjs(throughDate)}
                                onChange={(newValue) => {
                                    setThroughDate(newValue!);
                                }}
                            />
                        </LocalizationProvider>
                    </Box>
                    <LoadingButton
                        sx={{ marginTop: 2, marginBottom: 2 }}
                        variant="contained"
                        color="primary"
                        onClick={refresh_click}
                        loading={false}>
                        Refresh
                    </LoadingButton>
                </Stack>
                <TableContainer component={Paper} sx={{ margin: 2, maxWidth: 450 }}>
                    <Table size='small' aria-label="simple table">
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell align="center">Emails Sent</StyledTableCell>
                                <StyledTableCell>Text Messages Sent</StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {getTextAndEmailUsage && getTextAndEmailUsage.map((info) =>
                                <StyledTableRow key={info.Cono}>
                                    <StyledTableCell align="center">{info.EmailCount}</StyledTableCell>
                                    <StyledTableCell align="center">{info.TextCount}</StyledTableCell>
                                </StyledTableRow >
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Stack>
        </>
    );
})