import React from 'react';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ReactDOM from 'react-dom/client'
import { CssBaseline } from '@mui/material'
import { BrowserRouter } from 'react-router-dom'
import { createBrowserHistory } from 'history';
import { store, StoreContext } from './app/stores/store';
export const history = createBrowserHistory();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <StoreContext.Provider value={store}>
    <BrowserRouter history={history}>
      <CssBaseline />
      <App />
    </BrowserRouter>
  </StoreContext.Provider>
);

// ReactDOM.render(
//   <BrowserRouter history={history}>
//      <CssBaseline />
//        <App />
//      </BrowserRouter>,
//      document.getElementById('root')
// )

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
