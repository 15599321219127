import { object, string, number, boolean } from "yup";
import * as yup from 'yup'

export const ChangePasswordSchema = object({
    email: string(),
    password: string().required(),
    confirmpassword: string().required('Confirm Password is a required field').oneOf([yup.ref('password')], 'Passwords do not match').min(5, 'Password must be at least 5 characters'),
    resetkey: string(),
    phone:string(),
});

export interface IChangePassword{
    email: string;
    password: string;
    confirmpassword: string;
    resetkey: string;
    phone: string;
}

class ChangePasswordModel implements IChangePassword{
    email: string = "";
    password: string = "";    
    confirmpassword: string = "";
    resetkey: string = "";
    phone: string = "";
}

export default ChangePasswordModel;