import React, { useEffect } from 'react';
import { observer } from "mobx-react-lite";
import { Button, Checkbox, DialogActions, DialogContent, Drawer, FormControlLabel, FormGroup, Grid, LinearProgress, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { CheckBox } from '@mui/icons-material';
import { useStore } from '../../stores/store';
import CompanyRole from '../../models/CompanyRole';
import { UnitSetup } from '../../models/Schedule/UnitSetup';

interface SelectRoleDialogProps {
  open: boolean;
  onClose: () => void;
}

export default observer(function RotationFilterDialog(props: SelectRoleDialogProps) {
  const { onClose, open } = props;
  const { userStore, scheduleStore } = useStore();
  const { getCompanyRoles, loadCompanyRoles, loading, setCompanyRoleSelected, setCompanyRoleUnSelected, setCompanyRoleToDefault } = userStore;
  const {  loadUnitSetups, getUnitSetups, setCompanyUnitSelected, setCompanyUnitUnSelected } = scheduleStore;

  useEffect(() => {
    loadCompanyRoles();
    loadUnitSetups();
  }, []);

  const handleClose = () => {
    onClose();
  }; 
   

  const handleResetToDefault = () =>{
    setCompanyRoleToDefault();
    //onClose(hideStopped,fromDate,thruDate);
  }

  const handleToggleRoleSelect = (r: CompanyRole) => () =>{
    if(r.isSelected){
      setCompanyRoleUnSelected(r);
    }
    else{
      setCompanyRoleSelected(r);
    }
  }
  const handleToggleUnitSelect = (r: UnitSetup) => () =>{
    if(r.isselected){
      setCompanyUnitUnSelected(r);
    }
    else{
      setCompanyUnitSelected(r);
    }
  }

  return (
    <Drawer
    anchor={'right'}
    open={open}
    onClose={handleClose}
>
      <DialogTitle>Filter Results</DialogTitle>
      <DialogContent>        
      <Stack margin={2}>
        <Typography component="h6" variant="h6">
          <span>Roles</span>
        </Typography>
        {loading ?
          <Stack margin={2}>
            <LinearProgress />
          </Stack>
          :
          <>
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>


              {getCompanyRoles.map((role, index) => (
                <ListItem
                  key={role.roleid}
                  disablePadding>
                  <ListItemButton key={role.roleid} onClick={handleToggleRoleSelect(role)} dense>
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={role.isSelected}
                        tabIndex={-1}
                        disableRipple
                        defaultValue={'true'}
                        inputProps={{ 'aria-labelledby': role.role }}
                      />
                    </ListItemIcon>
                    <ListItemText id={role.roleid.toString()} primary={role.role} />
                  </ListItemButton>
                </ListItem>
              ))
              }
            </List></>
        }
        <Typography component="h6" variant="h6">
          <span>Units</span>
        </Typography>
        {loading ?
          <Stack margin={2}>
            <LinearProgress />
          </Stack>
          :
          <>
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>


              {getUnitSetups.map((unit, index) => (
                <ListItem
                  key={unit.unitid}
                  disablePadding>
                  <ListItemButton key={unit.unitid} onClick={handleToggleUnitSelect(unit)} dense>
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={unit.isselected}
                        tabIndex={-1}
                        disableRipple
                        defaultValue={'true'}
                        inputProps={{ 'aria-labelledby': unit.unitname }}
                      />
                    </ListItemIcon>
                    <ListItemText id={unit.unitid.toString()} primary={unit.unitname} />
                  </ListItemButton>
                </ListItem>
              ))
              }
            </List></>
        }
      </Stack>
      </DialogContent>
      <DialogActions>
      <Button
          variant="outlined"
          color="primary"
          autoFocus onClick={handleResetToDefault}
          sx={{ margin: 1, borderRadius:5  }}>
          Reset To Default
        </Button>
        <Button
          variant="contained"
          color="primary"
          autoFocus onClick={handleClose}
          sx={{ margin: 1, borderRadius:5  }}>
          Apply
        </Button>
      </DialogActions>
    </Drawer>
  );
})