import { object, string, number } from "yup";

export const NewShiftAlertModelSchema = object({
    cono: string().required(),
    shiftid: number().required().moreThan(-1),
    descr: string().required(),
    roleid: number().required().moreThan(0),
    numofopenings: number().required(),
    shiftdate: string().required(),
});

export interface INewShiftAlert{
    cono: string;
    shiftdate: string;
    shiftid: number;
    descr: string;
    roleid: number;
    numofopenings: number;
    createdbyusername: string;
    sendimmediatealert: boolean;
    autoconfirm: boolean;
}

class NewShiftAlertModel implements INewShiftAlert{
    cono: string = window.localStorage.getItem('cono') || "1";
    shiftdate: string = "";
    shiftid: number = -1;
    descr: string = "";
    roleid: number = -1;
    numofopenings: number = 1;
    createdbyusername: string = window.localStorage.getItem('user') || "";
    sendimmediatealert: boolean = false;
    autoconfirm: boolean = false;
}

export default NewShiftAlertModel;