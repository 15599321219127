import React, { useEffect, useRef } from "react";
import { observer } from 'mobx-react-lite';
import { useStore } from "../stores/store";
import { Box, TableContainer, Table, TableCell, TableHead, TableRow, Stack, TextField } from "@mui/material";
import Paper from '@mui/material/Paper';

export default observer(function CensusMain() {
    const { userStore } = useStore();

    const [currentCensus, setCurrentCensus] = React.useState(160);
    useEffect(() => {
        document.title = 'Census';
    }, []);


    return (
        <>
            <Stack>
                <Box sx={{ margin: 2 }}>
                    <TableContainer component={Paper} >
                        <Table aria-label="spanning table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center" colSpan={12}>
                                        May
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell>
                                        <TextField value={currentCensus}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                setCurrentCensus(Number(event.target.value));
                                            }}
                                            sx={{ margin: 1, alignSelf: 'end', width: 140 }} id="outlined-basic" label="Census" variant="outlined">

                                        </TextField>
                                    </TableCell>
                                    <TableCell>
                                        <TextField value={currentCensus}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                setCurrentCensus(Number(event.target.value));
                                            }}
                                            sx={{ margin: 1, alignSelf: 'end', width: 140 }} id="outlined-basic" label="Census" variant="outlined">

                                        </TextField>
                                    </TableCell>
                                    <TableCell>
                                        <TextField value={currentCensus}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                setCurrentCensus(Number(event.target.value));
                                            }}
                                            sx={{ margin: 1, alignSelf: 'end', width: 140 }} id="outlined-basic" label="Census" variant="outlined">

                                        </TextField>
                                    </TableCell>
                                    <TableCell>
                                        <TextField value={currentCensus}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                setCurrentCensus(Number(event.target.value));
                                            }}
                                            sx={{ margin: 1, alignSelf: 'end', width: 140 }} id="outlined-basic" label="Census" variant="outlined">

                                        </TextField>
                                    </TableCell>
                                    <TableCell>
                                        <TextField value={currentCensus}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                setCurrentCensus(Number(event.target.value));
                                            }}
                                            sx={{ margin: 1, alignSelf: 'end', width: 140 }} id="outlined-basic" label="Census" variant="outlined">

                                        </TextField>
                                    </TableCell>
                                    <TableCell>
                                        <TextField value={currentCensus}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                setCurrentCensus(Number(event.target.value));
                                            }}
                                            sx={{ margin: 1, alignSelf: 'end', width: 140 }} id="outlined-basic" label="Census" variant="outlined">

                                        </TextField>
                                    </TableCell>
                                    <TableCell>
                                        <TextField value={currentCensus}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                setCurrentCensus(Number(event.target.value));
                                            }}
                                            sx={{ margin: 1, alignSelf: 'end', width: 140 }} id="outlined-basic" label="Census" variant="outlined">

                                        </TextField>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Role</TableCell>
                                    <TableCell>1</TableCell>
                                    <TableCell>2</TableCell>
                                    <TableCell>3</TableCell>
                                    <TableCell>4</TableCell>
                                    <TableCell>5</TableCell>
                                    <TableCell>6</TableCell>
                                    <TableCell>7</TableCell>
                                </TableRow>
                            </TableHead>
                        </Table>
                    </TableContainer>
                </Box>
            </Stack>
        </>
    );
})