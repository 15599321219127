import { object, string, number, boolean } from "yup";
import { CompanyRole } from "./CompanyRole";

export const NewUserModelSchema = object({
    cono: string(),
    firstname: string().required(),
    lastname: string().required(),
    credentials: string(),
    email: string(),
    optinemail: boolean(),
    phone: string(),
    optintext: boolean(),
    hiredate: string(),
    employeeid:string(),
    username:string(),
});

export interface INewUser{
    cono: string;
    firstname: string;
    lastname: string;
    credentials: string;
    email: string;
    optinemail: boolean;
    phone: string;
    optintext: boolean;
    roleid: number;
    hiredate: string;
    roles: CompanyRole[];
    employeeid:string;
    username:string;
}

class NewUserModel implements INewUser{
    cono: string = "";
    firstname: string = "";
    lastname: string = "";
    credentials: string = "";
    email: string = "";
    optinemail: boolean = false;
    phone: string = "";
    optintext: boolean = false;
    roleid: number = 0;
    hiredate: string = "";
    roles: CompanyRole[] = [];
    employeeid: string = "";
    username:string = "";
}

export default NewUserModel;