import { observer } from "mobx-react-lite";
import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import TextField from '@mui/material/TextField';
import { Button, Checkbox, Container, Box, Stack, Typography, Select, SelectChangeEvent, Alert, Snackbar } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import FormGroup from '@mui/material/FormGroup';
import { useStore } from '../../stores/store';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import * as Yup from "yup";
import { Link } from 'react-router-dom';
import ArrowCircleLeftRoundedIcon from '@mui/icons-material/ArrowCircleLeftRounded';
import AddUserToCompanyDTOModel from "../../models/Setups/AddUserToCompanyDTO";
import { PropaneSharp } from "@mui/icons-material";

export interface SimpleProps {
    handleCloseOnSave: () => void;
}

const ValidationSchemaLookUpPhone = Yup.object().shape({
    phone: Yup.string().required("Phone Number Is Required"),
});

const ValidationSchema = Yup.object().shape({
    roleid: Yup.number().required("Role is Required").moreThan(-1),
});


export default observer(function LookUpUserByPhone(props: SimpleProps) {
    const { setupsStore, userStore } = useStore();
    const { getCompanyRoles, loadCompanyRoles, getUserLookUpByPhone, lookUpUserByPhone, resetUserLookUpByPhone, loadingLookup,putAddUserToCompany } = userStore;
    const { loading } = setupsStore;
    const cono = window.localStorage.getItem('cono');
    const [selectedRole, setSelectedRole] = React.useState('');
    const [foundUsername, setFoundUsername] = React.useState('');
    const [openCantFindUser, setOpenCantFindUser] = React.useState(false);

    const handleCloseCantFindUser = () => {
        setOpenCantFindUser(false);
    }

    useEffect(() => {
        resetUserLookUpByPhone();
        loadCompanyRoles();
    }, []);
    const formikFindUser = useFormik({
        initialValues: { phone: '' },
        validationSchema: ValidationSchemaLookUpPhone,
        validateOnBlur: true,
        onSubmit: values => {
            if (cono == null) {
                return;
            }
            getUserLookUpByPhone(values.phone).then((result) => {
                console.log(result);
                if (result === null) {
                    console.log('ok here');
                    setOpenCantFindUser(true);
                }
            })
            //let template: ShiftTemplateSetupModel = { cono: cono, roleid: values.roleid, templatedescr: values.templatename, templateid: -1, role: '', rownum: -1 };
        }
    });

    const formik = useFormik({
        initialValues: { cono: '', templatename: '', roleid: -1 },
        validationSchema: ValidationSchema,
        validateOnBlur: true,
        onSubmit: values => {
            if (cono == null) {
                return;
            }
            if(lookUpUserByPhone == null){
                return;
            }
            let user: AddUserToCompanyDTOModel = {cono : cono, username: lookUpUserByPhone.username,roleid:values.roleid};
            putAddUserToCompany(user).then(() => {
                resetUserLookUpByPhone();
                props.handleCloseOnSave();
            });
        }
    });

    const handleSelectRole = (e: SelectChangeEvent<number>) => {
        console.log('change role' + e.target.value.toString());
        if (+e.target.value > -1) {
            setSelectedRole(e.target.value.toString());
            formik.handleChange(e);
        }
    }
    if (!lookUpUserByPhone) {
        return (
            <Box
                sx={{
                    margin: 5,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}>
                <Stack direction="row" justifyContent="left" alignItems="center">
                    <Typography component="h1" variant="h5">
                        <span>Find a User</span>
                    </Typography>
                </Stack>
                <form onSubmit={formikFindUser.handleSubmit} onReset={formikFindUser.handleReset}>
                    <FormGroup>
                        <InputLabel id="demo-simple-select-autowidth-label" sx={{ marginTop: 2, textAlign: 'left' }}>Phone Number</InputLabel>
                        <TextField
                            name="phone"
                            value={formikFindUser.values.phone}
                            onChange={formikFindUser.handleChange} />
                        {formikFindUser.errors.phone &&
                            <Typography style={{ marginBottom: 10 }} color='red'>Phone Number Is Required</Typography>
                        }
                    </FormGroup>
                    <LoadingButton
                        sx={{ marginTop: 2, borderRadius:5 }}
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={formikFindUser.submitForm}
                        loading={loadingLookup}>
                        Search
                    </LoadingButton>
                </form>
                <Snackbar open={openCantFindUser} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} sx={{ width: "100%" }} autoHideDuration={5000} onClose={handleCloseCantFindUser}>
                <Alert onClose={handleCloseCantFindUser} severity="error" variant="filled" sx={{ fontSize: 18 }}>
                    Can't Find User With That Phone Number. Please Try Again
                </Alert>
            </Snackbar>
            </Box>
        )
    }
    return (
        <Box
            sx={{
                margin: 5,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}>
            <Stack direction="row" justifyContent="left" alignItems="center">
                <Button onClick={resetUserLookUpByPhone}><ArrowCircleLeftRoundedIcon fontSize="large" /></Button>
                <Stack direction='row' alignItems={'center'}>
                    <Stack alignItems={'center'} textAlign={'center'} marginLeft={2} marginRight={2}>
                        <b>Username:</b>
                        {lookUpUserByPhone.username}
                    </Stack>
                    <Stack alignItems={'center'} textAlign={'center'} marginLeft={2} marginRight={2}>
                        <b>Name:</b>
                        {lookUpUserByPhone.firstname} {lookUpUserByPhone.lastname}
                    </Stack>
                </Stack>
            </Stack>
            <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                <FormGroup>
                    <InputLabel id="demo-simple-select-autowidth-label" sx={{ marginTop: 2, textAlign: 'left' }}>Phone Number</InputLabel>
                    <TextField
                        name="templatename"
                        disabled
                        value={formikFindUser.values.phone} />
                    <InputLabel id="demo-simple-select-autowidth-label" sx={{ marginTop: 2, textAlign: 'left' }}>Role To Assign</InputLabel>
                    <Select
                        name="roleid"
                        value={formik.values.roleid}
                        onChange={handleSelectRole}
                        style={{ display: "block" }}>
                        {getCompanyRoles.map((role) => (
                            <MenuItem key={role.roleid} value={role.roleid}>{role.role}</MenuItem>
                        ))}
                    </Select>
                    {formik.errors.roleid &&
                        <Typography style={{ marginBottom: 10 }} color='red'>Role Is Required</Typography>
                    }
                </FormGroup>
                <LoadingButton
                    sx={{ marginTop: 2, borderRadius:5 }}
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={formik.submitForm}
                    loading={loadingLookup}>
                    Save
                </LoadingButton>
            </form>
        </Box>
    )
})