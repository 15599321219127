import { observer } from "mobx-react-lite";
import React, { useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { Stack } from '@mui/system';
import { useStore } from "../../../stores/store";
import { format } from 'date-fns'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, LinearProgress, MenuItem, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import { Link } from 'react-router-dom';
import { UnitSetupDTO } from "../../../models/Setups/UnitSetupDTO";
import { CompanyRolePPD } from "../../../models/Setups/CompanyRolePPD";
import { LoadingButton } from "@mui/lab";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default observer(function PPDSetup() {
    const { scheduleStore, setupsStore, userStore } = useStore();
    const { getCompanyRoles, loadCompanyRoles } = userStore;
    const { loading, loadCompanyRolePPDs, getCompanyRolePPDs } = setupsStore;
    const { loadingDetails, putEditCompanyPPD, putNewCompanyPPD } = scheduleStore;

    const [selectedRoleid, setSelectedRoleid] = React.useState(-1);
    const [roleName, setRoleName] = React.useState('');
    const [ppdbudget, setPPDBudget] = React.useState(0);
    const [ppdbudgetmin, setPPDBudgetMin] = React.useState(0);
    const [ppdbudgetmax, setPPDBudgetMax] = React.useState(0);
    const [isEditingPPD, setIsEditingPPD] = React.useState(false);
    const [openCreateOrEditPPD, setOpenCreateOrEditPPD] = React.useState(false);
    const [selectedRoleError, setSelectedRoleError] = React.useState(false);
    const [ppdbudgetError, setppdbudgetError] = React.useState(false);
    const [ppdbudgetMinError, setppdbudgetMinError] = React.useState(false);
    const [ppdbudgetMaxError, setppdbudgetMaxError] = React.useState(false);
    const [selectedroleobject, setSelectedRoleObject] = React.useState('');
    const [refreshKey, setRefreshKey] = React.useState(0);

    useEffect(() => {
        loadCompanyRoles();
        loadCompanyRolePPDs();
    }, [refreshKey]);

    const onCloseCreatOrEditUnit = () => {
        setppdbudgetError(false);
        setppdbudgetMinError(false);
        setppdbudgetMaxError(false);
        setOpenCreateOrEditPPD(false);
    }

    const handleSaveCreateOrEditUnit = () => {
        // if(unitName.trim() === ''){
        //     setUnitNameError(true);
        // }
        // if(unitShortName.trim() === ''){
        //     setUnitShortNameError(true);
        // }
        if (selectedRoleid < 0) {
            setSelectedRoleError(true);
            return;
        }

        let cono = window.localStorage.getItem('cono');
        if (!cono) {
            return;
        }
        let u: CompanyRolePPD = { cono: cono, roleid: selectedRoleid, role: roleName, ppdbudget: ppdbudget, ppdbudgetmin: ppdbudgetmin, ppdbudgetmax: ppdbudgetmax }
        if (isEditingPPD) {
            putEditCompanyPPD(u).then(() => {
                setRefreshKey(refreshKey + 1);
                setOpenCreateOrEditPPD(false);
            });
        }
        else {
            putNewCompanyPPD(u).then(() => {
                setRefreshKey(refreshKey + 1);
                setOpenCreateOrEditPPD(false);
            });;
        }
    }

    const handleClickEditPPD = (roleid: number, role: string, ppd: number, ppdmin: number, ppdmax: number) => {
        setRoleName(role);
        setSelectedRoleid(roleid);
        setSelectedRoleObject(JSON.stringify({ roleid: roleid, role: role }))
        setPPDBudget(ppd);
        setPPDBudgetMin(ppdmin);
        setPPDBudgetMax(ppdmax);
        setIsEditingPPD(true);
        setOpenCreateOrEditPPD(true);
    }
    const handleClickCreateNewUnit = () => {
        setRoleName('');
        setSelectedRoleid(-1);
        setSelectedRoleObject('')
        setPPDBudget(0);
        setPPDBudgetMin(0);
        setPPDBudgetMax(0);
        setIsEditingPPD(false);
        setOpenCreateOrEditPPD(true);
    }
    const handleRoleChange = (event: SelectChangeEvent<string>) => {
        setSelectedRoleObject(event.target.value.toString());
        let role = JSON.parse(event.target.value.toString());
        setSelectedRoleid(role.roleid);
    };
    function excludeRolesAlreadySetup(role: CompanyRolePPD) {
        if (getCompanyRolePPDs == null) {
            return true;
        }
        else {
            for (const each of getCompanyRolePPDs) {
                if (role.roleid == selectedRoleid) {
                    return true;
                }
                if (role.roleid === each.roleid) {
                    return false;
                }
            }
        }
        return true;
    }
    const isRoleDisabled = () => {
        if (isEditingPPD) {
            return true;
        }
        else {
            return false;
        }
    }


    if (loading) return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Stack style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <h1 style={{
                    textAlign: 'center', fontSize: '18',
                    fontWeight: 700,
                    color: 'inherit',
                }}>PPD SETUP</h1>
                <Box sx={{ width: '100%' }}>
                    <LinearProgress color="secondary" />
                </Box>
            </Stack>
        </div>
    )

    return (
        <>
            <Stack marginLeft={2} marginRight={2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: 55 }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Stack style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <h1 style={{
                            textAlign: 'center', fontSize: '18',
                            fontWeight: 700,
                            color: 'inherit',
                        }}>PPD SETUP</h1>
                        {/* <Box sx={{ width: '100%' }}>
                        <LinearProgress />
                    </Box> */}
                    </Stack>
                </div>
                <TableContainer component={Paper} sx={{ margin: 2 }}>
                    <Table sx={{ minWidth: 300 }} size='small' aria-label="simple table">
                        <TableHead>
                            <StyledTableRow key="100">
                                <StyledTableCell align="center">Role</StyledTableCell>
                                <StyledTableCell align="center">PPD Budget</StyledTableCell>
                                <StyledTableCell align="center">PPD Budget Min</StyledTableCell>
                                <StyledTableCell align="center">PPD Budget Max</StyledTableCell>
                                <StyledTableCell width={200}></StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {getCompanyRolePPDs && getCompanyRolePPDs.map((ppd) =>
                                <StyledTableRow key={ppd.roleid}>
                                    <StyledTableCell align="center">{ppd.role}</StyledTableCell>
                                    <StyledTableCell align="center">{ppd.ppdbudget}</StyledTableCell>
                                    <StyledTableCell align="center">{ppd.ppdbudgetmin}</StyledTableCell>
                                    <StyledTableCell align="center">{ppd.ppdbudgetmax}</StyledTableCell>
                                    <StyledTableCell width={200}>
                                        <Button
                                            sx={{ margin: 1, borderRadius: 5 }}
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => handleClickEditPPD(ppd.roleid, ppd.role, ppd.ppdbudget, ppd.ppdbudgetmin, ppd.ppdbudgetmax)}
                                        >
                                            Edit PPD
                                        </Button>
                                    </StyledTableCell>
                                </StyledTableRow >
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Button
                    sx={{ margin: 1, borderRadius: 5 }}
                    variant="contained"
                    color="secondary"
                    onClick={() => handleClickCreateNewUnit()}
                >
                    Create New PPD
                </Button>
            </Stack>
            <Dialog open={openCreateOrEditPPD} onClose={onCloseCreatOrEditUnit}>
                {isEditingPPD ?
                    <DialogTitle>Edit PPD</DialogTitle>
                    :
                    <DialogTitle>Create New PPD</DialogTitle>
                }
                <DialogContent dividers={true}>
                    <FormControl fullWidth sx={{ marginTop: 1, marginBottom: 1 }}>
                        <InputLabel id="role-label">Role</InputLabel>
                        <Select
                            labelId='role-label'
                            label="Role"
                            margin="dense"
                            fullWidth
                            required
                            value={selectedroleobject}
                            disabled={isRoleDisabled()}
                            onChange={handleRoleChange}
                            error={selectedRoleError}>
                            {getCompanyRoles.filter(excludeRolesAlreadySetup).map((role) => (
                                <MenuItem key={role.roleid} value={JSON.stringify({ roleid: role.roleid, role: role.role })}>
                                    {role.role}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="ppdbudgetmin"
                        label="PPD Budget Min"
                        type="number"
                        fullWidth
                        value={ppdbudgetmin}
                        required
                        defaultValue={0}
                        onChange={(e) => setPPDBudgetMin(Number.parseFloat(e.target.value))}
                        error={ppdbudgetMinError}
                        helperText={ppdbudgetMinError ? "Min PPD Budget is required" : ""}
                        inputProps={{ maxLength: 6 }}
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="ppdbudget"
                        label="PPD Budget"
                        type="number"
                        fullWidth
                        value={ppdbudget}
                        required
                        defaultValue={0}
                        onChange={(e) => setPPDBudget(Number.parseFloat(e.target.value))}
                        error={ppdbudgetError}
                        helperText={ppdbudgetError ? "PPD Budget is required" : ""}
                        inputProps={{ maxLength: 6 }}
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="ppdbudgetmax"
                        label="PPD Budget Max"
                        defaultValue={0}
                        type="number"
                        fullWidth
                        value={ppdbudgetmax}
                        required

                        onChange={(e) => setPPDBudgetMax(Number.parseFloat(e.target.value))}
                        error={ppdbudgetMaxError}
                        helperText={ppdbudgetMaxError ? "Max PPD Budget is required" : ""}
                        inputProps={{ maxLength: 6 }}
                    />
                </DialogContent>
                <DialogActions>
                    <LoadingButton loading={loadingDetails} onClick={onCloseCreatOrEditUnit}>Cancel</LoadingButton>
                    <LoadingButton loading={loadingDetails} variant="contained" onClick={handleSaveCreateOrEditUnit}>Save</LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
})