import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import ListItemText from '@mui/material/ListItemText';
import { Button, Checkbox, Container, Box, Stack, Typography, Alert, Snackbar } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import Card from '@mui/material/Card';
import { useStore } from '../../stores/store';
import { Link } from 'react-router-dom';
import ArrowCircleLeftRoundedIcon from '@mui/icons-material/ArrowCircleLeftRounded';
import NewUserModel, { NewUserModelSchema } from '../../models/NewUser';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import * as Yup from 'yup';
import { AxiosError } from 'axios';
import { readJsonConfigFile } from 'typescript';
import { history } from '../../..';
import LoadingButton from '@mui/lab/LoadingButton';
import CircularProgress from '@mui/material/CircularProgress';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import { observer } from 'mobx-react-lite';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default observer(function ForgotPassword() {
    const { userStore } = useStore();
    const { loading, checkConfirmationCode, DoesConfirmationKeyMatch, sendingConfirmationCode, IsUserAbleToResetPassword, checkCanUserResetPassword } = userStore;
    const [sentCode, setSentCode] = React.useState(false);
    const [passwordWasReset, setPasswordWasReset] = React.useState(false);
    const [openEmailSentAlert, setOpenEmailSentAlert] = React.useState(false);
    const [error, setError] = React.useState('');
    const [canResetError, setCanResetError] = React.useState('');

    let [checkConfirmCode, setCheckConfirmCode] = React.useState(0); //0 mean never checked, 1 means check and not correct, 2 means checks IS correct

    useEffect(() => {
        document.title = 'Forgot Password';
    }, []);

    const handleCloseSnack = () => {
        setOpenEmailSentAlert(false);
        setPasswordWasReset(false);
    }

    const EmailSchema = Yup.object().shape({
        phone: Yup.string().required('Required'),
    });

    const ChangePasswordSchema = Yup.object().shape({
        phone: Yup.string().required('Required'),
        resetkey: Yup.string().required('Required'),
        password: Yup.string().required('Required'),
        confirmpassword: Yup.string().required('Required'),
    });

    const formik = useFormik({
        initialValues: { phone: '' },
        onSubmit: values => {
            checkUserAndSendEmail();
        },
        validationSchema: EmailSchema,
        validateOnBlur: true,

    });

    const checkUserAndSendEmail = async () => {
        await userStore.IsUserAbleToResetPassword(formik.values.phone).then((response) => {
            if (response === true) {
                console.log('send code for ' + formik.values.phone);
                setCanResetError('');
                userStore.sendconfirmationcode(formik.values.phone).then(() => {
                    formik2.values.phone = formik.values.phone;
                    setSentCode(true);
                    setOpenEmailSentAlert(true);
                });
            }
            else {
                setCanResetError('User Does Not Exist or Not Set Up For A Login');
            }
        });
    };

    const formik2 = useFormik({
        initialValues: { email: '',phone: '', password: '', confirmpassword: '', resetkey: '' },
        onSubmit: values => {
            // alert(JSON.stringify(values, null, 2));
            if (formik2.values.password != formik2.values.confirmpassword) {
                setError('Passwords Do Not Match');
                console.log('passwords dont match' + ' ok ' + error);
            }
            else {
                if (formik2.values.password.length > 5) {
                    userStore.resetUserPassword(values)
                        .then((response) => {
                            handleReset();
                        })
                        .catch((reason: AxiosError) => {
                            console.log('got error');
                            setPasswordWasReset(false);
                            if (reason.response!.status === 404) {
                                console.log('confirm key is wrong')
                            }
                            if (reason.response!.status === 409) {
                                console.log('confirm key expired')
                            }
                        })
                }
                else {
                    setError('Passwords must be at least 6 characters long');
                }
            }
        },
        validationSchema: ChangePasswordSchema,
        validateOnBlur: true,

    });

    const handleInput = async () => {
        if (formik2.values.resetkey) {
            await DoesConfirmationKeyMatch(formik.values.phone, formik2.values.resetkey).then((response) => {
                console.log('ok ' + response);
                if (response === true) {
                    setCheckConfirmCode(2);
                } else {
                    console.log('set 1');
                    setCheckConfirmCode(1);
                }
            });
        }
    };

    const handleGoToLoginClick = () => {
        history.push("/login");
        history.go(0);
    }

    const handleChange = () => {

    };

    const handleChange2 = (event: { target: { value: any; }; }) => {
        const {
            target: { value },
        } = event;
    };

    const handleReset = () => {
        console.log('try to reset');
        formik.resetForm({
            values: { phone: '' },
        });
        formik2.resetForm({
            values: { email: '',phone: '', resetkey: '', password: '', confirmpassword: '' }
        });
        setSentCode(false);
        setPasswordWasReset(true);
    };

    function IsCorrectConfirmationCode() {
        if (checkConfirmCode == 0) {
            return (
                <></>
            )
        }
        if (checkConfirmCode == 1) {
            setError('Invalid Confirmation Code or Code is Expired');
            return (
                <ClearRoundedIcon color="error" />
            )
        }
        if (checkConfirmCode == 2) {
            return (
                <DoneRoundedIcon color="success" />
            )
        }
        return (<></>);

    };
    // console.log('Form Values', formik.values);

    if (passwordWasReset) {
        return (
            <Container component="main" maxWidth='sm' >
                <Card sx={{ margin: 2, paddingLeft: 1, paddingRight: 1 }} raised>
                    <h1 style={{
                        textAlign: 'center', fontSize: '18',
                        fontWeight: 700,
                        letterSpacing: '.2rem',
                        color: 'inherit'
                    }}>Password Was Reset</h1>
                    <Button
                        sx={{ marginTop: 2, marginBottom: 2 }}
                        variant="contained"
                        color="primary"
                        onClick={handleGoToLoginClick}>
                        Go To Login
                    </Button>
                </Card>
            </Container >
        )
    }

    return (
        <Container component="main" maxWidth="sm" sx={{ paddingBottom: 10 }}>
            <Card sx={{ margin: 2, paddingLeft: 1, paddingRight: 1 }} raised>
                <Box
                    sx={{
                        marginTop: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Stack direction="column" justifyContent="left" alignItems="center">
                        {/* <Button component={Link} to="/users"><ArrowCircleLeftRoundedIcon fontSize="large" /></Button> */}
                        <Typography component="h1" variant="h5">
                            Forgot Password
                        </Typography>
                        <Typography fontSize={11} textAlign='center' margin={1}>
                            *Note, you only need a password if you will be creating shift alerts. You do not need a password if you will only receive shift alerts.
                        </Typography>
                    </Stack>
                    <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                        <InputLabel id="demo-simple-select-autowidth-label" sx={{ marginTop: 2, textAlign: 'left' }}>Phone Number</InputLabel>
                        <TextField
                            error={!!formik.errors.phone}
                            name="phone"
                            value={formik.values.phone}
                            onChange={formik.handleChange} />
                        <Typography style={{ marginBottom: 2 }} color='red'>{canResetError}</Typography>
                        <InputLabel id="demo-simple-select-autowidth-label" sx={{ marginTop: 2, textAlign: 'left' }}></InputLabel>
                        <LoadingButton
                            sx={{ marginTop: 0, marginBottom: 1 }}
                            variant="contained"
                            color="warning"
                            loading={sendingConfirmationCode}
                            onClick={formik.submitForm}>
                            Send Confirmation Code
                        </LoadingButton>
                        {sentCode &&
                            <>
                                <InputLabel id="demo-simple-select-autowidth-label" sx={{ marginTop: 2, textAlign: 'left' }}>Confirmation Code</InputLabel>
                                <TextField
                                    error={!!formik2.errors.resetkey}
                                    name="resetkey"
                                    value={formik2.values.resetkey}
                                    onChange={formik2.handleChange}
                                    onBlur={handleInput} />{checkConfirmationCode ? <><CircularProgress /></> : <><IsCorrectConfirmationCode /></>}
                                <InputLabel id="demo-simple-select-autowidth-label" sx={{ marginTop: 2, textAlign: 'left' }}>Password</InputLabel>
                                <TextField
                                    error={!!formik2.errors.password}
                                    name="password"
                                    type="password"
                                    value={formik2.values.password}
                                    onChange={formik2.handleChange} />
                                <InputLabel id="demo-simple-select-autowidth-label" sx={{ marginTop: 2, textAlign: 'left' }}>Confirm Password</InputLabel>
                                <TextField
                                    error={!!formik2.errors.confirmpassword}
                                    name="confirmpassword"
                                    type="password"
                                    value={formik2.values.confirmpassword}
                                    onChange={formik2.handleChange} />
                                <InputLabel id="demo-simple-select-autowidth-label" sx={{ marginTop: 2, textAlign: 'left' }}></InputLabel>
                                <Typography style={{ marginBottom: 2 }} color='red'>{error}</Typography>
                                <LoadingButton
                                    sx={{ marginTop: 2, marginBottom: 2 }}
                                    variant="contained"
                                    color="primary"
                                    loading={loading}
                                    onClick={formik2.submitForm}>
                                    Save Password
                                </LoadingButton>
                            </>
                        }
                    </form>

                    {/* <div>
                        <h3>Validation Model</h3>
                        <pre>{JSON.stringify(formik.errors, null, 2)}</pre>
                    </div>
                    <div>
                        <h3>Form Model</h3>
                        <pre>{JSON.stringify(formik.values, null, 2)}</pre>
                    </div> */}
                </Box >
            </Card>

            <Snackbar open={openEmailSentAlert} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} sx={{ width: "100%" }} autoHideDuration={5000} onClose={handleCloseSnack}>
                <Alert onClose={handleCloseSnack} severity="success" variant="filled" sx={{ fontSize: 24 }}>
                    Text Message Sent With Confirmation Code
                </Alert>
            </Snackbar>
        </Container>
    );
})