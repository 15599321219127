import './App.css';
import { Route, Routes, useLocation } from "react-router-dom"
import React from 'react';
import HomePage from './app/views/home/HomePage';
import UsersMain from './app/views/users/UsersMain';
import InActiveUsersMain from './app/views/users/InActiveUsersMain';
import ShiftsMain from './app/views/shifts/ShiftsMain';
import ResponsiveAppBar from './app/components/ResponsiveAppBar';
import CreateShiftAlertNewest from './app/views/shifts/CreateShiftAlertNewest';
import CreateNewUser from './app/views/users/CreateNewUser';
import ClaimShiftForm from './app/views/shifts/ClaimShiftForm'
import LoginForm from './app/views/home/LoginForm';
import { PrivateRoute } from './app/views/home/PrivateRoute';
import CreatePassword from './app/views/users/CreatePassword';
import Preferences from './app/views/preferences/Preferences';
import ShiftSetupList from './app/views/setups/ShiftSetupList';
import RoleSetupList from './app/views/setups/RoleSetupList';
import EditRole from './app/views/setups/EditRole';
import AddNewRole from './app/views/setups/AddNewRole';
import AddNewShift from './app/views/setups/AddNewShift';
import SelfEnrollUser from './app/views/users/SelfEnrollUser';
import EditShift from './app/views/setups/EditShift';
import EditUser from './app/views/users/EditUser';
import ForgotPassword from './app/views/users/ForgotPassword';
import CompanyUsage from './app/views/usage/CompanyUsage';
import ViewSentHistory from './app/views/shifts/ViewSentHistory';
import EnterCompanyCode from './app/views/home/EnterCompanyCode';
import ShiftListForRole from './app/views/shifts/ShiftListForRole';
import ResponsesMain from './app/views/responses/ResponsesMain';
import ViewMyShifts from './app/views/home/ViewMyShifts';
import ViewUserShifts from './app/views/users/ViewUserShifts';
import ClaimAlertsList from './app/views/setups/ClaimAlertsList';
import AddNewClaimAlertEmail from './app/views/setups/AddNewClaimAlertEmail';
import AddNewClaimAlertText from './app/views/setups/AddNewClaimAlertText';
import TemplateSetupMain from './app/views/templates/TemplateSetupMain';
import CreateShiftsWithTemplate from './app/views/templates/CreateShiftsWithTemplate'
import CreateNewTemplate from './app/views/templates/CreateNewTemplate';
import AddShiftToTemplate from './app/views/templates/AddShiftToTemplate';
import MainCalendar from './app/views/calendar/MainCalendar';
import ViewUserSentHistory from './app/views/users/ViewUserSentHistory';
import ContactForm from './app/views/contact/ContactForm';
import ContactUsForm from './app/views/contact/ContactUsForm';
import NewScheduleMain from './app/views/schedule/NewScheduleMain';
import censusmain from './app/census/censusmain';
import RotationsMain from './app/views/rotations/RotationsMain';
import AvailabilityMain from './app/views/availability/AvailabilityMain';
import ApplyRotation from './app/views/rotations/ApplyRotation';
import ReportingMain from './app/views/reporting/ReportingMain';
import ScheduleMain from './app/views/schedule/ScheduleMain';
import NewScheduleMainPrintable from './app/views/schedule/NewScheduleMainPrintable';
import UnitSetupMain from './app/views/setups/unitsetup/UnitSetupMain';
import PPDSetup from './app/views/setups/ppdsetup/PPDSetup';
import ViewUserManual from './app/views/home/ViewUserManual';

function App() {
  const { pathname } = useLocation();
  return (
    <>
      {pathname.includes("/scheduleprint") ? <></> : <ResponsiveAppBar /> }
      <Routes>
        <Route path="/" element={<LoginForm />} />
        <Route path="/login" element={<LoginForm />} />
        <Route path="/contact" element={<ContactForm />} />
        <Route path="/contactus" element={<ContactUsForm />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/createnewpassword/:username" element={<PrivateRoute component={CreatePassword} />} />
        <Route path="/home" element={<PrivateRoute component={HomePage} />} />
        <Route path="/users" element={<PrivateRoute component={UsersMain} />} />
        <Route path="/usersenthistory/:username" element={<PrivateRoute component={ViewUserSentHistory} />} />
        <Route path="/inactiveusers" element={<PrivateRoute component={InActiveUsersMain} />} />
        <Route path="/templates" element={<PrivateRoute component={TemplateSetupMain} />} />
        <Route path="/responses" element={<PrivateRoute component={ResponsesMain} />} />
        <Route path="/createuser" element={<PrivateRoute component={CreateNewUser} />} />
        <Route path="/edituser/:fac/:username" element={<PrivateRoute component={EditUser} />} />
        <Route path="/shifts" element={<PrivateRoute component={ShiftsMain} />} />
        <Route path="/addshiftalert" element={<PrivateRoute component={CreateShiftAlertNewest} />} />
        <Route path="/createshiftswithtemplate" element={<PrivateRoute component={CreateShiftsWithTemplate} />} />
        <Route path="/createnewtemplate" element={<PrivateRoute component={CreateNewTemplate} />} />
        <Route path="/addshifttotemplate/:templateid/:roleid" element={<PrivateRoute component={AddShiftToTemplate} />} />
        <Route path="/claim/:fac/:id" element={<ClaimShiftForm />} />
        <Route path="/claimshift/:fac/:id" element={<ClaimShiftForm />} />
        <Route path="/viewshifts/:fac/:key" element={<ShiftListForRole />} />
        {/* <Route path="/viewshiftresponses/:fac/:shiftalertid" element={<PrivateRoute component={ViewShiftResponses} />} /> */}
        <Route path="/viewsenthistory/:fac/:shiftalertid" element={<PrivateRoute component={ViewSentHistory} />} />
        <Route path="/preferences" element={<PrivateRoute component={Preferences} />} />
        <Route path="/shiftsetup" element={<PrivateRoute component={ShiftSetupList} />} />
        <Route path="/claimalerts" element={<PrivateRoute component={ClaimAlertsList} />} />
        <Route path="/addclaimemail" element={<PrivateRoute component={AddNewClaimAlertEmail} />} />
        <Route path="/addclaimtext" element={<PrivateRoute component={AddNewClaimAlertText} />} />
        <Route path="/editshift/:roleid/:shiftid" element={<PrivateRoute component={EditShift} />} />
        <Route path="/rolesetup" element={<PrivateRoute component={RoleSetupList} />} />
        <Route path="/unitsetup" element={<PrivateRoute component={UnitSetupMain} />} />
        <Route path="/ppdsetup" element={<PrivateRoute component={PPDSetup} />} />
        <Route path="/editrole/:roleid" element={<PrivateRoute component={EditRole} />} />
        <Route path="/addnewrole" element={<PrivateRoute component={AddNewRole} />} />
        <Route path="/addnewshift" element={<PrivateRoute component={AddNewShift} />} />
        <Route path="/usage" element={<PrivateRoute component={CompanyUsage} />} />
        <Route path="/calendar" element={<PrivateRoute component={MainCalendar} />} />
        {/* <Route path="/schedule3" element={<PrivateRoute component={NewScheduleMainPrintable} />} /> */}
        <Route path="/schedule2" element={<PrivateRoute component={ScheduleMain} />} />
        <Route path="/schedule" element={<PrivateRoute component={NewScheduleMain} />} />
        <Route path="/scheduleprint/:selectedroleid/:rolename/:selectedSundayString" element={<PrivateRoute component={NewScheduleMainPrintable} />} />
        {/* <Route path="/census" element={<PrivateRoute component={censusmain} />} /> */}
        <Route path="/rotationsetup" element={<PrivateRoute component={RotationsMain} />} />
        <Route path="/rotations" element={<PrivateRoute component={ApplyRotation} />} />
        <Route path="/availability" element={<PrivateRoute component={AvailabilityMain} />} />
        <Route path="/reporting" element={<PrivateRoute component={ReportingMain} />} />
        <Route path="/entercompanycode" element={<EnterCompanyCode />} />
        <Route path="/viewusershifts" element={<ViewMyShifts />} />
        <Route path="/ViewMyShifts/:key" element={<ViewUserShifts />}/>
        <Route path="/register/:key" element={<SelfEnrollUser />} />
        <Route path="/viewusermanual" element={<PrivateRoute component={ViewUserManual} />} />
      </Routes>
      {/* <Footer /> */}
    </>
  );
}

export default App;