import { TableContainer, styled, tableCellClasses, Paper, Table, TableHead, TableBody, TableCell, TableRow, CssBaseline, Divider, Stack, Tab, Tabs, IconButton, TextField, Box, Typography, LinearProgress, Fab, Tooltip, Button, Container } from "@mui/material";
import { observer } from "mobx-react-lite"
import React, { useEffect, useRef } from "react";
import { useStore } from "../../stores/store";
import CompanyRole from "../../models/CompanyRole";
import { format, setDate } from 'date-fns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { CurrentSchedule } from "../../models/Schedule/CurrentSchedule";
import AddIcon from '@mui/icons-material/Add';
import AddNewShiftDialog from "./AddNewShiftDialog";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddNewShiftToScheduleDialog from "./AddNewShiftToScheduleDialog";
import PrintIcon from '@mui/icons-material/Print';
import EditIcon from '@mui/icons-material/Edit';
import { Margin, Padding } from "@mui/icons-material";
import InfoIcon from '@mui/icons-material/Info';
import AddUserToSchedule from "./AddUserToSchedule";
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import AddAlertIcon from '@mui/icons-material/AddAlert';
import CreateShiftAlertDialog from "../shifts/CreateShiftAlertDialog";
import { history } from '../../..';
import FilterListIcon from '@mui/icons-material/FilterList';

const style = (theme: any) => ({
    margin: 0,
    top: 'auto',
    left: 'auto',
    bottom: 20,
    right: 20,
    position: 'fixed',

});
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
function getNextSunday() {
    console.log('get previous sunday');
    let dt = new Date();
    switch (dt.getDay()) {
        case 0:
            break;
        case 1:
            dt.setDate(dt.getDate() + 6);
            break;
        case 2:
            dt.setDate(dt.getDate() + 5);
            break;
        case 3:
            dt.setDate(dt.getDate() + 4);
            break;
        case 4:
            dt.setDate(dt.getDate() + 3);
            break;
        case 5:
            dt.setDate(dt.getDate() + 2);
            break;
        case 6:
            dt.setDate(dt.getDate() + 1);
            break;
    }

    dt.setDate(dt.getDate() - 7);
    return dt;
}
const getFontSize = () => {
    return 12;
}
function isDateInSelectedWeek(date1: Date, sunday: Date): boolean {
    const date1WithoutTime = new Date(new Date(date1).getFullYear(), new Date(date1).getMonth(), new Date(date1).getDate());
    const sun = new Date(new Date(sunday).getFullYear(), new Date(sunday).getMonth(), new Date(sunday).getDate());
    const nextsun = new Date(sun.getDate() + 7);

    let result = false;

    if (date1WithoutTime >= sun && date1WithoutTime <= nextsun) {
        console.log('cyaahompare ' + date1WithoutTime + ' and ' + sun)
        result = true;
    }
    return result;
}
function formatDateToString(date: Date): string {
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear().toString();

    return `${month}${day}${year}`;
}
function compareDatesIgnoringTime(date1: Date, date2: Date): boolean {
    const date1WithoutTime = new Date(new Date(date1).getFullYear(), new Date(date1).getMonth(), new Date(date1).getDate());
    const date2WithoutTime = new Date(new Date(date2).getFullYear(), new Date(date2).getMonth(), new Date(date2).getDate());


    return date1WithoutTime.getTime() === date2WithoutTime.getTime();
}
function countHoursBetweenDates(startDate: Date, endDate: Date): number {

    const timeDiff = endDate.getTime() - startDate.getTime();
    let hoursDiff = timeDiff / (1000 * 60 * 60);
    if (endDate < startDate) {//exception for overnight shifts where the end time might be midnight
        hoursDiff = (24 - Math.abs(Math.round(hoursDiff)));
    }
    return Math.abs(Math.round(hoursDiff));
}

export default observer(function NewScheduleMain() {
    const { userStore, scheduleStore } = useStore();
    const { getCompanyRoles, loadCompanyRoles } = userStore;
    const { loadCurrentSchedule, getCurrentSchedule, loading, loadUnitSetups, getUnitSetups } = scheduleStore;
    const [selectedRoleIDStringTab, setSelectRoleIDStringTab] = React.useState('');
    const [selectedRoleIDTab, setSelectRoleIDTab] = React.useState(1);
    const [selectedTotals, setSelectedTotals] = React.useState(0);
    const [selectedSunday, setSelectedSunday] = React.useState(getNextSunday());
    const [compactMode, setCompactMode] = React.useState(true);
    const [currentCensus, setCurrentCensus] = React.useState([80, 80, 80, 80, 80, 80, 80]);
    const [openAddNewShift, setOpenAddNewShift] = React.useState(false);
    const [openAddUserToSchedule, setOpenAddUserToSchedule] = React.useState(false);
    const [dorefresh, setDoRefresh] = React.useState(false);
    const [refreshKey, setRefreshKey] = React.useState(0);
    const [selectedUser, setSelectedUser] = React.useState('');
    const [selectedName, setSelectedName] = React.useState('');
    const [selectedRoleid, setSelectedRoleid] = React.useState(-1);
    const [selectedRole, setSelectedRole] = React.useState('');
    const [selectedUnit, setSelectedUnit] = React.useState('');
    const [selectedShift, setSelectedShift] = React.useState('');
    const [selectedScheduleid, setSelectedScheduleid] = React.useState(-1); //used to edit a scheduled shift
    const [isEditingShift, setIsEditingShift] = React.useState(false);
    const [selectedShiftDate, setSelectedShiftDate] = React.useState(new Date());
    const [selectedStartTime, setSelectedStartTime] = React.useState(new Date());
    const [selectedEndTime, setSelectedEndTime] = React.useState(new Date());
    const [showCreateShiftAlertDialog, setShowCreateShiftAlertDialog] = React.useState(false);
    const [createShiftAlertSelectedRole, setCreateShiftAlertSelectedRole] = React.useState(-1);
    const [createShiftAlertSelectedDate, setCreateShiftAlertSelectedDate] = React.useState(new Date());


    const handlePrint = () => {
        history.push("/scheduleprint/" + selectedRoleIDTab + '/' + selectedRole + '/' + formatDateToString(selectedSunday) + '/');
        history.go(0);
    };

    const daysofweek: string[] = [
        "SUNDAY",
        "MONDAY",
        "TUESDAY",
        "WEDNESDAY",
        "THURSDAY",
        "FRIDAY",
        "SATURDAY"
    ];

    useEffect(() => {
        document.title = 'Current Schedule';
        async function fetchData() {
            loadCurrentSchedule(format(new Date(), 'MMddyyyy'));
            loadCompanyRoles();
            loadUnitSetups();
        }

        fetchData();

        let selected: CompanyRole = new CompanyRole();
        getCompanyRoles.map(r => {
            if (r.roleid == selectedRoleIDTab) {
                selected = r;
            }
        });
        setSelectedRole(selected.role);
    }, [refreshKey]);
    const handleOnCloseNewShiftDialog = (value: boolean) => {
        setOpenAddNewShift(false);
        if (value) {
            setRefreshKey(refreshKey + 1);
        }
    }
    const handleOnCloseAddUserDialog = (value: boolean) => {
        setOpenAddUserToSchedule(false);
        if (value) {
            setRefreshKey(refreshKey + 1);
        }
    }
    const onCloseCreateShiftAlertDialog = (didsave: boolean) => {
        setShowCreateShiftAlertDialog(false);
    }

    const handleUpdateCensus = (index: number, newValue: number) => {
        setCurrentCensus((prev) => {
            const updated = [...prev];
            updated[index] = newValue;
            return updated;
        });
    }
    const getFirstColumnWidth = () => {
        if (compactMode) {
            return 300;
        }
        else {
            return 300;
        }
    }
    const getSecondColunWidth = () => {
        if (compactMode) {
            return 130;
        }
        else {
            return 130;
        }
    }
    const getCombinedColumn = () => {
        let i: number = 0;
        i = getFirstColumnWidth() + getSecondColunWidth();
        return i;
    }
    const handleChangeTotals = (event: React.SyntheticEvent, newValue: number) => {
        setSelectedTotals(newValue);
    };
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectRoleIDTab(newValue);
        setSelectedRoleid(newValue);
        let selected: CompanyRole = new CompanyRole();
        getCompanyRoles.map(r => {
            if (r.roleid == newValue) {
                selected = r;
            }
        });
        console.log('set role ' + selected.role);
        setSelectedRole(selected.role);

    };
    const tabColorByRole = (r: number) => {
        if (r === 0) {
            return '#D18C19';
        }
        if (r === 1) {
            return '#1976D2';
        }
        else if (r === 2) {
            return '#3DD119';
        }
        else if (r === 3) {
            return '#D61AB0';
        }
        else if (r === 4) {
            return '#4E7EAD';
        }
        else {
            return '#1976d2';
        }
    }
    function excludeUsersThatDontHaveShiftInWeek(sched: CurrentSchedule) {
        let result = false;
        let nextSunday = new Date(selectedSunday);
        let thissunday = new Date(selectedSunday);
        thissunday.setHours(0, 0, 0, 0);
        nextSunday.setDate(nextSunday.getDate() + 6);
        sched.shifts.forEach(s => {
            // if (sched.username = 'colby.breidenstein@taracares.com') {
            //     console.log('colby compare ' + sched.roleid + new Date(s.shiftdate) + ' and ' + new Date(thissunday) + ' - ' + s.scheduleid + (new Date(s.shiftdate) >= new Date(thissunday) && new Date(s.shiftdate) < new Date(nextSunday)));
            // }
            if (new Date(s.shiftdate) >= new Date(thissunday) && new Date(s.shiftdate) < new Date(nextSunday)) {
                // console.log('testyes compare ' + new Date(s.shiftdate) + ' and ' + new Date(nextSunday));
                result = true;
            }
        })
        return result;
    }
    function excludeAdministrationRole(role: CompanyRole) {
        return !role.isadministrationrole;
    }
    function excludeRoles(shift: any) {
        if (shift.roleid == selectedRoleIDTab) {
            return true;
        }
        else {
            return false;
        }
    }
    function getDoesUserHaveShiftThisDate(shifts: {
        shiftdate: Date
        shiftstarttimestring: string,
        shiftendtimestring: string,
        unitid: number,
        unitname: string,
        unitshortname: string,
        shiftid: number,
    }[], date: Date, username: string) {
        let result = false;
        shifts.forEach((shift) => {
            if (shift.shiftid < -1) { //hide the shifts that don't exist, for the 
                result = false;
                return result;
            }
            if (new Date(shift.shiftdate) >= new Date(date) && new Date(shift.shiftdate) <= new Date(date)) {
                if (shift.shiftstarttimestring === shift.shiftendtimestring) {
                    result = false;
                }
                else {
                    result = true;
                }
                return result;
            }

        })
        return result;
    }
    function getShiftTimesForUserThisDate(shifts: CurrentSchedule, date: Date) {

        let result = '';
        let selected: {
            shiftdate: Date
            shiftid: number,
            scheduleid: number,
            shiftstarttimestring: string,
            shiftendtimestring: string,
            shiftendsnextday: boolean,
            unitid: number,
            unitname: string,
            unitshortname: string,
            seconddayofshift: boolean
        } = {
            shiftdate: new Date(),
            scheduleid: -1,
            shiftid: -1,
            shiftstarttimestring: "",
            shiftendtimestring: "",
            shiftendsnextday: false,
            unitid: 0,
            unitname: "",
            unitshortname: "",
            seconddayofshift: false
        };
        shifts.shifts.forEach((shift) => {
            if (new Date(shift.shiftdate) >= date && new Date(shift.shiftdate) <= date) {
                result += shift.shiftstarttimestring + '-' + shift.shiftendtimestring;
                selected = shift;
            }
        })

        if (selected == undefined) {
            return null;
        }
        if (selected.unitid >= 0) {
            return (<>
                <Tooltip title={selected.unitname}>
                    <Typography sx={{ fontSize: 11 }}>({selected.unitshortname})
                    </Typography>
                </Tooltip>
                <Typography sx={{ fontSize: 11 }}>{result}
                </Typography>
                {selected.scheduleid < 0 ?
                    <>
                        <Tooltip title='This is from Fill Open Shifts'>
                            <IconButton
                                aria-label="expand row"
                                size="small">
                                <InfoIcon sx={{ fontSize: 13 }} color='primary' />
                            </IconButton>
                        </Tooltip>
                    </>
                    :
                    selected.seconddayofshift == false ?    //doesn't show the edit button for the second day of the shift
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => clickEditShiftInSchedule(new Date(selected.shiftdate), selected.scheduleid, convertTimeStringToDate(selected.shiftstarttimestring), convertTimeStringToDate(selected.shiftendtimestring), shifts.roleid, shifts.role, shifts.username, shifts.name,
                                JSON.stringify({ shiftid: selected.shiftid, shiftendsnextday: selected.shiftendsnextday }), JSON.stringify({ unitid: selected.unitid, unitname: selected.unitname, unitshortname: selected.unitshortname }))}>
                            <EditIcon sx={{ fontSize: 12 }} color='warning' />
                        </IconButton> : <></>}
            </>)
        }
        else {
            return (<>
                <Typography sx={{ fontSize: 11 }}>{result}
                </Typography>
                {selected.scheduleid < 0 ?
                    <>
                        <Tooltip title='This is from Fill Open Shifts'>
                            <IconButton
                                aria-label="expand row"
                                size="small">
                                <InfoIcon sx={{ fontSize: 13 }} color='primary' />
                            </IconButton>
                        </Tooltip>
                    </>
                    :
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => clickEditShiftInSchedule(new Date(selected.shiftdate), selected.scheduleid, convertTimeStringToDate(selected.shiftstarttimestring), convertTimeStringToDate(selected.shiftendtimestring), shifts.roleid, shifts.role, shifts.username, shifts.name,
                            JSON.stringify({ shiftid: selected.shiftid, shiftendsnextday: selected.shiftendsnextday }), JSON.stringify({ unitid: selected.unitid, unitname: selected.unitname, unitshortname: selected.unitshortname }))}>
                        <EditIcon sx={{ fontSize: 12 }} color='warning' />
                    </IconButton>}
            </>)
        }

        //return result;
    }
    const getTotalHoursForUserAndRole = (schedule: CurrentSchedule, roleid: number) => {
        let count: number = 0;
        schedule.shifts.forEach(shift => {

            let nextsun = new Date(selectedSunday);
            let startsun = new Date(selectedSunday);
            startsun.setHours(0, 0, 0, 0);
            nextsun.setDate(nextsun.getDate() + 6);
            if (new Date(shift.shiftdate) >= startsun && new Date(shift.shiftdate) <= nextsun) {
                count += countHoursBetweenDates(convertTimeStringToDate(shift.shiftstarttimestring), convertTimeStringToDate(shift.shiftendtimestring));
            }
        })
        return count;
    }
    const isWeekend = (date: dayjs.Dayjs) => {
        const day = date.day();

        return day !== 0;
    };
    const getDateFromDOW = (dow: string) => {
        let dt = new Date(selectedSunday);

        switch (dow.toUpperCase()) {
            case "SUNDAY":
                dt.setDate(dt.getDate());
                break;
            case "MONDAY":
                dt.setDate(dt.getDate() + 1);
                break;
            case "TUESDAY":
                dt.setDate(dt.getDate() + 2);
                break;
            case "WEDNESDAY":
                dt.setDate(dt.getDate() + 3);
                break;
            case "THURSDAY":
                dt.setDate(dt.getDate() + 4);
                break;
            case "FRIDAY":
                dt.setDate(dt.getDate() + 5);
                break;
            case "SATURDAY":
                dt.setDate(dt.getDate() + 6);
                break;
        }
        return dt.toDateString();
    }

    const nextWeekClick = () => {
        let dt = new Date(selectedSunday);

        dt.setDate(dt.getDate() + 7);

        setSelectedSunday(dt);
    }
    const previousWeekClick = () => {
        let dt = new Date(selectedSunday);

        dt.setDate(dt.getDate() - 7);

        setSelectedSunday(dt);
    }

    if (loading) {
        return (
            <>
                <Stack direction={'column'} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 2 }}>
                    <Typography sx={{ fontSize: 20, fontWeight: 'bold' }}>Loading Schedule...</Typography>
                    <Box sx={{ width: '100%', marginTop: 2 }}>
                        <LinearProgress />
                    </Box>
                </Stack>
            </>
        )
    }
    const getDateForDOW = (dow: number) => {
        //sends 0 for sunday, 1 for monday, 2 for tuesday etc..
        let newdate = new Date(selectedSunday);

        return newdate.setDate(selectedSunday.getDate() + dow);
    }
    const getDayDateFromView = (i: number) => {
        let result = new Date(selectedSunday);
        result.setDate(result.getDate() + i);
        return result;
    }
    const getBudgetPPD = () => {
        if (getCompanyRoles == null || getCompanyRoles.length == 0) {
            return 0;
        }
        let result = 0;
        getCompanyRoles.forEach(r => {
            if (r.roleid === selectedRoleIDTab) {
                result = r.ppdbudget;
            }
        })

        return result;
    }
    const getBudgetPPDMin = () => {
        if (getCompanyRoles == null || getCompanyRoles.length == 0) {
            return 0;
        }
        let result = 0;
        getCompanyRoles.forEach(r => {
            if (r.roleid === selectedRoleIDTab) {
                result = r.ppdbudgetmin;
            }
        })

        return result;
    }
    const getBudgetPPDMax = () => {
        if (getCompanyRoles == null || getCompanyRoles.length == 0) {
            return 0;
        }
        let result = 0;
        getCompanyRoles.forEach(r => {
            if (r.roleid === selectedRoleIDTab) {
                result = r.ppdbudgetmax;
            }
        })

        return result;
    }

    const getCalcPPD = (index: number) => {
        return (getScheduledHoursForDay(index) / currentCensus[index]).toFixed(4);
    }

    const getVariance = (index: number) => {
        return (getBudgetPPD() - Number.parseFloat(getCalcPPD(index))).toFixed(4);
    }
    const getCalcDelta = (dow: number) => {
        let result = getScheduledHoursForDay(dow) - (currentCensus[dow] * getBudgetPPD());
        return result.toFixed(2);
    }
    function convertTimeStringToDate(timeString: string): Date {
        const [time, meridiem] = timeString.split(/(?=[ap])/);
        const [hours, minutes] = time.split(':').map(Number);

        const date = new Date();
        date.setHours(0, 0, 0, 0); // Reset to start of day

        let adjustedHours = hours;

        if (meridiem === 'p' && hours !== 12) {
            adjustedHours += 12;
        } else if (meridiem === 'a' && hours === 12) {
            adjustedHours = 0;
        }

        date.setHours(adjustedHours, minutes);

        return date;
    }
    const getUnitTotalHourForDate = (i: number, unitid: number) => {
        let columnDate = new Date(selectedSunday);
        columnDate.setDate(columnDate.getDate() + i);
        let count: number = 0;

        getCurrentSchedule.filter(excludeRoles).forEach(d => {
            d.shifts.forEach(shift => {
                if (compareDatesIgnoringTime(shift.shiftdate, columnDate) && shift.unitid == unitid) {
                    count += countHoursBetweenDates(convertTimeStringToDate(shift.shiftstarttimestring), convertTimeStringToDate(shift.shiftendtimestring));
                }
            })
        })
        return count;
    }

    const getScheduledHoursForDay = (i: number) => {
        let columnDate = new Date(selectedSunday);
        columnDate.setDate(columnDate.getDate() + i);
        let count: number = 0;

        getCurrentSchedule.filter(excludeRoles).forEach(d => {
            d.shifts.forEach(shift => {
                if (compareDatesIgnoringTime(shift.shiftdate, columnDate)) {
                    count += countHoursBetweenDates(convertTimeStringToDate(shift.shiftstarttimestring), convertTimeStringToDate(shift.shiftendtimestring));
                }
            })
        })
        return count;
    }
    const getPPDVarianceColor = (dow: number) => {
        let actual = Number.parseFloat(getCalcPPD(dow));
        console.log('actual ' + actual + ' and ' + getBudgetPPDMin());
        if (actual < getBudgetPPDMin()) {
            return 'red';
        }
        else if (actual > getBudgetPPDMax()) {
            return 'blue';
        }
        else {
            return 'green';
        }
    }
    const clickEditShiftInSchedule = (shiftdate: Date, scheid: number, shiftstart: Date, shiftend: Date, roleid: number, role: string, user: string, name: string, shift: string, unit: string) => {
        setIsEditingShift(true);
        setSelectedShift(shift);
        setSelectedScheduleid(scheid);
        setSelectedUnit(unit);
        setSelectedShiftDate(shiftdate);
        setSelectedStartTime(new Date(shiftstart));
        setSelectedEndTime(new Date(shiftend));
        setSelectedRoleid(roleid);
        setSelectedRole(role);
        setSelectedUser(user);
        setSelectedName(name);
        setOpenAddNewShift(true);
    }
    const clickAddNewShiftToSchedule = (shiftdate: Date, roleid: number, role: string, user: string, name: string) => {
        setIsEditingShift(false);
        setSelectedShiftDate(shiftdate);
        setSelectedUnit('');
        setSelectedShift('');
        setSelectedRoleid(roleid);
        setSelectedRole(role);
        setSelectedUser(user);
        setSelectedName(name);
        setOpenAddNewShift(true);
    }

    const handleCreateShiftAlertClick = (shiftdate: Date, roleid: number) => {
        setCreateShiftAlertSelectedDate(new Date(shiftdate));
        setCreateShiftAlertSelectedRole(roleid);
        setShowCreateShiftAlertDialog(true);
    }

    return (
        <>
            <Stack sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} divider={<Divider orientation="horizontal" flexItem />}>
                <Box sx={{
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%'
                }}>

                    <Stack direction={'row'} sx={{ alignContent: 'center', alignItems: 'center', alignSelf: 'center' }}>
                        {/* <Typography sx={{ fontSize: 20, fontWeight: 'bold' }}>Current Schedule</Typography> */}
                        <Box flexGrow={1} />
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <IconButton
                                size="medium"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={previousWeekClick}
                                color="inherit"
                            >
                                <ArrowBackIcon />
                            </IconButton>
                            <DatePicker value={dayjs(selectedSunday)}
                                sx={{ fontSize: 13, marginTop: 2, marginBottom: 1, alignContent: 'center', alignItems: 'center', alignSelf: 'center' }}
                                label="Week Of (Sunday)"
                                shouldDisableDate={isWeekend}
                                slotProps={{ textField: { size: 'small' } }}
                                onChange={(newValue) => {
                                    if (newValue === null) {
                                        return;
                                    }
                                    setSelectedSunday(newValue.toDate());
                                }} />
                        </LocalizationProvider>
                        <IconButton
                            size="medium"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={nextWeekClick}
                            color="inherit"
                        >
                            <ArrowForwardIcon />
                        </IconButton>

                        <Box flexGrow={1} display="flex" justifyContent="flex-end">
                            {/* <Tooltip title="Filter Results">
                                <IconButton
                                    aria-label="expand row"
                                    size="small">
                                    <FilterListIcon fontSize='large' />
                                </IconButton>
                            </Tooltip> */}
                            <IconButton
                                sx={{ marginRight: 2 }}
                                size="large"
                                color="primary"
                                onClick={handlePrint}>
                                <PrintIcon sx={{ fontSize: 30 }} color="primary" />
                            </IconButton>
                        </Box>
                    </Stack>
                </Box>
                <CssBaseline />
                <Tabs
                    value={selectedRoleIDTab}
                    onChange={handleChange}
                    TabIndicatorProps={{ style: { backgroundColor: tabColorByRole(selectedRoleIDTab), height: 2 } }}
                    // TabIndicatorProps={{ style: { backgroundColor: '#1976D2' } }}
                    centered
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example" >
                    {getCompanyRoles.filter(excludeAdministrationRole).map((role) => //////{getCompanyRoles.filter(t => t.roleid > 0).map((role) =>
                        <Tab label={role.role} value={role.roleid} style={{ color: tabColorByRole(role.roleid), fontWeight: 'bold' }} ></Tab>
                    )}
                </Tabs>

                <TableContainer component={Paper} sx={{ margin: 1, display: 'block' }}>
                    <Table stickyHeader={true} sx={{ minWidth: 300 }} size='small' aria-label="simple table">
                        <TableHead>
                            <StyledTableRow key="100">
                                <StyledTableCell width={getFirstColumnWidth()} align="center">USER</StyledTableCell>
                                <StyledTableCell width={getSecondColunWidth()} align="center">ROLE</StyledTableCell>
                                <StyledTableCell align="center" width="12%">
                                    <Stack>
                                        <Typography fontSize={14}>
                                            SUNDAY
                                        </Typography>
                                        <Typography fontSize={14}>
                                            {format(getDateForDOW(0), 'MM/dd/yy')}
                                        </Typography>
                                    </Stack>
                                </StyledTableCell>
                                <StyledTableCell align="center" width="12%">
                                    <Stack>
                                        <Typography fontSize={14}>
                                            MONDAY
                                        </Typography>
                                        <Typography fontSize={14}>
                                            {format(getDateForDOW(1), 'MM/dd/yy')}
                                        </Typography>
                                    </Stack>
                                </StyledTableCell>
                                <StyledTableCell align="center" width="12%">
                                    <Stack>
                                        <Typography fontSize={14}>
                                            TUESDAY
                                        </Typography>
                                        <Typography fontSize={14}>
                                            {format(getDateForDOW(2), 'MM/dd/yy')}
                                        </Typography>
                                    </Stack>
                                </StyledTableCell>
                                <StyledTableCell align="center" width="12%">
                                    <Stack>
                                        <Typography fontSize={14}>
                                            WEDNESDAY
                                        </Typography>
                                        <Typography fontSize={14}>
                                            {format(getDateForDOW(3), 'MM/dd/yy')}
                                        </Typography>
                                    </Stack>
                                </StyledTableCell>
                                <StyledTableCell align="center" width="12%">
                                    <Stack>
                                        <Typography fontSize={14}>
                                            THURSDAY
                                        </Typography>
                                        <Typography fontSize={14}>
                                            {format(getDateForDOW(4), 'MM/dd/yy')}
                                        </Typography>
                                    </Stack>
                                </StyledTableCell>
                                <StyledTableCell align="center" width="12%">
                                    <Stack>
                                        <Typography fontSize={14}>
                                            FRIDAY
                                        </Typography>
                                        <Typography fontSize={14}>
                                            {format(getDateForDOW(5), 'MM/dd/yy')}
                                        </Typography>
                                    </Stack>
                                </StyledTableCell>
                                <StyledTableCell align="center" width="12%">
                                    <Stack>
                                        <Typography fontSize={14}>
                                            SATURDAY
                                        </Typography>
                                        <Typography fontSize={14}>
                                            {format(getDateForDOW(6), 'MM/dd/yy')}
                                        </Typography>
                                    </Stack>
                                </StyledTableCell>
                                <StyledTableCell width={80} align="center">TOTAL</StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {getCurrentSchedule.sort((a, b) => a['lastname'].localeCompare(b['lastname'])).filter(excludeRoles).filter(excludeUsersThatDontHaveShiftInWeek).map((schedule) => (
                                <StyledTableRow>
                                    <StyledTableCell width={getFirstColumnWidth()} key={-1} align='center' sx={{ fontWeight: 'bold' }}>
                                        <Typography sx={{ fontSize: 11 }}>
                                            {schedule.name}({schedule.username})
                                        </Typography>
                                    </StyledTableCell>
                                    <StyledTableCell width={getSecondColunWidth()} key={-2} align='center' sx={{ fontWeight: 'bold' }}>
                                        <Typography sx={{ fontSize: 11 }}>
                                            {schedule.role}
                                        </Typography>
                                    </StyledTableCell>
                                    {Array.from(daysofweek).map((day, dayindex) => (
                                        getDoesUserHaveShiftThisDate(schedule.shifts, new Date(getDateFromDOW(day)), schedule.username) ?
                                            <StyledTableCell key={dayindex + schedule.username} align='center' width="12%">
                                                <Stack direction='row' sx={{ marginLeft: 2, alignItems: 'center', alignContent: 'center', justifyContent: 'center' }}>
                                                    {getShiftTimesForUserThisDate(schedule, new Date(getDateFromDOW(day)))}
                                                    {/* <IconButton
                                                        aria-label="expand row"
                                                        size="small"
                                                        onClick={() => clickEditShiftInSchedule(new Date(getDateFromDOW(day)), schedule.roleid, schedule.role, schedule.username, schedule.name,'','')}>
                                                        <EditIcon sx={{ fontSize: 12 }} color='warning' />
                                                    </IconButton> */}
                                                </Stack>
                                            </StyledTableCell>
                                            :
                                            <StyledTableCell key={dayindex + schedule.username} align='center' sx={{ fontWeight: 'bold' }}>
                                                <Box sx={{ alignContent: 'center', alignItems: 'center', alignSelf: 'center' }}>
                                                    <IconButton
                                                        aria-label="expand row"
                                                        size="small"
                                                        onClick={() => clickAddNewShiftToSchedule(new Date(getDateFromDOW(day)), schedule.roleid, schedule.role, schedule.username, schedule.name)}>
                                                        <AddCircleIcon sx={{ fontSize: 14 }} color='success' />
                                                    </IconButton></Box>
                                            </StyledTableCell>
                                    ))}
                                    <StyledTableCell width={80} key={-4} align='center' sx={{ fontWeight: 'bold' }}>
                                        <Typography sx={{ fontSize: 11 }}>
                                            {getTotalHoursForUserAndRole(schedule, schedule.roleid)}
                                        </Typography>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Tabs
                        value={selectedTotals}
                        onChange={handleChangeTotals}
                        TabIndicatorProps={{ style: { backgroundColor: '#1976D2', height: 3 } }}
                        centered
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example" >
                        <Tab label='PPD Totals' value={0} style={{ color: '#1976D2', fontWeight: 'bold' }}></Tab>
                        <Tab label='Totals By Unit' value={1} style={{ color: '#1976D2', fontWeight: 'bold' }}></Tab>
                    </Tabs>
                </Box>
                {selectedTotals == 0 ?
                    <TableContainer component={Paper} sx={{ margin: 1 }}>
                        <Table aria-label="spanning table" size='small'>
                            <TableHead>
                                <StyledTableRow key="101">
                                    <StyledTableCell width={getCombinedColumn()} align="center"></StyledTableCell>
                                    <StyledTableCell width={getSecondColunWidth()} align="center"> </StyledTableCell>
                                    <StyledTableCell align="center" width="12%">
                                        <Stack>
                                            <Typography fontSize={14}>
                                                SUNDAY
                                            </Typography>
                                            <Typography fontSize={14}>
                                                {format(getDateForDOW(0), 'MM/dd/yy')}
                                            </Typography>
                                        </Stack>
                                    </StyledTableCell>
                                    <StyledTableCell align="center" width="12%">
                                        <Stack>
                                            <Typography fontSize={14}>
                                                MONDAY
                                            </Typography>
                                            <Typography fontSize={14}>
                                                {format(getDateForDOW(1), 'MM/dd/yy')}
                                            </Typography>
                                        </Stack>
                                    </StyledTableCell>
                                    <StyledTableCell align="center" width="12%">
                                        <Stack>
                                            <Typography fontSize={14}>
                                                TUESDAY
                                            </Typography>
                                            <Typography fontSize={14}>
                                                {format(getDateForDOW(2), 'MM/dd/yy')}
                                            </Typography>
                                        </Stack>
                                    </StyledTableCell>
                                    <StyledTableCell align="center" width="12%">
                                        <Stack>
                                            <Typography fontSize={14}>
                                                WEDNESDAY
                                            </Typography>
                                            <Typography fontSize={14}>
                                                {format(getDateForDOW(3), 'MM/dd/yy')}
                                            </Typography>
                                        </Stack>
                                    </StyledTableCell>
                                    <StyledTableCell align="center" width="12%">
                                        <Stack>
                                            <Typography fontSize={14}>
                                                THURSDAY
                                            </Typography>
                                            <Typography fontSize={14}>
                                                {format(getDateForDOW(4), 'MM/dd/yy')}
                                            </Typography>
                                        </Stack>
                                    </StyledTableCell>
                                    <StyledTableCell align="center" width="12%">
                                        <Stack>
                                            <Typography fontSize={14}>
                                                FRIDAY
                                            </Typography>
                                            <Typography fontSize={14}>
                                                {format(getDateForDOW(5), 'MM/dd/yy')}
                                            </Typography>
                                        </Stack>
                                    </StyledTableCell>
                                    <StyledTableCell align="center" width="12%">
                                        <Stack>
                                            <Typography fontSize={14}>
                                                SATURDAY
                                            </Typography>
                                            <Typography fontSize={14}>
                                                {format(getDateForDOW(6), 'MM/dd/yy')}
                                            </Typography>
                                        </Stack>
                                    </StyledTableCell>
                                    <StyledTableCell width={80} align="center"></StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                <StyledTableRow>
                                    <StyledTableCell align="center" sx={{ fontSize: getFontSize() }}>
                                        <Typography sx={{ fontSize: 11 }}>Actual Hours</Typography>
                                    </StyledTableCell>
                                    <StyledTableCell align="center" sx={{ fontSize: getFontSize() }}> </StyledTableCell>
                                    <StyledTableCell align="center" sx={{ fontSize: getFontSize() }} width="12%">
                                        <Typography sx={{ fontSize: 11 }}>{getScheduledHoursForDay(0)}</Typography>
                                    </StyledTableCell>
                                    <StyledTableCell align="center" sx={{ fontSize: getFontSize() }} width="12%">
                                        <Typography sx={{ fontSize: 11 }}>{getScheduledHoursForDay(1)}</Typography>
                                    </StyledTableCell>
                                    <StyledTableCell align="center" sx={{ fontSize: getFontSize() }} width="12%">
                                        <Typography sx={{ fontSize: 11 }}>{getScheduledHoursForDay(2)}</Typography>
                                    </StyledTableCell>
                                    <StyledTableCell align="center" sx={{ fontSize: getFontSize() }} width="12%">
                                        <Typography sx={{ fontSize: 11 }}>{getScheduledHoursForDay(3)}</Typography>
                                    </StyledTableCell>
                                    <StyledTableCell align="center" sx={{ fontSize: getFontSize() }} width="12%">
                                        <Typography sx={{ fontSize: 11 }}>{getScheduledHoursForDay(4)}</Typography>
                                    </StyledTableCell>
                                    <StyledTableCell align="center" sx={{ fontSize: getFontSize() }} width="12%">
                                        <Typography sx={{ fontSize: 11 }}>{getScheduledHoursForDay(5)}</Typography>
                                    </StyledTableCell>
                                    <StyledTableCell align="center" sx={{ fontSize: getFontSize() }} width="12%">
                                        <Typography sx={{ fontSize: 11 }}>{getScheduledHoursForDay(6)}</Typography>
                                    </StyledTableCell>
                                    <StyledTableCell width={80} align="center"></StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow>
                                    <StyledTableCell align="center" sx={{ fontSize: getFontSize() }} width={getFirstColumnWidth()}>
                                        <Typography sx={{ fontSize: 11 }}>Actual PPD</Typography>
                                    </StyledTableCell>
                                    <StyledTableCell align="center" sx={{ fontSize: getFontSize() }} width={getSecondColunWidth()}></StyledTableCell>
                                    <StyledTableCell align="center" sx={{ fontSize: getFontSize() }} width="12%">
                                        <Typography sx={{ fontSize: 11 }}>{getCalcPPD(0)}</Typography>
                                    </StyledTableCell>
                                    <StyledTableCell align="center" sx={{ fontSize: getFontSize() }} width="12%">
                                        <Typography sx={{ fontSize: 11 }}>{getCalcPPD(1)}</Typography>
                                    </StyledTableCell>
                                    <StyledTableCell align="center" sx={{ fontSize: getFontSize() }} width="12%">
                                        <Typography sx={{ fontSize: 11 }}>{getCalcPPD(2)}</Typography>
                                    </StyledTableCell>
                                    <StyledTableCell align="center" sx={{ fontSize: getFontSize() }} width="12%">
                                        <Typography sx={{ fontSize: 11 }}>{getCalcPPD(3)}</Typography>
                                    </StyledTableCell>
                                    <StyledTableCell align="center" sx={{ fontSize: getFontSize() }} width="12%">
                                        <Typography sx={{ fontSize: 11 }}>{getCalcPPD(4)}</Typography>
                                    </StyledTableCell>
                                    <StyledTableCell align="center" sx={{ fontSize: getFontSize() }} width="12%">
                                        <Typography sx={{ fontSize: 11 }}>{getCalcPPD(5)}</Typography>
                                    </StyledTableCell>
                                    <StyledTableCell align="center" sx={{ fontSize: getFontSize() }} width="12%">
                                        <Typography sx={{ fontSize: 11 }}>{getCalcPPD(6)}</Typography>
                                    </StyledTableCell>
                                    <StyledTableCell width={80} align="center"></StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow>
                                    <StyledTableCell align="center" sx={{ fontSize: getFontSize() }} width={getFirstColumnWidth()}>
                                        <Typography sx={{ fontSize: 11 }}>Budget PPD</Typography>
                                    </StyledTableCell>
                                    <StyledTableCell align="center" sx={{ fontSize: getFontSize() }} width={getSecondColunWidth()}></StyledTableCell>
                                    <StyledTableCell align="center" sx={{ fontSize: getFontSize() }} width="12%">
                                        <Typography sx={{ fontSize: 11 }}>{getBudgetPPD().toString()}</Typography>
                                    </StyledTableCell>
                                    <StyledTableCell align="center" sx={{ fontSize: getFontSize() }} width="12%">
                                        <Typography sx={{ fontSize: 11 }}>{getBudgetPPD()}</Typography>
                                    </StyledTableCell>
                                    <StyledTableCell align="center" sx={{ fontSize: getFontSize() }} width="12%">
                                        <Typography sx={{ fontSize: 11 }}>{getBudgetPPD()}</Typography>
                                    </StyledTableCell>
                                    <StyledTableCell align="center" sx={{ fontSize: getFontSize() }} width="12%">
                                        <Typography sx={{ fontSize: 11 }}>{getBudgetPPD()}</Typography>
                                    </StyledTableCell>
                                    <StyledTableCell align="center" sx={{ fontSize: getFontSize() }} width="12%">
                                        <Typography sx={{ fontSize: 11 }}>{getBudgetPPD()}</Typography>
                                    </StyledTableCell>
                                    <StyledTableCell align="center" sx={{ fontSize: getFontSize() }} width="12%">
                                        <Typography sx={{ fontSize: 11 }}>{getBudgetPPD()}</Typography>
                                    </StyledTableCell>
                                    <StyledTableCell align="center" sx={{ fontSize: getFontSize() }} width="12%">
                                        <Typography sx={{ fontSize: 11 }}>{getBudgetPPD()}</Typography>
                                    </StyledTableCell>
                                    <StyledTableCell width={80} align="center"></StyledTableCell>
                                </StyledTableRow>

                                <StyledTableRow>
                                    <StyledTableCell align="center" sx={{ fontSize: getFontSize() }} width={getFirstColumnWidth()}>
                                        <Typography sx={{ fontSize: 11 }}>Delta(hours)</Typography>
                                    </StyledTableCell>
                                    <StyledTableCell align="center" sx={{ fontSize: getFontSize() }} width={getSecondColunWidth()}></StyledTableCell>
                                    <StyledTableCell align="center" width="12%" sx={{ fontSize: getFontSize(), color: getPPDVarianceColor(0) }}>
                                        <Stack direction={'row'} alignSelf={'center'} sx={{ marginLeft: 3, justifyContent: 'center', alignContent: 'center', alignItems: 'center', alignSelf: 'center' }}>
                                            <Typography sx={{ fontSize: 11, fontWeight:'bold' }}>{getCalcDelta(0)}</Typography>
                                            <Tooltip title='Create Shift Alert In Fill Open Shifts'>
                                                <IconButton
                                                    aria-label="expand row"
                                                    size="small"
                                                    onClick={() => handleCreateShiftAlertClick(new Date(getDateForDOW(0)), selectedRoleIDTab)}>
                                                    <AddAlertIcon sx={{ fontSize: 15 }} color='primary' />
                                                </IconButton>
                                            </Tooltip>
                                        </Stack>
                                    </StyledTableCell>
                                    <StyledTableCell align="center" width="12%" sx={{ fontSize: getFontSize(), color: getPPDVarianceColor(1) }}>
                                        <Stack direction={'row'} alignSelf={'center'} sx={{ marginLeft: 3, justifyContent: 'center', alignContent: 'center', alignItems: 'center', alignSelf: 'center' }}>
                                            <Typography sx={{ fontSize: 11, fontWeight:'bold' }}>{getCalcDelta(1)}</Typography>
                                            <Tooltip title='Create Shift Alert In Fill Open Shifts'>
                                                <IconButton
                                                    aria-label="expand row"
                                                    size="small"
                                                    onClick={() => handleCreateShiftAlertClick(new Date(getDateForDOW(1)), selectedRoleIDTab)}>
                                                    <AddAlertIcon sx={{ fontSize: 15 }} color='primary' />
                                                </IconButton>
                                            </Tooltip>
                                        </Stack>
                                    </StyledTableCell>
                                    <StyledTableCell align="center" width="12%" sx={{ fontSize: getFontSize(), color: getPPDVarianceColor(2) }}>
                                        <Stack direction={'row'} alignSelf={'center'} sx={{ marginLeft: 3, justifyContent: 'center', alignContent: 'center', alignItems: 'center', alignSelf: 'center' }}>
                                            <Typography sx={{ fontSize: 11, fontWeight:'bold' }}>{getCalcDelta(2)}</Typography>
                                            <Tooltip title='Create Shift Alert In Fill Open Shifts'>
                                                <IconButton
                                                    aria-label="expand row"
                                                    size="small"
                                                    onClick={() => handleCreateShiftAlertClick(new Date(getDateForDOW(2)), selectedRoleIDTab)}>
                                                    <AddAlertIcon sx={{ fontSize: 15 }} color='primary' />
                                                </IconButton>
                                            </Tooltip>
                                        </Stack>
                                    </StyledTableCell>
                                    <StyledTableCell align="center" width="12%" sx={{ fontSize: getFontSize(), color: getPPDVarianceColor(3) }}>
                                        <Stack direction={'row'} alignSelf={'center'} sx={{ marginLeft: 3, justifyContent: 'center', alignContent: 'center', alignItems: 'center', alignSelf: 'center' }}>
                                            <Typography sx={{ fontSize: 11, fontWeight:'bold' }}>{getCalcDelta(3)}</Typography>
                                            <Tooltip title='Create Shift Alert In Fill Open Shifts'>
                                                <IconButton
                                                    aria-label="expand row"
                                                    size="small"
                                                    onClick={() => handleCreateShiftAlertClick(new Date(getDateForDOW(3)), selectedRoleIDTab)}>
                                                    <AddAlertIcon sx={{ fontSize: 15 }} color='primary' />
                                                </IconButton>
                                            </Tooltip>
                                        </Stack>
                                    </StyledTableCell>
                                    <StyledTableCell align="center" width="12%" sx={{ fontSize: getFontSize(), color: getPPDVarianceColor(4) }}>
                                        <Stack direction={'row'} alignSelf={'center'} sx={{ marginLeft: 3, justifyContent: 'center', alignContent: 'center', alignItems: 'center', alignSelf: 'center' }}>
                                            <Typography sx={{ fontSize: 11, fontWeight:'bold' }}>{getCalcDelta(4)}</Typography>
                                            <Tooltip title='Create Shift Alert In Fill Open Shifts'>
                                                <IconButton
                                                    aria-label="expand row"
                                                    size="small"
                                                    onClick={() => handleCreateShiftAlertClick(new Date(getDateForDOW(4)), selectedRoleIDTab)}>
                                                    <AddAlertIcon sx={{ fontSize: 15 }} color='primary' />
                                                </IconButton>
                                            </Tooltip>
                                        </Stack>
                                    </StyledTableCell>
                                    <StyledTableCell align="center" width="12%" sx={{ fontSize: getFontSize(), color: getPPDVarianceColor(5) }}>
                                        <Stack direction={'row'} alignSelf={'center'} sx={{ marginLeft: 3, justifyContent: 'center', alignContent: 'center', alignItems: 'center', alignSelf: 'center' }}>
                                            <Typography sx={{ fontSize: 11, fontWeight:'bold' }}>{getCalcDelta(5)}</Typography>
                                            <Tooltip title='Create Shift Alert In Fill Open Shifts'>
                                                <IconButton
                                                    aria-label="expand row"
                                                    size="small"
                                                    onClick={() => handleCreateShiftAlertClick(new Date(getDateForDOW(5)), selectedRoleIDTab)}>
                                                    <AddAlertIcon sx={{ fontSize: 15 }} color='primary' />
                                                </IconButton>
                                            </Tooltip>
                                        </Stack>
                                    </StyledTableCell>
                                    <StyledTableCell align="center" width="12%" sx={{ fontSize: getFontSize(), color: getPPDVarianceColor(6) }}>
                                        <Stack direction={'row'} alignSelf={'center'} sx={{ marginLeft: 3, justifyContent: 'center', alignContent: 'center', alignItems: 'center', alignSelf: 'center' }}>
                                            <Typography sx={{ fontSize: 11, fontWeight:'bold' }}>{getCalcDelta(6)}</Typography>
                                            <Tooltip title='Create Shift Alert In Fill Open Shifts'>
                                                <IconButton
                                                    aria-label="expand row"
                                                    size="small"
                                                    onClick={() => handleCreateShiftAlertClick(new Date(getDateForDOW(6)), selectedRoleIDTab)}>
                                                    <AddAlertIcon sx={{ fontSize: 15 }} color='primary' />
                                                </IconButton>
                                            </Tooltip>
                                        </Stack>
                                    </StyledTableCell>
                                    <StyledTableCell width={80} align="center"></StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow key="200">
                                    <StyledTableCell align="center" width={getFirstColumnWidth()}></StyledTableCell>
                                    <StyledTableCell align="center" width={getSecondColunWidth()}></StyledTableCell>
                                    <StyledTableCell align="center" width="12%">
                                        <TextField value={currentCensus[0]} size='small'
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                handleUpdateCensus(0, Number(event.target.value));
                                            }}
                                            sx={{ minWidth: 60, alignSelf: 'center', alignContent: 'center', alignItems: 'center', marginLeft: 3, marginRight: 3 }} id="outlined-basic" label="Census" variant="outlined">

                                        </TextField>
                                    </StyledTableCell>
                                    <StyledTableCell align="center" width="12%">
                                        <TextField value={currentCensus[1]} size='small'
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                handleUpdateCensus(1, Number(event.target.value));
                                            }}
                                            sx={{ minWidth: 60, alignSelf: 'center', alignContent: 'center', alignItems: 'center', marginLeft: 3, marginRight: 3 }} id="outlined-basic" label="Census" variant="outlined">

                                        </TextField>
                                    </StyledTableCell>
                                    <StyledTableCell align="center" width="12%">
                                        <TextField value={currentCensus[2]} size='small'
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                handleUpdateCensus(2, Number(event.target.value));
                                            }}
                                            sx={{ minWidth: 60, alignSelf: 'center', alignContent: 'center', alignItems: 'center', marginLeft: 3, marginRight: 3 }} id="outlined-basic" label="Census" variant="outlined">

                                        </TextField>
                                    </StyledTableCell>
                                    <StyledTableCell align="center" width="12%">
                                        <TextField value={currentCensus[3]} size='small'
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                handleUpdateCensus(3, Number(event.target.value));
                                            }}
                                            sx={{ minWidth: 60, alignSelf: 'center', alignContent: 'center', alignItems: 'center', marginLeft: 3, marginRight: 3 }} id="outlined-basic" label="Census" variant="outlined">

                                        </TextField>
                                    </StyledTableCell>
                                    <StyledTableCell align="center" width="12%">
                                        <TextField value={currentCensus[4]} size='small'
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                handleUpdateCensus(4, Number(event.target.value));
                                            }}
                                            sx={{ minWidth: 60, alignSelf: 'center', alignContent: 'center', alignItems: 'center', marginLeft: 3, marginRight: 3 }} id="outlined-basic" label="Census" variant="outlined">

                                        </TextField>
                                    </StyledTableCell>
                                    <StyledTableCell align="center" width="12%">
                                        <TextField value={currentCensus[5]} size='small'
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                handleUpdateCensus(5, Number(event.target.value));
                                            }}
                                            sx={{ minWidth: 60, alignSelf: 'center', alignContent: 'center', alignItems: 'center', marginLeft: 3, marginRight: 3 }} id="outlined-basic" label="Census" variant="outlined">

                                        </TextField>
                                    </StyledTableCell>
                                    <StyledTableCell align="center" width="12%">
                                        <TextField value={currentCensus[6]} size='small'
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                handleUpdateCensus(6, Number(event.target.value));
                                            }}
                                            sx={{ minWidth: 60, alignSelf: 'center', alignContent: 'center', alignItems: 'center', marginLeft: 3, marginRight: 3 }} id="outlined-basic" label="Census" variant="outlined">

                                        </TextField>
                                    </StyledTableCell>
                                    <StyledTableCell width={80} align="center"></StyledTableCell>
                                </StyledTableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    :
                    <TableContainer component={Paper} sx={{ margin: 1, display: 'block' }}>
                        <Table sx={{ minWidth: 300 }} size='small' aria-label="simple table">
                            <TableHead>
                                {/* <StyledTableRow key="101"> */}
                                <StyledTableCell width={getCombinedColumn()} align="center">UNIT</StyledTableCell>
                                <StyledTableCell align="center"></StyledTableCell>
                                <StyledTableCell align="center" width="11%">
                                    <Stack>
                                        <Typography fontSize={14}>
                                            SUNDAY
                                        </Typography>
                                        <Typography fontSize={14}>
                                            ({format(getDateForDOW(0), 'MM/dd/yy')})
                                        </Typography>
                                    </Stack>
                                </StyledTableCell>
                                <StyledTableCell align="center" width="11%">
                                    <Stack>
                                        <Typography fontSize={14}>
                                            MONDAY
                                        </Typography>
                                        <Typography fontSize={14}>
                                            ({format(getDateForDOW(1), 'MM/dd/yy')})
                                        </Typography>
                                    </Stack>
                                </StyledTableCell>
                                <StyledTableCell align="center" width="11%">
                                    <Stack>
                                        <Typography fontSize={14}>
                                            TUESDAY
                                        </Typography>
                                        <Typography fontSize={14}>
                                            ({format(getDateForDOW(2), 'MM/dd/yy')})
                                        </Typography>
                                    </Stack>
                                </StyledTableCell>
                                <StyledTableCell align="center" width="11%">
                                    <Stack>
                                        <Typography fontSize={14}>
                                            WEDNESDAY
                                        </Typography>
                                        <Typography fontSize={14}>
                                            ({format(getDateForDOW(3), 'MM/dd/yy')})
                                        </Typography>
                                    </Stack>
                                </StyledTableCell>
                                <StyledTableCell align="center" width="11%">
                                    <Stack>
                                        <Typography fontSize={14}>
                                            THURSDAY
                                        </Typography>
                                        <Typography fontSize={14}>
                                            ({format(getDateForDOW(4), 'MM/dd/yy')})
                                        </Typography>
                                    </Stack>
                                </StyledTableCell>
                                <StyledTableCell align="center" width="11%">
                                    <Stack>
                                        <Typography fontSize={14}>
                                            FRIDAY
                                        </Typography>
                                        <Typography fontSize={14}>
                                            ({format(getDateForDOW(5), 'MM/dd/yy')})
                                        </Typography>
                                    </Stack>
                                </StyledTableCell>
                                <StyledTableCell align="center" width="11%">
                                    <Stack>
                                        <Typography fontSize={14}>
                                            SATURDAY
                                        </Typography>
                                        <Typography fontSize={14}>
                                            ({format(getDateForDOW(6), 'MM/dd/yy')})
                                        </Typography>
                                    </Stack>
                                </StyledTableCell>
                                <StyledTableCell width={80} align="center"></StyledTableCell>
                                {/* </StyledTableRow> */}
                            </TableHead>
                            <TableBody>
                                {getUnitSetups.map((unit) => (
                                    <StyledTableRow>
                                        <StyledTableCell align="center" sx={{ fontSize: getFontSize() }} width={getFirstColumnWidth()}>
                                            <Typography sx={{ fontSize: 11 }}>{unit.unitname}</Typography>
                                        </StyledTableCell>
                                        <StyledTableCell align="center" sx={{ fontSize: getFontSize() }} width={getSecondColunWidth()}></StyledTableCell>
                                        <StyledTableCell align="center" sx={{ fontSize: getFontSize() }} width="12%">
                                            <Typography sx={{ fontSize: 11 }}>{getUnitTotalHourForDate(0, unit.unitid)}</Typography>
                                        </StyledTableCell>
                                        <StyledTableCell align="center" sx={{ fontSize: getFontSize() }} width="12%">
                                            <Typography sx={{ fontSize: 11 }}>{getUnitTotalHourForDate(1, unit.unitid)}</Typography>
                                        </StyledTableCell>
                                        <StyledTableCell align="center" sx={{ fontSize: getFontSize() }} width="12%">
                                            <Typography sx={{ fontSize: 11 }}>{getUnitTotalHourForDate(2, unit.unitid)}</Typography>
                                        </StyledTableCell>
                                        <StyledTableCell align="center" sx={{ fontSize: getFontSize() }} width="12%">
                                            <Typography sx={{ fontSize: 11 }}>{getUnitTotalHourForDate(3, unit.unitid)}</Typography>
                                        </StyledTableCell>
                                        <StyledTableCell align="center" sx={{ fontSize: getFontSize() }} width="12%">
                                            <Typography sx={{ fontSize: 11 }}>{getUnitTotalHourForDate(4, unit.unitid)}</Typography>
                                        </StyledTableCell>
                                        <StyledTableCell align="center" sx={{ fontSize: getFontSize() }} width="12%">
                                            <Typography sx={{ fontSize: 11 }}>{getUnitTotalHourForDate(5, unit.unitid)}</Typography>
                                        </StyledTableCell>
                                        <StyledTableCell align="center" sx={{ fontSize: getFontSize() }} width="12%">
                                            <Typography sx={{ fontSize: 11 }}>{getUnitTotalHourForDate(6, unit.unitid)}</Typography>
                                        </StyledTableCell>
                                        <StyledTableCell width={80} align="center"></StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                }
            </Stack>
            <Fab color="primary" aria-label="add" sx={style} onClick={() => { setOpenAddUserToSchedule(true) }}>
                <AddIcon />
            </Fab>
            {/* <ComponentToPrint ref={componentRef} /> */}
            {/* <div style={{ display: "none" }} >
                <NewScheduleMainPrintable getCurrentScheduleRef={getCurrentSchedule} ref={componentRef} />
            </div> */}
            <AddNewShiftToScheduleDialog
                open={openAddNewShift}
                name={selectedName}
                shiftdate={selectedShiftDate}
                scheduleid={selectedScheduleid}
                username={selectedUser}
                roleid={selectedRoleid}
                role={selectedRole}
                isEditing={isEditingShift}
                unitobject={selectedUnit}
                shiftobject={selectedShift}
                shiftstarttime={selectedStartTime}
                shiftendtime={selectedEndTime}
                onClose={handleOnCloseNewShiftDialog}></AddNewShiftToScheduleDialog>
            <AddUserToSchedule
                open={openAddUserToSchedule}
                sunday={selectedSunday}
                onClose={handleOnCloseAddUserDialog}></AddUserToSchedule>
            <CreateShiftAlertDialog
                open={showCreateShiftAlertDialog}
                selecteddate={createShiftAlertSelectedDate}
                roleid={createShiftAlertSelectedRole}
                onClose={onCloseCreateShiftAlertDialog}></CreateShiftAlertDialog>

        </>
    )
})