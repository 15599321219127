import { observer } from "mobx-react-lite";
import React, { useState, useRef, useEffect } from 'react';
import { Box, Grid, Paper, Stack, ToggleButton, FormControlLabel, Typography, styled, Button, tableCellClasses, Table, TableRow, TableCell, TableContainer, TableHead, TableBody, ToggleButtonGroup, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import { useStore } from "../../stores/store";
import { blue } from "@mui/material/colors";
import { User } from "../../models/User";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const StyledTableCell2 = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        fontSize: 10,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
export default observer(function AvailabilityMain() {
    const { userStore } = useStore();
    const { loadUsers, getAllUsers } = userStore;
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const timeOfDay = ['12a', '1a', '2a', '3a', '4a', '5a', '6a',
        '7a', '8a', '9a', '10a', '11a', '12p', '1p',
        '2p', '3p', '4p', '5p', '6 p', '7p', '8p',
        '9p', '10p', '11p'
    ];
    const [selectedIndex, setSelectedIndex] = React.useState(-1);
    const [selectedName, setSelectedName] = React.useState('');
    const [alignment, setAlignment] = React.useState('left');
    useEffect(() => {
        document.title = 'Availability';
        loadUsers();
    }, []);

    const handleChange = (
        event: React.MouseEvent<HTMLElement>,
        newAlignment: string,
    ) => {
        setAlignment(newAlignment);
    };

    const handleListItemClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        index: number,
    ) => {
        setSelectedIndex(index);
        setSelectedName(getAllUsers.at(index)?.firstname + ' ' + getAllUsers.at(index)?.lastname);
    };

    const getDisplayName = (u: User) => {
        return u.lastname + ', ' + u.firstname + ' (' + u.Roles + ')';
    }

    return (
        <Box margin={2} display={'flex'}>
            {/* <Grid margin={1} container spacing={1} columns={7} width={440} alignContent={'center'} alignItems={'center'} alignSelf={'center'}>
                {daysOfWeek.map((day, rowIndex) => (
                    <Grid item xs={1} key={rowIndex}>
                        <StyledPaper elevation={1} style={{ backgroundColor: 'inherit' }}>
                            <Typography variant="body1">{day}</Typography>
                        </StyledPaper>
                    </Grid>
                ))}
                {daysOfWeek.map((day, rowIndex) => (
                    <Grid item xs={1} alignContent={'center'}>
                        {timeOfDay.map((time, timeIndex) => 
                        <Button>{time}</Button>
                        )}
                    </Grid>
                ))}
            </Grid> */}

            {/* <Grid margin={1} container spacing={1} alignContent={'center'} alignItems={'center'} alignSelf={'center'}>
                {daysOfWeek.map((day, rowIndex) => (
                    <Grid item key={rowIndex}>
                        <StyledPaper elevation={1} style={{ backgroundColor: 'inherit' }}>
                            <Typography fontWeight={'bold'} variant="body1" fontSize={16}>{day}</Typography>
                            <Grid item>
                                <Stack direction={'row'}>
                                    {timeOfDay.map((time, timeIndex) =>
                                        <Button>{time}</Button>
                                    )}
                                </Stack>
                            </Grid>
                        </StyledPaper>
                    </Grid>
                ))}
            </Grid> */}
            <Grid container>
                <Grid item xs={2}>
                    <List dense={true} sx={{marginTop:5, position: 'relative',width: '100%',
        overflow: 'auto',
        maxHeight: 600,                       
                        // selected and (selected + hover) states
                        '&& .Mui-selected, && .Mui-selected:hover': {
                            bgcolor: blue[300]
                        }
                    }}>
                        {getAllUsers.map((user, index) => (
                            <ListItemButton selected={selectedIndex === index}
                                onClick={(event) => handleListItemClick(event, index)}>
                                <ListItemText
                                    primary={getDisplayName(user)} secondary={user.username}></ListItemText>
                            </ListItemButton>
                        ))}
                    </List>
                </Grid>
                <Grid item xs={10}>
                    {selectedName != '' ?
                        <>
                            <Typography sx={{ width: 800, textAlign: 'center', fontWeight: 'bold', fontSize: 20 }}>{selectedName} Availability</Typography>
                            <TableContainer component={Paper} sx={{ margin: 2, width: 720 }}>
                                <Table sx={{ minWidth: 400 }} size='small' aria-label="simple table">
                                    <TableHead>
                                        <StyledTableRow key="100">
                                            <StyledTableCell align="center"></StyledTableCell>
                                            <StyledTableCell align="center">SUN</StyledTableCell>
                                            <StyledTableCell align="center">MON</StyledTableCell>
                                            <StyledTableCell align="center">TUE</StyledTableCell>
                                            <StyledTableCell align="center">WED</StyledTableCell>
                                            <StyledTableCell align="center">THU</StyledTableCell>
                                            <StyledTableCell align="center">FRI</StyledTableCell>
                                            <StyledTableCell align="center">SAT</StyledTableCell>
                                        </StyledTableRow>
                                    </TableHead>
                                    <TableBody>
                                        {timeOfDay.map((time, timeIndex) =>
                                            <TableRow>
                                                <TableCell padding="none" align="center">{time}</TableCell>
                                                {daysOfWeek.map((item, index) => (
                                                    <TableCell padding="none" align="center">
                                                        <ToggleButtonGroup
                                                            value={alignment}
                                                            exclusive
                                                            onChange={handleChange}
                                                            aria-label="text alignment">
                                                            <ToggleButton sx={{ margin: 0, padding: .5 }} value="left" size="small" aria-label="left aligned">
                                                                <DoDisturbIcon sx={{ fontSize: 17 }} color="error" />
                                                            </ToggleButton>
                                                            <ToggleButton sx={{ margin: 0, padding: .5 }} value="center" size="small" aria-label="centered">
                                                                <HorizontalRuleIcon sx={{ fontSize: 10 }} color="primary" />
                                                            </ToggleButton>
                                                            <ToggleButton sx={{ margin: 0, padding: .5 }} value="right" size="small" aria-label="right aligned">
                                                                <CheckCircleOutlineIcon sx={{ fontSize: 17 }} />
                                                            </ToggleButton>
                                                        </ToggleButtonGroup>
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer></>
                        : <>
                            <Typography sx={{ width: 800, textAlign: 'center', fontWeight: 'bold', fontSize: 20 }}>Select a User</Typography></>}
                </Grid>
            </Grid>
        </Box>
    );
})