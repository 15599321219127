import { Navigate } from 'react-router-dom'
import { useStore } from "../../stores/store";
import React from "react";

interface Props {
  component: React.ComponentType
  path?: string
}

export const PrivateRoute: React.FC<Props> = ({ component: RouteComponent }) => {
  const { userStore } = useStore();

  if (userStore.isLoggedIn) {
    return <RouteComponent />
  }
  console.log('not logged from private');
  return <Navigate to="/" />
}