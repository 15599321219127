import React, { useEffect } from 'react';
import {
    Box, Grid, Paper, Typography, Button, InputLabel, MenuItem, Select, Stack, Table, TableCell, TableContainer, TableHead, TableRow, tableCellClasses, TableBody, Dialog, SelectChangeEvent, Tooltip, IconButton, TextField, Fab,
    ThemeProvider, DialogActions, DialogContent, DialogContentText, DialogTitle, FormGroup, FormControlLabel, Checkbox,
    styled,
    FormControl,
    Alert,
    Snackbar
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import CreateNewRotation from './CreateNewRotation';
import { useStore } from '../../stores/store';
import RotationFilterDialog from './RotationFilterDialog';
import AddIcon from '@mui/icons-material/Add';
import AddNewRotationShift from './AddNewRotationShift';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddUserToRotation from './AddUserToRotation';
import EditIcon from '@mui/icons-material/Edit';
import { LoadingButton } from '@mui/lab';
import ChangeUserInRotationDialog from './ChangeUserInRotationDialog';
import { RotationDetailNew } from '../../models/Schedule/RotationDetailNew';
import ColoredTypography from '../../components/ColoredTypography';
import { format } from 'date-fns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import AddOrEditRotationShiftInMemory from './AddOrEditRotationShiftInMemory';
import InfoIcon from '@mui/icons-material/Info';
const style = (theme: any) => ({
    margin: 0,
    top: 'auto',
    left: 'auto',
    bottom: 20,
    right: 20,
    position: 'fixed',

});
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
<ThemeProvider
    theme={{
        palette: {
            primary: {
                main: '#007FFF',
                dark: '#0066CC',
            },
        },
    }}
></ThemeProvider>

const defaultValues = {
    cono: '',
    roleid: -1,
    role: '',
    username: '',
    name: '',
    rotationid: -1,
    shifts: {
        shiftid: -1,
        dow: '',
        shiftstarttimestring: '',
        shiftendtimestring: '',
        shiftendsnextday: false,
        partialshift: false,
        descr: '',
        unitid: -1,
        unitname: '',
        unitshortname: ''
    }
};

function getNextSunday() {
    console.log('get next sunday');
    let dt = new Date();
    switch (dt.getDay()) {
        case 0:
            break;
        case 1:
            dt.setDate(dt.getDate() + 6);
            break;
        case 2:
            dt.setDate(dt.getDate() + 5);
            break;
        case 3:
            dt.setDate(dt.getDate() + 4);
            break;
        case 4:
            dt.setDate(dt.getDate() + 3);
            break;
        case 5:
            dt.setDate(dt.getDate() + 2);
            break;
        case 6:
            dt.setDate(dt.getDate() + 1);
            break;
    }
    return dt;
}
function formatTime(date: Date): string {
    const hours = new Date(date).getHours();
    const minutes = new Date(date).getMinutes();
    const ampm = hours >= 12 ? 'p' : 'a';

    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    return `${formattedHours}:${formattedMinutes}${ampm}`;
}

export default observer(function ApplyRotation() {
    const { scheduleStore, userStore } = useStore();
    const { getCompanyRoles } = userStore;
    const { loadRotationSetups, getRotationSetups, loadRotationDetails,
        getRotationDetails, getRotationRoles, removeShiftFromRotation,
        loading, getUnitSetups, putRotationShiftsInSchedule, loadScheduleWeeklyTotals, getScheduleWeeklyTotals } = scheduleStore;
    const [selectedRotation, setSelectedRotation] = React.useState(-1);
    const [showFilterDialog, setShowFilterDialog] = React.useState(false);
    const [currentCensus, setCurrentCensus] = React.useState([85, 85, 85, 85, 85, 85, 85]);
    const daysofweek: string[] = [
        "SUNDAY",
        "MONDAY",
        "TUESDAY",
        "WEDNESDAY",
        "THURSDAY",
        "FRIDAY",
        "SATURDAY"
    ];
    const [openAddNewRotationSetup, setOpenAddNewRotationSetup] = React.useState(false);
    const [openAreYouSureSaveShiftsToSchedule, setOpenAreYouSureSaveShiftsToSchedule] = React.useState(false);
    const [showAddNewShiftToRotation, setAddNewShiftToRotation] = React.useState(false);
    const [showAddNewUserToRotation, setShowAddNewUserToRotation] = React.useState(false);

    const [areYouSureDeleteShiftOpen, setAreYouSureDeleteShiftOpen] = React.useState(false);
    const [selectedUser, setSelectedUser] = React.useState('');
    const [selectedName, setSelectedName] = React.useState('');
    const [selectedDOW, setSelectedDOW] = React.useState('');
    const [selectedRoleid, setSelectedRoleid] = React.useState(-1);
    const [selectedRole, setSelectedRole] = React.useState('');
    const [selectedUnit, setSelectedUnit] = React.useState('');
    const [selectedShift, setSelectedShift] = React.useState('');
    const [selectedStartTime, setSelectedStartTime] = React.useState(new Date());
    const [selectedEndTime, setSelectedEndTime] = React.useState(new Date());
    const [compactMode, setCompactMode] = React.useState(true);
    const [selectedUserShiftInRotation, setSelectedUserShiftInRotation] = React.useState(defaultValues);
    const [showChangeUserInRotation, setShowChangeUserInRotation] = React.useState(false);
    const [isEditingRotation, setIsEditingRotation] = React.useState(false);
    const [isAddingNewShiftInRotation, setIsAddingNewShiftInRotation] = React.useState(false);
    const [nextSunday, setNextSunday] = React.useState(getNextSunday());
    const [openSuccess, setOpenSuccess] = React.useState(false);
    const [openWeeklyTotalDialog, setOpenWeeklyTotalDialog] = React.useState(false);
    const handleCloseSnack = () => {
        setOpenSuccess(false);
    };

    const isWeekend = (date: dayjs.Dayjs) => {
        const day = date.day();

        return day !== 0;
    };
    const closeWeeklyTotal = () =>{
        setOpenWeeklyTotalDialog(false);
    }
    const openWeeklyTotal = () =>{
        setOpenWeeklyTotalDialog(true);
    }
    // const getweeklytotals = () =>{
    //     return(
    //         <>
    //         {getScheduleWeeklyTotals.map((m) => {
    //             m.role + ' - ' + m.count;
    //         })}
    //         </>
    //     )
    // }
    const getBudgetPPD = (role: string) => {
        if (getCompanyRoles == null || getCompanyRoles.length == 0) {
            return 0;
        }
        let result = 0;
        getCompanyRoles.forEach(r => {
            if (r.role === role) {
                result = (r.ppdbudget);// * currentCensus[dow]);
            }
        })

        return result;
    }
    const getBudgetPPDMin = (role: string) => {
        if (getCompanyRoles == null || getCompanyRoles.length == 0) {
            return 0;
        }
        let result = 0;
        getCompanyRoles.forEach(r => {
            if (r.role === role) {
                result = (r.ppdbudgetmin);
            }
        })

        return result;
    }
    const getBudgetPPDMax = (role: string) => {
        if (getCompanyRoles == null || getCompanyRoles.length == 0) {
            return 0;
        }
        let result = 0;
        getCompanyRoles.forEach(r => {
            if (r.role === role) {
                result = (r.ppdbudgetmax);
            }
        })

        return result;
    }
    const getBudgetPPDToolTip = (roleid: string) => {
        if (getCompanyRoles == null || getCompanyRoles.length == 0) {
            return 0;
        }
        let resultMin = 0;
        let resultMax = 0;
        getCompanyRoles.forEach(r => {
            if (r.role === roleid) {
                resultMin = (r.ppdbudgetmin);
                resultMax = (r.ppdbudgetmax);
            }
        })

        return 'Min: ' + resultMin + ' Max: ' + resultMax;
    }
    const getBudgetPPDByName = (role: string) => {
        if (getCompanyRoles == null || getCompanyRoles.length == 0) {
            return 0;
        }
        let result = 0;
        getCompanyRoles.forEach(r => {
            if (r.role === role) {
                result = (r.ppdbudget);// * currentCensus[dow]);
            }
        })

        return result;
    }

    const getFirstColumnWidth = () => {
        if (compactMode) {
            return 200;
        }
        else {
            return 200;
        }
    }
    const getSecondColunWidth = () => {
        if (compactMode) {
            return 100;
        }
        else {
            return 100;
        }
    }

    const getFontSize = () => {
        if (compactMode) {
            return 11;
        }
        else {
            return 13;
        }
    }

    const getCalculatedPPDColor = (actual: string,role:string) => {
        let actualppd: number = Number.parseFloat(actual);
        console.log('actual ok ' + actual + ' and ' + getBudgetPPDMin(role) + ' role is ' + role);
        if (actualppd < getBudgetPPDMin(role)) {
            return 'red';
        }
        else if (actualppd > getBudgetPPDMax(role)) {
            return 'blue';
        }
        else {
            return 'green';
        }
    }

    const getBoxBorder = () => {
        return 1;
    }

    const getBoxShadow = () => {
        return 0;
    }

    const getCalcDelta = (dow: string, role: string) => {
        //census * budgetppd - total for day
        let index = 0;
        switch (dow.toUpperCase()) {
            case "SUNDAY":
                index = 0;
                break;
            case "MONDAY":
                index = 1;
                break;
            case "TUESDAY":
                index = 2;
                break;
            case "WEDNESDAY":
                index = 3;
                break;
            case "THURSDAY":
                index = 4;
                break;
            case "FRIDAY":
                index = 5;
                break;
            case "SATURDAY":
                index = 6;
                break;
        }

        let result = getTotalHoursForDOWAndRole(dow, role) - (currentCensus[index] * getBudgetPPD(role));
        return result.toFixed(2);
    }

    const getCalcDeltaTooltip = (dow: string, role: string) => {
        //census * budgetppd - total for day
        let index = 0;
        switch (dow.toUpperCase()) {
            case "SUNDAY":
                index = 0;
                break;
            case "MONDAY":
                index = 1;
                break;
            case "TUESDAY":
                index = 2;
                break;
            case "WEDNESDAY":
                index = 3;
                break;
            case "THURSDAY":
                index = 4;
                break;
            case "FRIDAY":
                index = 5;
                break;
            case "SATURDAY":
                index = 6;
                break;
        }

        let resultMin = getTotalHoursForDOWAndRole(dow, role) - (currentCensus[index] * getBudgetPPDMin(role));
        let resultMax = getTotalHoursForDOWAndRole(dow, role) - (currentCensus[index] * getBudgetPPDMax(role));
        return 'Min: ' + resultMin.toFixed(2) + ' Max: ' + resultMax.toFixed(2);
    }
    const getCalcPPD = (dow: string, role: string) => {
        let index = 0;
        switch (dow.toUpperCase()) {
            case "SUNDAY":
                index = 0;
                break;
            case "MONDAY":
                index = 1;
                break;
            case "TUESDAY":
                index = 2;
                break;
            case "WEDNESDAY":
                index = 3;
                break;
            case "THURSDAY":
                index = 4;
                break;
            case "FRIDAY":
                index = 5;
                break;
            case "SATURDAY":
                index = 6;
                break;
        }
        return (getTotalHoursForDOWAndRole(dow, role) / currentCensus[index]).toFixed(4);
    }

    const getBottomLineBGColor = (d1: Date, tod: string) => {

        let hours = new Date(d1).getHours();
        if (hours >= 0 && hours < 10) {
            if (tod === "Morning") {
                return 'primary.light';
            }
            else {
                return '';
            }
        }
        else if (hours >= 10 && hours < 18) {
            if (tod === 'Afternoon') {
                return 'primary.main';
            }
            else {
                return '';
            }
        }
        else {
            if (tod === 'Evening') {
                return 'primary.dark';
            }
            else {
                return '';
            }
        }
    }

    const clickShowAddNewUserToRotation = () => {
        setIsEditingRotation(false);
        setShowAddNewUserToRotation(true);
    }
    const onCloseAddNewUserToRotation = (value: boolean) => {
        setShowAddNewUserToRotation(false);
        if (value) {
            loadRotationDetails(selectedRotation);
        }
    }

    const onCloseChangeUserInRotation = (value: boolean) => {
        setShowChangeUserInRotation(false);
        if (value) {
            loadRotationDetails(selectedRotation);
        }
    }

    const clickAddOrEditShiftInRotation = (user: RotationDetailNew, dow: string, isediting: boolean, shift: { shiftid: number, shiftendsnextday: boolean, unitid: number, unitname: string, unitshortname: string, shiftstarttimestring: string, shiftendtimestring: string } | null) => {
        setIsEditingRotation(isediting);
        setSelectedName(user.name);
        setSelectedUser(user.username);
        setSelectedDOW(dow);
        setSelectedRole(user.role);
        setSelectedRoleid(user.roleid);
        if (isediting && shift != null) {
            setSelectedUnit(JSON.stringify({ unitid: shift.unitid, unitname: shift.unitname, unitshortname: shift.unitshortname }));
            setSelectedShift(JSON.stringify({ shiftid: shift.shiftid, shiftendsnextday: shift.shiftendsnextday }));
            setSelectedStartTime(convertTimeStringToDate(shift.shiftstarttimestring));
            setSelectedEndTime(convertTimeStringToDate(shift.shiftendtimestring));
        }
        else {
            setSelectedUnit('');
            setSelectedShift('');
            setSelectedStartTime(new Date());
            setSelectedEndTime(new Date());
        }
        setAddNewShiftToRotation(true);
    }

    const clickEditUserInRotation = (user: RotationDetailNew) => {
        setSelectedName(user.name);
        setSelectedUser(user.username);
        setSelectedRole(user.role);
        setSelectedRoleid(user.roleid);
        setShowChangeUserInRotation(true);
    }
    function convertTimeStringToDate(timeString: string): Date {
        const [time, meridiem] = timeString.split(/(?=[ap])/);
        const [hours, minutes] = time.split(':').map(Number);

        const date = new Date();
        date.setHours(0, 0, 0, 0); // Reset to start of day

        let adjustedHours = hours;

        if (meridiem === 'p' && hours !== 12) {
            adjustedHours += 12;
        } else if (meridiem === 'a' && hours === 12) {
            adjustedHours = 0;
        }

        date.setHours(adjustedHours, minutes);

        return date;
    }

    const handleClickDeleteShiftYes = () => {
        let cono = window.localStorage.getItem('cono');
        if (!cono) {
            return;
        }
        if (selectedUserShiftInRotation == null) {
            return;
        }
        // let rot = {
        //     cono: cono, roleid: selectedUserShiftInRotation.roleid, username: selectedUserShiftInRotation.username,
        //     rotationid: selectedUserShiftInRotation.rotationid, shiftid: selectedUserShiftInRotation.shiftid, dow: selectedDOW.toUpperCase()
        // };

        // removeShiftFromRotation(rot).then(() => {
        //     setAreYouSureDeleteShiftOpen(false);
        //     loadRotationDetails(selectedRotation);
        // })
    }
    const handleClickDeleteShiftNo = () => {
        setAreYouSureDeleteShiftOpen(false);
    }

    const onCloseAddNewRotationShift = (shoulddelete: boolean, shouldedit: boolean, shouldadd:boolean, newunitid: number, newunitname: string, newunitshortname: string, newshiftid: string, newshiftdatetime: Date, newshiftendtime: Date, partialshift:boolean, shiftendsnextday:boolean) => {
        setAddNewShiftToRotation(false);

        if (shoulddelete) {
            //delete rotation in memory
            //loadRotationDetails(selectedRotation);
            let shiftid = JSON.parse(selectedShift);
            getRotationDetails.forEach((rot) => {
                if (rot.username == selectedUser && rot.roleid == selectedRoleid) {
                    rot.shifts.forEach((shift, index) => {
                        if (shift.dow.toUpperCase() == selectedDOW.toUpperCase() && shift.shiftid == shiftid.shiftid) {
                            rot.shifts.splice(index, 1);
                        }
                    })
                }
            })
        }
        if (shouldedit) {
            console.log('should edit ' + newshiftendtime);
            let shiftid = JSON.parse(selectedShift);
            getRotationDetails.forEach((rot) => {
                if (rot.username == selectedUser && rot.roleid == selectedRoleid) {
                    rot.shifts.forEach((shift, index) => {
                        if (shift.dow.toUpperCase() == selectedDOW.toUpperCase() && shift.shiftid == shiftid.shiftid) {
                            shift.shiftstarttimestring = formatTime(newshiftdatetime);
                            shift.shiftendtimestring = formatTime(newshiftendtime);
                            shift.unitid = newunitid;
                            shift.unitname = newunitname;
                            shift.unitshortname = newunitshortname;
                        }
                    })
                }
            })
        }

        if(shouldadd){
            console.log("add shift to rotation in memory");
            getRotationDetails.forEach((rot) => {
                if (rot.username == selectedUser && rot.roleid == selectedRoleid) {
                    let newshift = {shiftid: Number.parseInt(newshiftid),dow:selectedDOW,shiftstarttimestring:formatTime(newshiftdatetime),shiftendtimestring:formatTime(newshiftendtime),
                                    partialshift:partialshift,descr:'',unitid:newunitid,unitname:newunitname,
                                    unitshortname:newunitshortname,seconddayofshift:false,shiftendsnextday:shiftendsnextday }
                    rot.shifts.push(newshift);
                }
            })
        }
    }

    const handleUpdateCensus = (index: number, newValue: number) => {
        setCurrentCensus((prev) => {
            const updated = [...prev];
            updated[index] = newValue;
            return updated;
        });
    }
    const clickShowFilterDialog = () => {
        setShowFilterDialog(true);
    }
    const hideFilterDialog = () => {
        setShowFilterDialog(false);
    }
    const handleOnClickSaveShifts = () => {
        putRotationShiftsInSchedule(getRotationDetails, nextSunday).then(() => {
            setOpenSuccess(true);
            setOpenAreYouSureSaveShiftsToSchedule(false);
        });
    }
    const handleOnClickSaveShiftsOpenAreYouSure = () => {
        setOpenAreYouSureSaveShiftsToSchedule(true);
    }
    const handleClickSaveShiftsNo = () => {
        setOpenAreYouSureSaveShiftsToSchedule(false);
    }
    const handleCloseCreateNewRotation = () => {
        setOpenAddNewRotationSetup(false);
    }
    const handleSelectedRotationChanged = (e: SelectChangeEvent<number>) => {
        if (+e.target.value > -1) {
            setSelectedRotation(+e.target.value);
            loadRotationDetails(+e.target.value);
        }
    }
    const getDateForDOW = (dow: number) => {
        //sends 0 for sunday, 1 for monday, 2 for tuesday etc..
        let newdate = new Date(nextSunday);
        return newdate.setDate(newdate.getDate() + dow);
    }
    function excludeRoles(shift: string) {
        if (getCompanyRoles == null) {
            return true;
        }
        else {
            for (const each of getCompanyRoles) {
                if (shift === each.role) {
                    return each.isSelected;
                }
            }
        }
    }
    function excludeRolesDetails(shift: RotationDetailNew) {
        if (getCompanyRoles == null) {
            return true;
        }
        else {
            for (const each of getCompanyRoles) {
                if (shift.roleid === each.roleid) {
                    return each.isSelected;
                }
            }
        }
    }
    function excludeUnits(shift: RotationDetailNew) {
        if (getCompanyRoles == null) {
            return true;
        }
        else {
            for (const each of getUnitSetups) {
                for (const s of shift.shifts) {
                    if (s.unitid === each.unitid) {
                        return each.isselected;
                    }

                    if (s.unitid === -1) {
                        return true;
                    }
                }
            }
        }
    }
    function excludeUnits2(shift: { shiftid: number, dow: string, unitid: number }) {
        if (getCompanyRoles == null) {
            return true;
        }
        else {
            for (const each of getUnitSetups) {
                if (shift.unitid === each.unitid) {
                    return each.isselected;
                }

                if (shift.unitid === -1) {
                    return true;
                }
            }
        }
    }
    useEffect(() => {
        document.title = 'Rotations';
        loadRotationSetups();
        loadScheduleWeeklyTotals(format(new Date(nextSunday), 'MMddyyyy'));
    }, []);

    const getUsersInRotation = () => {
        return getRotationDetails.map(user => user.username);
    }
    const getTotalHoursForUserAndRole = (username: string, roleid: number) => {
        let count: number = 0;
        getRotationDetails.forEach(d => {
            if (d.username == username && d.roleid == roleid) {
                d.shifts.forEach(s => {
                    // if (new Date(s.shiftstarttimestring).getFullYear() > 1920) {
                    count += CountHoursBetweenTimes(s.shiftstarttimestring, s.shiftendtimestring);
                    // }
                })
            }
        })
        return count;
    }

    function countHoursBetweenDates(startdate: Date, endDate: Date): number {
        console.log('try count ' + startdate);
        let timeDiff = 0;
        if (new Date(endDate).getHours() == 0) {
            let nextday = new Date(startdate);
            nextday.setHours(0, 0, 0, 0);
            nextday.setDate(nextday.getDate() + 1);
            timeDiff = nextday.getTime() - new Date(startdate).getTime();
        }
        else {
            timeDiff = new Date(endDate).getTime() - new Date(startdate).getTime();
        }
        const hoursDiff = timeDiff / (1000 * 60 * 60);
        return Math.abs(Math.round(hoursDiff));
    }
    const parseTime = (timeString: String) => {
        const period = timeString.slice(-1);
        let [hours, minutes] = timeString.slice(0, -1).split(':').map(Number);

        if (period === 'p' && hours !== 12) {
            hours += 12;
        } else if (period === 'a' && hours === 12) {
            hours = 0;
        }

        return hours + minutes / 60;
    };
    function CountHoursBetweenTimes(startTime: String, endTime: String): number {
        const start = parseTime(startTime);
        const end = parseTime(endTime);

        let difference = end - start;
        if (difference < 0) {
            difference += 24;
        }
        return difference;
    }
    function countHoursBetweenDatesForTimeOfDay(startdate: Date, endDate: Date, timeofday: string): number {
        let breakfastHours = 0;
        let lunchHours = 0;
        let dinnerHours = 0;

        let diffMillis = 0;
        if (new Date(endDate).getHours() == 0) {
            let nextday = new Date(startdate);
            nextday.setHours(0, 0, 0, 0);
            nextday.setDate(nextday.getDate() + 1);
            diffMillis = nextday.getTime() - new Date(startdate).getTime();
        }
        else {
            diffMillis = new Date(endDate).getTime() - new Date(startdate).getTime();
        }

        // Convert the difference to hours
        const totalHours = Math.floor(diffMillis / (1000 * 60 * 60));

        // Loop through each hour
        for (let i = 0; i < totalHours; i++) {
            const currentHour = new Date(startdate).getHours() + i;

            // Group the hours by meal
            if (currentHour < 9) {
                breakfastHours++;
            } else if (currentHour >= 9 && currentHour < 16) {
                lunchHours++;
            } else {
                dinnerHours++;
            }
        }

        // Return the grouped hours
        if (timeofday.toUpperCase() == "BREAKFAST") {
            return breakfastHours;
        }
        else if (timeofday.toUpperCase() == "LUNCH") {
            return lunchHours;
        }
        else {
            return dinnerHours;
        }
    }
    const getTotalHoursForDOWAndRole = (dow: string, role: string) => {
        let count: number = 0;
        getRotationDetails.forEach(d => {
            if (d.role == role) {
                d.shifts.forEach(s => {
                    if (s.dow.toUpperCase() === dow.toUpperCase()) {
                        count = count + CountHoursBetweenTimes(s.shiftstarttimestring, s.shiftendtimestring);
                    }
                })
            }
        })
        return count;
    }

    const getTotalHoursForDOWAndRoleByTimeOfDay = (dow: string, role: string) => {
        let countBreakfast: number = 0;
        let countLunch: number = 0;
        let countDinner: number = 0;
        getRotationDetails.forEach(d => {
            if (d.role == role) {
                // switch (dow) {
                //     case "SUNDAY":
                //         if (new Date(d.sundaystarttime).getFullYear() > 1920) {
                //             countBreakfast += countHoursBetweenDatesForTimeOfDay(d.sundaystarttime, d.sundayendtime, 'BREAKFAST');
                //             countLunch += countHoursBetweenDatesForTimeOfDay(d.sundaystarttime, d.sundayendtime, 'LUNCH');
                //             countDinner += countHoursBetweenDatesForTimeOfDay(d.sundaystarttime, d.sundayendtime, 'DINNER');
                //         }
                //         break;
                //     case "MONDAY":
                //         if (new Date(d.mondaystarttime).getFullYear() > 1920) {
                //             countBreakfast += countHoursBetweenDatesForTimeOfDay(d.mondaystarttime, d.mondayendtime, 'BREAKFAST');
                //             countLunch += countHoursBetweenDatesForTimeOfDay(d.mondaystarttime, d.mondayendtime, 'BREAKFAST');
                //             countDinner += countHoursBetweenDatesForTimeOfDay(d.mondaystarttime, d.mondayendtime, 'BREAKFAST');
                //         }
                //         break;
                //     case "TUESDAY":
                //         if (new Date(d.tuesdaystarttime).getFullYear() > 1920) {
                //             countBreakfast += countHoursBetweenDatesForTimeOfDay(d.tuesdaystarttime, d.tuesdayendtime, 'BREAKFAST');
                //             countLunch += countHoursBetweenDatesForTimeOfDay(d.tuesdaystarttime, d.tuesdayendtime, 'LUNCH');
                //             countDinner += countHoursBetweenDatesForTimeOfDay(d.tuesdaystarttime, d.tuesdayendtime, 'DINNER');
                //         }
                //         break;
                //     case "WEDNESDAY":
                //         if (new Date(d.wednesdaystarttime).getFullYear() > 1920) {
                //             countBreakfast += countHoursBetweenDatesForTimeOfDay(d.wednesdaystarttime, d.wednesdayendtime, 'BREAKFAST');
                //             countLunch += countHoursBetweenDatesForTimeOfDay(d.wednesdaystarttime, d.wednesdayendtime, 'LUNCH');
                //             countDinner += countHoursBetweenDatesForTimeOfDay(d.wednesdaystarttime, d.wednesdayendtime, 'DINNER');
                //         }
                //         break;
                //     case "THURSDAY":
                //         if (new Date(d.thursdaystarttime).getFullYear() > 1920) {
                //             countBreakfast += countHoursBetweenDatesForTimeOfDay(d.thursdaystarttime, d.thursdayendtime, 'BREAKFAST');
                //             countLunch += countHoursBetweenDatesForTimeOfDay(d.thursdaystarttime, d.thursdayendtime, 'LUNCH');
                //             countDinner += countHoursBetweenDatesForTimeOfDay(d.thursdaystarttime, d.thursdayendtime, 'DINNER');
                //         }
                //         break;
                //     case "FRIDAY":
                //         if (new Date(d.fridaystarttime).getFullYear() > 1920) {
                //             countBreakfast += countHoursBetweenDatesForTimeOfDay(d.fridaystarttime, d.fridayendtime, 'BREAKFAST');
                //             countLunch += countHoursBetweenDatesForTimeOfDay(d.fridaystarttime, d.fridayendtime, 'LUNCH');
                //             countDinner += countHoursBetweenDatesForTimeOfDay(d.fridaystarttime, d.fridayendtime, 'DINNER');
                //         }
                //         break;
                //     case "SATURDAY":
                //         if (new Date(d.saturdaystarttime).getFullYear() > 1920) {
                //             countBreakfast += countHoursBetweenDatesForTimeOfDay(d.saturdaystarttime, d.saturdayendtime, 'BREAKFAST');
                //             countLunch += countHoursBetweenDatesForTimeOfDay(d.saturdaystarttime, d.saturdayendtime, 'LUNCH');
                //             countDinner += countHoursBetweenDatesForTimeOfDay(d.saturdaystarttime, d.saturdayendtime, 'DINNER');
                //         }
                //         break;
                // }
            }
        })
        return '(' + countBreakfast + '/' + countLunch + '/' + countDinner + ')';
    }
    const isValidDates = (d: Date, d2: Date) => {
        if (new Date(d).getFullYear() > 1920 && new Date(d).getFullYear() > 1920) {
            return true;
        }
        else {
            return false;
        }
    }
    const formatDateField = (d: Date) => {
        if (new Date(d).getFullYear() < 1920) {
            return '';
        }
        else {
            return new Date(d).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
        }
    }
    const isSaveToScheduleButtonDisabled = () => {
        if (selectedRotation != null && selectedRotation > -1) {
            return false;
        }
        else {
            return true;
        }
    }

    const formatDateFieldCombined = (d: Date, d2: Date) => {
        if (new Date(d).getFullYear() < 1920) {
            return '';
        }
        else {
            return new Date(d).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' }) + '-' + new Date(d2).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
        }
    }

    const getUserShiftsForRow = (shifts: any, dow: string) => {
        // return (shifts.forEach((s: any) =>{
        //     <StyledTableCell align='center'>
        //     {s.dow}fds
        //     </StyledTableCell>
        // }))
        let result = '';
        for (let i = 0; i < shifts.length; i++) {
            if (shifts[i].dow.toUpperCase() === dow.toUpperCase()) {
                result += shifts[i].shiftstarttimestring + '-' + shifts[i].shiftendtimestring;
            }
        }
        return result;
    }

    const getDoesUserHaveShiftsThisDOW = (rot: RotationDetailNew, dow: string) => {
        let result = false;
        rot.shifts.map((shift, shiftindex) => {
            if (shift.dow.toUpperCase() === dow.toUpperCase()) {
                result = true;
            }
        })
        return result;
    }

    return (
        <>
            <Stack style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: 55, marginLeft: 3, marginRight: 3 }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Stack style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Typography sx={{ fontSize: 20, marginTop: 1, marginBottom: 1, fontWeight: 'bold' }}>Apply Rotation To Schedule</Typography>
                        {/* <Box sx={{ width: '100%' }}>
                        <LinearProgress />
                    </Box> */}
                        <Stack direction="row" style={{ display: 'flex', alignItems: 'end', justifyContent: 'center', marginBottom: 2, marginTop: 2 }}>
                            <Stack style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <FormControl>
                                    <InputLabel id="rotation-labelid">Rotation</InputLabel>
                                    <Select
                                        labelId="rotation-labelid"
                                        label="Rotation"
                                        name="rotationid"
                                        value={selectedRotation}
                                        onChange={handleSelectedRotationChanged}
                                        style={{ display: "block", height: '50', minWidth: 200 }}>
                                        {getRotationSetups.map((rot) =>
                                            <MenuItem key={rot.rotationid} value={rot.rotationid}>{rot.rotationdescr}</MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </Stack>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker value={dayjs(nextSunday)}
                                    sx={{ marginLeft: 1 }}
                                    label="Start Date (Sunday)" minDate={dayjs(new Date())}
                                    shouldDisableDate={isWeekend}
                                    onChange={(newValue) => {
                                        if (newValue === null) {
                                            return;
                                        }
                                        console.log('date change' + newValue.toDate());
                                        setNextSunday(new Date(newValue.toDate()));
                                        loadScheduleWeeklyTotals(format(new Date(newValue.toDate()), 'MMddyyyy'));
                                    }} />
                            </LocalizationProvider>
                            <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={openWeeklyTotal}>
                                <InfoIcon sx={{ fontSize: 18 }} color='primary' />
                            </IconButton>
                            <Button
                                sx={{ marginLeft: 1, height: 55, borderRadius: 10 }}
                                variant="contained"
                                color="warning"
                                disabled={isSaveToScheduleButtonDisabled()}
                                onClick={() => handleOnClickSaveShiftsOpenAreYouSure()}
                            >
                                SAVE TO SCHEDULE
                            </Button>
                            {/* <Tooltip title="Filter Results" sx={{ marginLeft: 2 }}>
                                <IconButton
                                    aria-label="expand row"
                                    size="small"
                                    onClick={clickShowFilterDialog}>
                                    <FilterListIcon fontSize='large' />
                                </IconButton>
                            </Tooltip> */}
                        </Stack>
                    </Stack>
                </div>
                {selectedRotation > -1 ?
                    <>
                        <TableContainer component={Paper} sx={{ margin: 1, maxHeight: 440, display: 'block' }}>
                            <Table stickyHeader={true} sx={{ minWidth: 300 }} size='small' aria-label="simple table">
                                <TableHead>
                                    <StyledTableRow key="100">
                                        <StyledTableCell width={getFirstColumnWidth()} align="center">USER</StyledTableCell>
                                        <StyledTableCell width={getSecondColunWidth()} align="center">ROLE</StyledTableCell>
                                        <StyledTableCell align="center" width="12%">
                                            <Stack>
                                                <Typography fontSize={14}>
                                                    SUNDAY
                                                </Typography>
                                                <Typography fontSize={14}>
                                                    ({format(getDateForDOW(0), 'MM/dd/yy')})
                                                </Typography>
                                            </Stack>
                                        </StyledTableCell>
                                        <StyledTableCell align="center" width="12%">
                                            <Stack>
                                                <Typography fontSize={14}>
                                                    MONDAY
                                                </Typography>
                                                <Typography fontSize={14}>
                                                    ({format(getDateForDOW(1), 'MM/dd/yy')})
                                                </Typography>
                                            </Stack>
                                        </StyledTableCell>
                                        <StyledTableCell align="center" width="12%">
                                            <Stack>
                                                <Typography fontSize={14}>
                                                    TUESDAY
                                                </Typography>
                                                <Typography fontSize={14}>
                                                    ({format(getDateForDOW(2), 'MM/dd/yy')})
                                                </Typography>
                                            </Stack>
                                        </StyledTableCell>
                                        <StyledTableCell align="center" width="12%">
                                            <Stack>
                                                <Typography fontSize={14}>
                                                    WEDNESDAY
                                                </Typography>
                                                <Typography fontSize={14}>
                                                    ({format(getDateForDOW(3), 'MM/dd/yy')})
                                                </Typography>
                                            </Stack>
                                        </StyledTableCell>
                                        <StyledTableCell align="center" width="12%">
                                            <Stack>
                                                <Typography fontSize={14}>
                                                    THURSDAY
                                                </Typography>
                                                <Typography fontSize={14}>
                                                    ({format(getDateForDOW(4), 'MM/dd/yy')})
                                                </Typography>
                                            </Stack>
                                        </StyledTableCell>
                                        <StyledTableCell align="center" width="12%">
                                            <Stack>
                                                <Typography fontSize={14}>
                                                    FRIDAY
                                                </Typography>
                                                <Typography fontSize={14}>
                                                    ({format(getDateForDOW(5), 'MM/dd/yy')})
                                                </Typography>
                                            </Stack>
                                        </StyledTableCell>
                                        <StyledTableCell align="center" width="12%">
                                            <Stack>
                                                <Typography fontSize={14}>
                                                    SATURDAY
                                                </Typography>
                                                <Typography fontSize={14}>
                                                    ({format(getDateForDOW(6), 'MM/dd/yy')})
                                                </Typography>
                                            </Stack>
                                        </StyledTableCell>
                                        <StyledTableCell width={70} align="center">TOTAL</StyledTableCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {selectedRotation > -1 && getRotationDetails.filter(excludeRolesDetails).filter(excludeUnits).map((user, index) => (
                                        <StyledTableRow key={index}>
                                            <StyledTableCell align='center'>
                                                <Stack direction={'row'} sx={{ alignContent: 'center', alignItems: 'center' }}>
                                                    <Typography sx={{ fontSize: getFontSize(), textAlign: 'start' }}>
                                                        {user.name}({user.username})
                                                    </Typography>
                                                    <IconButton
                                                        aria-label="expand row"
                                                        size="small"
                                                        sx={{ alignSelf: 'end' }}
                                                        onClick={() => clickEditUserInRotation(user)}
                                                    >
                                                        <EditIcon sx={{ fontSize: 14 }} color='warning' />
                                                    </IconButton>
                                                </Stack>
                                            </StyledTableCell>
                                            <StyledTableCell align='center'>
                                                <Typography sx={{ fontSize: getFontSize() }}>
                                                    {user.role}
                                                </Typography>
                                            </StyledTableCell>

                                            <>
                                                {Array.from(daysofweek).map((day, dayindex) => (
                                                    getDoesUserHaveShiftsThisDOW(user, day) === true ?
                                                        <>
                                                            <StyledTableCell width="12%">
                                                                <Stack sx={{ alignContent: 'center', alignItems: 'center' }}>
                                                                    {user.shifts.filter(excludeUnits2).map((shift, shiftindex) => (
                                                                        shift.dow.toUpperCase() === day.toUpperCase() ?
                                                                            <Box sx={{ width: 130 }} boxShadow={getBoxShadow()} border={0} borderRadius={1} borderColor={'#e6e6e6'}>
                                                                                <Grid container>
                                                                                    <Grid xs={12} item display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                                                                        <Stack direction={'row'}>
                                                                                            {shift.unitid >= 0 ?
                                                                                                <Tooltip title={shift.unitname}>
                                                                                                    <Typography sx={{ fontSize: getFontSize(), fontWeight: 'bold', color: '#979797' }}>
                                                                                                        ({shift.unitshortname})
                                                                                                    </Typography>
                                                                                                </Tooltip>
                                                                                                : <></>}
                                                                                            <Typography sx={{ fontSize: getFontSize() }}>
                                                                                                {shift.shiftstarttimestring + '-' + shift.shiftendtimestring}
                                                                                            </Typography>
                                                                                            {!shift.seconddayofshift ?
                                                                                                <IconButton
                                                                                                    aria-label="expand row"
                                                                                                    size="small"
                                                                                                    sx={{ alignSelf: 'end' }}
                                                                                                    onClick={() => clickAddOrEditShiftInRotation(user, day, true, shift)}
                                                                                                >
                                                                                                    <EditIcon sx={{ fontSize: 11 }} color='warning' />
                                                                                                </IconButton>
                                                                                                : <></>}
                                                                                        </Stack>
                                                                                    </Grid>
                                                                                    {/* <Grid item xs={4}>
                                                                                        <Box height={1.5} bgcolor={getBottomLineBGColor(convertTimeStringToDate(shift.shiftstarttimestring), 'Morning')}></Box>
                                                                                    </Grid>
                                                                                    <Grid item xs={4}>
                                                                                        <Box height={1.5} bgcolor={getBottomLineBGColor(convertTimeStringToDate(shift.shiftstarttimestring), 'Afternoon')}></Box>
                                                                                    </Grid>
                                                                                    <Grid item xs={4}>
                                                                                        <Box height={1.5} bgcolor={getBottomLineBGColor(convertTimeStringToDate(shift.shiftstarttimestring), 'Evening')}></Box>
                                                                                    </Grid> */}
                                                                                </Grid>
                                                                            </Box>
                                                                            :
                                                                            <></>
                                                                    ))}
                                                                </Stack>
                                                            </StyledTableCell>
                                                        </>
                                                        :
                                                        <>
                                                            <StyledTableCell align="center">
                                                                <Box sx={{ alignContent: 'center', alignItems: 'center', alignSelf: 'center' }}>
                                                                    <IconButton
                                                                        aria-label="expand row"
                                                                        size="small"
                                                                        onClick={() => clickAddOrEditShiftInRotation(user, day, false, null)}>
                                                                        <AddCircleIcon fontSize='small' color='success' />
                                                                    </IconButton></Box></StyledTableCell></>

                                                ))}
                                            </>
                                            <StyledTableCell align='center'>
                                                <ColoredTypography fontSize={getFontSize()} value={getTotalHoursForUserAndRole(user.username, user.roleid)} />
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))}

                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Typography fontSize={20} fontWeight={'bold'}>Totals</Typography>
                            <Box sx={{ alignSelf: 'start' }}>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox checked={compactMode} onChange={e => { setCompactMode(e.target.checked) }} />} label="Compact" />
                                </FormGroup>
                            </Box>
                        </Box>
                        <TableContainer component={Paper} sx={{ margin: 1 }}>
                            <Table sx={{ minWidth: 300 }} size='small' aria-label="simple table">
                                <TableHead>
                                    <StyledTableRow key="100">
                                        <StyledTableCell width={getFirstColumnWidth()} align="center"></StyledTableCell>
                                        <StyledTableCell width={getSecondColunWidth()} align="center">ROLE</StyledTableCell>
                                        <StyledTableCell align="center" width="12%">
                                            <Typography fontSize={14}>
                                                SUNDAY
                                            </Typography>
                                            <Typography fontSize={14}>
                                                ({format(getDateForDOW(0), 'MM/dd/yy')})
                                            </Typography>
                                        </StyledTableCell>
                                        <StyledTableCell align="center" width="12%">
                                            <Typography fontSize={14}>
                                                MONDAY
                                            </Typography>
                                            <Typography fontSize={14}>
                                                ({format(getDateForDOW(1), 'MM/dd/yy')})
                                            </Typography>
                                        </StyledTableCell>
                                        <StyledTableCell align="center" width="12%">
                                            <Typography fontSize={14}>
                                                TUESDAY
                                            </Typography>
                                            <Typography fontSize={14}>
                                                ({format(getDateForDOW(2), 'MM/dd/yy')})
                                            </Typography>
                                        </StyledTableCell>
                                        <StyledTableCell align="center" width="12%">
                                            <Typography fontSize={14}>
                                                WEDNESDAY
                                            </Typography>
                                            <Typography fontSize={14}>
                                                ({format(getDateForDOW(3), 'MM/dd/yy')})
                                            </Typography>
                                        </StyledTableCell>
                                        <StyledTableCell align="center" width="12%">
                                            <Typography fontSize={14}>
                                                THURSDAY
                                            </Typography>
                                            <Typography fontSize={14}>
                                                ({format(getDateForDOW(4), 'MM/dd/yy')})
                                            </Typography>
                                        </StyledTableCell>
                                        <StyledTableCell align="center" width="12%">
                                            <Typography fontSize={14}>
                                                FRIDAY
                                            </Typography>
                                            <Typography fontSize={14}>
                                                ({format(getDateForDOW(5), 'MM/dd/yy')})
                                            </Typography>
                                        </StyledTableCell>
                                        <StyledTableCell align="center" width="12%">
                                            <Typography fontSize={14}>
                                                SATURDAY
                                            </Typography>
                                            <Typography fontSize={14}>
                                                ({format(getDateForDOW(6), 'MM/dd/yy')})
                                            </Typography>
                                        </StyledTableCell>
                                        <StyledTableCell width={80} align="center"></StyledTableCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    <StyledTableRow key="200">
                                        <StyledTableCell align="center" width={getFirstColumnWidth()}></StyledTableCell>
                                        <StyledTableCell align="center" width={getSecondColunWidth()}></StyledTableCell>
                                        <StyledTableCell align="center" width="12%">
                                            <TextField value={currentCensus[0]} size='small'
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                    handleUpdateCensus(0, Number(event.target.value));
                                                }}
                                                sx={{ minWidth: 60, alignSelf: 'center', alignContent: 'center', alignItems: 'center', marginLeft: 3, marginRight: 3 }} id="outlined-basic" label="Census" variant="outlined">

                                            </TextField>
                                        </StyledTableCell>
                                        <StyledTableCell align="center" width="12%">
                                            <TextField value={currentCensus[1]} size='small'
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                    handleUpdateCensus(1, Number(event.target.value));
                                                }}
                                                sx={{ minWidth: 60, alignSelf: 'center', alignContent: 'center', alignItems: 'center', marginLeft: 3, marginRight: 3 }} id="outlined-basic" label="Census" variant="outlined">

                                            </TextField>
                                        </StyledTableCell>
                                        <StyledTableCell align="center" width="12%">
                                            <TextField value={currentCensus[2]} size='small'
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                    handleUpdateCensus(2, Number(event.target.value));
                                                }}
                                                sx={{ minWidth: 60, alignSelf: 'center', alignContent: 'center', alignItems: 'center', marginLeft: 3, marginRight: 3 }} id="outlined-basic" label="Census" variant="outlined">

                                            </TextField>
                                        </StyledTableCell>
                                        <StyledTableCell align="center" width="12%">
                                            <TextField value={currentCensus[3]} size='small'
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                    handleUpdateCensus(3, Number(event.target.value));
                                                }}
                                                sx={{ minWidth: 60, alignSelf: 'center', alignContent: 'center', alignItems: 'center', marginLeft: 3, marginRight: 3 }} id="outlined-basic" label="Census" variant="outlined">

                                            </TextField>
                                        </StyledTableCell>
                                        <StyledTableCell align="center" width="12%">
                                            <TextField value={currentCensus[4]} size='small'
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                    handleUpdateCensus(4, Number(event.target.value));
                                                }}
                                                sx={{ minWidth: 60, alignSelf: 'center', alignContent: 'center', alignItems: 'center', marginLeft: 3, marginRight: 3 }} id="outlined-basic" label="Census" variant="outlined">

                                            </TextField>
                                        </StyledTableCell>
                                        <StyledTableCell align="center" width="12%">
                                            <TextField value={currentCensus[5]} size='small'
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                    handleUpdateCensus(5, Number(event.target.value));
                                                }}
                                                sx={{ minWidth: 60, alignSelf: 'center', alignContent: 'center', alignItems: 'center', marginLeft: 3, marginRight: 3 }} id="outlined-basic" label="Census" variant="outlined">

                                            </TextField>
                                        </StyledTableCell>
                                        <StyledTableCell align="center" width="12%">
                                            <TextField value={currentCensus[6]} size='small'
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                    handleUpdateCensus(6, Number(event.target.value));
                                                }}
                                                sx={{ minWidth: 60, alignSelf: 'center', alignContent: 'center', alignItems: 'center', marginLeft: 3, marginRight: 3 }} id="outlined-basic" label="Census" variant="outlined">

                                            </TextField>
                                        </StyledTableCell>
                                        <StyledTableCell width={80} align='center'>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    {selectedRotation > -1 && getRotationRoles.filter(excludeRoles).map((role, index) => (
                                        <>
                                            <StyledTableRow>
                                                <StyledTableCell key={-1} align='center' sx={{ fontWeight: 'bold' }}>
                                                    <Typography sx={{ fontSize: getFontSize() }}>
                                                        Actual Hours
                                                    </Typography>
                                                </StyledTableCell>
                                                <StyledTableCell key={-2} align='center' sx={{ fontWeight: 'bold' }}>
                                                    <Typography sx={{ fontSize: getFontSize() }}>
                                                        {role}
                                                    </Typography>
                                                </StyledTableCell>
                                                <StyledTableCell key={-3} align='center' sx={{ fontWeight: 'bold' }}>
                                                    <Typography sx={{ fontSize: getFontSize() }}>
                                                        {getTotalHoursForDOWAndRole('SUNDAY', role)}
                                                    </Typography>
                                                </StyledTableCell>
                                                <StyledTableCell key={-4} align='center' sx={{ fontWeight: 'bold' }}>
                                                    <Typography sx={{ fontSize: getFontSize() }}>
                                                        {getTotalHoursForDOWAndRole('MONDAY', role)}
                                                    </Typography>
                                                </StyledTableCell>
                                                <StyledTableCell key={-5} align='center' sx={{ fontWeight: 'bold' }}>
                                                    <Typography sx={{ fontSize: getFontSize() }}>
                                                        {getTotalHoursForDOWAndRole('TUESDAY', role)}
                                                    </Typography>
                                                </StyledTableCell>
                                                <StyledTableCell key={-6} align='center' sx={{ fontWeight: 'bold' }}>
                                                    <Typography sx={{ fontSize: getFontSize() }}>
                                                        {getTotalHoursForDOWAndRole('WEDNESDAY', role)}
                                                    </Typography>
                                                </StyledTableCell>
                                                <StyledTableCell key={-7} align='center' sx={{ fontWeight: 'bold' }}>
                                                    <Typography sx={{ fontSize: getFontSize() }}>
                                                        {getTotalHoursForDOWAndRole('THURSDAY', role)}
                                                    </Typography>
                                                </StyledTableCell>
                                                <StyledTableCell key={-8} align='center' sx={{ fontWeight: 'bold' }}>
                                                    <Typography sx={{ fontSize: getFontSize() }}>
                                                        {getTotalHoursForDOWAndRole('FRIDAY', role)}
                                                    </Typography>
                                                </StyledTableCell>
                                                <StyledTableCell key={-9} align='center' sx={{ fontWeight: 'bold' }}>
                                                    <Typography sx={{ fontSize: getFontSize() }}>
                                                        {getTotalHoursForDOWAndRole('SATURDAY', role)}
                                                    </Typography>
                                                </StyledTableCell>
                                                <StyledTableCell align='center'>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                            {!compactMode ?
                                                <>
                                                    {/* <StyledTableRow>
                                                        <StyledTableCell key={-1} align='center'>
                                                            <Typography sx={{ fontSize: getFontSize() }}>
                                                                Time Of Day Hours
                                                            </Typography>
                                                        </StyledTableCell>
                                                        <StyledTableCell key={-2} align='center'>
                                                            <Typography sx={{ fontSize: getFontSize() }}>
                                                                {role}
                                                            </Typography>
                                                        </StyledTableCell>
                                                        <StyledTableCell key={-3} align='center'>
                                                            <Typography sx={{ fontSize: getFontSize() }}>
                                                                {getTotalHoursForDOWAndRoleByTimeOfDay('SUNDAY', role)}
                                                            </Typography>
                                                        </StyledTableCell>
                                                        <StyledTableCell key={-4} align='center'>
                                                            <Typography sx={{ fontSize: getFontSize() }}>
                                                                {getTotalHoursForDOWAndRoleByTimeOfDay('MONDAY', role)}
                                                            </Typography>
                                                        </StyledTableCell>
                                                        <StyledTableCell key={-5} align='center'>
                                                            <Typography sx={{ fontSize: getFontSize() }}>
                                                                {getTotalHoursForDOWAndRoleByTimeOfDay('TUESDAY', role)}
                                                            </Typography>
                                                        </StyledTableCell>
                                                        <StyledTableCell key={-6} align='center'>
                                                            <Typography sx={{ fontSize: getFontSize() }}>
                                                                {getTotalHoursForDOWAndRoleByTimeOfDay('WEDNESDAY', role)}
                                                            </Typography>
                                                        </StyledTableCell>
                                                        <StyledTableCell key={-7} align='center'>
                                                            <Typography sx={{ fontSize: getFontSize() }}>
                                                                {getTotalHoursForDOWAndRoleByTimeOfDay('THURSDAY', role)}
                                                            </Typography>
                                                        </StyledTableCell>
                                                        <StyledTableCell key={-8} align='center'>
                                                            <Typography sx={{ fontSize: getFontSize() }}>
                                                                {getTotalHoursForDOWAndRoleByTimeOfDay('FRIDAY', role)}
                                                            </Typography>
                                                        </StyledTableCell>
                                                        <StyledTableCell key={-9} align='center'>
                                                            <Typography sx={{ fontSize: getFontSize() }}>
                                                                {getTotalHoursForDOWAndRoleByTimeOfDay('SATURDAY', role)}
                                                            </Typography>
                                                        </StyledTableCell>
                                                        <StyledTableCell key={-10} align='center'>
                                                        </StyledTableCell>
                                                    </StyledTableRow> */}
                                                    <StyledTableRow>
                                                        <StyledTableCell align='center'>
                                                            <Typography sx={{ fontSize: getFontSize() }}>
                                                                Actual PPD
                                                            </Typography>
                                                        </StyledTableCell>
                                                        <StyledTableCell align='center'>
                                                            <Typography sx={{ fontSize: getFontSize() }}>
                                                                {role}
                                                            </Typography>
                                                        </StyledTableCell>
                                                        <StyledTableCell align='center'>
                                                            <Typography sx={{ fontSize: getFontSize() }}>
                                                                {getCalcPPD('SUNDAY', role)}
                                                            </Typography>
                                                        </StyledTableCell>
                                                        <StyledTableCell align='center'>
                                                            <Typography sx={{ fontSize: getFontSize() }}>
                                                                {getCalcPPD('MONDAY', role)}
                                                            </Typography>
                                                        </StyledTableCell>
                                                        <StyledTableCell align='center'>
                                                            <Typography sx={{ fontSize: getFontSize() }}>
                                                                {getCalcPPD('TUESDAY', role)}
                                                            </Typography>
                                                        </StyledTableCell>
                                                        <StyledTableCell align='center'>
                                                            <Typography sx={{ fontSize: getFontSize() }}>
                                                                {getCalcPPD('WEDNESDAY', role)}
                                                            </Typography>
                                                        </StyledTableCell>
                                                        <StyledTableCell align='center'>
                                                            <Typography sx={{ fontSize: getFontSize() }}>
                                                                {getCalcPPD('THURSDAY', role)}
                                                            </Typography>
                                                        </StyledTableCell>
                                                        <StyledTableCell align='center'>
                                                            <Typography sx={{ fontSize: getFontSize() }}>
                                                                {getCalcPPD('FRIDAY', role)}
                                                            </Typography>
                                                        </StyledTableCell>
                                                        <StyledTableCell align='center'>
                                                            <Typography sx={{ fontSize: getFontSize() }}>
                                                                {getCalcPPD('SATURDAY', role)}
                                                            </Typography>
                                                        </StyledTableCell>
                                                        <StyledTableCell align='center'>
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                    <StyledTableRow>
                                                        <StyledTableCell align='center'>
                                                            <Typography sx={{ fontSize: getFontSize() }}>
                                                                Budget PPD
                                                            </Typography>
                                                        </StyledTableCell>
                                                        <StyledTableCell align='center'>
                                                            <Typography sx={{ fontSize: getFontSize() }}>
                                                                {role}
                                                            </Typography>
                                                        </StyledTableCell>
                                                        <StyledTableCell align='center'>
                                                            {/* <Tooltip title={getBudgetPPDToolTip(role)}> */}
                                                                <Typography sx={{ fontSize: getFontSize() }}>
                                                                    {getBudgetPPD(role)}
                                                                </Typography>
                                                            {/* </Tooltip> */}
                                                        </StyledTableCell>
                                                        <StyledTableCell align='center'>
                                                            {/* <Tooltip title={getBudgetPPDToolTip(role)}> */}
                                                                <Typography sx={{ fontSize: getFontSize() }}>
                                                                    {getBudgetPPD(role)}
                                                                </Typography>
                                                            {/* </Tooltip> */}
                                                        </StyledTableCell>
                                                        <StyledTableCell align='center'>
                                                            {/* <Tooltip title={getBudgetPPDToolTip(role)}> */}
                                                                <Typography sx={{ fontSize: getFontSize() }}>
                                                                    {getBudgetPPD(role)}
                                                                </Typography>
                                                            {/* </Tooltip> */}
                                                        </StyledTableCell>
                                                        <StyledTableCell align='center'>
                                                            {/* <Tooltip title={getBudgetPPDToolTip(role)}> */}
                                                                <Typography sx={{ fontSize: getFontSize() }}>
                                                                    {getBudgetPPD(role)}
                                                                </Typography>
                                                            {/* </Tooltip> */}
                                                        </StyledTableCell>
                                                        <StyledTableCell align='center'>
                                                            {/* <Tooltip title={getBudgetPPDToolTip(role)}> */}
                                                                <Typography sx={{ fontSize: getFontSize() }}>
                                                                    {getBudgetPPD(role)}
                                                                </Typography>
                                                            {/* </Tooltip> */}
                                                        </StyledTableCell>
                                                        <StyledTableCell align='center'>
                                                            {/* <Tooltip title={getBudgetPPDToolTip(role)}> */}
                                                                <Typography sx={{ fontSize: getFontSize() }}>
                                                                    {getBudgetPPD(role)}
                                                                </Typography>
                                                            {/* </Tooltip> */}
                                                        </StyledTableCell>
                                                        <StyledTableCell align='center'>
                                                            {/* <Tooltip title={getBudgetPPDToolTip(role)}> */}
                                                                <Typography sx={{ fontSize: getFontSize() }}>
                                                                    {getBudgetPPD(role)}
                                                                </Typography>
                                                            {/* </Tooltip> */}
                                                        </StyledTableCell>
                                                        <StyledTableCell align='center'>
                                                        </StyledTableCell>
                                                    </StyledTableRow></>
                                                : <></>}
                                            <StyledTableRow>
                                                <StyledTableCell align='center'>
                                                    <Typography sx={{ fontSize: getFontSize() }}>
                                                        Delta(hours)
                                                    </Typography>
                                                </StyledTableCell>
                                                <StyledTableCell align='center'>
                                                    <Typography sx={{ fontSize: getFontSize() }}>
                                                        {role}
                                                    </Typography>
                                                </StyledTableCell>
                                                <StyledTableCell align='center'>
                                                    {/* <Tooltip title={getCalcDeltaTooltip('SUNDAY', role)}> */}
                                                        <Typography sx={{ fontSize: getFontSize(), color: getCalculatedPPDColor(getCalcPPD('SUNDAY', role),role), fontWeight: 'bold' }}>
                                                            {getCalcDelta('SUNDAY', role)}
                                                        </Typography>
                                                    {/* </Tooltip> */}
                                                </StyledTableCell>
                                                <StyledTableCell align='center'>
                                                    <Typography sx={{ fontSize: getFontSize(), color: getCalculatedPPDColor(getCalcPPD('MONDAY', role),role), fontWeight: 'bold' }}>
                                                        {getCalcDelta('MONDAY', role)}
                                                    </Typography>
                                                </StyledTableCell>
                                                <StyledTableCell align='center'>
                                                    <Typography sx={{ fontSize: getFontSize(), color: getCalculatedPPDColor(getCalcPPD('TUESDAY', role),role), fontWeight: 'bold' }}>
                                                        {getCalcDelta('TUESDAY', role)}
                                                    </Typography>
                                                </StyledTableCell>
                                                <StyledTableCell align='center'>
                                                    <Typography sx={{ fontSize: getFontSize(), color: getCalculatedPPDColor(getCalcPPD('WEDNESDAY', role),role), fontWeight: 'bold' }}>
                                                        {getCalcDelta('WEDNESDAY', role)}
                                                    </Typography>
                                                </StyledTableCell>
                                                <StyledTableCell align='center'>
                                                    <Typography sx={{ fontSize: getFontSize(), color: getCalculatedPPDColor(getCalcPPD('THURSDAY', role),role), fontWeight: 'bold' }}>
                                                        {getCalcDelta('THURSDAY', role)}
                                                    </Typography>
                                                </StyledTableCell>
                                                <StyledTableCell align='center'>
                                                    <Typography sx={{ fontSize: getFontSize(), color: getCalculatedPPDColor(getCalcPPD('FRIDAY', role),role), fontWeight: 'bold' }}>
                                                        {getCalcDelta('FRIDAY', role)}
                                                    </Typography>
                                                </StyledTableCell>
                                                <StyledTableCell align='center'>
                                                    <Typography sx={{ fontSize: getFontSize(), color: getCalculatedPPDColor(getCalcPPD('SATURDAY', role),role), fontWeight: 'bold' }}>
                                                        {getCalcDelta('SATURDAY', role)}
                                                    </Typography>
                                                </StyledTableCell>
                                                <StyledTableCell align='center'>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                            <StyledTableRow>
                                                <StyledTableCell></StyledTableCell>
                                                <StyledTableCell></StyledTableCell>
                                                <StyledTableCell></StyledTableCell>
                                                <StyledTableCell></StyledTableCell>
                                                <StyledTableCell></StyledTableCell>
                                                <StyledTableCell></StyledTableCell>
                                                <StyledTableCell></StyledTableCell>
                                                <StyledTableCell></StyledTableCell>
                                                <StyledTableCell></StyledTableCell>
                                                <StyledTableCell align='center'>
                                                </StyledTableCell>
                                            </StyledTableRow></>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                    :
                    <></>}
                {/* <Button
                    sx={{ marginLeft: 1, height: 55, borderRadius: 5 }}
                    variant="contained"
                    color="primary"
                    onClick={() => handleOnClickNewRotation()}>
                    ADD NEW SHIFT
                </Button> */}
            </Stack >
            {selectedRotation > -1 ?
                <Tooltip title='Add User(s) To Rotation'>
                    <Fab color="primary" aria-label="add" sx={style} onClick={() => { clickShowAddNewUserToRotation() }}>
                        <AddIcon />
                    </Fab>
                </Tooltip>
                :
                <></>
            }
            <CreateNewRotation
                open={openAddNewRotationSetup}
                onClose={handleCloseCreateNewRotation} />
            <RotationFilterDialog
                open={showFilterDialog}
                onClose={hideFilterDialog}
            />
            <AddOrEditRotationShiftInMemory
                open={showAddNewShiftToRotation}
                name={selectedName}
                username={selectedUser}
                dow={selectedDOW}
                roleid={selectedRoleid}
                role={selectedRole}
                rotationid={selectedRotation}
                onClose={onCloseAddNewRotationShift}
                isEditing={isEditingRotation}
                isAddingNew={isAddingNewShiftInRotation}
                unitid={selectedUnit}
                shiftid={selectedShift}
                shiftstarttime={selectedStartTime}
                shiftendtime={selectedEndTime}
            />
            <ChangeUserInRotationDialog
                open={showChangeUserInRotation}
                onClose={onCloseChangeUserInRotation}
                rotationid={selectedRotation}
                rolename={selectedRole}
                oldusername={selectedUser}
                roleid={selectedRoleid}
                usersinrotation={getUsersInRotation()}
            />
            <AddUserToRotation
                open={showAddNewUserToRotation}
                onClose={onCloseAddNewUserToRotation}
                rotationid={selectedRotation}
                savetodb={false}
            />
            <Dialog
                open={areYouSureDeleteShiftOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Are You Sure?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to remove this shift?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' onClick={handleClickDeleteShiftNo}>No</Button>
                    <LoadingButton loading={loading} variant='contained' onClick={handleClickDeleteShiftYes} autoFocus>
                        Yes
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openAreYouSureSaveShiftsToSchedule}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    {"Are You Sure?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want add these shifts to the schedule for the selected week?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' onClick={handleClickSaveShiftsNo}>No</Button>
                    <LoadingButton loading={loading} variant='contained' onClick={handleOnClickSaveShifts} autoFocus>
                        Yes
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <Dialog open={openWeeklyTotalDialog} onClose={closeWeeklyTotal}>
      <DialogContent className="sm:max-w-[425px]">
          <DialogTitle>Currently Scheduled Totals</DialogTitle>
        <Table>
            <TableRow>
              <TableCell sx={{fontWeight:'bold'}}>Role</TableCell>
              <TableCell sx={{fontWeight:'bold'}}>Hours</TableCell>
            </TableRow>
          <TableBody>
            {getScheduleWeeklyTotals.length > 0 ?
            getScheduleWeeklyTotals.map((total) => (
              <TableRow key={total.role}>
                <TableCell>{total.role}</TableCell>
                <TableCell>{total.count}</TableCell>
              </TableRow>
            ))
            :
            <>No Scheduled Hours</>}
          </TableBody>
        </Table>
      </DialogContent>
    </Dialog>
            <Snackbar open={openSuccess} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={5000} onClose={handleCloseSnack}>
                <Alert onClose={handleCloseSnack} severity="success" variant="filled" sx={{ fontSize: 24 }}>
                    Successfully Added Shifts To The Schedule
                </Alert>
            </Snackbar>
        </>
    );
})