export interface IUserLoginModel{
    Email: string;
    Password: string;
    Location: string;
}

class UserLoginModel implements IUserLoginModel{
    Email: string = "";
    Password: string = "";
    Location: string = "";
}

export default UserLoginModel;